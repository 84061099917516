import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Table,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import * as Utils from "../Utils";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { setBreadcrumbItems, profileSuccess } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { post, put, uploadWithTokan } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import MyPrompt from 'components/MyPrompt';
import DeleteModal from 'pages/Admin/DeleteModal';
import Loader from 'components/Loader';
import ExcelFile from '../../assets/excel/samplecash.xlsx'
import { FileUploader } from "react-drag-drop-files";

const Index = props => {

    const { i18n, t } = useTranslation();
    const years = Utils.getYears();
    const [operatingCashFlow1, setOperatingCashFlow1] = useState(0);
    const [operatingCashFlow2, setOperatingCashFlow2] = useState(0);
    const [operatingCashFlow3, setOperatingCashFlow3] = useState(0);
    const [changeinWorkingCapitalRequirement1, setChangeinWorkingCapitalRequirement1] = useState(0);
    const [changeinWorkingCapitalRequirement2, setChangeinWorkingCapitalRequirement2] = useState(0);
    const [changeinWorkingCapitalRequirement3, setChangeinWorkingCapitalRequirement3] = useState(0);
    const [investingCash1, setInvestingCash1] = useState(0);
    const [investingCash2, setInvestingCash2] = useState(0);
    const [investingCash3, setInvestingCash3] = useState(0);

    const [financingEquity1, setFinancingEquity1] = useState(0);
    const [financingEquity2, setFinancingEquity2] = useState(0);
    const [financingEquity3, setFinancingEquity3] = useState(0);
    const [financingDebt1, setFinancingDebt1] = useState(0);
    const [financingDebt2, setFinancingDebt2] = useState(0);
    const [financingDebt3, setFinancingDebt3] = useState(0);
    const [changeTreasury1, setChangeTreasury1] = useState(0);
    const [changeTreasury2, setChangeTreasury2] = useState(0);
    const [changeTreasury3, setChangeTreasury3] = useState(0);
    const [firstTreasury1, setFirstTreasury1] = useState(0);
    const [firstTreasury2, setFirstTreasury2] = useState(0);
    const [firstTreasury3, setFirstTreasury3] = useState(0);
    const [totalTreasury1, setTotalTreasury1] = useState(0);
    const [totalTreasury2, setTotalTreasury2] = useState(0);
    const [totalTreasury3, setTotalTreasury3] = useState(0);
    const [financingCashFlow1, setFinancingCashFlow1] = useState(0);
    const [financingCashFlow2, setFinancingCashFlow2] = useState(0);
    const [financingCashFlow3, setFinancingCashFlow3] = useState(0);
    const [allFieldsNotEmpty, setAllFieldsNotEmpty] = useState(false)
    const [formValue, setFormValue] = useState();
    const [helpModal, setHelpModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [data, setData] = useState();
    const [currentData, setCurrentData] = useState(null);
    const [loading, setLoading] = useState(false);
    const formRef = useRef();
    const [excelUploadModal, setExcelUploadModal] = useState(false);
    const [file, setFile] = useState(null);
    const [type, setType] = useState('save');
    const toggle = () => setExcelUploadModal(!excelUploadModal);
    const fileTypes = ["xlsx"];
    const handleChange = (file) => {
        setFile(file);
    };
    const { user } = props;
    const [year1, setYear1] = useState(undefined);
    const [year2, setYear2] = useState(undefined);
    const [year3, setYear3] = useState(undefined);
    const dataReset = {
        "ZA": 0,
        "FA": 0,
        "FB": 0,
        "FC": 0,
        "FD": 0,
        "FE": 0,
        "changeInWorkingCapitalRequirement": 0,
        "ZB": 0,
        "FF": 0,
        "FG": 0,
        "FH": 0,
        "FI": 0,
        "FJ": 0,
        "ZC": 0,
        "FK": 0,
        "FL": 0,
        "FM": 0,
        "FN": 0,
        "ZD": 0,
        "FO": 0,
        "FP": 0,
        "FQ": 0,
        "ZE": 0,
        "ZF": 0,
        "ZG": 0,
        "ZH": 0,
    }

    useEffect(() => {
        OperatingCashFlow()
        ChangeinWorking()
        InvestingCashFlow()
        FinancingCashflowfromEquity()
        FinancingCashFlowfromDebt()
        firstTreasuryFun();
        setFormValue()
    }, [year1, year2, year3])

    const getUserData = (year, setter) => {
        setter(dataReset)
        setLoading(true);
        let body = {
            year,
            userId: user?._id,
            token: user?.token
        }
        post('/cash_flow', body)
            .then((res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    console.log('res for profit', res);
                    // if (location?.state?.id)
                    setter(res?.data);
                    // else {
                    //     setCurrentData({ actionType: 'Confirm', confirmMsg: 'You have already submitted data for ' + year + 'Try to edit it!' })
                    //     setDeleteModal(true)

                    // }
                } else {
                    // setter(res?.data);
                    console.log(res?.error);
                }
            }))
            .catch(error => {
                setter(undefined);
                setLoading(false);
                console.log('error while login', error);
                console.log('Something went wrong');
            });
    }
    useEffect(() => {
        // const isEmpty = Object.values(form).some((field) => field?.answer === 0 || field?.answer == '');


        // if (isEmpty) {
        //    setButtonStatus(false)
        // } else {
        //     setButtonStatus(true)
        // }
        const form = document.getElementById('myForm'); // Replace 'myForm' with your form's ID
        const formElements = form.getElementsByTagName('input');
        let allFieldsNotEmpty1 = true;
        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            console.log('element', element.value)
            if (element.tagName === 'INPUT' && element.value === '') {
                allFieldsNotEmpty1 = false;
                break;
            }
        }
        setAllFieldsNotEmpty(allFieldsNotEmpty1)

    }, [formValue])

    // console.log('location', year1)

    const onChangeSetValue = (val) => {
        OperatingCashFlow()
        ChangeinWorking()
        InvestingCashFlow()
        FinancingCashflowfromEquity()
        FinancingCashFlowfromDebt()
        firstTreasuryFun();
        setFormValue(val)
        OperatingCashFlow()
    }

    useEffect(() => {
        setFinancingCashFlow1(financingEquity1 + financingDebt1)
        setFinancingCashFlow2(financingEquity2 + financingDebt2)
        setFinancingCashFlow3(financingEquity3 + financingDebt3)

    }, [financingEquity1, financingEquity2, financingEquity3, financingDebt1, financingDebt2, financingDebt3])
    useEffect(() => {
        setChangeTreasury1(operatingCashFlow1 + investingCash1 + financingCashFlow1)
        setChangeTreasury2(operatingCashFlow2 + investingCash2 + financingCashFlow2)
        setChangeTreasury3(operatingCashFlow3 + investingCash3 + financingCashFlow3)

    }, [operatingCashFlow1, operatingCashFlow2, operatingCashFlow3, investingCash1, investingCash2, investingCash3, financingCashFlow1, financingCashFlow2, financingCashFlow3])
    // console.log('financingCashFlow1',financingCashFlow1)

    useEffect(() => {
        setTotalTreasury1(firstTreasury1 + changeTreasury1)
        setTotalTreasury2(firstTreasury2 + changeTreasury2)
        setTotalTreasury3(firstTreasury3 + changeTreasury3)

    }, [firstTreasury1, firstTreasury2, firstTreasury3, changeTreasury1, changeTreasury2, changeTreasury3])

    // const OperatingCashFlow = () => {
    //     let grosstotal1 = Number(formRef.current?._inputs?.FA1?.value);
    //     grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.FB1?.value);
    //     grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.FC1?.value);
    //     grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.FD1?.value);
    //     grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.FE1?.value);
    //     let grosstotal2 = Number(formRef.current?._inputs?.FA2?.value);
    //     grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.FB2?.value);
    //     grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.FC2?.value);
    //     grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.FD2?.value);
    //     grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.FE2?.value);
    //     let grosstotal3 = Number(formRef.current?._inputs?.FA3?.value);
    //     grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.FB3?.value);
    //     grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.FC3?.value);
    //     grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.FD3?.value);
    //     grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.FE3?.value);
    //     setOperatingCashFlow1(grosstotal1)
    //     setOperatingCashFlow2(grosstotal2)
    //     setOperatingCashFlow3(grosstotal3)
    // }

    const OperatingCashFlow = () => {

        let grosstotal1 = Number(formRef.current?._inputs?.ZB1?.value);
        let grosstotal2 = Number(formRef.current?._inputs?.ZB2?.value);
        let grosstotal3 = Number(formRef.current?._inputs?.ZB3?.value);




        setOperatingCashFlow1(grosstotal1)
        setOperatingCashFlow2(grosstotal2)
        setOperatingCashFlow3(grosstotal3)
    }

    const ChangeinWorking = () => {

        let grosstotal1 = Number(formRef.current?._inputs?.BF1?.value);
        // grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.FC1?.value);
        // grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.FD1?.value);
        // grosstotal1 = grosstotal1 - Number(formRef.current?._inputs?.FE1?.value);
        let grosstotal2 = Number(formRef.current?._inputs?.BF2?.value);
        // grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.FC2?.value);
        // grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.FD2?.value);
        // grosstotal2 = grosstotal2 - Number(formRef.current?._inputs?.FE2?.value);
        let grosstotal3 = Number(formRef.current?._inputs?.BF3?.value);
        // grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.FC3?.value);
        // grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.FD3?.value);
        // grosstotal3 = grosstotal3 - Number(formRef.current?._inputs?.FE3?.value);
        console.log('ChangeinWorking', grosstotal1, grosstotal2, grosstotal3)
        setChangeinWorkingCapitalRequirement1(grosstotal1)
        setChangeinWorkingCapitalRequirement2(grosstotal2)
        setChangeinWorkingCapitalRequirement3(grosstotal3)
    }

    const InvestingCashFlow = (val) => {
        // console.log('form', formRef.current?._inputs?.TB?.value);
        let investingCash = Math.abs(Number(formRef.current?._inputs?.FF1?.value));
        investingCash = investingCash - Math.abs(Number(formRef.current?._inputs?.FG1?.value));
        investingCash = investingCash - Math.abs(Number(formRef.current?._inputs?.FH1?.value));
        investingCash = investingCash + Math.abs(Number(formRef.current?._inputs?.FI1?.value));
        investingCash = investingCash + Math.abs(Number(formRef.current?._inputs?.FJ1?.value));
        let investingCash_2 = Math.abs(Number(formRef.current?._inputs?.FF2?.value));
        investingCash_2 = investingCash_2 - Math.abs(Number(formRef.current?._inputs?.FG2?.value));
        investingCash_2 = investingCash_2 - Math.abs(Number(formRef.current?._inputs?.FH2?.value));
        investingCash_2 = investingCash_2 + Math.abs(Number(formRef.current?._inputs?.FI2?.value));
        investingCash_2 = investingCash_2 + Math.abs(Number(formRef.current?._inputs?.FJ2?.value));
        let investingCash_3 = Math.abs(Number(formRef.current?._inputs?.FF3?.value));
        investingCash_3 = investingCash_3 - Math.abs(Number(formRef.current?._inputs?.FG3?.value));
        investingCash_3 = investingCash_3 - Math.abs(Number(formRef.current?._inputs?.FH3?.value));
        investingCash_3 = investingCash_3 + Math.abs(Number(formRef.current?._inputs?.FI3?.value));
        investingCash_3 = investingCash_3 + Math.abs(Number(formRef.current?._inputs?.FJ3?.value));
        setInvestingCash1(investingCash)
        setInvestingCash2(investingCash_2)
        setInvestingCash3(investingCash_3)
    }

    const FinancingCashflowfromEquity = (val) => {

        let financingEquity_1 = Math.abs(Number(formRef.current?._inputs?.FK1?.value));
        financingEquity_1 = financingEquity_1 + Math.abs(Number(formRef.current?._inputs?.FL1?.value));
        financingEquity_1 = financingEquity_1 - Math.abs(Number(formRef.current?._inputs?.FM1?.value));
        financingEquity_1 = financingEquity_1 - Math.abs(Number(formRef.current?._inputs?.FN1?.value));
        let financingEquity_2 = Math.abs(Number(formRef.current?._inputs?.FK2?.value));
        financingEquity_2 = financingEquity_2 + Math.abs(Number(formRef.current?._inputs?.FL2?.value));
        financingEquity_2 = financingEquity_2 - Math.abs(Number(formRef.current?._inputs?.FM2?.value));
        financingEquity_2 = financingEquity_2 - Math.abs(Number(formRef.current?._inputs?.FN2?.value));
        let financingEquity_3 = Math.abs(Number(formRef.current?._inputs?.FK3?.value));
        financingEquity_3 = financingEquity_3 + Math.abs(Number(formRef.current?._inputs?.FL3?.value));
        financingEquity_3 = financingEquity_3 - Math.abs(Number(formRef.current?._inputs?.FM3?.value));
        financingEquity_3 = financingEquity_3 - Math.abs(Number(formRef.current?._inputs?.FN3?.value));
        setFinancingEquity1(financingEquity_1)
        setFinancingEquity2(financingEquity_2)
        setFinancingEquity3(financingEquity_3)
    }
    const FinancingCashFlowfromDebt = () => {
        let financingDebt_1 = Math.abs(Number(formRef.current?._inputs?.FO1?.value));
        financingDebt_1 = financingDebt_1 + Math.abs(Number(formRef.current?._inputs?.FP1?.value));
        financingDebt_1 = financingDebt_1 - Math.abs(Number(formRef.current?._inputs?.FQ1?.value));
        let financingDebt_2 = Math.abs(Number(formRef.current?._inputs?.FO2?.value));
        financingDebt_2 = financingDebt_2 + Math.abs(Number(formRef.current?._inputs?.FP2?.value));
        financingDebt_2 = financingDebt_2 - Math.abs(Number(formRef.current?._inputs?.FQ2?.value));
        let financingDebt_3 = Math.abs(Number(formRef.current?._inputs?.FO3?.value));
        financingDebt_3 = financingDebt_3 + Math.abs(Number(formRef.current?._inputs?.FP3?.value));
        financingDebt_3 = financingDebt_3 - Math.abs(Number(formRef.current?._inputs?.FQ3?.value));
        setFinancingDebt1(financingDebt_1)
        setFinancingDebt2(financingDebt_2)
        setFinancingDebt3(financingDebt_3)

    }


    const firstTreasuryFun = () => {
        let treasury1 = Number(formRef.current?._inputs?.ZA1?.value);
        let treasury2 = Number(formRef.current?._inputs?.ZA2?.value);
        let treasury3 = Number(formRef.current?._inputs?.ZA3?.value);
        setFirstTreasury1(treasury1)
        setFirstTreasury2(treasury2)
        setFirstTreasury3(treasury3)
    }

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: t("Cash Flow Statement"), link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems(t("Cash Flow Statement"), breadcrumbItems)
    }, [])

    function handleValidSubmit(event, values) {
        if (!loading) {
            setLoading(true);
            if (values?.year_one) {
                let data = [];
                if (values?.year_one) {
                    data.push({
                        ...renameKeys(values, 1),
                        userId: user?._id,
                        contentId: year1?._id,
                        token: user.token,
                        year: values?.year_one,
                        isSubmit: type == 'submit' ? true : undefined,
                        changeInWorkingCapitalRequirement: Number(changeinWorkingCapitalRequirement1),
                        ZC: Number(investingCash1),
                        ZD: Number(financingEquity1),
                        ZE: Number(financingDebt1),
                        ZF: Number(financingCashFlow1),
                        ZG: Number(changeTreasury1),
                        ZH: Number(totalTreasury1),

                    });
                }
                if (values?.year_two) {
                    data.push({
                        ...renameKeys(values, 2),
                        userId: user?._id,
                        contentId: year2?._id,
                        token: user.token,
                        year: values?.year_two,
                        isSubmit: type == 'submit' ? true : undefined,
                        changeInWorkingCapitalRequirement: Number(changeinWorkingCapitalRequirement2),
                        ZC: Number(investingCash2),
                        ZD: Number(financingEquity2),
                        ZE: Number(financingDebt2),
                        ZF: Number(financingCashFlow2),
                        ZG: Number(changeTreasury2),
                        ZH: Number(totalTreasury2),
                    });
                }
                if (values?.year_three) {
                    data.push({
                        ...renameKeys(values, 3),
                        userId: user?._id,
                        contentId: year3?._id,
                        token: user.token,
                        year: values?.year_three,
                        isSubmit: type == 'submit' ? true : undefined,
                        changeInWorkingCapitalRequirement: Number(changeinWorkingCapitalRequirement3),
                        ZC: Number(investingCash3),
                        ZD: Number(financingEquity3),
                        ZE: Number(financingDebt3),
                        ZF: Number(financingCashFlow3),
                        ZG: Number(changeTreasury3),
                        ZH: Number(totalTreasury3),
                    });
                }
                //   console.log('data', data);
                //   return
                data?.forEach((body, index) => {
                    post('/cash_flow/add', body)
                        .then((res) => {
                            if (data?.length - 1 === index)
                                if (res?.statusCode == 200) {
                                    // console.log('request on login', res);
                                    toast.success(res?.message);
                                    // setLoading(false);
                                    let body = {
                                        userId: user?._id,
                                        token: user?.token
                                    }
                                    post('/user/profile/status', body)
                                        .then((json => {
                                            if (json?.statusCode == 200) {
                                                if (json?.data?.profit) {
                                                    if (json?.data?.balance) {
                                                        updateProfile();
                                                    } else
                                                        setLoading(false);
                                                } else
                                                    setLoading(false);
                                            } else {
                                                setLoading(false);
                                            }
                                        }));
                                } else {
                                    console.log(res?.error);
                                    toast.error(res?.error);
                                    setLoading(false);
                                }
                        })
                        .catch((err) => {
                            setLoading(false)
                            console.log(err)
                        })
                })
            }
            else {
                setLoading(false)
                toast?.error('First Year is Required')
            }
        }
    }

    const updateProfile = () => {
        let body = {
            userId: user?._id,
            token: user?.token,
            step: 6
        }
        put('/user', body)
            .then((res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    props?.profileSuccess({ ...user, ...body });
                    localStorage.setItem("user", JSON.stringify({ ...user, ...body }));
                }
            }))
            .catch(error => {
                setLoading(false);
                console.log('error while login', error);
            })
    }

    function renameKeys(obj, to) {
        const newKeys = {};

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const regexPattern = new RegExp(to + "$");
                const newKey = key.replace(regexPattern, ''); // Remove trailing digits
                newKeys[newKey] = obj[key];
            }
        }

        return newKeys;
    }

    const onConfirm = () => {
        if (currentData?.actionType == 'Status') {
            statusChange();
        } else if (currentData?.actionType == 'Delete') {
            onDelete()
        } else {
            setDeleteModal(false)
        }
    }

    const uploadExcel = () => {
        if (!loading) {
            setLoading(true);
            if (file) {
                const form = new FormData();
                form.append("sample", file[0]);
                uploadWithTokan('/cash_flow/sample_upload', form, user?.token)
                    .then((res) => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            // console.log('request on login', res);
                            toast.success(res?.message);
                            setExcelUploadModal(false);
                            res?.data?.forEach((item, index) => {
                                if (index < 3) {
                                    if (index == 0) {
                                        setTimeout(() => {
                                            getUserData(String(item), setYear1);
                                        }, 1100);
                                    } if (index == 1)
                                        setTimeout(() => {
                                            getUserData(String(item), setYear2);
                                        }, 1100);
                                    else
                                        setTimeout(() => {
                                            getUserData(String(item), setYear3);
                                        }, 1300);
                                }
                            });
                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    })
                    .catch((err) => {
                        setLoading(false)
                        console.log(err)
                    })
            } else {
                setLoading(false);
                toast.error('Please choose file');
            }
        }
    }

    return (
        <React.Fragment>
            <MyPrompt />
            <MetaTags>
                <title>Cash Flow Statement | Demleen </title>
            </MetaTags>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={onConfirm}
                onCloseClick={() => setDeleteModal(false)}
                data={currentData}
            />
            <Loader loading={loading} />
            <Modal size="lg" className='file_upload_popup' isOpen={helpModal} centered>
                <ModalHeader toggle={() => setHelpModal(!helpModal)}>Instruction</ModalHeader>
                <ModalBody>
                    <div className="popup_content">
                        <ul>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {t("FINANCIAL DOCUMENTS one")}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {t("FINANCIAL DOCUMENTS two")}
                            </li>
                        </ul>
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("EmailContact") }}></p>
                    </div>
                </ModalBody>

            </Modal>
            <Modal className='file_upload_popup' isOpen={excelUploadModal} centered>
                <ModalHeader toggle={toggle}>Upload Excel</ModalHeader>
                <ModalBody>

                    <FileUploader
                        multiple={true}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                    />
                    <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>

                </ModalBody>
                <ModalFooter>
                    <Button className='btn_bg' onClick={uploadExcel}>
                        Upload
                    </Button>{' '}
                    <Button color="secondary" className='btn_secondary btn_bg' onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

            <button className="help_btn" onClick={() => setHelpModal(!helpModal)}><span>i</span> Help</button>

            <AvForm
                id="myForm"
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
                ref={formRef}
            >
                {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
                <Card>
                    <CardBody>

                        <Row>
                            <Col lg={12}>
                                <Table
                                    bordered
                                    style={{ marginBottom: 0 }}
                                >

                                    <tbody >

                                        <tr>
                                            <td style={{ padding: 0, border: 'none' }} colspan="2">
                                                <Table
                                                    style={{ border: 'none', borderColor: 'transparent', marginBottom: 0 }}
                                                >
                                                    <thead >
                                                        <tr className='right_head'>
                                                            <th colspan="1" className='profit_lo_heading' style={{ textAlign: 'left' }}>{t("Cash Flow Statement")}</th>
                                                            <th colspan="3">

                                                                <a className='table_btn' href={ExcelFile} download >{t("Download Excel Sample")}
                                                                </a>
                                                                <button onClick={toggle} type='button' className='table_btn'>{t("Upload via Excel")}
                                                                </button>
                                                            </th>

                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_1'>

                                                            <th style={{ width: '50%' }}>

                                                            </th>
                                                            <th style={{ width: '16.66666666666667%' }}>

                                                                <AvField type="select" name="year_one" value={year1?.year} onChange={(e) => getUserData(e.target.value, setYear1)}>
                                                                    <option value="">{t("Select Year")}*</option>
                                                                    {years?.map((item) => (
                                                                        <option value={item?.value}>{item?.label}</option>
                                                                    ))}
                                                                </AvField>
                                                            </th>
                                                            <th style={{ width: '16.66666666666667%' }}>
                                                                <AvField type="select" name="year_two" value={year2?.year} onChange={(e) => getUserData(e.target.value, setYear2)}>
                                                                    <option value="">{t("Select Year")}</option>
                                                                    {years?.map((item) => (
                                                                        <option value={item?.value}>{item?.label}</option>
                                                                    ))}
                                                                </AvField>
                                                            </th>
                                                            <th style={{ width: '16.66666666666667%' }}>
                                                                <AvField type="select" name="year_three" value={year3?.year} onChange={(e) => getUserData(e.target.value, setYear3)}>
                                                                    <option value="">{t("Select Year")}</option>
                                                                    {years?.map((item) => (
                                                                        <option value={item?.value}>{item?.label}</option>
                                                                    ))}
                                                                </AvField>
                                                            </th>

                                                        </tr>
                                                        <tr className='second_head'>

                                                            <th style={{ width: '50%' }}>
                                                                {t("Treasury at 1st Jan")}
                                                            </th>
                                                            <th style={{ width: '16.66666666666667%' }}>
                                                                <AvField
                                                                    name="ZA1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.ZA}
                                                                />
                                                            </th>
                                                            <th style={{ width: '16.66666666666667%' }}>
                                                                <AvField
                                                                    name="ZA2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.ZA}
                                                                />
                                                            </th>
                                                            <th style={{ width: '16.66666666666667%' }}>
                                                                <AvField
                                                                    name="ZA3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.ZA}
                                                                />
                                                            </th>


                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Self-financing Capacity = Net Income + Depreciation & Amortization - Exceptional Income")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FA1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FA}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FA2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FA}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FA3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FA}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Change in Current Assets (excl. Exceptional Income)")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FB1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FB}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FB2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FB}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FB3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FB}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Change in Inventory")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FC1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FC}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FC2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FC}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FC3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FC}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Change in Accounts Receivable")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FD1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FD}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FD2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FD}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FD3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FD}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Change in Current Liabilities")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FE1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FE}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FE2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FE}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FE3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FE}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                                            <td>
                                                                {t("Change in Working Capital Requirement")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="BF1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    // disabled
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    value={year1?.changeInWorkingCapitalRequirement}
                                                                // required

                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="BF2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    // disabled
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    value={year2?.changeInWorkingCapitalRequirement}
                                                                // required
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="BF3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    // disabled
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    value={year3?.changeInWorkingCapitalRequirement}
                                                                // required
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr style={{ backgroundColor: '#012E67', color: '#fff' }} className='second_head'>

                                                            <td>
                                                                {t("Operating Cash Flow")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="ZB1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    // disabled
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    value={year1?.ZB}
                                                                // required

                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="ZB2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    // disabled
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    value={year2?.ZB}
                                                                // required
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="ZB3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    // disabled
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    value={year3?.ZB}
                                                                // required
                                                                />
                                                            </td>

                                                        </tr>

                                                        {/* <tr style={{ backgroundColor: '#012E67', color: '#fff' }} className='second_head_2'>

                                                            <th style={{ width: '50%' }}>
                                                                {t("Operating Cash Flow")}
                                                            </th>
                                                            <th style={{ width: '16.66666666666667%' }}>{operatingCashFlow1?.toLocaleString()}</th>
                                                            <th style={{ width: '16.66666666666667%' }}>{operatingCashFlow2?.toLocaleString()}</th>
                                                            <th style={{ width: '16.66666666666667%' }}>{operatingCashFlow3?.toLocaleString()}</th>


                                                        </tr> */}
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_2'>
                                                            <th style={{ width: '50%' }}>
                                                                {t("Cash flow from investing activities")}
                                                            </th>

                                                            <th style={{ width: '16.66666666666667%' }}></th>
                                                            <th style={{ width: '16.66666666666667%' }}></th>
                                                            <th style={{ width: '16.66666666666667%' }}></th>
                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Capital Expenditures (intangible assets)")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FF1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FF}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FF2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FF}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FF3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FF}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Capital Expenditures (tangible assets)")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FG1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FG}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FG2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FG}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FG3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FG}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Acquisition of Financial Assets")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FH1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FH}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FH2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FH}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FH3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FH}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Sale of Property, Plant & Equipment")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FI1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FI}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FI2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FI}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FI3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FI}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Sale of financial assets")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FJ1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FJ}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FJ2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FJ}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FJ3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FJ}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr style={{ backgroundColor: '#012E67', color: '#fff' }} className='second_head_2'>

                                                            <th style={{ width: '50%' }}>
                                                                {t("Investing Cash Flow")}
                                                            </th>
                                                            <th style={{ width: '16.66666666666667%' }}>{investingCash1?.toLocaleString()}</th>
                                                            <th style={{ width: '16.66666666666667%' }}>{investingCash2?.toLocaleString()}</th>
                                                            <th style={{ width: '16.66666666666667%' }}>{investingCash3?.toLocaleString()}</th>


                                                        </tr>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_2'>
                                                            <th style={{ width: '50%' }}>
                                                                {t("Cash flow from equity financing")}
                                                            </th>

                                                            <th style={{ width: '16.66666666666667%' }}></th>
                                                            <th style={{ width: '16.66666666666667%' }}></th>
                                                            <th style={{ width: '16.66666666666667%' }}></th>
                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Issuance of new shares")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FK1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FK}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FK2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FK}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FK3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FK}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Received Investment grants")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FL1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FL}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FL2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FL}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FL3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FL}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Levy on Equity")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FM1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FM}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FM2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FM}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FM3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FM}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Paid Dividend")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FN1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FN}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FN2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FN}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FN3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FN}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                                            <td>
                                                                {t("Financing Cash flow from Equity")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="ZD1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    value={financingEquity1}
                                                                // required

                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="ZD2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    value={financingEquity2}
                                                                // required
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="ZD3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    value={financingEquity3}
                                                                // required
                                                                />
                                                            </td>

                                                        </tr>

                                                        <tr style={{ backgroundColor: 'rgba(225,225,225,.2)' }} className='second_head_2'>
                                                            <th style={{ width: '50%' }}>
                                                                {t("Cash flow from external financing")}
                                                            </th>

                                                            <th style={{ width: '16.66666666666667%' }}></th>
                                                            <th style={{ width: '16.66666666666667%' }}></th>
                                                            <th style={{ width: '16.66666666666667%' }}></th>
                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Debt")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FO1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FO}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FO2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FO}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FO3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FO}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Other loans and debts")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FP1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FP}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FP2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FP}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FP3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FP}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Repayments of loans and other financial debts")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="FQ1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.FQ}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FQ2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.FQ}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="FQ3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.FQ}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                                            <td>
                                                                {t("Financing Cash Flow from Debt")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="ZE1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    value={financingDebt1}
                                                                // required
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="ZE2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    value={financingDebt2}
                                                                // required
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="ZE3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    value={financingDebt3}
                                                                // required
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr style={{ backgroundColor: '#012E67', color: '#fff' }} className='second_head_2'>

                                                            <th style={{ width: '50%' }}>
                                                                {t("Financing Cash Flow")}
                                                            </th>
                                                            <th style={{ width: '16.66666666666667%' }}>{financingCashFlow1?.toLocaleString()}</th>
                                                            <th style={{ width: '16.66666666666667%' }}>{financingCashFlow2?.toLocaleString()}</th>
                                                            <th style={{ width: '16.66666666666667%' }}>{financingCashFlow3?.toLocaleString()}</th>

                                                        </tr>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                                            <td>
                                                                {t("Change in Net Treasury")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    name="ZG1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    value={changeTreasury1}
                                                                // required
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="ZG2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    disabled
                                                                    value={changeTreasury2}
                                                                // required
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="ZG3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    value={changeTreasury3}
                                                                // required
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr style={{ backgroundColor: 'rgb(223 220 220)' }} className='second_head_2'>
                                                            <td colSpan={1}>
                                                                {t("Treasury at 31 Dec")}
                                                            </td>
                                                            <td style={{ borderRight: '1px solid #ccc', borderLeft: 'none' }}>
                                                                <AvField
                                                                    name="ZH1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    value={totalTreasury1}
                                                                // required
                                                                />
                                                            </td>
                                                            <td >
                                                                <AvField
                                                                    name="ZH2"
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    value={totalTreasury2}
                                                                // required
                                                                />
                                                            </td>
                                                            <td >
                                                                <AvField
                                                                    name="ZH3"
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    value={totalTreasury3}
                                                                // required
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>

                                        </tr>

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <div className="text-center">
                            <Button type="submit" className='btn_bg' color="">
                                {t("Save")}
                            </Button>
                            <Button type="submit" onClick={() => setType('submit')} className='btn_bg mx-5' color="">
                                {t("Save & Submit")}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </AvForm>


        </React.Fragment>
    )
}

Index.propTypes = {
    profileSuccess: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems, profileSuccess })(Index)
)
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Table,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { withRouter, useLocation } from "react-router-dom"
import { setBreadcrumbItems, profileSuccess } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { FileUploader } from "react-drag-drop-files";
import { upload, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import MyPrompt from 'components/MyPrompt';
import DeleteModal from 'pages/Admin/DeleteModal';
import Loader from 'components/Loader';
import * as Utils from "../Utils";

const Liabilities = props => {
    const location = useLocation();
    const years = Utils.getYears();
    const fileTypes = ["xlsx"];
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [idx, setidx] = useState(1)
    const [helpModal, setHelpModal] = useState(false);
    const [type, setType] = useState('save');
    const { i18n, t } = useTranslation();
    const [totalOwnersEquity1, setTotalOwnersEquity1] = useState(0);
    const [totalOwnersEquity2, setTotalOwnersEquity2] = useState(0);
    const [totalOwnersEquity3, setTotalOwnersEquity3] = useState(0);
    const [totalFinancial1, setTotalFinancial1] = useState(0);
    const [totalFinancial2, setTotalFinancial2] = useState(0);
    const [totalFinancial3, setTotalFinancial3] = useState(0);
    const [fixedLiabilities1, setFixedLiabilities1] = useState(0);
    const [fixedLiabilities2, setFixedLiabilities2] = useState(0);
    const [fixedLiabilities3, setFixedLiabilities3] = useState(0);
    const [currentLiabilities1, setCurrentLiabilities1] = useState(0);
    const [currentLiabilities2, setCurrentLiabilities2] = useState(0);
    const [currentLiabilities3, setCurrentLiabilities3] = useState(0);
    const [totalCashLiabilities1, setTotalCashLiabilities1] = useState(0);
    const [totalCashLiabilities2, setTotalCashLiabilities2] = useState(0);
    const [totalCashLiabilities3, setTotalCashLiabilities3] = useState(0);
    const [valuationDifference1, setValuationDifference1] = useState(0);
    const [valuationDifference2, setValuationDifference2] = useState(0);
    const [valuationDifference3, setValuationDifference3] = useState(0);
    const [totalLiabilities1, setTotalLiabilities1] = useState(0);
    const [totalLiabilities2, setTotalLiabilities2] = useState(0);
    const [totalLiabilities3, setTotalLiabilities3] = useState(0);
    const [excelUploadModal, setExcelUploadModal] = useState(false);
    const [file, setFile] = useState(null);
    const [allFieldsNotEmpty, setAllFieldsNotEmpty] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [data, setData] = useState();
    const [currentData, setCurrentData] = useState(null);
    const [formValue, setFormValue] = useState();
    const handleChange = (file) => {
        setFile(file);
    };
    const toggle = () => setExcelUploadModal(!excelUploadModal);
    const formRef = useRef();

    const { user } = props;
    const [loading, setLoading] = useState(false);
    const [year1, setYear1] = useState(undefined);
    const [year2, setYear2] = useState(undefined);
    const [year3, setYear3] = useState(undefined);


    const dataReset = {
        "accountspayableAndRelatedAccounts": 0,
        "advancePaymentsonAccountReceivedonOrdersInProgress": 0,
        "capital": 0,
        "currentDebt": 0,
        "currentLiabilities": 0,
        "fixedLiabilities": 0,
        "investmentGrants": 0,
        "leasingDebt": 0,
        "legalReserve": 0,
        "loansandDebtFromFinancialInstitutions": 0,
        "otherLoansAndSimilarDebts": 0,
        "otherPayables": 0,
        "premiumsRelatedtoShareCapital": 0,
        "profitOrLossForTheFinancialYear": 0,
        "provisions": 0,
        "regulatedProvisions": 0,
        "reservesNotAvailableForDistribution": 0,
        "retainedEarnings": 0,
        "revaluationDifference": 0,
        "shareholdersSubscribedUncalledCapital": 0,
        "shortTermBankAdvances": 0,
        "taxAndSocialSecurityDebts": 0,
        "totalCashLiabilities": 0,
        "totalDettesFinancieresEtRessourcesAssimilees": 0,
        "totalLiabilities": 0,
        "totalOwnersEquity": 0,
        "unrealizedForeignExchangeGainsAndValuationDifferences": 0,
        "valuationDifferenceLiabilities": 0,
    }

    const getUserData = (year, setter) => {
        if (!loading) {
            setter(dataReset)
            setLoading(true);
            let body = {
                year,
                userId: user?._id,
                token: user?.token
            }
            post('/liab', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        console.log('res for profit', location?.state?.id, res);
                        if (location?.state?.id)
                            setter(res?.data);
                        else {
                            setCurrentData({ actionType: 'Confirm', confirmMsg: 'You have already submitted data for ' + year + 'Try to edit it!' })
                            setDeleteModal(true)

                        }

                    } else {
                        setter(res?.data);
                        console.log(res?.error);
                    }


                }))
                .catch(error => {
                    setter(undefined);
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                });
        }
    }
    useEffect(() => {
        // const isEmpty = Object.values(form).some((field) => field?.answer === 0 || field?.answer == '');


        // if (isEmpty) {
        //    setButtonStatus(false)
        // } else {
        //     setButtonStatus(true)
        // }
        const form = document.getElementById('myForm'); // Replace 'myForm' with your form's ID
        const formElements = form.getElementsByTagName('input');
        let allFieldsNotEmpty1 = true;
        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            console.log('element', element.value)
            if (element.tagName === 'INPUT' && element.value === '') {
                allFieldsNotEmpty1 = false;
                break;
            }
        }
        setAllFieldsNotEmpty(allFieldsNotEmpty1)

    }, [formValue])

    useEffect(() => {
        setFixedLiabilities1(totalOwnersEquity1 + totalFinancial1)
    }, [totalOwnersEquity1, totalFinancial1])


    useEffect(() => {
        setFixedLiabilities2(totalOwnersEquity2 + totalFinancial2)
    }, [totalOwnersEquity2, totalFinancial2])

    useEffect(() => {
        setFixedLiabilities3(totalOwnersEquity3 + totalFinancial3)
    }, [totalOwnersEquity3, totalFinancial3])


    useEffect(() => {
        setTotalLiabilities1(fixedLiabilities1 + currentLiabilities1 + totalCashLiabilities1 + valuationDifference1)
    }, [fixedLiabilities1, currentLiabilities1, totalCashLiabilities1, valuationDifference1])

    useEffect(() => {
        setTotalLiabilities2(fixedLiabilities2 + currentLiabilities2 + totalCashLiabilities2 + valuationDifference2)
    }, [fixedLiabilities2, currentLiabilities2, totalCashLiabilities2, valuationDifference2])

    useEffect(() => {
        setTotalLiabilities3(fixedLiabilities3 + currentLiabilities3 + totalCashLiabilities3 + valuationDifference3)
    }, [fixedLiabilities3, currentLiabilities3, totalCashLiabilities3, valuationDifference3])

    const onChangeSetValue = (val) => {

        totalOwnersEquityFun();
        totalFinancialFun();
        currentLiabilitiesFun();
        totalCashLiabilitiesFun();
        valuationDifferenceFun();
    }

    const totalOwnersEquityFun = () => {
        let net1 = Number(formRef.current?._inputs?.capital1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.shareholdersSubscribedUncalledCapital1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.premiumsRelatedtoShareCapital1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.revaluationDifference1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.reservesNotAvailableForDistribution1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.legalReserve1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.retainedEarnings1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.profitOrLossForTheFinancialYear1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.investmentGrants1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.regulatedProvisions1?.value);

        let net2 = Number(formRef.current?._inputs?.capital2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.shareholdersSubscribedUncalledCapital2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.premiumsRelatedtoShareCapital2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.revaluationDifference2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.reservesNotAvailableForDistribution2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.legalReserve2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.retainedEarnings2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.profitOrLossForTheFinancialYear2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.investmentGrants2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.regulatedProvisions2?.value);

        let net3 = Number(formRef.current?._inputs?.capital3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.shareholdersSubscribedUncalledCapital3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.premiumsRelatedtoShareCapital3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.revaluationDifference3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.reservesNotAvailableForDistribution3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.legalReserve3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.retainedEarnings3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.profitOrLossForTheFinancialYear3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.investmentGrants3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.regulatedProvisions3?.value);


        setTotalOwnersEquity1(net1)
        setTotalOwnersEquity2(net2)
        setTotalOwnersEquity3(net3)


    }

    const totalFinancialFun = () => {
        let net1 = Number(formRef.current?._inputs?.loansandDebtFromFinancialInstitutions1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.leasingDebt1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.provisions1?.value);

        let net2 = Number(formRef.current?._inputs?.loansandDebtFromFinancialInstitutions2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.leasingDebt2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.provisions2?.value);

        let net3 = Number(formRef.current?._inputs?.loansandDebtFromFinancialInstitutions3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.leasingDebt3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.provisions3?.value);


        setTotalFinancial1(net1)
        setTotalFinancial2(net2)
        setTotalFinancial3(net3)
    }
    const currentLiabilitiesFun = () => {
        let net1 = Number(formRef.current?._inputs?.currentDebt1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.advancePaymentsonAccountReceivedonOrdersInProgress1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.accountspayableAndRelatedAccounts1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.taxAndSocialSecurityDebts1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.otherLoansAndSimilarDebts1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.unrealizedForeignExchangeGainsAndValuationDifferences1?.value);

        let net2 = Number(formRef.current?._inputs?.currentDebt2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.advancePaymentsonAccountReceivedonOrdersInProgress2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.accountspayableAndRelatedAccounts2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.taxAndSocialSecurityDebts2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.otherLoansAndSimilarDebts2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.unrealizedForeignExchangeGainsAndValuationDifferences2?.value);

        let net3 = Number(formRef.current?._inputs?.currentDebt3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.advancePaymentsonAccountReceivedonOrdersInProgress3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.accountspayableAndRelatedAccounts3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.taxAndSocialSecurityDebts3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.otherLoansAndSimilarDebts3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.unrealizedForeignExchangeGainsAndValuationDifferences3?.value);


        setCurrentLiabilities1(net1)
        setCurrentLiabilities2(net2)
        setCurrentLiabilities3(net3)
    }

    const totalCashLiabilitiesFun = () => {
        let net1 = Number(formRef.current?._inputs?.otherPayables1?.value);
        net1 = net1 + Number(formRef.current?._inputs?.shortTermBankAdvances1?.value);


        let net2 = Number(formRef.current?._inputs?.otherPayables2?.value);
        net2 = net2 + Number(formRef.current?._inputs?.shortTermBankAdvances2?.value);

        let net3 = Number(formRef.current?._inputs?.otherPayables3?.value);
        net3 = net3 + Number(formRef.current?._inputs?.shortTermBankAdvances3?.value);


        setTotalCashLiabilities1(net1)
        setTotalCashLiabilities2(net2)
        setTotalCashLiabilities3(net3)
    }

    const valuationDifferenceFun = () => {
        let net1 = Number(formRef.current?._inputs?.valuationDifferenceLiabilities1?.value);

        let net2 = Number(formRef.current?._inputs?.valuationDifferenceLiabilities2?.value);

        let net3 = Number(formRef.current?._inputs?.valuationDifferenceLiabilities3?.value);

        setValuationDifference1(net1)
        setValuationDifference2(net2)
        setValuationDifference3(net3)
    }

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"))
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                setname(obj.displayName)
                setemail(obj.email)
                setidx(obj.uid)
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                setname(obj.username)
                setemail(obj.email)
                setidx(obj.uid)
            }
            setTimeout(() => {
                props.resetProfileFlag();
            }, 3000);
        }
    }, [props.success])

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Liabilities", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Liabilities', breadcrumbItems)
    }, [])

    function handleValidSubmit(event, values) {
        if (!loading) {
            setLoading(true)
            if (values?.year_one) {
                let data = [];
                if (values?.year_one) {
                    data.push({
                        ...renameKeys(values, 1),
                        userId: user?._id,
                        contentId: year1?._id,
                        token: user.token,
                        year: values?.year_one,
                        isSubmit: type == 'submit' ? true : undefined,
                        totalOwnersEquity: Number(totalOwnersEquity1),
                        fixedLiabilities: Number(fixedLiabilities1),
                        currentLiabilities: Number(currentLiabilities1),
                        totalCashLiabilities: Number(totalCashLiabilities1),
                        totalLiabilities: Number(totalLiabilities1),

                    });
                }
                // if (values?.year_two) {
                //     data.push({
                //         ...renameKeys(values, 2),
                //         userId: user?._id,
                //         contentId: year2?._id,
                //         token: user.token,
                //         year: values?.year_two,
                //         isSubmit: type == 'submit' ? true : undefined,
                //         totalOwnersEquity: Number(totalOwnersEquity2),
                //         fixedLiabilities: Number(fixedLiabilities2),
                //         currentLiabilities: Number(currentLiabilities2),
                //         totalCashLiabilities: Number(totalCashLiabilities2),
                //         totalLiabilities: Number(totalLiabilities2),
                //     });
                // }
                // if (values?.year_three) {
                //     data.push({
                //         ...renameKeys(values, 3),
                //         userId: user?._id,
                //         contentId: year3?._id,
                //         token: user.token,
                //         year: values?.year_three,
                //         isSubmit: type == 'submit' ? true : undefined,
                //         totalOwnersEquity: Number(totalOwnersEquity3),
                //         fixedLiabilities: Number(fixedLiabilities3),
                //         currentLiabilities: Number(currentLiabilities3),
                //         totalCashLiabilities: Number(totalCashLiabilities3),
                //         totalLiabilities: Number(totalLiabilities3),
                //     });
                // }
                console.log('data', data);

                data?.forEach((body) => {
                    if (body?.contentId) {
                        if (location?.state?.id) {
                            put('/liab', body)
                                .then((res) => {
                                    setLoading(false)
                                    if (res?.statusCode == 200) {
                                        // console.log('request on login', res);
                                        toast.success(res?.message)
                                    } else {
                                        console.log(res?.error);
                                        toast.error(res?.error)
                                    }
                                })
                                .catch((err) => {
                                    setLoading(false)
                                    console.log(err)
                                })
                        }
                    } else {
                        post('/liab/add', body)
                            .then((res) => {
                                // setLoading(false)
                                if (res?.statusCode == 200) {
                                    // console.log('request on login', res);
                                    toast.success(res?.message);
                                    let body = {
                                        userId: user?._id,
                                        token: user?.token
                                    }
                                    post('/user/profile/status', body)
                                        .then((json => {
                                            if (json?.statusCode == 200) {
                                                if (json?.data?.cashFlow) {
                                                    if (json?.data?.profit) {
                                                        if (json?.data?.balance) {
                                                            updateProfile();
                                                        } else
                                                            setLoading(false);
                                                    } else
                                                        setLoading(false);
                                                } else
                                                    setLoading(false);
                                            } else {
                                                setLoading(false);
                                            }
                                        }));
                                } else {
                                    setLoading(false);
                                    console.log(res?.error);
                                    toast.error(res?.error)
                                }
                            })
                            .catch((err) => {
                                setLoading(false)
                                console.log(err)
                            })
                    }
                })
            }
            else {
                setLoading(false)
                toast?.error('First Year is Required')
            }
        }
    }

    const updateProfile = () => {
        let body = {
            userId: user?._id,
            token: user?.token,
            step: 6
        }
        put('/user', body)
            .then((res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    props?.profileSuccess({ ...user, ...body });
                    localStorage.setItem("user", JSON.stringify({ ...user, ...body }));
                }
            }))
            .catch(error => {
                setLoading(false);
                console.log('error while login', error);
            })
    }

    const uploadExcel = () => {
        if (file) {
            const form = new FormData();
            form.append("sample", file[0]);

            upload('/profit_loss/sample_upload', form, user?.token)
                .then((res) => {
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        toast.success(res?.message)
                        setExcelUploadModal(false)
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toast.error('Please choose file')
        }
    }

    function renameKeys(obj, to) {
        const newKeys = {};

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const regexPattern = new RegExp(to + "$");
                const newKey = key.replace(regexPattern, ''); // Remove trailing digits
                newKeys[newKey] = obj[key];
            }
        }

        return newKeys;
    }

    const onConfirm = () => {
        if (currentData?.actionType == 'Status') {
            statusChange();
        } else if (currentData?.actionType == 'Delete') {
            onDelete()
        } else {
            setDeleteModal(false)
        }
    }


    return (
        <React.Fragment>
            <MyPrompt />
            <MetaTags>
                <title>Profit and Loss account | Demleen </title>
            </MetaTags>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={onConfirm}
                onCloseClick={() => setDeleteModal(false)}
                data={currentData}
            />
            <Loader loading={loading} />
            <Modal size="lg" className='file_upload_popup' isOpen={helpModal} centered>
                <ModalHeader toggle={() => setHelpModal(!helpModal)}>Instruction</ModalHeader>
                <ModalBody>
                    <div className="popup_content">
                        <ul>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {t("FINANCIAL DOCUMENTS one")}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {t("FINANCIAL DOCUMENTS two")}
                            </li>
                        </ul>
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("EmailContact") }}></p>
                    </div>
                </ModalBody>

            </Modal>

            <button className="help_btn" onClick={() => setHelpModal(!helpModal)}><span>i</span> Help</button>
            <Modal className='file_upload_popup' isOpen={excelUploadModal} centered>
                <ModalHeader toggle={toggle}>Upload Excel</ModalHeader>
                <ModalBody>

                    <FileUploader
                        multiple={true}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                    />
                    <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>

                </ModalBody>
                <ModalFooter>
                    <Button className='btn_bg' onClick={uploadExcel}>
                        Upload
                    </Button>{' '}
                    <Button color="secondary" className='btn_secondary btn_bg' onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <AvForm
                id="myForm"
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
                ref={formRef}
            >
                {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
                <Card>
                    <CardBody>

                        <Row>
                            <Col lg={12}>
                                <Table
                                    bordered
                                    style={{ marginBottom: 0 }}
                                >

                                    <tbody >

                                        <tr>
                                            <td style={{ padding: 0, border: 'none' }} colspan="2">
                                                <Table
                                                    className='common_table'
                                                    style={{ border: 'none', borderColor: 'transparent', marginBottom: 0 }}
                                                >
                                                    <thead >

                                                        <tr className='right_head'>

                                                            <th style={{ width: '70%', textAlign: 'left' }}>
                                                                {t("Liabilities")}
                                                            </th>
                                                            <th style={{ width: '30%' }}>

                                                                <AvField type="select" name="year_one" onChange={(e) => getUserData(e.target.value, setYear1)}>
                                                                    <option value="">{t("Select Year")}*</option>
                                                                    {years?.map((item) => (
                                                                        <option value={item?.value}>{item?.label}</option>
                                                                    ))}
                                                                </AvField>
                                                            </th>
                                                            {/* <th style={{ width: '16.66666666666667%' }}>
                                                                <AvField type="select" name="year_two" onChange={(e) => getUserData(e.target.value, setYear2)} >
                                                                    <option value="">{t("Select Year")}</option>
                                                                    {years?.map((item) => (
                                                                        <option value={item?.value}>{item?.label}</option>
                                                                    ))}
                                                                </AvField>
                                                            </th>
                                                            <th style={{ width: '16.66666666666667%' }}>
                                                                <AvField type="select" name="year_three" onChange={(e) => getUserData(e.target.value, setYear3)} >
                                                                    <option value="">{t("Select Year")}</option>
                                                                    {years?.map((item) => (
                                                                        <option value={item?.value}>{item?.label}</option>
                                                                    ))}
                                                                </AvField>
                                                            </th> */}

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {t("Capital")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="capital1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.capital}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="capital2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.capital}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="capital3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.capital}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Shareholders subscribed uncalled capital")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="shareholdersSubscribedUncalledCapital1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.shareholdersSubscribedUncalledCapital}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="shareholdersSubscribedUncalledCapital2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.shareholdersSubscribedUncalledCapital}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="shareholdersSubscribedUncalledCapital3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.shareholdersSubscribedUncalledCapital}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Premiums related to share capital")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="premiumsRelatedtoShareCapital1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.premiumsRelatedtoShareCapital}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="premiumsRelatedtoShareCapital2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.premiumsRelatedtoShareCapital}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="premiumsRelatedtoShareCapital3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.premiumsRelatedtoShareCapital}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Revaluation difference")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="revaluationDifference1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.revaluationDifference}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="revaluationDifference2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.revaluationDifference}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="revaluationDifference3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.revaluationDifference}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Reserves not available for distribution")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="reservesNotAvailableForDistribution1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.reservesNotAvailableForDistribution}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="reservesNotAvailableForDistribution2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.reservesNotAvailableForDistribution}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="reservesNotAvailableForDistribution3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.reservesNotAvailableForDistribution}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Legal Reserve")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="legalReserve1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.legalReserve}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="legalReserve2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.legalReserve}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="legalReserve3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.legalReserve}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Retained Earnings")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="retainedEarnings1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.retainedEarnings}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="retainedEarnings2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.retainedEarnings}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="retainedEarnings3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.retainedEarnings}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Profit or loss for the financial year")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="profitOrLossForTheFinancialYear1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.profitOrLossForTheFinancialYear}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="profitOrLossForTheFinancialYear2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.profitOrLossForTheFinancialYear}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="profitOrLossForTheFinancialYear3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.profitOrLossForTheFinancialYear}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Investment grants")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="investmentGrants1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.investmentGrants}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="investmentGrants2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.investmentGrants}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="investmentGrants3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.investmentGrants}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Regulated provisions")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="regulatedProvisions1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.regulatedProvisions}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="regulatedProvisions2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.regulatedProvisions}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="regulatedProvisions3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.regulatedProvisions}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                                            <th style={{ width: '70%' }}>
                                                                {t("Total Owner's Equity")}
                                                            </th>
                                                            <th style={{ width: '30%' }}>{totalOwnersEquity1?.toLocaleString()}</th>
                                                            {/* <th style={{ width: '16.66666666666667%' }}>{totalOwnersEquity2?.toLocaleString()}</th>
                                                            <th style={{ width: '16.66666666666667%' }}>{totalOwnersEquity3?.toLocaleString()}</th> */}

                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Loans and debt from financial institutions")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="loansandDebtFromFinancialInstitutions1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.loansandDebtFromFinancialInstitutions}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="loansandDebtFromFinancialInstitutions2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.loansandDebtFromFinancialInstitutions}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="loansandDebtFromFinancialInstitutions3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.loansandDebtFromFinancialInstitutions}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Leasing debt")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="leasingDebt1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.leasingDebt}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="leasingDebt2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.leasingDebt}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="leasingDebt3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.leasingDebt}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Provisions")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="provisions1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.provisions}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="provisions2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.provisions}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="provisions3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.provisions}
                                                                />
                                                            </td> */}
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                {t("Total Dettes Financieres Et Ressources Assimilees")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="totalDettesFinancieresEtRessourcesAssimilees1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    value={totalFinancial1}
                                                                    disabled
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="totalDettesFinancieresEtRessourcesAssimilees2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    value={totalFinancial2}
                                                                    disabled
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="totalDettesFinancieresEtRessourcesAssimilees3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    value={totalFinancial3}
                                                                    disabled
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                                            <th style={{ width: '70%' }}>
                                                                {t("Fixed Liabilities")}
                                                            </th>
                                                            <th style={{ width: '30%' }}>{fixedLiabilities1?.toLocaleString()}</th>
                                                            {/* <th style={{ width: '16.66666666666667%' }}>{fixedLiabilities2?.toLocaleString()}</th>
                                                            <th style={{ width: '16.66666666666667%' }}>{fixedLiabilities3?.toLocaleString()}</th> */}

                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Current debt")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="currentDebt1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.currentDebt}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="currentDebt2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.currentDebt}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="currentDebt3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.currentDebt}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Advance payments on account received on orders in progress")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="advancePaymentsonAccountReceivedonOrdersInProgress1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.advancePaymentsonAccountReceivedonOrdersInProgress}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="advancePaymentsonAccountReceivedonOrdersInProgress2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.advancePaymentsonAccountReceivedonOrdersInProgress}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="advancePaymentsonAccountReceivedonOrdersInProgress3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.advancePaymentsonAccountReceivedonOrdersInProgress}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Accounts payable and related accounts")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="accountspayableAndRelatedAccounts1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.accountspayableAndRelatedAccounts}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="accountspayableAndRelatedAccounts2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.accountspayableAndRelatedAccounts}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="accountspayableAndRelatedAccounts3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.accountspayableAndRelatedAccounts}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Tax and social security debts")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="taxAndSocialSecurityDebts1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.taxAndSocialSecurityDebts}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="taxAndSocialSecurityDebts2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.taxAndSocialSecurityDebts}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="taxAndSocialSecurityDebts3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.taxAndSocialSecurityDebts}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Other loans and similar debts")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="otherLoansAndSimilarDebts1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.otherLoansAndSimilarDebts}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="otherLoansAndSimilarDebts2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.otherLoansAndSimilarDebts}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="otherLoansAndSimilarDebts3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.otherLoansAndSimilarDebts}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Unrealized foreign exchange gains and valuation differences")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="unrealizedForeignExchangeGainsAndValuationDifferences1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.unrealizedForeignExchangeGainsAndValuationDifferences}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="unrealizedForeignExchangeGainsAndValuationDifferences2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.unrealizedForeignExchangeGainsAndValuationDifferences}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="unrealizedForeignExchangeGainsAndValuationDifferences3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.unrealizedForeignExchangeGainsAndValuationDifferences}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                                            <th style={{ width: '70%' }}>
                                                                {t("Current liabilities")}
                                                            </th>
                                                            <th style={{ width: '30%' }}>{currentLiabilities1?.toLocaleString()}</th>
                                                            {/* <th style={{ width: '16.66666666666667%' }}>{currentLiabilities2?.toLocaleString()}</th>
                                                            <th style={{ width: '16.66666666666667%' }}>{currentLiabilities3?.toLocaleString()}</th> */}

                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Other payables")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="otherPayables1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.otherPayables}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="otherPayables2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.otherPayables}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="otherPayables3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.otherPayables}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Short-term bank advances")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="shortTermBankAdvances1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.shortTermBankAdvances}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="shortTermBankAdvances2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.shortTermBankAdvances}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="shortTermBankAdvances3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.shortTermBankAdvances}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                                            <th style={{ width: '70%' }}>
                                                                {t("Total tresorerie-passif")}
                                                            </th>
                                                            <th style={{ width: '30%' }}>{totalCashLiabilities1?.toLocaleString()}</th>
                                                            {/* <th style={{ width: '16.66666666666667%' }}>{totalCashLiabilities2?.toLocaleString()}</th>
                                                            <th style={{ width: '16.66666666666667%' }}>{totalCashLiabilities3?.toLocaleString()}</th> */}

                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Valuation difference - Liabilities")}
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="valuationDifferenceLiabilities1"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year1?.valuationDifferenceLiabilities}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                <AvField
                                                                    name="valuationDifferenceLiabilities2"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year2?.valuationDifferenceLiabilities}
                                                                />
                                                            </td>
                                                            <td>
                                                                <AvField
                                                                    name="valuationDifferenceLiabilities3"
                                                                    className="form-control"
                                                                    // placeholder="Sales of goods for resale"
                                                                    type="number"
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    // required
                                                                    value={year3?.valuationDifferenceLiabilities}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                                            <th style={{ width: '70%' }}>
                                                                {t("Total liabilities")}
                                                            </th>
                                                            <th style={{ width: '30%' }}>{totalLiabilities1?.toLocaleString()}</th>
                                                            {/* <th style={{ width: '16.66666666666667%' }}>{totalLiabilities2?.toLocaleString()}</th>
                                                            <th style={{ width: '16.66666666666667%' }}>{totalLiabilities3?.toLocaleString()}</th> */}

                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>

                                        </tr>

                                    </tbody>
                                </Table>
                            </Col>

                        </Row>

                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <div className="text-center">
                            <Button type="submit" className='btn_bg' color="">
                                {t("Save")}
                            </Button>
                            <Button type="submit" onClick={() => setType('submit')} className='btn_bg mx-5' color="">
                                {t("Save & Submit")}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </AvForm>


        </React.Fragment>
    )
}

Liabilities.propTypes = {
    t: PropTypes.any,
    profileSuccess: PropTypes.func
}

const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems, profileSuccess })(Liabilities)
)





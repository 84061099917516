import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"

const EsgDetails = props => {
    // console.log('esg details', props?.location?.state?.data)
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [idx, setidx] = useState(1)

    const { i18n, t } = useTranslation();

    const [sdg, setSdg] = useState([]);
    const [region, setRegion] = useState([]);
    const [investment, seInvestment] = useState([]);
    const [grossMargin, setGrossMargin] = useState(0);
    const [turnover, setTurnover] = useState(0);
    const [addedValue, setAddedValue] = useState(0);
    const [grossOperating, setGrossOperating] = useState(0);
    const [totalOperating, setTotalOperating] = useState(0);
    const [totalFinancial, setTotalFinancial] = useState(0);
    const [pretaxprofit, setPretaxprofit] = useState(0);
    const [exceptionalProfit, setExceptionalProfit] = useState(0);
    const [profitorLoss, setProfitorLoss] = useState(0);
    const [wastemanagement, setWastemanagement] = useState(false);
    const [hSSmanagement, setHSSmanagement] = useState(false);
    const [administrationBoard, setAdministrationBoard] = useState(false);
    const [explicitdisclosure, setExplicitdisclosure] = useState(false);
    const [separation, setSeparation] = useState(false);
    const formRef = useRef();
    const [data, setData] = useState(null);

    useEffect(() => {
        setData(props?.location?.state?.data)
    }, [, props])
    const wasteManagement = (e) => {
        if (e.target?.value == "Yes") {
            setWastemanagement(true)
        } else {
            setWastemanagement(false)
        }
    }
    const administrationBoardFun = (e) => {
        if (e.target?.value == "Yes") {
            setAdministrationBoard(true)
        } else {
            setAdministrationBoard(false)
        }
    }
    const explicitdisclosureFun = (e) => {
        if (e.target?.value == "Yes") {
            setExplicitdisclosure(true)
        } else {
            setExplicitdisclosure(false)
        }
    }
    const separationFun = (e) => {
        if (e.target?.value == "Yes") {
            setSeparation(true)
        } else {
            setSeparation(false)
        }
    }


    const HSSmanagementFun = (e) => {
        if (e.target?.value == "Yes") {
            setHSSmanagement(true)
        } else {
            setHSSmanagement(false)
        }
    }




    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"))
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                setname(obj.displayName)
                setemail(obj.email)
                setidx(obj.uid)
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                setname(obj.username)
                setemail(obj.email)
                setidx(obj.uid)
            }
            setTimeout(() => {
                props.resetProfileFlag();
            }, 3000);
        }
    }, [props.success])

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "ESG", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('ESG', breadcrumbItems)
    }, [])

    function handleValidSubmit(event, values) {
        let body = {
            ...values,
            XA: grossMargin,
            XB: turnover,
            XC: addedValue,
            XD: grossOperating,
            XE: totalOperating,
            XF: totalFinancial,
            XG: pretaxprofit,
            XH: exceptionalProfit,
            XI: profitorLoss
        }
        // console.log('body',body)
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>ESG | Demleen </title>
            </MetaTags>


            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
                ref={formRef}
            >
                {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
                <Card>
                    <CardBody>

                        <Row>
                            <Col lg={12}>
                                <Table

                                >

                                    <tbody >

                                        <tr>
                                            <td style={{ padding: 0, border: 'none' }} colspan="2">
                                                <Table
                                                    bordered
                                                >
                                                    <thead >
                                                        <tr className='right_head'>
                                                            <th colspan="5">{t("ESG")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data?.map((item, index) => {
                                                                // console.log('seg detail lis',item)
                                                                return (
                                                                    <>
                                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_theree'>

                                                                            <th colSpan={2} style={{ width: '70%' }}>
                                                                                {i18n?.language == 'en' ? item?.title:item?.titleFs}
                                                                            </th>
                                                                            {/* <th style={{ width: '20%' }}>{t("Type")}</th> */}


                                                                        </tr>

                                                                        <tr className='second_head_theree'>

                                                                            <th colSpan={2} style={{ width: '70%' }}>
                                                                                {t("Environmental impact is a key topic and many businesses communicate on their energy consumption levels to demonstrate their ability to manage and or mitigate these. Please specify")}
                                                                            </th>

                                                                        </tr>
                                                                        <tr>

                                                                            <td>
                                                                                {t("What is your total energy consumption (MWh/m€) for the past 12 months?")}
                                                                            </td>

                                                                            <td>
                                                                                <AvField
                                                                                    type="number"
                                                                                    name="Amount"
                                                                                />
                                                                            </td>

                                                                        </tr>
                                                                        <tr>

                                                                            <td>
                                                                                {t("What is the level of your water consumption (in L)?")}
                                                                            </td>

                                                                            <td>
                                                                                <AvField
                                                                                    type="number"
                                                                                    name="Amount"
                                                                                />
                                                                            </td>

                                                                        </tr>
                                                                        <tr>

                                                                            <td>
                                                                                {t("Does the organisation have a waste management policy for the organisation (if yes, please specifiy)")}
                                                                            </td>

                                                                            <td>
                                                                                <div className="form-group form_group">

                                                                                    <AvRadioGroup inline name="existance" onChange={wasteManagement}>
                                                                                        <AvRadio label={t("Yes")} value="Yes" />
                                                                                        <AvRadio label={t("No")} value="No" />
                                                                                    </AvRadioGroup>
                                                                                </div>
                                                                                {
                                                                                    wastemanagement == true && <Col lg={12} md={12}>
                                                                                        <div className="form-group">
                                                                                            <AvField
                                                                                                name="activity_description"

                                                                                                className="form-control"
                                                                                                placeholder={t("Short Description")}
                                                                                                type="textarea"

                                                                                            />

                                                                                        </div>
                                                                                    </Col>
                                                                                }
                                                                            </td>

                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }


                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_theree'>

                                                            <th colSpan={2} style={{ width: '80%' }}>
                                                                {t("Social")}
                                                            </th>
                                                            {/* <th style={{ width: '20%' }}>{t("Type")}</th> */}


                                                        </tr>

                                                        <tr className='second_head_theree'>

                                                            <th colSpan={2} style={{ width: '80%' }}>
                                                                {t("The social impact of your entreprise is a key aspect to highlight. The following questions are created in that context. Please specify")}
                                                            </th>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Number of current employees ?")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Number of women employed ?")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Number of women employed as key decision partner ?")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Number of employees with a disability ?")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Number of permanent employees ?")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Number of non-permament employees ?")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Number of employees under 35 years old ?")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Average number of training hours per employee (total training hours / total workforce) ?")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Number of beneficiaries related to the good pursuit of the business (number of children, or spouses or parents financially supported bu the employees)")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Existence of an HSS management system (health, safety, security) ?")}
                                                            </td>

                                                            <td>
                                                                <div className="form-group form_group">

                                                                    <AvRadioGroup inline name="HSSmanagement" onChange={HSSmanagementFun}>
                                                                        <AvRadio label={t("Yes")} value="Yes" />
                                                                        <AvRadio label={t("No")} value="No" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                                {
                                                                    hSSmanagement == true && <Col lg={12} md={12}>
                                                                        <div className="form-group">
                                                                            <AvField
                                                                                name="activity_description"

                                                                                className="form-control"
                                                                                placeholder={t("Short Description")}
                                                                                type="textarea"

                                                                            />

                                                                        </div>
                                                                    </Col>
                                                                }
                                                            </td>

                                                        </tr>
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_theree'>

                                                            <th colSpan={2} style={{ width: '80%' }}>
                                                                {t("Governance")}
                                                            </th>
                                                            {/* <th style={{ width: '20%' }}>{t("Type")}</th> */}


                                                        </tr>

                                                        <tr className='second_head_theree'>

                                                            <th colSpan={2} style={{ width: '80%' }}>
                                                                {t("Having a governance policy is considered as a key step for the sustainability of any business on the path to growth. It also reassures financial partners. Please specify")}
                                                            </th>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("How many persons do own your company ?")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("How much is owned by the first shareholder ?")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Have you established an Administration Board ?")}
                                                            </td>

                                                            <td>
                                                                <div className="form-group form_group">

                                                                    <AvRadioGroup inline name="administrationBoard" onChange={administrationBoardFun}>
                                                                        <AvRadio label={t("Yes")} value="Yes" />
                                                                        <AvRadio label={t("No")} value="No" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                                {
                                                                    administrationBoard == true && <Col lg={12} md={12}>
                                                                        <div className="form-group">
                                                                            <AvField
                                                                                name="activity_description"

                                                                                className="form-control"
                                                                                placeholder={t("Short Description")}
                                                                                type="textarea"

                                                                            />

                                                                        </div>
                                                                    </Col>
                                                                }
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Number of independent board members for your company ?")}
                                                            </td>

                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="Amount"
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Have you done a explicit disclosure of formalised business Code of Conduct and Corruption Policy, that is available?")}
                                                            </td>

                                                            <td>
                                                                <div className="form-group form_group">

                                                                    <AvRadioGroup inline name="explicitdisclosure" onChange={explicitdisclosureFun}>
                                                                        <AvRadio label={t("Yes")} value="Yes" />
                                                                        <AvRadio label={t("No")} value="No" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                                {
                                                                    explicitdisclosure == true && <Col lg={12} md={12}>
                                                                        <div className="form-group">
                                                                            <AvField
                                                                                name="activity_description"

                                                                                className="form-control"
                                                                                placeholder={t("Short Description")}
                                                                                type="textarea"

                                                                            />

                                                                        </div>
                                                                    </Col>
                                                                }
                                                            </td>

                                                        </tr>
                                                        <tr>

                                                            <td>
                                                                {t("Is there a separation of the roles of CEO and Chair of Board ?")}
                                                            </td>

                                                            <td>
                                                                <div className="form-group form_group">

                                                                    <AvRadioGroup inline name="separation" onChange={separationFun}>
                                                                        <AvRadio label={t("Yes")} value="Yes" />
                                                                        <AvRadio label={t("No")} value="No" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                                {
                                                                    separation == true && <Col lg={12} md={12}>
                                                                        <div className="form-group">
                                                                            <AvField
                                                                                name="activity_description"

                                                                                className="form-control"
                                                                                placeholder={t("Short Description")}
                                                                                type="textarea"

                                                                            />

                                                                        </div>
                                                                    </Col>
                                                                }
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>

                                        </tr>

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <div className="text-center">
                            <Button type="submit" className='btn_bg' color="">
                                {t("Submit")}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </AvForm>


        </React.Fragment>
    )
}

EsgDetails.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {
    const { error, success } = state.Profile
    return { error, success }
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag, setBreadcrumbItems })(EsgDetails)
)
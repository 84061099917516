import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react";
import {
    Col,
    Card,
    CardBody,
    Button,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import "../Tables/datatables.scss"
import classnames from "classnames"
import { get, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import ProfitandLoss from './ProfitandLoss';
import CashFlow from './CashFlow';
import BalanceSheet from './BalanceSheet';
import ProfileData from './ProfileData';
import Neuroprofiler from './Neuroprofiler';
import ReportMemo from './ReportMemo';
import RangeSlider from 'react-bootstrap-range-slider';
import moment from 'moment';
import Loader from 'components/Loader';
import IncomestatementanalysisChart from 'pages/AllCharts/chartjs/incomeChart';
import BarApexChart from "../AllCharts/apex/balanceChart1";
import GraphThree from '../AllCharts/apex/graphthreeChart';
import BalancepieChart from '../AllCharts/apex/balancepieChart';
import BalancepieChart1 from '../AllCharts/apex/balancepieChart1';
import Profitability from 'components/CommonForBoth/ProfitabilityRatio';
import Liquidity from 'components/CommonForBoth/LiquidityRatio';
import Solvency from 'components/CommonForBoth/SolvencyRatio';
import { useScreenshot } from 'use-react-screenshot'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import html2PDF from 'jspdf-html2canvas';
import * as htmlToImage from 'html-to-image';
import ReportMemo1 from './ReportMemo1';
import Neuroprofiler1 from './Neuroprofiler1';

const dataAnlysis = [
    {
        title: 'Revenue Growth',
        ratio: 2.5,
        desc: "Revenue Growth Desc"
    },
    {
        title: 'Gross Margin',
        ratio: 2.5,
        desc: "Gross Margin Desc"
    },
    {
        title: 'Operating Expense',
        ratio: 2.5,
        desc: "Operating Expense Desc"
    },
    {
        title: 'EBITDA Margin',
        ratio: 2.5,
        desc: "EBITDA Margin Desc"
    },
    {
        title: 'Operating Profit Margin',
        ratio: 2.5,
        desc: "Operating Profit Margin Desc"
    },
    {
        title: 'Return On Assets',
        ratio: 2.5,
        desc: "Return On Assets Desc"
    },
    {
        title: 'Return On Equity',
        ratio: 2.5,
        desc: "Return On Equity Desc"
    }
]

const dataAnlysis2 = [
    {
        title: 'Net Working Capital',
        ratio: 2.5,
        desc: "Net Working Capital Desc",
        label: 'Amount'
    },
    {
        title: 'Net Cash',
        ratio: 2.5,
        desc: "Net Cash Desc",
        label: 'Amount'
    },
    {
        title: 'Days Of Sales Outstanding',
        ratio: 2.5,
        desc: "Days Of Sales Outstanding Desc"
    },
    {
        title: 'Days Of Invertory On Hands',
        ratio: 2.5,
        desc: "Days Of Invertory On Hands Desc"
    },
    {
        title: 'Number Of Days Of Payables',
        ratio: 2.5,
        desc: "Number Of Days Of Payables Desc"
    },
    {
        title: 'Cash Conversion Cycle',
        ratio: 2.5,
        desc: "Cash Conversion Cycle Desc"
    },
    {
        title: 'Working Capital',
        ratio: 2.5,
        desc: "Working Capital Desc",
        label: 'Amount'
    },
    {
        title: 'Equity-to-Working Capital Ratio',
        ratio: 2.5,
        desc: "Equity-to-Working Capital Ratio Desc",
        label: 'Times'
    },
    {
        title: 'Current Ratio',
        ratio: 2.5,
        desc: "Current Ratio Desc",
        label: 'Times'
    },
    {
        title: 'Liquidity Cover',
        ratio: 2.5,
        desc: "Liquidity Cover Desc",
        label: 'Times'
    },
    {
        title: 'Cash Ratio',
        ratio: 2.5,
        desc: "Cash ratio Desc",
        label: 'Times'
    }
]


const SearchDetails = (props) => {
    const { t } = useTranslation();
    const [customActiveTab, setCustomActiveTab] = useState('7')
    const [years, setYears] = useState('');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [value1, setValue1] = useState(0);
    const [value2, setValue2] = useState(0);

    const { user } = props;
    const { userId } = props?.location.state;
    const [esgQues, setEsgQues] = useState([]);
    const [year1, setYear1] = useState(undefined);
    const [year2, setYear2] = useState(undefined);
    const [year3, setYear3] = useState(undefined);
    const [restKey, seRestKey] = useState(undefined);
    const [financeQues, setFinanceQues] = useState([]);
    const [data, setData] = useState(null);
    const [ratioCurrentTab, setRatioCurrentTab] = useState("1");
    const [ratioTab, setRatioTab] = useState([]);
    const [ratioYears, setRatioYears] = useState([]);
    const [ratioCurrentData, setRatioCurrentData] = useState(null);
    const [currentGraphTab, setCurrentGraphTab] = useState(0);
    const [graphTitle, setGraphTitle] = useState(undefined);
    const [graphValue, setGraphValue] = useState(undefined);
    const [title, setTitle] = useState(undefined);
    const [allGraph, setAllGraph] = useState(undefined);
    const [typeGraph, setTypeGraph] = useState(undefined);
    const [horizontal, setHorizontal] = useState(false);
    const [breadcrum, setBreadcrum] = useState(undefined);
    const divRef = useRef(null);
    const [image, takeScreenshot] = useScreenshot();
    const [img, setImg] = useState();
    const [screenshot, setScreenshot] = useState(null);


    const getImage = async () => {

        // Define the HTML content as a string
        var printContents = document.getElementById('divToExport').innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;

        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();


    };

    //     const getImage = async () => {
    //         const canvas = await html2canvas(document.getElementById('data'));
    //         const dataURL = canvas.toDataURL();
    //         setScreenshot(dataURL);


    //         // const invoice = await divRef?.current;
    //         // console.log('invoice1', invoice);
    //         // var opt = {
    //         //     margin: 1,
    //         //     filename: 'myfile.pdf',
    //         //     image: { type: 'jpeg', quality: 0.98 },
    //         //     html2canvas: { scale: 2 },
    //         //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    //         // };
    //         //   if(invoice)
    //         //   html2pdf().from(invoice).set(opt).save();
    //     }

    // console.log('screenshot',screenshot)


    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: `${breadcrum ? breadcrum : 'Company name'}`, link: "#" },
    ];

    // console.log('sme list page',  typeof breadcrum, String(breadcrum))

    useEffect(() => {
        props.setBreadcrumbItems(breadcrum, breadcrumbItems);
    }, [data]);

    useEffect(() => {
        if (customActiveTab == "3" || customActiveTab == "9")
            getEsgAnswers();
        if (customActiveTab == "4" || customActiveTab == "9")
            getFinanceAnswers();
    }, [customActiveTab, user]);

    const toggleCustom = (tab) => {
        if (setCustomActiveTab !== tab) {
            setCustomActiveTab(tab)
        }
    }
    useEffect(() => {
        if (user?.token)
            getUserData();
    }, [user?.token]);

    useEffect(() => {
        if (user?.token) {
            if (ratioCurrentTab == "1")
                getRatioData1();
            if (ratioCurrentTab == "2")
                getRatioData2();
        }
    }, [ratioCurrentTab, user, years]);

    const getUserData = () => {
        let body = {
            userId: userId,
            token: user?.token,
        }
        post('/user', body)
            .then((res => {
                // setLoading(false);
                if (res?.statusCode == 200) {
                    // console.log('request on login', res);
                    setBreadcrum(res?.data?.companyName)
                    setData(res?.data)
                    setValue(res?.data?.esgRating?.rating)
                    setValue1(res?.data?.financeRating?.rating)
                    setValue2(res?.data?.ratioRating?.rating)

                } else {
                    console.log(res?.error);
                }
            }))
            .catch(error => {
                // setLoading(false);
                console.log('error while login', error);
                console.log('Something went wrong');
            })
    }

    // console.log('userid on seaarch details', userId, props);

    const getEsgAnswers = () => {
        if (userId) {
            if (!loading) {
                setLoading(true);
                get("/esg/answer/user?userId=" + userId, { token: user?.token })
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setEsgQues(res?.data);
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("while get Esg user data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const getFinanceAnswers = () => {
        if (userId) {
            if (!loading) {
                setLoading(true);
                get("/finance/answer/user?userId=" + userId, { token: user?.token })
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setFinanceQues(res?.data);
                            seRestKey(res?.restKey)
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("while get Esg user data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    function handleValidSubmit1(e, v) {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                userId: userId,
                token: user?.token,
                esgRating: {
                    comment: v?.esgComment,
                    rating: value
                }
            }
            put('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        getUserData();
                        toast.success(res?.message)
                        // setValue(0)
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }
    function handleValidSubmit2(e, v) {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                userId: userId,
                token: user?.token,
                financeRating: {
                    comment: v?.financeComment,
                    rating: value1
                },
            }
            put('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        getUserData();
                        toast.success(res?.message)
                        // setValue(0)
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    function handleValidSubmit3(e, v) {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                userId: userId,
                token: user?.token,
                ratioRating: {
                    comment: v?.ratioComment,
                    rating: value2
                },
            }
            put('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        getUserData();
                        toast.success(res?.message)
                        // setValue(0)
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    const getRatioData1 = () => {
        let body = {
            token: user?.token,
        }
        let url = `/ratio/profit?userId=${userId}`;
        if (years)
            url = `/ratio/profit?year=${years}&userId=${userId}`
        get(url, body)
            .then((res) => {
                if (res?.statusCode == 200) {
                    // console.log('ration res', res?.data)
                    if (res?.data) {
                        if (res?.data?.years?.length) {
                            if (years == '') {
                                setYears(res?.data?.years[0])
                            }
                            let yearsArr = res?.data?.years?.reverse();
                            setRatioYears(yearsArr)
                        }
                        let temp = [];
                        res?.data?.allGraprh?.map((item) => (
                            temp.push(item?.title)
                        ))
                        // console.log('ration temp', temp)
                        setRatioTab(res?.data?.loop)
                        if (res?.data?.loop?.length)
                            res?.data?.loop?.forEach((item) => {
                                dataAnlysis?.forEach((dat) => {
                                    if (dat?.title == item?.title)
                                        item.desc = dat.desc;
                                })
                            })
                        setRatioCurrentData(res?.data?.loop[0]);
                        setCurrentGraphTab(0);
                        if (res?.data?.loop?.length > 0) {
                            let graphT = [];
                            res?.data?.loop[0]?.graph.map((item) => (
                                graphT.push(item?.title)
                            ))
                            setGraphTitle(graphT?.reverse())
                            let graphV = [];
                            res?.data?.loop[0]?.graph.map((item) => (
                                graphV.push(item?.value)
                            ))
                            setGraphValue(graphV?.reverse())
                        }
                        setTitle(temp);
                        let temp1 = [];
                        res?.data?.allGraprh?.map((item) => (
                            temp1.push(item?.value)
                        ))
                        setValue(temp1);
                    } else {
                        console.error('Data is undefined in the response');
                    }
                }
            }).catch((err) => {
                console.log('err', err)
            })
    }

    const getRatioData2 = () => {
        setLoading(true);
        let body = {
            userId,
            token: user?.token,
        }
        let url = '/ratio/liquid?userId=' + userId;
        get(url, body)
            .then((res) => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    if (res?.data) {
                        if (res?.data?.years?.length) {
                            if (years == '') {
                                setYears(res?.data?.years[0])
                            }
                            let yearsArr = res?.data?.years?.reverse();
                            setRatioYears(yearsArr)
                            setAllGraph(res?.data?.allGraprh)
                            setRatioTab(res?.data?.loop);
                            let typeG = localStorage.getItem('type');
                            if (res?.data?.loop?.length) {
                                if (typeG) {
                                    setTypeGraph(typeG)
                                } else {
                                    setTypeGraph(res?.data?.loop[0]?.type)
                                }
                                setRatioCurrentData(res?.data?.loop[0])
                            }
                            if (res?.data?.loop?.length > 0) {
                                res?.data?.loop?.forEach((item) => {
                                    dataAnlysis2?.forEach((dat) => {
                                        if (dat?.title == item?.title)
                                            item.desc = dat.desc;
                                    })
                                })
                                let graphT = [];
                                res?.data?.loop[0]?.graph.map((item) => (
                                    graphT.push(item?.title)
                                ))
                                setGraphTitle(graphT?.reverse())
                                let graphV = [];
                                res?.data?.loop[0]?.graph.map((item) => (
                                    graphV.push(item?.value)
                                ))
                                setGraphValue(graphV?.reverse())
                            }
                        } else {
                            console.error('Data is undefined in the response');
                        }
                    }
                } else {
                    toast.error("Something Went wrong!");
                }
            }).catch((err) => {
                setLoading(false);
                console.log('err', err)
            })
    }

    useEffect(() => {
        if (allGraph?.length) {
            let temp = [];
            let temp1 = [];
            if (typeGraph == '5') {
                temp.push(allGraph[0]?.title)
                temp.push(allGraph[1]?.title)
                temp.push(allGraph[6]?.title)
            }
            if (typeGraph == '6') {
                temp.push(allGraph[2]?.title)
                temp.push(allGraph[3]?.title)
                temp.push(allGraph[4]?.title)
                temp.push(allGraph[5]?.title)
            }
            if (typeGraph == '3') {
                temp.push(allGraph[7]?.title)
                temp.push(allGraph[8]?.title)
                temp.push(allGraph[9]?.title)
                temp.push(allGraph[10]?.title)
            }
            if (typeGraph == '3') {
                temp1.push(allGraph[7]?.value)
                temp1.push(allGraph[8]?.value)
                temp1.push(allGraph[9]?.value)
                temp1.push(allGraph[10]?.value)
            }
            if (typeGraph == '6') {
                temp1.push(allGraph[2]?.value)
                temp1.push(allGraph[3]?.value)
                temp1.push(allGraph[4]?.value)
                temp1.push(allGraph[5]?.value)
            }
            if (typeGraph == '5') {
                temp1.push(allGraph[0]?.value);
                temp1.push(allGraph[1]?.value);
                temp1.push(allGraph[6]?.value);
            }
            setTitle(temp)
            setValue(temp1)
        }
    }, [allGraph, years, typeGraph])

    const changeTab = (item, index) => {
        setLoading(true);
        setCurrentGraphTab(index)
        console.log('indexindexindex', index)
        setRatioCurrentData(item)
        if (item?.graph?.length > 0) {
            let graphT = [];
            item?.graph.map((item) => (
                graphT.push(item?.title)
            ))
            setGraphTitle(graphT?.reverse())
            let graphV = [];
            item?.graph.map((item) => (
                graphV.push(item?.value)
            ))
            setGraphValue(graphV?.reverse())
        }
        setLoading(false);
        // setCurruntData(explainData?.loop[index])
    }

    const changeYearsData = (item) => {
        let body = {
            token: user?.token,
        }
        if (item)
            get(`/ratio/liquid?year=${item}`, body)
                .then((res) => {
                    if (res?.statusCode == 200) {
                        // console.log('ration res', res?.data)
                        if (res?.data) {
                            if (res?.data?.years?.length) {
                                let yearsArr = res?.data?.years?.reverse();
                                setRatioYears(yearsArr)
                                setAllGraph(res?.data?.allGraprh)
                            } else {
                                console.error('Data is undefined in the response');
                            }
                        }
                    }
                }).catch((err) => {
                    console.log('err', err)
                })
    }

    // const handleExportPdf = async () => {
    //     try {
    //         const divToExport = document.getElementById('divToExport');
    //         html2canvas(divToExport)
    //             .then(canvas => {
    //                 console.log("canvas", canvas);
    //                 const imgData = canvas.toDataURL('image/png');
    //                 console.log("pdf image data", imgData);
    //                 const pdf = new jsPDF('p', 'mm', 'a4');
    //                 const imgWidth = 210;
    //                 const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //                 pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    //                 pdf.save('exported-div.pdf');
    //             })
    //             .catch(error => {
    //                 console.error('Error exporting to PDF:', error);
    //             });
    //         // const divToExport = divRef.current;

    //         // html2canvas(divToExport)
    //         // .then(canvas => {
    //         //   const imgData = canvas.toDataURL('image/png');
    //         // const imgData = await getImage()
    //         //     .then(() => {
    //         //         // console.log("image data then", imgData, image);
    //         //     })
    //         // console.log("image data", imgData, image);
    //         // const pdf = new jsPDF();
    //         // const imgWidth = 210; // Width of A4 in mm
    //         // const imgHeight = (canvas.height * imgWidth) / canvas.width;

    //         // pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    //         // pdf.save('exported-div.pdf');
    //         // });
    //     } catch (error) {
    //         console.log("error while exporting pdf", error);
    //     }
    // };



    return (
        <React.Fragment>
            <MetaTags>
                <title>Profit and Loss account | Demleen </title>
            </MetaTags>
            <Loader loading={loading} />
            <Row>

                <Col className="col-12">
                    <Card id='data'>
                        <CardBody>
                            <Nav tabs className="nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "7",
                                        })}
                                        onClick={() => {
                                            toggleCustom("7")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Profile")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleCustom("1")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Ratio Analysis")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleCustom("2")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Profit & Loss")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "5",
                                        })}
                                        onClick={() => {
                                            toggleCustom("5")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Cash Flow")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "6",
                                        })}
                                        onClick={() => {
                                            toggleCustom("6")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Balance Sheet")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "3",
                                        })}
                                        onClick={() => {
                                            toggleCustom("3")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("ESG Form")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "4",
                                        })}
                                        onClick={() => {
                                            toggleCustom("4")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Financing Request")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "8",
                                        })}
                                        onClick={() => {
                                            toggleCustom("8")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Neuroprofiler")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "9",
                                        })}
                                        onClick={() => {
                                            toggleCustom("9")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Report Memo")}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardBody>
                    </Card>
                    {/* <img width={210} src={img} alt={'Screenshot'} /> */}
                    <Card>
                        <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <Nav tabs className="nav-tabs-custom">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: ratioCurrentTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        setRatioCurrentTab("1");
                                                        setRatioCurrentData(dataAnlysis[0]);
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block tab_text">{t("Income Statement Analysis")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: ratioCurrentTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        setRatioCurrentTab("2");
                                                        setRatioCurrentData(dataAnlysis2[0]);
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block tab_text">{t("Balance Sheet Analysis")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: ratioCurrentTab === "3",
                                                    })}
                                                    onClick={() => {
                                                        setRatioCurrentTab("3");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block tab_text">{t("Leverage Analysis")}</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                    <TabContent activeTab={ratioCurrentTab} className="p-3 text-muted">
                                        <TabPane tabId="1">
                                            <Profitability userId={userId} user={user} />
                                            {/* <Col sm="12">
                                                <Card>
                                                    <CardBody>
                                                        <h6 className='explain-text mb-4'><strong><span>{t("profit_chat_label")} : {years}</span></strong></h6>
                                                        {ratioTab?.length > 0 &&
                                                            <div className='top_ratio mb-4'>
                                                                <ul className='ratio_list'>
                                                                    {
                                                                        ratioTab?.map((item, index) => (
                                                                            <li className={index == currentGraphTab ? 'active' : null} onClick={() => changeTab(item, index)}>
                                                                                <h3>{t(item?.title)}</h3>
                                                                                <h1>{Number(item?.value).toLocaleString()} {t(item?.valueType)}</h1>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </div>
                                                        }
                                                        <p className='explain-text mb-4'><span>{t(ratioCurrentData?.title)} :</span> {t(ratioCurrentData?.desc)}</p>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <Card>
                                                                    <CardBody>
                                                                        <h4 className='text-center mb-3 grap_heading'>{t(ratioCurrentData?.title)}</h4>
                                                                        <div className='year_chart'>
                                                                            {
                                                                                graphTitle && graphValue && <IncomestatementanalysisChart label={ratioCurrentData?.title} title={graphTitle} value={graphValue} />
                                                                            }
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Card>
                                                                    <CardBody>
                                                                        <h4 className='text-center mb-3 grap_heading'>{t("Profitability Ratios")}</h4>
                                                                        <div className='ratio_year_tabs'>
                                                                            <ul>
                                                                                {
                                                                                    ratioYears?.map((item, index) => (
                                                                                        <li>
                                                                                            <button onClick={() => setYears(item)} className={item == years ? 'active' : null}>{item}</button>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                        <div className='year_chart mt-3'>
                                                                            {
                                                                                title ? <BarApexChart t={t} data={title} value={value} /> : null
                                                                            }
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col> */}
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Liquidity userId={userId} user={user} />
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Solvency userId={userId} user={user} />
                                        </TabPane>
                                    </TabContent>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">

                                        <div className='search_expended_component'>
                                            <h3>{t("Profit & Loss")}</h3>
                                            {
                                                customActiveTab == '2' &&
                                                <ProfitandLoss user={user} userId={userId} data={data} isComment={false} />
                                            }

                                        </div>

                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="3">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("ESG Form")}</h3>
                                            <table class="table normel_tabkle table-bordered" >
                                                <tbody>
                                                    {esgQues?.map((item, index) => (
                                                        <>
                                                            <tr>
                                                                <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.title : item?.titleFs}</th>
                                                            </tr>
                                                            <tr>
                                                                <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.desc : item?.descFs}</th>
                                                            </tr>
                                                            {item?.questions?.map((ques) => (
                                                                <tr>
                                                                    <td>{i18n?.language == 'en' ? ques.title : ques.titleFs}</td>
                                                                    <td>{ques?.answer}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                    <Col sm="12">
                                        {
                                            customActiveTab == '3' &&

                                            <AvForm
                                                onValidSubmit={(e, v) => {
                                                    handleValidSubmit1(e, v)
                                                }}
                                            >
                                                <h4 className="card-title mb-4">Final Assessment</h4>
                                                <Card className='comment_section'>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="form-group mb-3">
                                                                    <AvField type="textarea" name="esgComment" label="Write Comment"
                                                                        value={data?.esgRating?.comment} />
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="form-group rating-box mb-3">
                                                                    <label>Rating</label>
                                                                    <div className='slider-value'>
                                                                        <span>0</span>
                                                                        <RangeSlider
                                                                            tooltip="on"
                                                                            tooltipPlacement="bottom"
                                                                            value={value}
                                                                            onChange={changeEvent => setValue(changeEvent.target.value)}
                                                                            min={0}
                                                                            max={5}
                                                                            // range={currentQues ? currentQues?.options?.map((item) => { return item?.binary_gamble?.y }) : ["0"]}
                                                                            step={1}

                                                                        />
                                                                        <span>5</span>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <CardBody>
                                                        <div className="text-center">
                                                            <Button type="submit" className='btn_bg' color="">
                                                                {t("Submit")}
                                                            </Button>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </AvForm>
                                        }
                                    </Col>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <h4 className="card-title comment_title mb-4">Latest Comment <span>{moment(data?.esgRating?.commentedOn).format("YYYY-MM-DD HH:mm:ss")}</span></h4>
                                                <p>{data?.esgRating?.comment}</p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="4">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("Financing Request")}</h3>
                                            <table class="table normel_tabkle table-bordered" >
                                                <tbody>
                                                    {financeQues?.map((item, index) => (
                                                        <>
                                                            <tr>
                                                                <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.title : item?.titleFs}</th>
                                                            </tr>
                                                            {item?.questions?.map((ques) => (
                                                                <tr>
                                                                    <td>{i18n?.language == 'en' ? ques.title : ques.titleFs}</td>
                                                                    <td>{ques?.answer}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ))}
                                                    <tr style={{ backgroundColor: '#f5f5f5' }} className=''>
                                                        <th style={{ width: '100%' }} colSpan={2}>
                                                            {t("Assessment of the monthly activity - based on the past 12 months?")}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Minimum monthly revenue based on the past 12 months?")}</td>
                                                        <td>
                                                            {restKey?.eFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Average monthy revenue based on the past 12 months?")}</td>
                                                        <td>
                                                            {restKey?.fFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Monthly fixed charges based on the past 12 months?")}</td>
                                                        <td>
                                                            {restKey?.gFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Monthly variable charges based on the past 12 months?")}</td>
                                                        <td>
                                                            {restKey?.hFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Monthly expenses based on the past 12 months?")}</td>
                                                        <td>
                                                            {restKey?.iFine}
                                                        </td>
                                                    </tr>
                                                    <tr className='backgroun_td'>
                                                        <td>{t("Minimum monthly profit based on the past 12 months?")}</td>
                                                        <td>
                                                            {restKey?.jFine}
                                                        </td>
                                                    </tr>
                                                    <tr className='backgroun_td'>
                                                        <td>{t("Average monthly profit based on the past 12 months?")}</td>
                                                        <td>
                                                            {restKey?.kFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Minimum monthly cash based on the past 12 months?")}</td>
                                                        <td>
                                                            {restKey?.lFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Average monthly cash based on the past 12 months?")}</td>
                                                        <td>
                                                            {restKey?.mFine}
                                                        </td>
                                                    </tr>
                                                    <tr className='backgroun_td'>
                                                        <td>{t("Maximum amount to consider (50% of minimum monthly profit)")}</td>
                                                        <td>
                                                            {restKey?.nFine}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                    <Col sm="12">
                                        {
                                            customActiveTab == '4' &&

                                            <AvForm
                                                onValidSubmit={(e, v) => {
                                                    handleValidSubmit2(e, v)
                                                }}
                                            >
                                                <h4 className="card-title mb-4">Final Assessment</h4>
                                                <Card className='comment_section'>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="form-group mb-3">
                                                                    <AvField type="textarea" name="financeComment" label="Write Comment"
                                                                        value={data?.financeRating?.comment}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="form-group rating-box mb-3">
                                                                    <label>Rating</label>
                                                                    <div className='slider-value'>
                                                                        <span>0</span>
                                                                        <RangeSlider
                                                                            tooltip="on"
                                                                            tooltipPlacement="bottom"
                                                                            value={value1}
                                                                            onChange={changeEvent => setValue1(changeEvent.target.value)}
                                                                            min={0}
                                                                            max={5}
                                                                            // range={currentQues ? currentQues?.options?.map((item) => { return item?.binary_gamble?.y }) : ["0"]}
                                                                            step={1}

                                                                        />
                                                                        <span>5</span>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <CardBody>
                                                        <div className="text-center">
                                                            <Button type="submit" className='btn_bg' color="">
                                                                {t("Submit")}
                                                            </Button>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </AvForm>
                                        }
                                    </Col>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <h4 className="card-title comment_title mb-4">Latest Comment <span>{moment(data?.financeRating?.commentedOn).format("YYYY-MM-DD HH:mm:ss")}</span></h4>
                                                <p>{data?.financeRating?.comment}</p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="5">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("Cash Flow")}</h3>
                                            {
                                                customActiveTab == '5' &&
                                                <CashFlow user={user} userId={userId} data={data} isComment={false} />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="6">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("Balance Sheet")}</h3>
                                            {
                                                customActiveTab == '6' &&
                                                <BalanceSheet user={user} userId={userId} data={data} isComment={false} />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="7">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("Profile")}</h3>
                                            {
                                                customActiveTab == '7' &&
                                                <ProfileData user={user} userId={userId} data={data} isComment={false} />
                                            }

                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="8">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("Neuroprofiler")}</h3>
                                            {
                                                customActiveTab == '8' &&
                                                <Neuroprofiler user={user} userId={userId} data={data} isComment={true} />
                                            }

                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="9">
                                <div className='report_memo_section'>
                                    <div className='memo_section_one'>
                                        <Row>
                                            <Col sm="12">
                                                <div className='search_expended_component'>
                                                    <Row>
                                                        <Col md={6}>
                                                            <h3>{t("Report Memo")}</h3>
                                                        </Col>
                                                        <Col md={6} className='download_pdf_btn'>
                                                            <Button className='btn_bg' id="download_btn" style={{ marginTop: -10 }}
                                                                onClick={() => getImage(img)}
                                                            // onClick={handleExportPdf}
                                                            >
                                                                Export to PDF
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <ReportMemo id="pdf_section" user={user} userId={userId} isComment={false} />
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className='search_expended_component'>
                                                                        <h3>{t("ESG Form")}</h3>
                                                                        <table class="table normel_tabkle table-bordered" >
                                                                            <tbody>
                                                                                {esgQues?.map((item, index) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.title : item?.titleFs}</th>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.desc : item?.descFs}</th>
                                                                                        </tr>
                                                                                        {item?.questions?.map((ques, index) => (
                                                                                            <tr>
                                                                                                <td>{i18n?.language == 'en' ? ques.title : ques.titleFs}</td>
                                                                                                <td>{ques?.answer}</td>
                                                                                            </tr>

                                                                                        ))}
                                                                                    </>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className='search_expended_component'>
                                                                        <h3>{t("Financing Request")}</h3>
                                                                        <table class="table normel_tabkle table-bordered" >
                                                                            <tbody>
                                                                                {financeQues?.map((item, index) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.title : item?.titleFs}</th>
                                                                                        </tr>
                                                                                        {item?.questions?.map((ques) => (
                                                                                            <tr>
                                                                                                <td>{i18n?.language == 'en' ? ques.title : ques.titleFs}</td>
                                                                                                <td>{ques?.answer}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </>
                                                                                ))}
                                                                                <tr style={{ backgroundColor: '#f5f5f5' }} className=''>
                                                                                    <th style={{ width: '100%' }} colSpan={2}>
                                                                                        {t("Assessment of the monthly activity - based on the past 12 months?")}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{t("Minimum monthly revenue based on the past 12 months?")}</td>
                                                                                    <td>
                                                                                        {restKey?.eFine}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{t("Average monthy revenue based on the past 12 months?")}</td>
                                                                                    <td>
                                                                                        {restKey?.fFine}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{t("Monthly fixed charges based on the past 12 months?")}</td>
                                                                                    <td>
                                                                                        {restKey?.gFine}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{t("Monthly variable charges based on the past 12 months?")}</td>
                                                                                    <td>
                                                                                        {restKey?.hFine}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{t("Monthly expenses based on the past 12 months?")}</td>
                                                                                    <td>
                                                                                        {restKey?.iFine}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className='backgroun_td'>
                                                                                    <td>{t("Minimum monthly profit based on the past 12 months?")}</td>
                                                                                    <td>
                                                                                        {restKey?.jFine}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className='backgroun_td'>
                                                                                    <td>{t("Average monthly profit based on the past 12 months?")}</td>
                                                                                    <td>
                                                                                        {restKey?.kFine}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{t("Minimum monthly cash based on the past 12 months?")}</td>
                                                                                    <td>
                                                                                        {restKey?.lFine}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{t("Average monthly cash based on the past 12 months?")}</td>
                                                                                    <td>
                                                                                        {restKey?.mFine}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className='backgroun_td'>
                                                                                    <td>{t("Maximum amount to consider (50% of minimum monthly profit)")}</td>
                                                                                    <td>
                                                                                        {restKey?.nFine}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardBody>
                                                            <h3>{t("Neuroprofiler")}</h3>

                                                            <Neuroprofiler user={user} userId={userId} isComment={false} />
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div id="divToExport" className='memo_section_two' ref={divRef}>
                                        <Row>
                                            <Col sm="12">
                                                <div className='search_expended_component'>
                                                    <Row>
                                                        <Col md={6}>
                                                            <h3>{t("Report Memo")}</h3>
                                                        </Col>

                                                    </Row>
                                                    <ReportMemo1 user={user} userId={userId} isComment={false} />
                                                    <Row className='mb-3'>
                                                        <Col sm="12">
                                                            <div className='search_expended_component p-0 pb-0'>
                                                                <h5 className="mb-3">{t("ESG Form")}</h5>
                                                                <table class="table normel_tabkle table-bordered" >
                                                                    <tbody>
                                                                        {esgQues?.map((item, index) => (
                                                                            <>
                                                                                <tr>
                                                                                    <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.title : item?.titleFs}</th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.desc : item?.descFs}</th>
                                                                                </tr>
                                                                                {item?.questions?.map((ques, index) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td>{i18n?.language == 'en' ? ques.title : ques.titleFs}</td>
                                                                                            <td>{ques?.answer}</td>
                                                                                        </tr>
                                                                                    </>
                                                                                ))}
                                                                            </>
                                                                        ))}
                                                                        <tr>
                                                                            <td>Admin Review</td>
                                                                            <td>{data?.esgRating?.comment}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Admin Rating</td>
                                                                            <td>{data?.esgRating?.rating}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-3'>
                                                        <Col sm="12">
                                                            <div className='search_expended_component p-0'>
                                                                <h5 className="mb-3">{t("Financing Request")}</h5>
                                                                <table class="table normel_tabkle table-bordered" >
                                                                    <tbody>
                                                                        {financeQues?.map((item, index) => (
                                                                            <>
                                                                                <tr>
                                                                                    <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.title : item?.titleFs}</th>
                                                                                </tr>
                                                                                {item?.questions?.map((ques) => (
                                                                                    <tr>
                                                                                        <td>{i18n?.language == 'en' ? ques.title : ques.titleFs}</td>
                                                                                        <td>{ques?.answer}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </>
                                                                        ))}
                                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className=''>
                                                                            <th style={{ width: '100%' }} colSpan={2}>
                                                                                {t("Assessment of the monthly activity - based on the past 12 months?")}
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{t("Minimum monthly revenue based on the past 12 months?")}</td>
                                                                            <td>
                                                                                {restKey?.eFine}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{t("Average monthy revenue based on the past 12 months?")}</td>
                                                                            <td>
                                                                                {restKey?.fFine}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{t("Monthly fixed charges based on the past 12 months?")}</td>
                                                                            <td>
                                                                                {restKey?.gFine}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{t("Monthly variable charges based on the past 12 months?")}</td>
                                                                            <td>
                                                                                {restKey?.hFine}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{t("Monthly expenses based on the past 12 months?")}</td>
                                                                            <td>
                                                                                {restKey?.iFine}
                                                                            </td>
                                                                        </tr>
                                                                        <tr className='backgroun_td'>
                                                                            <td>{t("Minimum monthly profit based on the past 12 months?")}</td>
                                                                            <td>
                                                                                {restKey?.jFine}
                                                                            </td>
                                                                        </tr>
                                                                        <tr className='backgroun_td'>
                                                                            <td>{t("Average monthly profit based on the past 12 months?")}</td>
                                                                            <td>
                                                                                {restKey?.kFine}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{t("Minimum monthly cash based on the past 12 months?")}</td>
                                                                            <td>
                                                                                {restKey?.lFine}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{t("Average monthly cash based on the past 12 months?")}</td>
                                                                            <td>
                                                                                {restKey?.mFine}
                                                                            </td>
                                                                        </tr>
                                                                        <tr className='backgroun_td'>
                                                                            <td>{t("Maximum amount to consider (50% of minimum monthly profit)")}</td>
                                                                            <td>
                                                                                {restKey?.nFine}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Admin Review</td>
                                                                            <td>{data?.financeRating?.comment}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Admin Rating</td>
                                                                            <td>{data?.financeRating?.rating}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div>
                                                        <h5 className=" mb-3">{t("Neuroprofiler")}</h5>
                                                        <Neuroprofiler1 user={user} userId={userId} isComment={false} />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </TabPane>
                        </TabContent>
                    </Card>
                </Col>
            </Row>

        </React.Fragment >
    )
}
const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(SearchDetails)
)
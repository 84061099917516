import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useTranslation } from 'react-i18next';
import { get, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import "react-step-progress-bar/styles.css";
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import moment from 'moment';
import Loader from 'components/Loader';
import RadarChart from "pages/AllCharts/RadarChart";

const Neuroprofiler = props => {

    let { user } = props;
    const { i18n, t } = useTranslation();
    // console.log('profile page', props?.user)
    const [lang, setLang] = useState(null);
    const [data, setData] = useState(null);
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(undefined);
    const [newResult, setNewResult] = useState(undefined);


    useEffect(() => {
        if (user?.token)
            getUserData();
    }, [user])

    useEffect(() => {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
        if (currentLanguage)
            setLang(currentLanguage)
    }, [lang])


    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: props?.userId,
                token: user?.token

            }
            post('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        setData(res?.data)
                        setValue(res?.data?.nuroRating?.rating)

                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }

    useEffect(() => {
        // console.log("user on nuero", user);
        if (!loading) {
            setLoading(true)
            if (props?.userId)
                post("/nuro/profile", { token: user?.token, userId: props?.userId })
                    .then(res => {
                        setLoading(false)
                        if (res?.statusCode == 200) {
                            setResult(res?.data);
                            let winnTemp = res?.answers?.winning_appetite;
                            winnTemp = ((winnTemp - 0.4) / 0.6) * 100;
                            winnTemp = winnTemp.toFixed(2);
                            let loseTemp = res?.answers?.loss_aversion;
                            if (loseTemp >= 0.5 && loseTemp <= 1) {
                                loseTemp = ((loseTemp - 0.5) * 100).toFixed(2);
                            } else {
                                loseTemp = ((((loseTemp - 1) / 9) * 50) + 50).toFixed(2)
                            }
                            console.log("winnTemp", loseTemp);
                            let temp = {
                                labels: [
                                    `Overconfidence (${res?.newAnswer?.overconfidence}%)`,
                                    `Organization (${(res?.newAnswer?.conscientiousness?.organization * 100) / 20}%)`,
                                    `Productiveness (${(res?.newAnswer?.conscientiousness?.productiveness * 100) / 20}%)`,
                                    `Responsibility (${(res?.newAnswer?.conscientiousness?.responsibility * 100) / 20}%)`,
                                    `Anxiety (${(res?.newAnswer?.optimism?.anxiety * 100) / 20}%)`,
                                    `Depression (${(res?.newAnswer?.optimism?.depression * 100) / 20}%)`,
                                    `Emotional Volatility (${(res?.newAnswer?.optimism?.emotional * 100) / 20}%)`,
                                    `Winning Appetite (${winnTemp}%)`,
                                    `Loss aversion (${loseTemp}%)`,
                                ], data: [
                                    res?.newAnswer?.overconfidence,
                                    (res?.newAnswer?.conscientiousness?.organization * 100) / 20,
                                    (res?.newAnswer?.conscientiousness?.productiveness * 100) / 20,
                                    (res?.newAnswer?.conscientiousness?.responsibility * 100) / 20,
                                    (res?.newAnswer?.optimism?.anxiety * 100) / 20,
                                    (res?.newAnswer?.optimism?.depression * 100) / 20,
                                    (res?.newAnswer?.optimism?.emotional * 100) / 20,
                                    40,
                                    60
                                ]
                            };
                            setNewResult(temp);
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        console.error(err);
                        toast.error("Something Went Wrong!");
                    })
        }
    }, [props?.userId]);


    function handleValidSubmit(e, v) {
        if (!loading) {
            setLoading(true)
            let body = {
                ...v,
                userId: props?.userId,
                token: props?.user?.token,
                nuroRating: {
                    comment: v?.nuroComment,
                    rating: value
                },

            }
            put('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        getUserData()
                        toast.success(res?.message)
                        // setValue(0)
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }


    return (
        <React.Fragment>

            <Loader loading={loading} />

            <AvForm
                className="form-horizontal form_horizontal_admin"
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
            >
                <div className='member_section member_section_new'>
                    <Row>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>
                                            <li><p><strong>Loss Aversion :</strong> {result?.loss_aversion}</p></li>
                                        </ul>

                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>
                                            <li><p><strong>Loss Aversion Quantile :</strong> {result?.loss_aversion_quantile}</p></li>
                                        </ul>

                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>

                                            <li><p><strong>Risk Profile :</strong> {t(result?.risk_profile)}</p></li>

                                        </ul>

                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>

                                            <li><p><strong>Safe Asset Proportion in Allocation :</strong> {result?.safe_asset_proportion_in_allocation}</p></li>

                                        </ul>

                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>

                                            <li><p><strong>Winning Appetite :</strong> {result?.winning_appetite}</p></li>

                                        </ul>

                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <ul className='member_section_detail'>
                                            <li><p><strong>Winning Appetite Quantile :</strong> {result?.winning_appetite_quantile}</p></li>
                                        </ul>
                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
                <div className='member_section member_section_new'>
                    <Row>
                        <Col md={12}>
                            <Card className="" style={{ minWidth: "100%", height: "70vh" }}>
                                <CardBody>
                                    <div className='d-flex justify-content-center' style={{ minWidth: "100%", height: "90vh", position: "relative" }}>
                                        <div className="radar-headings">
                                            <h3 style={{ borderBottom: '5px solid rgba(29, 6, 116, 1)' }}>Overconfidence</h3>
                                            <h3 style={{ borderBottom: '5px solid rgba(85, 154, 157, 1)' }}>Conscientiousness</h3>
                                            <h3 style={{ borderBottom: '5px solid rgba(27, 0, 94, 1)' }} className="adjust-label">Optimism / Anxiety</h3>
                                            <h3 style={{ borderBottom: '5px solid rgba(23, 202, 208, 1)' }}>Risk Appetite</h3>
                                        </div>
                                        <div style={{ width: "60%", height: "60%", marginTop: 80 }}>
                                            <RadarChart result={newResult} />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                {
                    props?.isComment == true &&
                    <>
                        <h4 className="card-title mb-4">Final Assessment</h4>
                        <Card className='comment_section'>
                            <CardBody>
                                <Row>
                                    <Col lg={12}>
                                        <div className="form-group mb-3">
                                            <AvField type="textarea" name="nuroComment" label="Write Comment" value={data?.nuroRating?.comment} />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="form-group rating-box mb-3">
                                            <label>Rating</label>
                                            <div className='slider-value'>
                                                <span>0</span>
                                                <RangeSlider
                                                    tooltip="on"
                                                    tooltipPlacement="bottom"
                                                    value={value}
                                                    onChange={changeEvent => setValue(changeEvent.target.value)}
                                                    min={0}
                                                    max={5}
                                                    // range={currentQues ? currentQues?.options?.map((item) => { return item?.binary_gamble?.y }) : ["0"]}
                                                    step={1}
                                                />
                                                <span>5</span>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h4 className="card-title comment_title mb-4">Latest Comment <span>{moment(data?.nuroRating?.commentedOn).format("YYYY-MM-DD HH:mm:ss")}</span></h4>
                                <p>{data?.nuroRating?.comment}</p>

                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div className="text-center">
                                    <Button type="submit" className='btn_bg' color="">
                                        {t("Submit")}
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </>
                }

            </AvForm>


        </React.Fragment>
    )
}



export default Neuroprofiler;


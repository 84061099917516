import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import avatar from "../../assets/images/users/user-1.jpg";
import { editProfile, resetProfileFlag, profileSuccess } from "../../store/actions";
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { get, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import * as Utils from "../Utils";
import "react-step-progress-bar/styles.css";
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { ProgressBar, Step } from "react-step-progress-bar";
import moment from 'moment';
import Loader from 'components/Loader';
const Neuroprofiler = props => {
    let { user } = props;
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [idx, setidx] = useState(1)

    const { i18n, t } = useTranslation();
    // console.log('profile page', props?.user)
    const { role } = props?.user;
    const [selectedSdg, setSelectedSdg] = useState([]);
    const [sdg, setSdg] = useState([]);
    const [region, setRegion] = useState([]);
    const [investmentRegion, setInvestmentRegion] = useState([]);
    const [investment, seInvestment] = useState([]);
    const [businessStructure, setBusinessStructure] = useState([]);
    const [lang, setLang] = useState(null);

    const [data, setData] = useState(null);
    const [age, setAge] = useState(null);
    const [userID, setUserID] = useState(null);
    const [alertType, setAlerType] = useState("");
    const [file, setFile] = useState(avatar);
    const [file1, setFile1] = useState(avatar);
    const [changeIcon, setChangeIcon] = useState(false);
    const [changeIcon1, setChangeIcon1] = useState(false);
    const [progress, setProgress] = useState(45)
    const [member, setMember] = useState([{}])
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(undefined);




    useEffect(() => {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
        if (currentLanguage)
            setLang(currentLanguage)
    }, [lang])



    useEffect(() => {
        // console.log("user on nuero", user);
        if (!loading) {
            setLoading(true)
            if (props?.userId)
                post("/nuro/profile", { token: user?.token, userId: props?.userId })
                    .then(res => {
                        setLoading(false)
                        if (res?.statusCode == 200) {
                            setResult(res?.data)
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        console.error(err);
                        toast.error("Something Went Wrong!");
                    })
        }
    }, [props?.userId]);




    return (
        <React.Fragment>

            <Loader loading={loading} />

            <AvForm
                className="form-horizontal form_horizontal_admin"

            >
                <div className='member_section member_section_new'>
                    <Row>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>
                                            <li><p><strong>Loss Aversion :</strong> {result?.loss_aversion}</p></li>
                                        </ul>

                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>
                                            <li><p><strong>Loss Aversion Quantile :</strong> {result?.loss_aversion_quantile}</p></li>
                                        </ul>

                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>

                                            <li><p><strong>Risk Profile :</strong> {result?.risk_profile && 'Very Conservatif'}</p></li>

                                        </ul>

                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>

                                            <li><p><strong>Safe Asset Proportion in Allocation :</strong> {result?.safe_asset_proportion_in_allocation}</p></li>

                                        </ul>

                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>

                                            <li><p><strong>Winning Appetite :</strong> {result?.winning_appetite}</p></li>

                                        </ul>

                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card className="">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>


                                            <li><p><strong>Winning Appetite Quantile :</strong> {result?.winning_appetite_quantile}</p></li>
                                        </ul>

                                    </blockquote>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>

            </AvForm>


        </React.Fragment>
    )
}



export default Neuroprofiler;


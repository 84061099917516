import React from 'react'
import ProfileData from './ProfileData'

export default function ReportMemo(props) {
  return (
    <div>
        <ProfileData user={props?.user} userId={props?.userId} isComment={true} />
    </div>
  )
}

import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { Card, CardBody, Col, Modal, ModalBody, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { get, post } from 'helpers/helper_api';
import { connect } from "react-redux"
import { withRouter, useParams } from "react-router-dom";
import jsonp from 'jsonp';
import { toast } from 'react-toastify';
import { ModalTitle } from 'react-bootstrap';
import Loader from 'components/Loader';


const NeuroprofilerInvite = (props) => {
    const params = useParams();
    const [formData, setFormData] = useState({
        step: 1,
    });
    const { user } = props;
    const { i18n, t } = useTranslation();
    const [data, setData] = useState([]);
    const [value, setValue] = useState(0);
    const [qid, setQid] = useState(undefined);
    const [currentQues, setCurrentQues] = useState(undefined);
    const [currentOptId, setCurrentOtpId] = useState(undefined);
    const [resultModal, setResultModal] = useState(false);
    const [invite, setInvite] = useState(false);
    const [trustedAmount, setTrustedAmount] = useState(false);
    const [trustedAmountVal, setTrustedAmountVal] = useState('');
    const [memId, setMemId] = useState(null);
    const [result, setResult] = useState(undefined);
    const [loading, setLoading] = useState(false)
    const [userId, setUserId] = useState(null);
    const [getQuestionStatus, setGetQuestionStatus] = useState(false);
    const [answeredQues, setAnsweredQues] = useState([]);
    console.log('props  nuro', params);

    useEffect(() => {
        if (params) {
            setUserId(params?.id)
            setTrustedAmountVal(params.amount);
            setMemId(params.memId);
        }
    }, [params]);
    //   const userId = props?.location?.padh

    useEffect(() => {
        if (userId) {
            setLoading(true)
            post("/nuro/profile", { userId: userId, memId })
                .then(res => {
                    setLoading(false)
                    if (res?.statusCode == 200) {
                        setResult(res?.data)
                        console.log("rosk profile", res?.data?.risk_profile);
                        if (!res?.data?.risk_profile) {
                            setGetQuestionStatus(true);
                        }
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.error(err);
                    toast.error("Something Went Wrong!");
                });
            get("/nuro/list")
                .then(res => {
                    setLoading(false)
                    if (res?.statusCode == 200) {
                        res?.data?.forEach((item) => {
                            item.used = false;
                        })
                        setData(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.error(err);
                    toast.error("Something Went Wrong!");
                });
        }
    }, [userId, memId]);

    useEffect(() => {
        setLoading(true)
        if (memId) {

        }
    }, [memId]);

    useEffect(() => {
        if (getQuestionStatus)
            getQuestion();
    }, [getQuestionStatus])

    // useEffect(() => {
    //     if (data?.length) {
    //         if (!qid)
    //             createTest();
    //     }
    // }, [data]);

    console.log('questtion list', data, getQuestionStatus)

    useEffect(() => {
        console.log("qid", qid);
        if (qid)
            getQues([]);
    }, [qid, userId])

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: t("Neuroprofiler"), link: "#" },
    ]

    const handleInputChange = (e) => {
        // const { name, value } = e.target;
        // setFormData({ ...formData, [name]: value });
    };

    const nextStep = () => {
        if (!loading) {
            setLoading(true)
            fetch("https://testapi.neuroprofiler.com/risk-test/" + qid, {
                // fetch("http://146.59.154.238:443/risk-test/create", {
                method: "POST",
                headers: {
                    'accept': 'application/json',
                    'x-api-key': 'a64d54e8-62a0-41e1-a9a8-e2a71486a4c3',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "answer_id": currentOptId
                })
            })
                .then(json => json.json())
                .then(res => {
                    setLoading(false)
                    console.log("response from send Response", res);
                    if (!res.finished) {
                        setAnsweredQues([...answeredQues, currentQues]);
                        getQues([...answeredQues, currentQues]);
                    } else {
                        getResult();
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.error(err);
                    toast.error("Something Went wrong while creating a test!");
                })
        }
    };

    const prevStep = () => {
        // setFormData({ ...formData, step: formData.step - 1 });
        setLoading(true);
        fetch("https://testapi.neuroprofiler.com/risk-test/back/" + qid, {
            // fetch("http://146.59.154.238:443/risk-test/create", {
            method: "PUT",
            headers: {
                'accept': 'application/json',
                'x-api-key': 'a64d54e8-62a0-41e1-a9a8-e2a71486a4c3',
                'Content-Type': 'application/json'
            },
            body: undefined
            // JSON.stringify({
            //     "answer_id": currentOptId
            // })
        })
            .then(json => json.json())
            .then(res => {
                setLoading(false);
                setValue(0);
                setCurrentOtpId("option_0");
                setCurrentQues(answeredQues[answeredQues?.length - 1]);
                let temp = Object.assign([], answeredQues);
                temp.pop();
                setAnsweredQues(temp);
            })
            .catch(err => {
                setLoading(false)
                console.error(err);
                toast.error("Something Went wrong while creating a test!");
            })
    };

    const { step, firstName, lastName, email, password } = formData;

    const getQuestion = () => {

        fetch("https://testapi.neuroprofiler.com/risk-test/create", {
            // fetch("http://146.59.154.238:443/risk-test/create", {
            method: "POST",
            headers: {
                'accept': 'application/json',
                'x-api-key': 'a64d54e8-62a0-41e1-a9a8-e2a71486a4c3',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "trusted_amount": Number(trustedAmountVal),
                "investment_horizon": "1year",
                "questionnaire_version": "short"
            })
        })
            .then(json => json.json())
            .then(res => {
                console.log("response from create Test", res);
                if (res?.id) {
                    setQid(res?.id);
                } else {
                    toast.error("Something Went Wrong! Test Could not be started!");
                }
            })
            .catch(err => {
                console.error(err);
                toast.error("Something Went wrong while creating a test!");
            })
    }
    // const handleValidSubmit1 = (e, v) => {


    //     fetch("https://testapi.neuroprofiler.com/risk-test/create", {
    //         // fetch("http://146.59.154.238:443/risk-test/create", {
    //         method: "POST",
    //         headers: {
    //             'accept': 'application/json',
    //             'x-api-key': 'a64d54e8-62a0-41e1-a9a8-e2a71486a4c3',
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             "trusted_amount": Number(v?.trusted_amount),
    //             "investment_horizon": "1year",
    //             "questionnaire_version": "short"
    //         })
    //     })
    //         .then(json => json.json())
    //         .then(res => {
    //             console.log("response from create Test", res);
    //             if (res?.id) {
    //                 setQid(res?.id);
    //                 setTrustedAmount(false)
    //             } else {
    //                 toast.error("Something Went Wrong! Test Could not be started!");
    //             }
    //         })
    //         .catch(err => {
    //             console.error(err);
    //             toast.error("Something Went wrong while creating a test!");
    //         })
    // }

   
    const getQues = (answered) => {
        fetch("https://testapi.neuroprofiler.com/risk-test/" + qid, {
            method: "GET",
            headers: {
                'accept': 'application/json',
                'x-api-key': 'a64d54e8-62a0-41e1-a9a8-e2a71486a4c3',
                'Content-Type': 'application/json'
            }
        })
            .then(json => json.json())
            .then(res => {
                // console.log("response from get question", res);
                if (res) {
                    if (!res?.finished) {
                        setCurrentOtpId(res?.options[0]?.option_id);
                        let temp = Object.assign([], data);
                        console.log("answered before setting next question", answered);
                        if (res?.options?.length == 2) {
                            setValue("");
                            for (const item of temp) {
                                if (item?.type == "Binary") {
                                    let tempAnswered = Object.assign([], answered);
                                    let used = tempAnswered.findIndex(x => x?._id == item?._id);
                                    if (used == -1) {
                                        setCurrentQues({ ...item, ...res });
                                        break; // This will stop the loop when item is 3.
                                    }
                                }
                            }
                        } else {
                            for (const item of temp) {
                                setValue(0);
                                if (item?.type == "Gamble") {
                                    let tempAnswered = Object.assign([], answeredQues);
                                    let used = tempAnswered.findIndex(x => x?._id == item?._id);
                                    if (used == -1) {
                                        setCurrentQues({ ...item, ...res });
                                        break; // This will stop the loop when item is 3.
                                    }
                                }
                            }
                        }
                        setLoading(false);
                    }
                } else {
                    toast.error("Something Went Wrong! Question Not Found!");
                    setLoading(false);
                }
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
                toast.error("Something Went wrong while creating a test!");
            })
    }

    const handleSliderValue = (val) => {
        setValue(val)
        let optionId = undefined;
        currentQues?.options?.forEach((item) => {
            console.log("checking for option", val, item?.binary_gamble?.y);
            if (item?.binary_gamble?.y == val)
                setCurrentOtpId(item?.option_id);
        })
    }

    const getResult = () => {
        fetch("https://testapi.neuroprofiler.com/risk-test/results/" + qid, {
            method: "GET",
            headers: {
                'accept': 'application/json',
                'x-api-key': 'a64d54e8-62a0-41e1-a9a8-e2a71486a4c3',
                'Content-Type': 'application/json'
            }
        })
            .then(json => json.json())
            .then(res => {
                // console.log("response from get question", res);
                // setResult(res);
          
                let risk_profile = undefined;
                if(res?.loss_aversion > 0 && res?.loss_aversion <= 2){
                    risk_profile = "Verydynamic";
                }else if(res?.loss_aversion > 2 && res?.loss_aversion <= 4){
                    risk_profile = "Dynamic";
                }else if(res?.loss_aversion > 4 && res?.loss_aversion <= 6){
                    risk_profile = "Moderate";
                }else if(res?.loss_aversion > 6 && res?.loss_aversion <= 8){
                    risk_profile = "Conservative";
                }else if(res?.loss_aversion > 8 && res?.loss_aversion <= 10){
                    risk_profile = "Veryconservative";
                }else{
                    risk_profile = "Verydynamic";
                }
                let body = {
                    ...res,
                    risk_profile:risk_profile,
                    userId: userId,
                    memId,
                    trusted_amount: trustedAmountVal
                }
                post('/nuro/save', body)
                    .then((res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            // console.log('request on login', res);
                            toast.success(res?.message);
                            setCurrentQues(null)
                            post("/nuro/profile", { userId: userId })
                                .then(res => {
                                    if (res?.statusCode == 200) {
                                        setResult(res?.data?.memResult[0])
                                    }
                                })
                                .catch(err => {
                                    setLoading(false)
                                    console.error(err);
                                    toast.error("Something Went Wrong!");
                                })
                            // setResult(true)
                            // history.push('/dashboard')
                        } else if (res?.statusCode == 401) {
                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    }))
                    .catch(error => {
                        setLoading(false);
                        console.log('error while login', error);
                        console.log('Something went wrong');
                        toast.error('Something went wrong')
                    })
                setResultModal(true);
                // post()
            })
            .catch(err => {
                console.error(err);
                toast.error("Something Went wrong while creating a test!");
            })
    }

    console.log("current ques", trustedAmountVal, currentQues, result);

    // const replaceAmount = (str, type) => {
    //     console.log('chaek type str', str, currentQues, type)
    //     let options = currentQues?.options;
    //     if (options?.length) {
    //         let max = options[0]?.binary_gamble?.y?.toLocaleString();
    //         let min = options[options?.length - 1]?.binary_gamble?.y?.toLocaleString();
    //         if (currentQues?.type == "Binary") {
    //             if (type == "bull") {
    //                 const newStr = str.replace(/\X\X\X/g, (match, index, original) => {
    //                     // console.log("str", match, index);
    //                     if (original.indexOf('XXX') === index) {
    //                         return max; // Replace the first XXX
    //                     } else {
    //                         return min; // Replace the second XXX
    //                     }
    //                     // return index === 0 ? max : min;
    //                 });
    //                 // let temp = str?.split('XXX');
    //                 // console.log('str', temp, max, min)
    //                 // let newStr = "";
    //                 // if (temp?.length>0)
    //                 //     newStr = temp[0] + max + temp?.length > 0 ? temp[1] : "";
    //                 // if (temp?.length > 1)
    //                 //     newStr += min + temp[2];
    //                 console.log("binary bull str", newStr);
    //                 return newStr;
    //             } else {
    //                 if (str?.includes('XXX')) {
    //                     let temp = str?.split('XXX');
    //                     let newStr = temp[0] + max + temp[1];
    //                     if (temp?.length > 1)
    //                         newStr += min + temp[2];
    //                     console.log("new Str2", newStr);
    //                     return newStr;
    //                 } else
    //                     return str;
    //             }
    //         } else {
    //             let temp = str?.split('XXX');
    //             let newStr = temp[0] + max + temp[1];
    //             if (temp?.length > 1)
    //                 newStr += min + temp[2];
    //             console.log("gamble new Str", newStr);
    //             return newStr;
    //         }
    //     }
    //     return ""
    // }
    const replaceAmount = (str, type) => {
        // console.log('str', str, currentQues)
        let options = currentQues?.options;
        if (options?.length) {
            let max = 0;
            let min = 0;
            if (currentQues?.type == "Binary") {
                min = options[0]?.binary_gamble?.x?.toLocaleString();
                max = options[0]?.binary_gamble?.y?.toLocaleString();
                if (type == "bull") {
                    const newStr = str.replace(/\X\X\X/g, (match, index, original) => {
                        // console.log("str", match, index);
                        if (original.indexOf('XXX') === index) {
                            return `<span style="color:green;">${max}</span>`; // Replace the first XXX
                            // return max; // Replace the first XXX
                        } else {
                            return `<span style="color:red;">${max}</span>`; // Replace the second XXX
                            // return min; // Replace the second XXX
                        }
                        // return index === 0 ? max : min;
                    });
                    // let temp = str?.split('XXX');
                    // console.log('str', temp, max, min)
                    // let newStr = "";
                    // if (temp?.length>0)
                    //     newStr = temp[0] + max + temp?.length > 0 ? temp[1] : "";
                    // if (temp?.length > 1)
                    //     newStr += min + temp[2];
                    // console.log("str", newStr);
                    return newStr;
                } else {
                    if (str?.includes('XXX')) {
                        let temp = str?.split('XXX');
                        let newStr = temp[0] + `<span style="color:green;">${max}</span>` + temp[1];
                        if (temp?.length > 1)
                            newStr += `<span style="color:red;">${min}</span>` + temp[2];
                        // console.log("new Str2", newStr);
                        return newStr;
                    } else
                        return str;
                }
            } else {
                // min = currentQues?.reference_gamble?.x?.toLocaleString();
                // max = currentQues?.reference_gamble?.y?.toLocaleString()
                min = `<span style="color:red;">${currentQues?.reference_gamble?.x?.toLocaleString()}</span>`;
                max = `<span style="color:green;">${currentQues?.reference_gamble?.y?.toLocaleString()}</span>`;
                let temp = str?.split('XXX');
                let newStr = temp[0] + max + temp[1];
                if (temp?.length > 1)
                    newStr += min + temp[2];
                // console.log("new Str", newStr);
                return newStr;
            }
        }
        return ""
    }

    const formatTooltip = (value) => {
        // Customize the tooltip label here
        return value?.toLocaleString();
    };

    return (
        <div className='member_form_section p-0'>
            <Row>
                <Loader loading={loading} />

                {result &&
                    result?.risk_profile &&

                    <div class="page_wrapper page_wrapper_page">
                        <div className='success_box'>
                            <div class="custom-modal">
                                <div class="succes succes-animation icon-top"><i class="fa fa-check"></i></div>
                                <div class="border-bottom"></div>
                                <p className="success-paragraph mb-0">You have completed the questionnaire successfully.</p>
                                <div class="content m-0">
                                    <p class="type">Success</p>
                                </div>
                            </div>
                        </div>
                    </div>

                }
                {currentQues &&
                    <Col className="col-12">
                        <h4 className="card-title mb-4 mt-4">{t("Neuroprofiler Questions")}</h4>
                        <Card>
                            <CardBody >
                                <div className="wizard-form">
                                <p className='total_question'>Question {currentQues?.current_depth + 1}/{currentQues?.max_depth}</p>
                                    <div className="wizard-step">
                                        {step === 1 && (
                                            <div className='card_box_question'>
                                                {currentQues?.type == "Gamble" ?
                                                    <>
                                                        <Row>
                                                            <Col md={currentQues?.image ? 8 : 12}>
                                                                {i18n?.language == "en" ?
                                                                    <p className='p1' dangerouslySetInnerHTML={{ __html: replaceAmount(currentQues?.title, "ques") }}></p> :
                                                                    <p className='p1' dangerouslySetInnerHTML={{ __html: replaceAmount(currentQues?.titleFs, "ques") }}></p>
                                                                }
                                                                <ul>
                                                                    {currentQues?.bullets?.map((item) => (
                                                                        <li>{i18n?.language == "en" ? <p className='p1' dangerouslySetInnerHTML={{ __html: "✓" + replaceAmount(item?.title, "bull") }}></p> : <p className='p1' dangerouslySetInnerHTML={{ __html: "✓" + replaceAmount(item?.titleFs, "bull") }}></p>}</li>
                                                                    ))}
                                                                </ul>
                                                                <p className='p1'>{i18n?.language == 'en' ? currentQues?.line : currentQues?.lineFs}</p>
                                                            </Col>
                                                            {
                                                                currentQues?.image && <Col md={4}>
                                                                    <div className='question_image_section'>
                                                                        <img src={currentQues?.image} alt='question_img' />
                                                                    </div>
                                                                </Col>
                                                            }

                                                        </Row>
                                                        <div className='range_slider_box'>
                                                            <div className='range_slider_box_2'>
                                                                <span>{currentQues?.reference_gamble?.x?.toLocaleString()}</span>
                                                                <div className='inpu_amount'>
                                                                    {/* <input type='number' className='form-control' onChange={(e) => setValue(e.target.value)} placeholder='0' /> */}
                                                                    <span>{(currentQues?.reference_gamble?.x - currentQues?.options[currentQues?.options?.length - 1]?.binary_gamble?.x)?.toLocaleString()}</span>
                                                                </div>
                                                            </div>

                                                            <RangeSlider
                                                                tooltip="on"
                                                                tooltipPlacement="bottom"
                                                                value={value}
                                                                tooltipLabel={formatTooltip}
                                                                min={currentQues ? String(currentQues?.reference_gamble?.x) : "0"}
                                                                max={currentQues ? String(currentQues?.reference_gamble?.x - currentQues?.options[currentQues?.options?.length - 1]?.binary_gamble?.x) : "0"}
                                                                // range={currentQues ? currentQues?.options?.map((item) => { return item?.binary_gamble?.y }) : ["0"]}
                                                                step={10000}
                                                                onChange={changeEvent => handleSliderValue(changeEvent.target.value)}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <Row>
                                                            <Col md={currentQues?.image ? 8 : 12}>
                                                                {/* neutral investment – if the case the slider max is previously selected */}
                                                                {currentQues?.options[1]?.binary_gamble?.px == 1 && currentQues?.options[1]?.binary_gamble?.x == 0
                                                                    // || currentQues?.options[1]?.binary_gamble?.py == 1 && currentQues?.options[1]?.binary_gamble?.y == 0
                                                                    ?
                                                                    <p className='p1'>{i18n?.language == 'en' ? '“You can invest again in a new investment with a 50% chance of earning' : '“Vous pouvez investir à nouveau avec 50% de chance de gagner reference_gamble.x Francs CFA et 50% chance de perdre'}
                                                                        <span style={{ color: "green" }}> {currentQues?.options[0]?.binary_gamble?.y.toLocaleString()} </span>
                                                                        {i18n?.language == 'en' ? 'Francs CFA and a 50% chance of losing' : 'Francs CFA et 50% chance de perdre'}
                                                                        <span style={{ color: "red" }}> {currentQues?.options[0]?.binary_gamble?.x.toLocaleString()} </span>
                                                                        {i18n?.language == 'en' ? 'Francs CFA. What do you do ?”' : 'Francs CFA. Que décidez-vous?”'}
                                                                    </p>
                                                                    :
                                                                    <div>
                                                                        <p className='p1'>{i18n?.language == 'en' ? 'You have already invested in a project. Your current options are :' : 'Vous avez déjà investi dans un projet. Vous avez, à présent, le choix de :'}</p>
                                                                        {currentQues?.options?.map((item, index) => {
                                                                            // if (index == 0) {
                                                                            if (item?.binary_gamble?.px == 1) {
                                                                                if (item?.binary_gamble?.x > 0)
                                                                                    return <p className='p2'>{i18n?.language == 'en' ? '✓ stop now and earn' : '✓ Arrêter maintenant et gagner'} <span style={{ color: "green" }}>{item?.binary_gamble?.x?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA for sure' : 'Francs CFA en même temps'}</p>
                                                                                if (item?.binary_gamble?.x < 0)
                                                                                    return <p className='p2'>{i18n?.language == 'en' ? '✓ stop now and lose' : '✓ Arrêter maintenant et perdre'} <span style={{ color: "red" }}>{item?.binary_gamble?.x?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA for sure' : 'Francs CFA en même temps'}</p>
                                                                            } else if (item?.binary_gamble?.px == 0) {
                                                                                if (item?.binary_gamble?.py == 1) {
                                                                                    if (item?.binary_gamble?.y > 0)
                                                                                        return <p className='p2'>{i18n?.language == 'en' ? '✓ stop now and earn' : '✓ Arrêter maintenant et gagner'} <span style={{ color: "green" }}>{item?.binary_gamble?.y?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA for sure' : 'Francs CFA en même temps'}</p>
                                                                                    if (item?.binary_gamble?.y < 0)
                                                                                        return <p className='p2'>{i18n?.language == 'en' ? '✓ stop now and lose' : '✓ Arrêter maintenant et perdre'} <span style={{ color: "red" }}>{item?.binary_gamble?.y?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA for sure' : 'Francs CFA en même temps'}</p>
                                                                                }
                                                                            }
                                                                            // } else {
                                                                            if (item?.binary_gamble?.py != 1)
                                                                                return <p className='p2'>{i18n?.language == 'en' ? '✓ continue to invest with a' : '✓ Continuer l’investissement avec'} {item?.binary_gamble.py * 100}{i18n?.language == 'en' ? '% chance to' : '% de chance'}
                                                                                    {item?.binary_gamble.y > 0 && <span> {i18n?.language == 'en' ? 'earn' : 'de gagner'} <span style={{ color: "green" }}>{item?.binary_gamble.y?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA' : 'Francs CFA'} </span>}
                                                                                    {item?.binary_gamble.y < 0 && <span> {i18n?.language == 'en' ? 'lose' : 'de perdre'} <span style={{ color: "red" }}>{item?.binary_gamble.y?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA' : 'Francs CFA'} </span>}
                                                                                    {item?.binary_gamble.y == 0 && `${i18n?.language == 'en' ? 'earn or lose nothing' : 'de ne rien gagner et de ne rien perdre'}`}
                                                                                    {i18n?.language == 'en' ? 'and a' : 'et'} {item?.binary_gamble.py * 100}{i18n?.language == 'en' ? '% chance to' : '% chance'}
                                                                                    {item?.binary_gamble.x > 0 && <span> {i18n?.language == 'en' ? 'earn' : 'de gagner'} <span style={{ color: "green" }}>{item?.binary_gamble.x?.toLocaleString()}</span> Francs CFA.</span>}
                                                                                    {item?.binary_gamble.x < 0 && <span> {i18n?.language == 'en' ? 'lose' : 'de perdre'} <span style={{ color: "red" }}>{item?.binary_gamble.x?.toLocaleString()}</span> Francs CFA.</span>}
                                                                                    {item?.binary_gamble.x == 0 && `${i18n?.language == 'en' ? 'earn or lose nothing' : 'de ne rien gagner et de ne rien perdre'}`}
                                                                                </p>
                                                                        })}
                                                                        <p className='p1'>{i18n?.language == 'en' ? 'What do you do in that case ?' : 'Que décidez-vous de faire ?'}</p>
                                                                    </div>
                                                                }
                                                                {/* {i18n?.language == "en" ?
                                                                <p className='p1' dangerouslySetInnerHTML={{ __html: replaceAmount(currentQues?.title, "ques") }}></p> :
                                                                <p className='p1' dangerouslySetInnerHTML={{ __html: replaceAmount(currentQues?.titleFs, "ques") }}></p>
                                                            }
                                                            <ul>
                                                                {currentQues?.bullets?.map((item) => (
                                                                    <li>{i18n?.language == "en" ? <p className='p1' dangerouslySetInnerHTML={{ __html: "✓" + replaceAmount(item?.title, "bull") }}></p> : <p className='p1' dangerouslySetInnerHTML={{ __html: "✓" + replaceAmount(item?.titleFs, "bull") }}></p>}</li>
                                                                ))}
                                                            </ul>
                                                            <p className='p1'>{i18n?.language == 'en' ? currentQues?.line : currentQues?.lineFs}</p> */}
                                                            </Col>
                                                            {
                                                                currentQues?.image && <Col md={4}>
                                                                    <div className='question_image_section'>
                                                                        <img src={currentQues?.image} alt='question_img' />
                                                                    </div>
                                                                </Col>
                                                            }

                                                        </Row>
                                                        <div className='yes_no_button'>
                                                            <button className={value == 'no' ? 'active' : ''} onClick={() => { setValue("no"); setCurrentOtpId(currentQues?.options[1]?.option_id) }}>{i18n?.language == 'en' ? "I stop" : currentQues?.negativeButtonFs}</button>
                                                            <button className={value == 'yes' ? 'active' : ''} onClick={() => { setValue("yes"); setCurrentOtpId(currentQues?.options[0]?.option_id) }}>{i18n?.language == 'en' ? "I continue" : currentQues?.positiveButtonFs}</button>
                                                        </div>
                                                    </>
                                                }
                                                {/* <p>Place the cursor on the maximum price you are willing to invest in this project</p> */}

                                                <div className='button_box_next mt-3'>
                                                    <button style={{ visibility: answeredQues?.length > 0 ? "visible" : "hidden" }} onClick={prevStep}>Previous</button>
                                                    <button onClick={nextStep}>Next</button>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                    </Col>
                }
            </Row>
        </div>
    );
};



export default NeuroprofilerInvite
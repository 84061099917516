import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Button
} from "reactstrap"
import { Modal, ModalBody } from 'reactstrap';
import Cropper from "react-cropper";
import Resizer from "react-image-file-resizer";
import "cropperjs/dist/cropper.css";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"


// actions


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import DataTable from 'react-data-table-component';


import { get, post, put, upload } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import DeleteModal from 'components/DeleteModal';
const AddQuestion = (props) => {
    let history = useHistory();
    const { user, setIsAdd, loading, setLoading, currentData, setCurrentData, getData } = props;
    const { i18n, t } = useTranslation();
    const [bullets, setBullets] = useState([{}]);
    const [questionType, setQuestionType] = useState("");
    const [file, setFile] = useState(null)
    const [imageUrl, setImageUrl] = useState(null);
    const [cropImg, setCropImg] = useState(false);
    const formRef = useRef(null)
    console.log('add question', props?.location?.state?.currentData);

    useEffect(() => {
        setImageUrl(props?.location?.state?.currentData?.image)
        if (props?.location?.state?.currentData) {
            if (props?.location?.state?.currentData?.bullets?.length)
                setBullets(props?.location?.state?.currentData?.bullets);
            if (props?.location?.state?.currentData?.type) {
                setQuestionType(props?.location?.state?.currentData?.type);
            }
        }
    }, [props?.location?.state?.currentData])
    // useEffect(() => {
    //     if (currentData) {
    //         if (currentData?.bullets?.length)
    //             setBullets(currentData?.bullets);
    //         if (currentData?.type) {
    //             setQuestionType(currentData?.type);
    //         }
    //     }
    // }, [currentData])
    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1080,
                1080,
                "webp",
                60,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const handleValidSubmit = async (e, values) => {
        // setLoading(true);
        if (!loading) {
            setLoading(true);
            let body = {
                ...values,
                token: user?.token
            }
            if (currentData?._id) {
                body.segmentId = currentData?._id
                put('/nuro/segment', body)
                    .then((res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            // console.log('request on login', res);
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                            // history.push('/dashboard')
                        } else if (res?.statusCode == 401) {
                            history?.push('/logout')
                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    }))
                    .catch(error => {
                        setLoading(false);
                        console.log('error while login', error);
                        console.log('Something went wrong');
                        toast.error('Something went wrong')
                    })
            }
            else {
                post('/nuro/segment', body)
                    .then((res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            // console.log('request on login', res);
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                            // history.push('/dashboard')
                        } else if (res?.statusCode == 401) {
                            history?.push('/logout')
                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    }))
                    .catch(error => {
                        setLoading(false);
                        console.log('error while login', error);
                        console.log('Something went wrong');
                        toast.error('Something went wrong')
                    })
            }
        }
    }

    const cropperRef = useRef(null);
    const cropImage = (e, v) => {
        const fileData = cropperRef?.current?.cropper?.getCroppedCanvas().toDataURL();
        setImageUrl(fileData)
    }

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Add Segment", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems(t("Segment Neuroprofiler"), breadcrumbItems)
    })

    return (
        <React.Fragment>
            <Modal isOpen={cropImg} className='trusted_amount' centered backdrop="static">
                <ModalBody>
                    <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                            cropImage()
                        }}
                        ref={formRef}
                    >
                        <Row>
                            <Col lg={12} className='mb-4'>
                                <Cropper
                                    src={file}
                                    style={{ height: 300, width: "100%" }}
                                    // Cropper.js options
                                    preview=".img-preview"
                                    initialAspectRatio={1 / 1}
                                    guides={true}
                                    ref={cropperRef}
                                />
                            </Col>
                            <Col md={6}>
                                <button type='button' className='btn_bg w-100' onClick={() => { setCropImg(false) }}>Cancel</button>
                            </Col>
                            <Col md={6}>
                                <button type='submit' onClick={() => { setCropImg(false) }} className='btn_bg w-100'>Submit</button>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>

            {/* <span>Add Segment</span> */}
            <div className={'add_question_modale active'}>
                <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                    }}
                >
                    <Row>
                        <Col md={6}>
                            <div className='mb-3'>
                                <AvField
                                    name="title"
                                    className="form-control"
                                    type="text"
                                    label="Title (English)"
                                    value={currentData?.title}
                                    placeholder="Enter the segment title for english"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='mb-3'>
                                <AvField
                                    name="titleFs"
                                    className="form-control"
                                    type="text"
                                    label="Title (French)"
                                    value={currentData?.titleFs}
                                    placeholder="Enter the segment title for french"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='mb-3'>
                                <AvField
                                    name="desc"
                                    className="form-control"
                                    type="textarea"
                                    label="Description (English)"
                                    value={currentData?.desc}
                                    rows={5}
                                    placeholder="Enter the description for english"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='mb-3'>
                                <AvField
                                    name="descFs"
                                    className="form-control"
                                    type="textarea"
                                    label="Description (French)"
                                    value={currentData?.descFs}
                                    rows={5}
                                    placeholder="Enter the description for french"
                                />
                            </div>
                        </Col>
                        <hr></hr>
                    </Row>
                    <div className="text-center">
                        <Button type="reset" onClick={() => setIsAdd(false)} className='btn_bg my-4' color="">
                            {t("Cancel")}
                        </Button>
                        <Button type="submit" className='btn_bg my-4 mx-4' color="">
                            {t(currentData ? "Update" : "Save")}
                        </Button>
                    </div>
                </AvForm>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {

    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(AddQuestion)
)
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardText,
    CardTitle,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import DataTable from 'react-data-table-component';
import classnames from "classnames"
import DountChart from 'pages/AllCharts/chartjs/dountchart';
import IncomestatementanalysisChart from 'pages/AllCharts/chartjs/incomeChart';
import { get, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import ProfitandLoss from './ProfitandLoss';
import CashFlow from './CashFlow';
import BalanceSheet from './BalanceSheet';
import ProfileData from './ProfileData';
import Neuroprofiler from './Neuroprofiler';
import ReportMemo from './ReportMemo';
import RangeSlider from 'react-bootstrap-range-slider';
import moment from 'moment';
import Loader from 'components/Loader';

const SearchDetails = (props) => {
    const { t } = useTranslation();
    const [customActiveTab, setCustomActiveTab] = useState('1')
    const [years, setYears] = useState('2020');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [value1, setValue1] = useState(0);
    const [value2, setValue2] = useState(0);
    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Search Details", link: "#" },
    ];
    const { user } = props;
    const { userId } = props?.location.state;
    const [esgQues, setEsgQues] = useState([]);
    const [year1, setYear1] = useState(undefined);
    const [year2, setYear2] = useState(undefined);
    const [year3, setYear3] = useState(undefined);
    const [financeQues, setFinanceQues] = useState([]);
    const [data, setData] = useState(null)

    useEffect(() => {
        props.setBreadcrumbItems('Search Details', breadcrumbItems)
    }, []);

    useEffect(() => {
        if (customActiveTab == "3" || customActiveTab == "9")
            getEsgAnswers();
        if (customActiveTab == "4" || customActiveTab == "9")
            getFinanceAnswers();
    }, [customActiveTab, user]);

    const toggleCustom = (tab) => {
        if (setCustomActiveTab !== tab) {
            setCustomActiveTab(tab)
        }
    }
    useEffect(() => {
        if (user?.token)
            getUserData();
    }, [user?.token])

    const getUserData = () => {
        let body = {
            userId: userId,
            token: user?.token,

        }
        post('/user', body)
            .then((res => {
                // setLoading(false);
                if (res?.statusCode == 200) {
                    // console.log('request on login', res);

                    setData(res?.data)
                    setValue(res?.data?.esgRating?.rating)
                    setValue1(res?.data?.financeRating?.rating)
                    setValue2(res?.data?.ratioRating?.rating)

                } else {
                    console.log(res?.error);
                }
            }))
            .catch(error => {
                // setLoading(false);
                console.log('error while login', error);
                console.log('Something went wrong');
            })
    }

    console.log('userid on seaarch details', userId, props);

    const getEsgAnswers = () => {
        if (userId) {
            if (!loading) {
                setLoading(true);
                get("/esg/answer/user?userId=" + userId, { token: user?.token })
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setEsgQues(res?.data);
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("while get Esg user data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const getFinanceAnswers = () => {
        if (userId) {
            if (!loading) {
                setLoading(true);
                get("/finance/answer/user?userId=" + userId, { token: user?.token })
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setFinanceQues(res?.data);
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("while get Esg user data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

   


    return (
        <React.Fragment>

            <MetaTags>
                <title>Profit and Loss account | Demleen </title>
            </MetaTags>
            <Loader loading={loading} />
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody>
                            <Nav tabs className="nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleCustom("1")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Ratio Analysis")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleCustom("2")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Profit & Loss")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "3",
                                        })}
                                        onClick={() => {
                                            toggleCustom("3")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("ESG Form")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "4",
                                        })}
                                        onClick={() => {
                                            toggleCustom("4")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Financing Request")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "5",
                                        })}
                                        onClick={() => {
                                            toggleCustom("5")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Cash Flow")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "6",
                                        })}
                                        onClick={() => {
                                            toggleCustom("6")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Balance Sheet")}</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "8",
                                        })}
                                        onClick={() => {
                                            toggleCustom("8")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Neuroprofiler")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "7",
                                        })}
                                        onClick={() => {
                                            toggleCustom("7")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Profile")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "9",
                                        })}
                                        onClick={() => {
                                            toggleCustom("9")
                                        }}
                                    >
                                        <span className="d-none d-sm-block tab_text">{t("Report Memo")}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardBody>
                    </Card>
                    <Card>
                        <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">

                                        <div className='search_expended_component'>
                                            <h3>Income Statement Analysis</h3>
                                            <Card className='ratio_section'>
                                                <CardBody>
                                                    <div className='top_ratio'>
                                                        <ul className='ratio_list'>
                                                            <li className='active'>
                                                                <h3>Revenue Growth</h3>
                                                                <h1>2.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Gross margin</h3>
                                                                <h1>3.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Operating Expense</h3>
                                                                <h1>1.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>EBITDA margin</h3>
                                                                <h1>4.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Operating Profit margin</h3>
                                                                <h1>8.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Return on Assets</h3>
                                                                <h1>3.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Return on Equity</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Return on Equity</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Return on Equity</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Return on Equity</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Return on Equity</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Return on Equity</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Return on Equity</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Return on Equity</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Row>
                                                <Col lg={6}>
                                                    <Card>
                                                        <CardBody>
                                                            <h4 className='text-center mb-3 grap_heading'>Revenue Growth</h4>
                                                            <div className='year_chart'>
                                                                <IncomestatementanalysisChart />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col lg={6}>
                                                    <Card>
                                                        <CardBody>
                                                            <h4 className='text-center mb-3 grap_heading'>Revenue Breakdown</h4>
                                                            <div className='ratio_year_tabs'>
                                                                <ul>
                                                                    <li>
                                                                        <button onClick={() => setYears('2020')} className={years == '2020' && 'active'}>2020</button>
                                                                    </li>
                                                                    <li>
                                                                        <button onClick={() => setYears('2021')} className={years == '2021' && 'active'}>2021</button>
                                                                    </li>
                                                                    <li>
                                                                        <button onClick={() => setYears('2022')} className={years == '2022' && 'active'}>2022</button>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className='year_chart mt-3'>
                                                                <DountChart />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='search_expended_component'>
                                            <h3>Balance Sheet Analysis</h3>
                                            <Card className='ratio_section'>
                                                <CardBody>
                                                    <div className='top_ratio'>
                                                        <ul className='ratio_list'>
                                                            <li className='active'>
                                                                <h3>days of sales outstanding</h3>
                                                                <h1>2.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>days of invertory on hands</h3>
                                                                <h1>3.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>number of days of payables</h3>
                                                                <h1>1.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>cash conversion cycle</h3>
                                                                <h1>4.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Working Capital</h3>
                                                                <h1>8.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Adjusted Working Capital</h3>
                                                                <h1>3.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3></h3>
                                                                <h1></h1>
                                                            </li>
                                                            <li>
                                                                <h3></h3>
                                                                <h1></h1>
                                                            </li>
                                                            <li>
                                                                <h3>Current Ratio</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Cash ratio</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3>receivable turnover</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3>inventory turnover</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3>payables turnover</h3>
                                                                <h1>3.2</h1>
                                                            </li>
                                                            <li>
                                                                <h3></h3>
                                                                <h1></h1>
                                                            </li>
                                                            <li>
                                                                <h3></h3>
                                                                <h1></h1>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Row>
                                                <Col lg={6}>
                                                    <Card>
                                                        <CardBody>
                                                            <h4 className='text-center grap_heading mb-3'>Days of sales outstanding Breakdown</h4>
                                                            <div className='year_chart'>
                                                                <IncomestatementanalysisChart />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col lg={6}>
                                                    <Card>
                                                        <CardBody>
                                                            <h4 className='text-center mb-3 grap_heading'>Days of sales outstanding Breakdown</h4>
                                                            <div className='ratio_year_tabs'>
                                                                <ul>
                                                                    <li>
                                                                        <button onClick={() => setYears('2020')} className={years == '2020' && 'active'}>2020</button>
                                                                    </li>
                                                                    <li>
                                                                        <button onClick={() => setYears('2021')} className={years == '2021' && 'active'}>2021</button>
                                                                    </li>
                                                                    <li>
                                                                        <button onClick={() => setYears('2022')} className={years == '2022' && 'active'}>2022</button>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className='year_chart mt-3'>
                                                                <DountChart />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='search_expended_component'>
                                            <h3>Leverage Analysis</h3>
                                            <Card className='ratio_section'>
                                                <CardBody>
                                                    <div className='top_ratio'>
                                                        <ul className='ratio_list'>
                                                            <li>
                                                                <h3></h3>
                                                                <h1></h1>
                                                            </li>
                                                            <li>
                                                                <h3></h3>
                                                                <h1></h1>
                                                            </li>
                                                            <li className='active'>
                                                                <h3>Gearing / Leverage /debt-to equity ratio</h3>
                                                                <h1>2.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>total debt ratio</h3>
                                                                <h1>3.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Equity ratio</h3>
                                                                <h1>1.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3></h3>
                                                                <h1></h1>
                                                            </li>
                                                            <li>
                                                                <h3>Net Profit Margin</h3>
                                                                <h1>4.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Interest Coverage</h3>
                                                                <h1>8.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Fixed Charge Coverage</h3>
                                                                <h1>3.5</h1>
                                                            </li>
                                                            <li>
                                                                <h3>Cash Flow Leverage</h3>
                                                                <h1>3.2</h1>
                                                            </li>


                                                        </ul>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Row>
                                                <Col lg={6}>
                                                    <Card>
                                                        <CardBody>
                                                            <h4 className='text-center mb-3 grap_heading'>Gearing / Leverage /debt-to equity ratio</h4>
                                                            <div className='year_chart'>
                                                                <IncomestatementanalysisChart />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col lg={6}>
                                                    <Card>
                                                        <CardBody>
                                                            <h4 className='text-center mb-3 grap_heading'>Gearing / Leverage /debt-to equity ratio Breakdown</h4>
                                                            <div className='ratio_year_tabs'>
                                                                <ul>
                                                                    <li>
                                                                        <button onClick={() => setYears('2020')} className={years == '2020' && 'active'}>2020</button>
                                                                    </li>
                                                                    <li>
                                                                        <button onClick={() => setYears('2021')} className={years == '2021' && 'active'}>2021</button>
                                                                    </li>
                                                                    <li>
                                                                        <button onClick={() => setYears('2022')} className={years == '2022' && 'active'}>2022</button>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className='year_chart mt-3'>
                                                                <DountChart />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                   
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">

                                        <div className='search_expended_component'>
                                            <h3>{t("Profit & Loss")}</h3>
                                            {
                                                customActiveTab == '2' &&
                                                <ProfitandLoss user={user} userId={userId} data={data} isComment={false} />
                                            }

                                        </div>

                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="3">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("ESG Form")}</h3>
                                            <table class="table normel_tabkle table-bordered" >
                                                <tbody>
                                                    {esgQues?.map((item, index) => (
                                                        <>
                                                            <tr>
                                                                <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.title : item?.titleFs}</th>
                                                            </tr>
                                                            <tr>
                                                                <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.desc : item?.descFs}</th>
                                                            </tr>
                                                            {item?.questions?.map((ques) => (
                                                                <tr>
                                                                    <td>{i18n?.language == 'en' ? ques.title : ques.titleFs}</td>
                                                                    <td>{ques?.answer}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                   
                                </Row>
                            </TabPane>
                            <TabPane tabId="4">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("Financing Request")}</h3>
                                            <table class="table normel_tabkle table-bordered" >
                                                <tbody>
                                                    {financeQues?.map((item, index) => (
                                                        <>
                                                            <tr>
                                                                <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.title : item?.titleFs}</th>
                                                            </tr>
                                                            {item?.questions?.map((ques) => (
                                                                <tr>
                                                                    <td>{i18n?.language == 'en' ? ques.title : ques.titleFs}</td>
                                                                    <td>{ques?.answer}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                   
                                </Row>
                            </TabPane>
                            <TabPane tabId="5">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("Cash Flow")}</h3>
                                            {
                                                customActiveTab == '5' &&
                                                <CashFlow user={user} userId={userId} data={data} isComment={false} />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="6">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("Balance Sheet")}</h3>
                                            {
                                                customActiveTab == '6' &&
                                                <BalanceSheet user={user} userId={userId} data={data} isComment={false} />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="7">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("Profile")}</h3>
                                            {
                                                customActiveTab == '7' &&
                                                <ProfileData user={user} userId={userId} data={data} isComment={false} />
                                            }

                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="8">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("Neuroprofiler")}</h3>
                                            {
                                                customActiveTab == '8' &&
                                                <Neuroprofiler user={user} userId={userId} data={data} isComment={true} />
                                            }

                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="9">
                                <Row>
                                    <Col sm="12">
                                        <div className='search_expended_component'>
                                            <h3>{t("Report Memo")}</h3>
                                            <ReportMemo user={user} userId={userId} isComment={false} />
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className='search_expended_component'>
                                                                <h3>{t("ESG Form")}</h3>
                                                                <table class="table normel_tabkle table-bordered" >
                                                                    <tbody>
                                                                        {esgQues?.map((item, index) => (
                                                                            <>
                                                                                <tr>
                                                                                    <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.title : item?.titleFs}</th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.desc : item?.descFs}</th>
                                                                                </tr>
                                                                                {item?.questions?.map((ques) => (
                                                                                    <tr>
                                                                                        <td>{i18n?.language == 'en' ? ques.title : ques.titleFs}</td>
                                                                                        <td>{ques?.answer}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className='search_expended_component'>
                                                                <h3>{t("Financing Request")}</h3>
                                                                <table class="table normel_tabkle table-bordered" >
                                                                    <tbody>
                                                                        {financeQues?.map((item, index) => (
                                                                            <>
                                                                                <tr>
                                                                                    <th scope="col" colSpan={2}>{i18n.language == 'en' ? item?.title : item?.titleFs}</th>
                                                                                </tr>
                                                                                {item?.questions?.map((ques) => (
                                                                                    <tr>
                                                                                        <td>{i18n?.language == 'en' ? ques.title : ques.titleFs}</td>
                                                                                        <td>{ques?.answer}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <h3>{t("Neuroprofiler")}</h3>

                                                    <Neuroprofiler user={user} userId={userId} isComment={false} />
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}
const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(SearchDetails)
)
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import logoLightPng from "../../assets/images/logo-sm.png"
import logoDark from "../../assets/images/logo-sm.png"
import { post } from 'helpers/helper_api';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
const ForgetPasswordPage = props => {
  const history = useHistory();
  const [isOtp, setIsOtp] = useState(false);
  const [isVery, setIsVery] = useState(false);
  const handleValidSubmit = (event, values) => {
    // setLoading(true);
console.log('hii')
    const body = {
      ...values,
      password: values?.cpassword
    }
    console.log('req on register', body);
    let url = '/forgot';
    if (isOtp)
      url = '/verify';
    post(url, body)
      .then(res => {
        // setLoading(false);
        if (res?.statusCode == 200) {
          console.log('request on register', res?.data);
          if (isOtp) {
            localStorage.setItem("user", JSON.stringify(res?.data));
            props.userForgetPassword(res?.data)
            setIsVery(true)
          } else {
            setIsOtp(true);
          }

        } else
          console.log(res?.error);
      })
      .catch(error => {
        // setLoading(false);
        console.log('error while login', error);
        console.log('Something went wrong');
      })
    // props.registerUser(values)
  }

  const handleResetSubmit = (event, values) => {
    // setLoading(true);

    const body = {
      ...values,
      password: values?.cpassword
    }
    if (values?.password == values?.cpassword) {
      let  url = '/reset_password';
      post(url, body)
        .then(res => {
          // setLoading(false);
          if (res?.statusCode == 200) {
            // console.log('request on register', res?.data);
            toast.success(res?.message)
              history.push('/login')
          } else
            console.log(res?.error);
            toast.error(res?.error)
        })
        .catch(error => {
          // setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
        })
    } else {
      toast.error('Password does not match. Please try again.')
    }
    // props.registerUser(values)
  }



  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | Demleen</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-2">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="60" className="auth-logo-dark" />
                      <img src={logoLightPng} alt="" height="60" className="auth-logo-light" />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-3 text-center">Reset Password</h4>
                    {/* <div className="alert alert-info" role="alert">
                      Enter your Email and instructions will be sent to you!
                    </div> */}
                    {/* {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null} */}

                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        if (!isVery)
                          handleValidSubmit(e, v)
                        else
                          handleResetSubmit(e, v)
                      }}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>
                      {
                        isOtp == true && <div className="mb-3">
                          <AvField
                            name="otp"
                            label="Otp"
                            type="number"
                            required
                            placeholder="Enter Otp"
                          />
                        </div>
                      }
                      {
                        isVery &&
                        <>
                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="Password"
                              value="123456"
                              type="password"
                              required
                              placeholder="Password"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="cpassword"
                              label="Confirm Password"
                              value="123456"
                              type="password"
                              required
                              placeholder="Confirm Password"
                            />
                          </div>
                        </>
                      }
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn_bg w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="login" className="text-primary">
                    Sign In Here
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Demleen
                  <span className="d-none d-sm-inline-block"> - All Rights Reserved. <i className="mdi mdi-heart text-danger"></i> Design by <a href='https://www.rajmith.com/'>RAJMITH</a> .</span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)

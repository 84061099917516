import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter as Router } from 'react-router-dom';
import "./i18n"
import { Provider } from "react-redux"
import './assets/css/style.css'
import './assets/css/clash-display.css'
import store from "./store"
import Toastify from "components/Toastify"

const app = (
  <Provider store={store}>
    {/* <BrowserRouter> */}
    <App />
    <Toastify />
    {/* </BrowserRouter> */}
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()

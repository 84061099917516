import React, { useEffect, useState } from 'react'
import img from './assets/img/map.svg'
import logo from './assets/img/logo-light.png'
import ChooseUs from './ChooseUs'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import Modal from 'react-bootstrap/Modal';
export default function Footer() {
    const { i18n, t } = useTranslation();
    const [isDisclaimer, setIsDisclaimer] = useState(false);
    // console.log(i18n?.language);
    const [profileModal, setProfileModal] = useState(false);
    const handleClose = () => setProfileModal(false);
    const handleShow = () => setProfileModal(true);

    useEffect(() => {
        let time = null;
        const getData = async () => {
            const item = await localStorage.getItem('policy');
            console.log('policy from storage', item);
            if (!item)
                time = setTimeout(() => {
                    setIsDisclaimer(true)
                }, 3000);
        }
        getData();
        return () => clearTimeout(time);
    }, []);

    const handleOkayDisclaimer = () => {
        setIsDisclaimer(false);
        setProfileModal(false)
        localStorage.setItem('policy', 'agreed');
    }

    return (
        <>
            <Modal centered show={profileModal} size='md' className='subscribe_popup home_page_popup subscribe_section_box_cookies' backdrop="static">
                <Modal.Body>
                    <div className='subscribe_section subscribe_section_box '>
                        <button className='close-btn' onClick={() => setProfileModal(false)}><i class="fa fa-times"></i></button>
                        {
                            i18n?.language == 'en' ?
                                <div className='cookies_box_content'>
                                    <p>What is a cookie ? A cookie is a small data file sent by a website and stored on the user's browser. Cookies are used to record information about user preferences and to track visited pages in order to personalize the content and services offered.</p>
                                    <p>Why do we use cookies on our site? We use cookies on our site to: improve your browsing experience by saving your preferences and offering you content and services tailored to your interests, track the pages visited and measure the audience of our site in order to help us improve it. content and ergonomics.
                                    </p>
                                    <p>How to manage cookies on our site? You can manage cookies from our site using your browser settings. You can choose to accept or refuse cookies, or to receive a notice before accepting them. You can also delete cookies already stored on your computer. Please note that if you choose to decline cookies, you may not be able to benefit from all of the features of our site.</p>
                                    <p><strong>Conclusion: By using our site, you accept the use of cookies as described in our privacy policy.</strong></p>
                                </div>
                                :
                                <div className='cookies_box_content'>
                                    <p>Qu'est-ce qu'un cookie ? Un cookie est un petit fichier de données envoyé par un site web et stocké sur le navigateur de l'utilisateur. Les cookies sont utilisés pour enregistrer des informations sur les préférences de l'utilisateur et pour suivre les pages visitées afin de personnaliser les contenus et les services proposés.</p>
                                    <p>Pourquoi utilisons-nous des cookies sur notre site ? Nous utilisons des cookies sur notre site pour : améliorer votre expérience de navigation en enregistrant vos préférences et en vous proposant des contenus et services adaptés à vos intérêts, suivre les pages visitées et mesurer l'audience de notre site afin de nous aider à en améliorer le contenu et l'ergonomie.</p>
                                    <p>Comment gérer les cookies sur notre site ? Vous avez la possibilité de gérer les cookies de notre site en utilisant les paramètres de votre navigateur. Vous pouvez choisir d'accepter ou de refuser les cookies, ou encore de recevoir un avis avant de les accepter. Vous pouvez également effacer les cookies déjà enregistrés sur votre ordinateur. Veuillez noter que si vous choisissez de refuser les cookies, vous pourriez ne pas être en mesure de bénéficier de toutes les fonctionnalités de notre site.</p>
                                    <p><strong>Conclusion : En utilisant notre site, vous acceptez l'utilisation des cookies telle que décrite dans notre politique de confidentialité.</strong></p>
                                </div>
                        }
                        <Button onClick={handleOkayDisclaimer} className='okay_cookies_btn'>{i18n?.language == 'en' ? "OK, I got it" : "OK, j'accepte"}</Button>
                    </div>
                </Modal.Body>
            </Modal>
            <ChooseUs />
            <footer class="text-light footer_front" style={{ backgroundColor: '#1b005e' }}>

                <div class="fixed-shape">
                    <img src={img} alt="Shape" />
                </div>

                <div class="container">
                    <div class="f-items default-padding">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 item">
                                <div class="f-item about">
                                    <img src={logo} alt="Logo" className='logo' />

                                    {/* <form action="#">
                                        <input type="email" placeholder="Your Email" class="form-control" name="email" />
                                        <button type="submit"> <i class="fa fa-paper-plane"></i></button>
                                    </form> */}
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6 item">
                                <div class="f-item link">
                                    <h4 class="widget-title">{t("Company")}</h4>
                                    <ul className='footer_link'>
                                        <li>
                                            <Link to="/">{t("Home")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/about-us">{t("About Us")}</Link>
                                        </li>

                                        <li>
                                            <Link to="/contact">Contact</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6 item">
                                <div class="f-item link">
                                    <h4 class="widget-title">{t("Solutions")}</h4>
                                    <ul className='footer_link'>
                                        <li>
                                            <Link to="/our-solution">{t("Product")}</Link>
                                        </li>
                                        <li>
                                            <a href="https://podcast.ausha.co/africa-s-investor-call" target='_blank'>{t("Podcasts")}</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 item">
                                <div class="f-item">
                                    <h4 class="widget-title">{t("Contact Info")}</h4>
                                    <div class="address">
                                        <ul className='footer_link'>

                                            <li>
                                                <strong>Email:</strong>
                                                <a href="mailto:contact@demleen.com">contact@demleen.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="download">
                                        <h5>Social Networks</h5>

                                        <a href='https://www.instagram.com/demleenteam' target='_blank'><i class="fab fa-instagram"></i></a>
                                        <a href='#'><i class="fab fa-facebook"></i></a>
                                        <a href='https://www.linkedin.com/company/demleen' target='_blank'><i class="fab fa-linkedin"></i> </a>
                                        <a href='https://substack.com/' target='_blank'><svg role="img" width="21" height="24" viewBox="0 0 21 24" fill="#c2d1e2" stroke-width="1.8" stroke="none" xmlns="http://www.w3.org/2000/svg" class="nav-logo-icon"><g><title></title><path d="M20.9991 5.40625H0V8.24275H20.9991V5.40625Z"></path><path d="M0 10.8125V24.0004L10.4991 18.1107L21 24.0004V10.8125H0Z"></path><path d="M20.9991 0H0V2.83603H20.9991V0Z"></path></g></svg> </a>
                                        <a href='https://podcasts.apple.com/us/podcast/africas-investor-call-fran%C3%A7ais/id1623183797' target='_blank'><i class="fab fa-apple"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer-bottom footer_bottom_n">
                    <div class="container">
                        <div class="row">
                            <div class='col-md-6'>
                                <p >Copyright &copy;  {new Date().getFullYear()} Demleen. Designed by <a href="https://www.rajmith.com/" target="_blank">RAJMITH</a></p>
                            </div>

                            <div class="col-md-6 text_right_f link">

                                <ul>
                                    <li>
                                        <Link to="/terms-of-service">Terms</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy-policy">Privacy</Link>
                                    </li>
                                    <li>
                                        <Link to="/mentions-legales">Légales</Link>
                                    </li>
                                </ul>

                            </div>

                        </div>
                    </div>
                </div>
            </footer>
            <div style={{ marginTop: 20 }} className={isDisclaimer ? 'disclaimer active' : 'disclaimer'}>
                <div className='box-cookies'>
                    <h2 style={{ color: '#fff' }}>Agree to cookies</h2>
                    <p style={{ fontSize: 12, margin: 0 }} >
                        {
                            i18n?.language == 'en' ?
                                <>
                                    We use cookies on our site to improve your browsing experience and provide you with content and services tailored to your interests. By continuing to browse our site, you accept the use of these cookies. If you would like to know more about our use of cookies or how to manage them, you can consult our privacy policy. <button className='more_button' onClick={handleShow}>{"More...>>"}</button>
                                </>
                                :
                                <>
                                    Nous utilisons des cookies sur notre site pour améliorer votre expérience de navigation et vous fournir des contenus et services adaptés à vos intérêts. En continuant à naviguer sur notre site, vous acceptez l'utilisation de ces cookies. Si vous souhaitez en savoir plus sur l'utilisation que nous faisons des cookies ou comment les gérer, vous pouvez consulter notre politique de confidentialité. <button className='more_button' onClick={handleShow}>{"En savoir plus…>>"}</button>
                                </>
                        }

                    </p>
                </div>
                <Button onClick={handleOkayDisclaimer} >{i18n?.language == 'en' ? "OK, I got it" : "OK, j'accepte"}</Button>
            </div>
        </>
    )
}

import React, { useState, useEffect } from 'react'

// import './assets/css/font-awesome.min.css'
// import './assets/css/themify-icons.css'
// import './assets/css/flaticon-set.css'
// import './assets/css/magnific-popup.css'
// import './assets/css/owl.carousel.min.css'
// import './assets/css/owl.theme.default.min.css'
import './assets/css/bootsnav.css'
import './assets/css/responsive.css'
import './style.css'
import logo from './assets/img/V4_Demleen_Blanc.svg'
import logo1 from './assets/img/V4_Demleen_Bleu.svg'
import { get, map } from "lodash"
import { withTranslation } from "react-i18next"
import { editProfile, resetProfileFlag } from "../store/actions"
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../store/actions";
//i18n
import i18n from "../i18n"
import languages from "common/languages"
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
function Header(props) {

    const [dropDown, setDropDown] = useState(false)
    const [selectedLang, setSelectedLang] = useState("")
    const [menu, setMenu] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(false)
    const [user, setUser] = useState(null)

    const { i18n, t } = useTranslation();
    useEffect(() => {
        let data = localStorage.getItem('user');
        if (data)
            setUser(data)
    }, [user])
    console.log('user', user)
    useEffect(() => {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
        setSelectedLang(currentLanguage)
    }, [])

    const changeLanguageAction = lang => {
        //set language as i18n
        console.log('lang change', lang)
        i18n.changeLanguage(lang)
        localStorage.setItem("I18N_LANGUAGE", lang)
        setSelectedLang(lang)
    }
    return (
        <header id="home">

            <nav class="navbar navbar-default navbar-fixed white no-background bootsnav">
                <div class="container">
                    <div class="nav-box">

                        <div class="attr-nav inc-border">
                            <ul>
                                <li class="contact">
                                    <Link to='/login'> <i class="fas fa-user"></i>
                                        <p><strong>Login</strong> New? Signup</p></Link>
                                </li>
                            </ul>
                        </div>

                        <div class="navbar-header">
                            <button type="button" onClick={() => setMobileMenu(!mobileMenu)} class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                                {mobileMenu == false ? <i class="fa fa-bars"></i> :
                                    <i class="fa fa-times"></i>}
                            </button>
                            <Link class="navbar-brand" to="/">
                                <img src={logo} class="logo logo-display" alt="Logo" />
                                <img src={logo1} class="logo logo-scrolled" alt="Logo" />
                            </Link>
                        </div>

                        <div class={mobileMenu ? "collapse navbar-collapse active" : "collapse navbar-collapse"} id="navbar-menu">
                            <ul class="nav navbar-nav navbar-center" data-in="fadeInDown" data-out="fadeOutUp">
                                <li>
                                    <Link to="/">{t("Home")}</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">{t("About us")}</Link>
                                </li>
                                <li class="dropdown dropdown_toggle">
                                    <a href="#" class="dropdown-toggle " data-toggle="dropdown">{t("Our Solution")}</a>
                                    <ul class={dropDown ? 'dropdown-menu active' : 'dropdown-menu'}>
                                        <li><Link to="/our-solution" >{t("Product")}</Link></li>
                                        <li><a href="https://podcast.ausha.co/africa-s-investor-call" target='_blank' >{t("Podcasts")}</a></li>

                                    </ul>
                                </li>
                                {/* <li>
                                    <a href="#">FAQ’s</a>
                                </li> */}
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                                <li class="dropdown dropdown_toggle">
                                    <a href="#" class="dropdown-toggle " data-toggle="dropdown" > {get(languages, `${selectedLang}.label`)}{" "}</a>
                                    <ul class={dropDown ? 'dropdown-menu active' : 'dropdown-menu'}>
                                        {map(Object.keys(languages), key => (
                                            <li
                                                key={key}
                                                onClick={() => changeLanguageAction(key)}
                                            ><a href="#" >{console.log('languages', languages)} {get(languages, `${key}.label`)}</a></li>
                                        ))}


                                    </ul>
                                </li>
                                <li className='login_btn'>
                                    {
                                        user ? <a href="https://demleen.com/v1-solution/dashboard" rel="noopener noreferrer">{t("Dashboard")}</a> : <a href="https://demleen.com/v1-solution/login" rel="noopener noreferrer">{t("Login")}</a>
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav >
        </header >
    )
}

Header.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {
    const { error, success } = state.Profile
    return { error, success }
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag, setBreadcrumbItems })(Header)
)



// Merci de vous être inscrit chez nous. 

// Nous avons constaté que votre profil sur Demleen n'est pas encore complet. En complétant votre profil, vous nous permettez de vous offrir une expérience plus personnalisée et de vous garantir un accès optimal à toutes les fonctionnalités de notre plateforme. Pour toute assistance supplémentaire, veuillez nous contacter au numéro suivant : +33632049668

// Cordialement,
// L'équipe Demleen
import React, { Component } from 'react';

import C3Chart from 'react-c3js';
import 'c3/c3.css';

class PieChart1 extends Component {

    render() {
        const data = {
            columns: [
                ["Risk Appetite",78],["Overconfidence / Illusion of control",55],["Stress Resistance / Optimism",40],["Integrity / Consciousness",25]
            ],
            type:"pie",
          };
          const color = {pattern:["#a834dd","#152c7e","#16075a","#7f218b"]}
          const pie = {
              label:{show:!1}
            }

        return (
            <React.Fragment>
               <C3Chart data={data} pie={pie} color={color}/>
            </React.Fragment>
        );
    }
}

export default PieChart1;
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, Media
} from "reactstrap"

import { setBreadcrumbItems } from "../../store/actions";
import * as Utils from "../Utils";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { post, get, put } from "helpers/helper_api";
import { withRouter, Link, useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import Loader from "components/Loader";
import DataTable from 'react-data-table-component';
import avatar1 from "../../assets/images/users/user-1.jpg";
import newsl from "../../front/assets/img/bg_why_us.jpg";
import { toast } from "react-toastify";
import CountUp from 'react-countup';

const Dashboard = (props) => {
  const { user } = props;
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const [progress, setProgress] = useState(0)
  const [progress1, setProgress1] = useState(0)
  const [readonly, setreadonly] = useState(85)
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [helpModal, setHelpModal] = useState(false);
  const [helpModal1, setHelpModal1] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleHelp = () => setTooltipOpen(!tooltipOpen);
  const [avgRating, setAvgRating] = useState(0)
  const [weight, setWeight] = useState([]);
  const [columns, setColumns] = useState([]);
  const [profileModal, setProfileModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [lastTab, setLastTab] = useState(false);
  const [fullConetnt, setFullConetnt] = useState(false);
  const [profileData, setProfileData] = useState();
  const [underReview, setUnderReview] = useState(false);
  const [adminData, setAdminData] = useState(undefined);
  const [invData, setInvData] = useState(undefined);
  const [statusBar, setStatusBar] = useState([
    {
      id: 1,
      titleEn: <span>Financing<br /> Request</span>,
      titleFr: <span>Demande de<br /> Financement</span>,
      icon: <i className="mdi mdi-check"></i>,
      finance: false
    },
    {
      id: 2,
      titleEn: <span>Profit <br />& Loss</span>,
      titleFr: <span>Compte de<br /> Résultat</span>,
      icon: <i className="mdi mdi-check"></i>,
      profit: false
    },
    {
      id: 3,
      titleEn: <span>Cash<br /> Flow</span>,
      titleFr: <span>Flux de <br />Trésorerie</span>,
      icon: <i className="mdi mdi-check"></i>,
      cashFlow: false
    },
    {
      id: 4,
      titleEn: <span>Balance<br /> Sheet</span>,
      titleFr: <span>Bilan<br /> Comptable</span>,
      icon: <i className="mdi mdi-check"></i>,
      balance: false
    },
    {
      id: 5,
      titleEn: <span>ESG Form</span>,
      titleFr: <span>Formulaire<br /> ESG</span>,
      icon: <i className="mdi mdi-check"></i>,
      ESG: false
    },
    {
      id: 6,
      titleEn: <span>Neuroprofiler</span>,
      titleFr: <span>Neuroprofiler</span>,
      icon: <i className="mdi mdi-check"></i>,
      neuro: false
    },
    {
      id: 7,
      titleEn: <span>Submit</span>,
      titleFr: <span>Soumettez <br />votre demande</span>,
      icon: <i className="mdi mdi-check"></i>,
      submit: false
    },
    {
      id: 8,
      titleEn: <span>Under Review</span>,
      titleFr: <span>En cours<br /> d’analyse</span>,
      icon: <i className="mdi mdi-check"></i>,
      underReview: false
    }
  ])
  const [statusBar1, setStatusBar1] = useState([])
  const breadcrumbItems = [
    { title: "Demleen", link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  // console.log('user', user)

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard', breadcrumbItems)
  }, [])

  useEffect(() => {
    introJs().start();
  }, [])


  useEffect(() => {
    // let user = localStorage.getItem('user');
    if (user?.token) {
      if (user?.role == 'SME') {
        getUserData();
      }
      if (user?.role == 'Admin') {
        FashboardData();
        getData();
      }
      if (user?.role == "Investor") {
        getInvestorDash();
      }
    }
  }, [user]);

  const getUserData = () => {
    console.log("checking user role ", user);
    if (!loading) {
      setLoading(true);
      let body = {
        userId: user?._id,
        token: user?.token
      }
      post('/user/profile/status', body)
        .then((res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setData(res?.data)
            let temp = [];

            let statusBarTemp = Object.assign([], statusBar);

            if (res?.data?.finance) {

              statusBarTemp[0].finance = res?.data?.finance;
              // setStatusBar({ ...statusBar })
              // console.log('temp1', temp, finance, res?.data?.finance)
              // if (res?.data?.finance == true) {
              //   setProgress(12.5)
              // }
            }
            if (res?.data?.profit) {
              statusBarTemp[1].profit = res?.data?.profit;
              // setStatusBar({ ...statusBar })
              // if (res?.data?.profit == true) {
              //   setProgress(25)
              // }

            }
            if (res?.data?.cashFlow) {
              statusBarTemp[2].cashFlow = res?.data?.cashFlow;
              // setStatusBar({ ...statusBar, cashFlow })
              // if (res?.data?.cashFlow == true){
              // setProgress(37.5)
              // }

            }
            if (res?.data?.balance) {
              statusBarTemp[3].balance = res?.data?.balance;
              // console.log('balancesheet',typeof res?.data?.balance)
              // setStatusBar({ ...statusBar })
              // if (res?.data?.balance == true){
              // setProgress(50)
              // }

            }
            if (res?.data?.ESG) {
              statusBarTemp[4].ESG = res?.data?.ESG;
              // console.log('ESG status', ESG, res?.data?.ESG)
              // setStatusBar({ ...statusBar })
              // if (res?.data?.ESG == true){
              // setProgress(62.5)
              // }

            }
            if (res?.data?.neuro) {
              statusBarTemp[5].neuro = res?.data?.neuro;
              // setStatusBar({ ...statusBar })
              //   setProgress(75)
            }
            if (res?.data?.approveStatus == 'In Progress') {
              statusBarTemp[7].underReview = true;
              // setStatusBar({ ...statusBar })
              //   setProgress(75)
            }

            const visibleItems = statusBarTemp.filter(item => item.finance || item.profit || item.cashFlow || item.balance || item.ESG || item.neuro || item.submit);
            const falseItems = statusBarTemp.filter(item => !item.finance && !item.profit && !item.cashFlow && !item.balance && !item.ESG && !item.neuro && !item.submit);

            console.log('resultArray', visibleItems?.length);
            const resultArray = visibleItems.concat(falseItems);

            setStatusBar1(resultArray);

            if (visibleItems?.length >= 6) {
              setProgress1(12.5 * 7)

            } else {
              visibleItems?.map((item, index) => {
                setProgress(12.5 * (index + 1))
                setProgress1(12.5 * (index + 1))
              })
            }
            if (visibleItems?.length >= 6) {
              setProgress(100)
            }
            if (res?.data?.finance && res?.data?.profit && res?.data?.cashFlow && res?.data?.balance && res?.data?.ESG && res?.data?.neuro) {
              setLastTab(true);

              if (res?.data?.approveStatus == 'Pending') {
                put('/user', {
                  token: user?.token,
                  userId: user?._id,
                  approveStatus: 'Submitted'
                })
              }
            }
            if (res?.data?.approveStatus == 'In Progress' || res?.data?.approveStatus == 'Accepted') {
              statusBarTemp[7].underReview = true;
              setProgress1(100);
              if (res?.data?.approveStatus == 'Accepted') {
                let temp = Object.assign([], statusBar);
                temp[7].titleEn = <span>Approved</span>
                temp[7].titleFr = <span>Approuvé</span>
                setStatusBar(temp);
              }
            }
          }
        }))
        .catch(error => {
          setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
        });
    }
  }

  const FashboardData = () => {
    if (!loading) {
      get('/user/admin', { token: user?.token })
        .then((res) => {
          // console.log('res dashboard', res);
          if (res?.statusCode == 200) {
            setAdminData(res?.data)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const getInvestorDash = () => {
    if (!loading) {
      get('/user/investor', { token: user?.token })
        .then((res) => {
          console.log('res dashboard', res);
          if (res?.statusCode == 200) {
            setInvData(res?.data)
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          console.log(err);
          toast.error("Something Went Wrong!");
        })
    }
  }

  const handleClose = () => setProfileModal(false);
  const handleShow = () => setProfileModal(true);

  const getData = () => {
    if (!loading) {
      setLoading(true);

      let body = {
        role: "SME",
        token: user?.token
      }
      post("/user/list", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setData1(res?.data);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          setLoading(false);
          console.error('While getting SME list', err);
          toast.error("Something Went Wrong!");
        })
    }
  }

  useEffect(() => {
    if (user?.token) {

      get('/weight/list', { token: user?.token }).
        then((res) => {
          if (res?.statusCode == 200) {
            setWeight(res?.data);
          }
        })
    }
  }, [user])

  const calculateRating = (row) => {
    let total = 0;
    if (row?.profRating?.rating) {
      let per = Number(weight?.profitAndLoss) / 100;
      total = total + (Number(row?.profRating?.rating) * per);

    }

    if (row?.esgRating?.rating) {
      let per = Number(weight?.eSG) / 100;
      total = total + (Number(row?.esgRating?.rating) * per)

    }
    if (row?.financeRating?.rating) {
      let per = Number(weight?.financingRequest) / 100;
      total = total + (Number(row?.financeRating?.rating) * per)

    }
    if (row?.cashFlowRating?.rating) {
      let per = Number(weight?.cashFlow) / 100;
      total = total + (Number(row?.cashFlowRating?.rating) * per)

    }
    if (row?.balanceRating?.rating) {
      let per = Number(weight?.balanceSheet) / 100;
      total = total + (Number(row?.balanceRating?.rating) * per)

    }
    if (row?.nuroRating?.rating) {
      let per = Number(weight?.nuroProfiler) / 100;
      total = total + (Number(row?.nuroRating?.rating) * per)

    }

    if (row?.ratioRating?.rating) {
      let per = Number(weight?.ratio) / 100;
      total = total + (Number(row?.ratioRating?.rating) * per)

    }
    return total;

  }

  useEffect(() => {
    setColumns([
      {
        name: t("Username"),
        selector: (row, index) => <button className='number_btn' style={{ backgroundColor: 'transparent' }} onClick={() => history.push('/search-details', { userId: row?._id })}>{row?.username}</button>,
        maxWidth: '30px'
      },
      {
        name: t("Company Name"),
        selector: row => <span title={row.companyName}>{row.companyName}</span>,
        maxWidth: '200px'
      },
      {
        name: t("Sector"),
        selector: row => <span title={t(row.businessSector)}>{t(row.businessSector)}</span>,
      },
      {
        name: t("Geography"),
        selector: row => <span title={row.businessCity}>{row.businessCity}</span>,
        maxWidth: '200px'
      },
      {
        name: t("Email"),
        selector: row => <span title={row?.email}>{row?.email}</span>,
      },
      {
        name: t("Rating"),
        selector: row => <span>{calculateRating(row)}</span>,
      },
      {
        name: t('Status'),
        selector: row => <span>
          {
            row?.approveStatus == 'Submitted' &&
            <p className='status_check mt-0' style={{ backgroundColor: 'orange', color: '#fff' }} >{t("New Request")}</p>
          }
          {
            row?.approveStatus == 'In Progress' &&
            <p className='status_check mt-0' style={{ backgroundColor: 'blue', color: '#fff' }} >{t("In Progress")}</p>
          }
          {
            row?.approveStatus == 'Accepted' &&
            <p className='status_check mt-0' style={{ backgroundColor: 'green', color: '#fff' }}>{t("Accepted")}</p>
          }
          {
            row?.approveStatus == 'Rejected' &&
            <p className='status_check mt-0' style={{ backgroundColor: 'red', color: '#fff' }}>{t("Rejected")}</p>
          }
        </span>
      }
    ]);
  }, [data1, i18n.language, weight]);

  const handleStatusChange = (row) => {
    setCurrentData({ ...row, actionType: 'Approve' });
    setDeleteModal(true);
  }
  const ExpandedComponent = ({ data }) =>
    <div className='search_expended_component'>
      <h3>Profile</h3>
    </div>;


  // console.log('statusBar')


  const reports = [
    { title: "Orders", iconClass: "cube-outline", total: "1,587", average: "+11%", badgecolor: "info" },
    { title: "Revenue", iconClass: "buffer", total: "$46,782", average: "-29%", badgecolor: "danger" },
    { title: "Average Price", iconClass: "tag-text-outline", total: "$15.9", average: "0%", badgecolor: "warning" },
    { title: "Product Sold", iconClass: "briefcase-check", total: "1890", average: "+89%", badgecolor: "info" },
  ]
  const percentage = 85;

  // console.log('progress', progress)
  return (
    <React.Fragment>
      <Loader loading={loading} />
      <MetaTags>
        <title>Dashboard | Demleen</title>
      </MetaTags>
      {
        user?.role == 'SME' &&
        <>
          <Modal size="lg" className='file_upload_popup ' isOpen={helpModal} centered>
            <ModalHeader toggle={() => setHelpModal(!helpModal)}>Instruction</ModalHeader>
            <ModalBody>
              <div className="popup_content">
                <p className="mt-0">{t("Dashboard one")}</p>
                <ul>
                  <li><i className="mdi mdi-hand-pointing-right"></i>
                    {t("Dashboard two")}
                  </li>
                  <li><i className="mdi mdi-hand-pointing-right"></i>
                    {t("Dashboard three")}
                  </li>
                  <li><i className="mdi mdi-hand-pointing-right"></i>{t("Dashboard four")}</li>
                  <li><i className="mdi mdi-hand-pointing-right"></i>{t("Dashboard five")}
                  </li>

                </ul>
                <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("EmailContact") }}></p>

              </div>
            </ModalBody>

          </Modal>
          <Modal size="lg" className='file_upload_popup file_upload_popup_new' isOpen={helpModal1} centered>
            <ModalHeader toggle={() => setHelpModal1(!helpModal1)}>Instruction</ModalHeader>
            <ModalBody>
              <div className="popup_content">
                <iframe src="https://www.youtube-nocookie.com/embed/xwMmSSvL0Ms?si=eM3EToe-iRyKDDwm&amp;start=3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </ModalBody>

          </Modal>

          <div className="button-section-dashboard">
            <button className="" onClick={() => setHelpModal(!helpModal)}><span>i</span> Help</button>
            <button className="" onClick={() => setHelpModal1(!helpModal1)}><span>i</span> {i18n?.language == 'en' ? 'Watch the user guide in video format (in French)' : 'Regarder le guide d’utilisation vidéo'}</button>
          </div>
        </>
      }
      {/*mimi widgets */}
      {/* <Miniwidget reports={reports} /> */}
      {/* <div style={{position:'absolute',right:0,top:0}}  data-intro="Hello World! 👋"></div>
      <div  style={{position:'absolute',right:0,bottom:0}} data-intro="Intro.js can highlight on elements"></div> */}
      {
        user?.role == 'SME' &&
        <>
          <Row >
            <Col lg={12} >
              <Card>
                <CardBody className="profile_progress_section_dashboard">
                  <div className='profile_progress_section'>
                    <ProgressBar
                      percent={progress1}
                      filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                    >
                      {
                        statusBar1?.map((item, index) => (
                          <Step transition="scale">
                            {({ accomplished }) => (
                              <div className='progress_bar_section'>
                                <div >
                                  {
                                    accomplished ? <div className='step_icon active_btn'>{item?.icon}</div> : <div className='step_icon'>{index + 1}</div>
                                  }
                                </div>
                                <span>{i18n?.language == 'en' ? <>{item?.titleEn}</> : <>{item?.titleFr}</>}</span>
                              </div>
                            )}
                          </Step>
                        ))
                      }
                    </ProgressBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="pb-5">
            <Col xl="12">
              <h4 className="heading-4 ">{t("Dashboard paragraph")}</h4>
            </Col>
            <Col xl="8" className="dashboard-left-content">
              <Card>
                <CardBody>


                  <ul className="progress_list">
                    <li>
                      <div className="left_section">
                        <div className={data?.finance == true ? 'icon_box active_btn' : 'icon_box'}>
                          {
                            data?.finance == true ? <p className="mb-0"><i className="mdi mdi-check"></i></p> : <p className="mb-0"><i className="mdi mdi-exclamation"></i></p>
                          }


                        </div>
                        <span>{t("Dashboard tabone")}</span>
                      </div>
                      <div className="right_section">
                        {
                          data?.finance == true ? <Link to="/financing-details" className="btn_ black_btn"><i className="mdi mdi-eye"></i></Link> : null
                        }

                        <Link to="/financing" className="btn_ yellow_btn"><i className="mdi mdi-pencil"></i></Link>
                        {/* <a className="btn_ red_btn"><i className="mdi mdi-chevron-right"></i></a> */}
                      </div>
                    </li>
                  </ul>


                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <ul className="progress_list">
                    <li>
                      <div className="left_section">
                        <div className={data?.profit == true ? 'icon_box active_btn' : 'icon_box'}>
                          {
                            data?.profit == true ? <p className="mb-0"><i className="mdi mdi-check"></i></p> : <p className="mb-0"><i className="mdi mdi-exclamation"></i></p>
                          }
                        </div>
                        <span>{t("Dashboard tabtwo")}</span>
                      </div>
                      <div className="right_section">
                        {
                          data?.profit == true ? <Link to="/profit-and-loss-account-detail" className="btn_ black_btn"><i className="mdi mdi-eye"></i></Link> : null
                        }
                        <Link to="/profit-and-loss-account" className="btn_ yellow_btn"><i className="mdi mdi-pencil"></i></Link>
                        {/* <a className="btn_ red_btn"><i className="mdi mdi-chevron-right"></i></a> */}
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <ul className="progress_list">
                    <li>
                      <div className="left_section">
                        <div className={data?.cashFlow == true ? 'icon_box active_btn' : 'icon_box'}>
                          {
                            data?.cashFlow == true ? <p className="mb-0"><i className="mdi mdi-check"></i></p> : <p className="mb-0"><i className="mdi mdi-exclamation"></i></p>
                          }
                        </div>
                        <span>{t("Dashboard tabthree")}</span>
                      </div>
                      <div className="right_section">
                        {
                          data?.cashFlow == true ? <Link to="/cash-flow-detail" className="btn_ black_btn"><i className="mdi mdi-eye"></i></Link> : null
                        }
                        <Link to="/cash-flow" className="btn_ yellow_btn"><i className="mdi mdi-pencil"></i></Link>
                        {/* <a className="btn_ red_btn"><i className="mdi mdi-chevron-right"></i></a> */}
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <ul className="progress_list">
                    <li>
                      <div className="left_section">
                        <div className={data?.balance == true ? 'icon_box active_btn' : 'icon_box'}>
                          {
                            data?.balance == true ? <p className="mb-0"><i className="mdi mdi-check"></i></p> : <p className="mb-0"><i className="mdi mdi-exclamation"></i></p>
                          }
                        </div>
                        <span>{t("Dashboard tabfour")}</span>
                      </div>
                      <div className="right_section">
                        {
                          data?.balance == true ? <Link to="/balance-sheet-detail" className="btn_"><i className="mdi mdi-eye"></i></Link> : null
                        }
                        <Link to="/balance-sheet" className="btn_"><i className="mdi mdi-pencil"></i></Link>
                        {/* <a className="btn_"><i className="mdi mdi-chevron-right"></i></a> */}
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <ul className="progress_list">
                    <li>
                      <div className="left_section">
                        <div className={data?.ESG == true ? 'icon_box active_btn' : 'icon_box'}>
                          {
                            data?.ESG == true ? <p className="mb-0"><i className="mdi mdi-check"></i></p> : <p className="mb-0"><i className="mdi mdi-exclamation"></i></p>
                          }
                        </div>
                        <span>{t("Dashboard tabfive")}</span>
                      </div>
                      <div className="right_section">
                        {
                          data?.ESG == true ? <Link to="/esg-details" className="btn_"><i className="mdi mdi-eye"></i></Link> : null
                        }
                        <Link to="/esg" className="btn_"><i className="mdi mdi-pencil"></i></Link>
                        {/* <a className="btn_"><i className="mdi mdi-chevron-right"></i></a> */}
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <ul className="progress_list">
                    <li>
                      <div className="left_section">
                        <div className={data?.neuro == true ? 'icon_box active_btn' : 'icon_box'}>
                          {
                            data?.neuro == true ? <p className="mb-0"><i className="mdi mdi-check"></i></p> : <p className="mb-0"><i className="mdi mdi-exclamation"></i></p>
                          }
                        </div>
                        <span>{t("Neuroprofiler")}</span>
                      </div>
                      <div className="right_section">
                        <Link to="/neuro-profiler" className="btn_"><i className="mdi mdi-pencil"></i></Link>
                        {/* <a className="btn_"><i className="mdi mdi-chevron-right"></i></a> */}
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <ul className="progress_list">
                    <li>
                      <div className="left_section">
                        <div className={lastTab == true ? 'icon_box active_btn' : 'icon_box'}>
                          {
                            lastTab == true ? <p className="mb-0"><i className="mdi mdi-check"></i></p> : <p className="mb-0"><i className="mdi mdi-exclamation"></i></p>
                          }
                        </div>
                        <span>{t("Dashboard tabsix")}</span>
                      </div>
                      {/* <div className="right_section">
                    <a className="btn_"><i className="mdi mdi-pencil"></i></a>
                    <a className="btn_"><i className="mdi mdi-chevron-right"></i></a>
                  </div> */}
                    </li>
                  </ul>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <ul className="progress_list">
                    <li>
                      <div className="left_section">
                        <div className={statusBar1[7]?.underReview == true ? 'icon_box active_btn' : 'icon_box'}>
                          {
                            statusBar1[7]?.underReview == true ? <p className="mb-0"><i className="mdi mdi-check"></i></p> : <p className="mb-0"><i className="mdi mdi-exclamation"></i></p>
                          }
                        </div>
                        <span>{statusBar1[7]?.approveStatus == "In Process" ? t("Dashboard tabseven") : t("Approved")}</span>
                      </div>
                      {/* <div className="right_section">
                    <a className="btn_"><i className="mdi mdi-pencil"></i></a>
                    <a className="btn_"><i className="mdi mdi-chevron-right"></i></a>
                  </div> */}
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>

            <Col xl="4">
              <Card className="right_box_card">
                <CardBody>
                  <div className="text-center" style={{ height: '100%' }} dir="ltr">
                    <h5 className="font-size-18 mb-4">{t("Dashboard tabeight")} ({`${Math.trunc(progress)}%`})</h5>
                    <div className="circle-progress-bar">
                      <div className="circle_b">
                        <CircularProgressbar
                          value={progress}
                          strokeWidth={10}

                          text={`${Math.trunc(progress)}%`}
                          styles={buildStyles({
                            rotation: 0.50,
                            strokeLinecap: 'butt',
                            textSize: '16px',
                            pathTransitionDuration: 0.5,
                            pathColor: `#1b005e`,
                            textColor: '#1b005e',
                            trailColor: '#f1f1f1',
                            backgroundColor: '#1b005e',
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </>
      }
      {
        user?.role == 'Admin' &&
        <>
          <div className="admin_section">
            <Row>
              <Col lg={3}>
                <Card className="card_new_box">
                  <CardBody>
                    <h4><span className="newsme_bg"></span>{t("New Request")}</h4>
                    <h5><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={adminData?.newCount} /></h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={3}>
                <Card className="card_new_box">
                  <CardBody>
                    <h4><span className="inprogress_bg"></span>{t("In Progress")}</h4>
                    <h5><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={adminData?.inProgressCount} /></h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={3}>
                <Card className="card_new_box">
                  <CardBody>
                    <h4><span className="accept_bg"></span>{t("Accepted")}</h4>
                    <h5><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={adminData?.acceptedCount} /></h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={3}>
                <Card className="card_new_box">
                  <CardBody>
                    <h4><span className="reject_bg"></span>{t("Rejected")}</h4>
                    <h5><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={adminData?.rejectedCount} /></h5>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </div>
          <Modal centered show={profileModal} className='model_profile' >
            <Modal.Header onHide={handleClose} closeButton>
              <Modal.Title>{t("COMPANY PROFILE")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='profile_d'>
                <Media className="mb-4">
                  <img
                    className="d-flex me-3 rounded-circle avatar-sm"
                    src={avatar1}
                    alt="lexa"
                  />
                  <Media body className="align-self-center">
                    <h4 className="font-size-18 m-0">
                      {profileData?.fname}  {profileData?.lname}
                    </h4>
                    <small className="text-muted">{profileData?.email}</small>
                  </Media>
                </Media>
                <ul className='profile_details_box'>
                  <li>
                    <h4 className="mt-0 font-size-16">
                      <strong>{t("Phone Number")}</strong> {profileData?.mobile}
                    </h4>
                  </li>
                  <li>
                    <h4 className="mt-0 font-size-16">
                      <strong>{t("Company Name")}</strong> {profileData?.companyName}
                    </h4>
                  </li>
                  {/* <li>
                                <h4 className="mt-0 font-size-16">
                                    <strong>Geography</strong> {profileData?.businessCity}
                                </h4>
                            </li> */}
                  <li>
                    <h4 className="mt-0 font-size-16">
                      <strong>{t("Business Sector")}</strong> {profileData?.businessSector}
                    </h4>
                  </li>
                  <li>
                    <h4 className="mt-0 font-size-16">
                      <strong>{t("Company Address")}</strong> {profileData?.businessAddress}
                    </h4>
                  </li>
                </ul>

                {/* <hr /> */}
              </div>
            </Modal.Body>

          </Modal>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody >
                  <div className='investor_component'>
                    <DataTable
                      columns={columns}
                      data={data1}
                      // expandableRows={true}
                      expandOnRowClicked={true}
                      expandableRowDisabled={row => row.disabled}
                      expandableRowsComponent={ExpandedComponent}
                      expandableRowsComponentProps={row => row.Action}
                      direction="ltr"
                      fixedHeader
                      fixedHeaderScrollHeight="500px"
                      // pagination
                      responsive
                      subHeaderAlign="right"
                      subHeaderWrap
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      }
      {
        user?.role == 'Investor' &&
        <>
          <Row>
            <Col lg={12}>
              <div className="admin_section">
                <Row>
                  <Col lg={4}>
                    <Card className="card_new_box">
                      <CardBody>
                        <h4><span className="reject_bg"></span>{t("Favourite Companies")}</h4>
                        <h5><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={invData?.likeCount} /></h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card className="card_new_box">
                      <CardBody>
                        <h4><span className="accept_bg"></span>{t("Total SME")}</h4>
                        <h5><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={invData?.smeCount} /></h5>
                      </CardBody>
                    </Card>
                  </Col>


                </Row>
              </div>
            </Col>

          </Row>
          {invData && invData?.news?.length &&
            <Row className="mb-5 pb-5">
              {invData?.news?.map((item, index) => (
                <Col lg={4}>
                  <div className="leftside_news">
                    <img src={item?.image ? item?.image : newsl} />
                    <div className="leftside_news-detail">
                      <h3>{item?.title}</h3>
                      <p>{item?.desc}</p>
                      {/* <button>Read More</button> */}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          }
        </>
      }
      <Row>

        <Col xl="4" lg="6">
          {/* inbox */}
          {/* <Inbox /> */}
        </Col>
        <Col xl="4" lg="6">
          {/* recent activity */}
          {/* <RecentActivity /> */}

        </Col>
        <Col xl="4">
          {/* widget user */}
          {/* <WidgetUser /> */}

          {/* yearly sales */}
          {/* <YearlySales /> */}
        </Col>
      </Row>

      <Row>
        <Col xl="6">
          {/* latest transactions */}
          {/* <LatestTransactions /> */}
        </Col>

        <Col xl="6">
          {/* latest orders */}
          {/* <LatestOrders /> */}
        </Col>
      </Row>

    </React.Fragment>
  )
}
const mapStatetoProps = state => {

  const user = state.Profile.success;
  return { user };
}

export default withRouter(
  connect(mapStatetoProps, { setBreadcrumbItems })(Dashboard)
)

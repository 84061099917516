import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Toastify = (props) => {


    // if (props.visible)
        return (
            <div style={{}}>
              <ToastContainer />
            </div>
        )
    // else
    //     return null;
}

export default Toastify;
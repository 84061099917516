import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation'
import img1 from './assets/img/800x600.png'
import img2 from './assets/img/side_image.png'
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
export default function OurSolution() {
    const { i18n, t } = useTranslation();
    const [activeTab, setActiveTab] = useState('1')
    return (
        <div className='body_front'>
            <Helmet>

                <title>African SME Financing | Financial Support</title>
                <meta name="description" content=" Get financial support for African SMEs. Find the best financing solutions for your business." />
                <link rel="canonical" href="https://demleen.com/our-solution" />
            </Helmet>
            <Header />
            <div class="breadcrumb-area shadow dark bg-fixed text-light inner_banner">
                <div class="container">
                    <div class="row align-center">
                        <div class="col-lg-6">
                            <h2>{t("Solution")}</h2>
                        </div>

                    </div>
                </div>
            </div>
            <div class="bg_white_b about-area faq-area inc-shape default-padding ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="thumb">

                                {/* <img src={img1} alt="Thumb" /> */}
                                <img src={img2} alt="Thumb" style={{ width: '100%' }} />


                            </div>
                        </div>
                        <div class="col-lg-5 offset-lg-1 info">
                            <h2 className='h2_m'>{t("Our Solution Heading")}</h2>
                            <p>
                                {t("Our Solution paragraph")}
                            </p>
                            <p>{t("Our Solution paragraph1")}</p>
                            <p>{t("Our Solution paragraph2")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

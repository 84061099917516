

import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

const balanceChart1 = ({ horizontal, title, value, label }) => {


    // console.log('pre%', per, temp)
    let years = ["11", "22", "33"];
    const series = [
        {
            name: '',
            data: value,
        }
    ]
    const options = {
        colors: ["#3c4ccf", "#1B005E"],
        chart: {
            stacked: true,
            toolbar: {
                show: true,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toLocaleString()
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "40%",
                horizontal: horizontal,
            },
        },
        grid: {
            borderColor: "#f8f8fa",
            row: {
                colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },

        xaxis: {
            categories: title,
            labels: {
                formatter: function (val) {
                    return val
                },
            },
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
            title: {
                text: horizontal == false ? 'Years' : label,
            },
        },
        yaxis: {
            title: {
                text: horizontal == false ? label : 'Years',
            },
        },
        // tooltip: {
        //     y: {
        //         formatter: function (val, opt) {

        //             let per = undefined;
        //             var temp = [0.0];
        //             if(opt?.series?.length)
        //             if (opt?.series[0]?.length > 1) {
        //                 let value = opt?.series[0];
        //                 per = ((value[1] - value[0]) * 100) / value[1]
        //                 temp.push(per)
        //                 if (value?.length > 2) {
        //                     per = ((value[2] - value[1]) * 100) / value[2]
        //                     temp.push(per)
        //                 }
        //             }
        //             console.log('opt', opt, temp)
        //             return `${val} (${temp[opt?.dataPointIndex].toFixed(2)}%)`
        //         },
        //     },
        //     x: {
        //         formatter: function (val) {
        //             console.log('value tooltips', val)
        //             return val
        //         },
        //     },
        // },
        fill: {
            opacity: 1,
        },

        legend: {
            show: false,
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
        },
    }



    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height="410"
            />
        </React.Fragment>
    )
}

export default balanceChart1
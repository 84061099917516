import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

const balanceChart = ({ title, value }) => {

    const series = [
        {
            name:'',
            data: value,
        },
    ]
    const options = {
        chart: {
            toolbar: {
                show: true,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toLocaleString()
            },
        },

        colors: ["#559A9D"],
        grid: {
            borderColor: "#f1f1f1",
        },
        xaxis: {
            categories: title,
        },
    }

    return (
        <ReactApexChart options={options} series={series} type="bar" height="350" />
    )
}

export default balanceChart

import PropTypes, { number } from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table as TableData,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap"
import { Space, Table, Tag } from 'antd';
const { Column, ColumnGroup } = Table;
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import ExcelFile from '../../assets/excel/sample.xlsx'
import { FileUploader } from "react-drag-drop-files";

import { upload, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import RangeSlider from 'react-bootstrap-range-slider';
import moment from 'moment';
import Loader from 'components/Loader';

const BalanceSheet = props => {
    const scrollContainerRef = useRef(null);
    const fileTypes = ["xlsx"];
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [idx, setidx] = useState(1)
    const [value, setValue] = useState(0);
    const [intangiblefixedassetsGross1, setIntangiblefixedassetsGross1] = useState(0)
    const [intangiblefixedassetsDepreciation1, setIntangiblefixedassetsDepreciation1] = useState(0)
    const [intangiblefixedassetsNet1, setIntangiblefixedassetsNet1] = useState(0)
    const [intangiblefixedassetsGross2, setIntangiblefixedassetsGross2] = useState(0)
    const [intangiblefixedassetsDepreciation2, setIntangiblefixedassetsDepreciation2] = useState(0)
    const [intangiblefixedassetsNet2, setIntangiblefixedassetsNet2] = useState(0)
    const [intangiblefixedassetsGross3, setIntangiblefixedassetsGross3] = useState(0)
    const [intangiblefixedassetsDepreciation3, setIntangiblefixedassetsDepreciation3] = useState(0)
    const [intangiblefixedassetsNet3, setIntangiblefixedassetsNet3] = useState(0)

    const [tangiblefixedassetsGross1, setTangiblefixedassetsGross1] = useState(0)
    const [tangiblefixedassetsDepreciation1, setTangiblefixedassetsDepreciation1] = useState(0)
    const [tangiblefixedassetsNet1, setTangiblefixedassetsNet1] = useState(0)
    const [tangiblefixedassetsGross2, setTangiblefixedassetsGross2] = useState(0)
    const [tangiblefixedassetsDepreciation2, setTangiblefixedassetsDepreciation2] = useState(0)
    const [tangiblefixedassetsNet2, setTangiblefixedassetsNet2] = useState(0)
    const [tangiblefixedassetsGross3, setTangiblefixedassetsGross3] = useState(0)
    const [tangiblefixedassetsDepreciation3, setTangiblefixedassetsDepreciation3] = useState(0)
    const [tangiblefixedassetsNet3, setTangiblefixedassetsNet3] = useState(0)

    const [financialFixedAssetsGross1, setFinancialFixedAssetsGross1] = useState(0)
    const [financialFixedAssetsDepreciation1, setFinancialFixedAssetsDepreciation1] = useState(0)
    const [financialFixedAssetsNet1, setFinancialFixedAssetsNet1] = useState(0)
    const [financialFixedAssetsGross2, setFinancialFixedAssetsGross2] = useState(0)
    const [financialFixedAssetsDepreciation2, setFinancialFixedAssetsDepreciation2] = useState(0)
    const [financialFixedAssetsNet2, setFinancialFixedAssetsNet2] = useState(0)
    const [financialFixedAssetsGross3, setFinancialFixedAssetsGross3] = useState(0)
    const [financialFixedAssetsDepreciation3, setFinancialFixedAssetsDepreciation3] = useState(0)
    const [financialFixedAssetsNet3, setFinancialFixedAssetsNet3] = useState(0)


    const [totalFixedAssetsGross1, setTotalFixedAssetsGross1] = useState(0)
    const [totalFixedAssetsDepreciation1, setTotalFixedAssetsDepreciation1] = useState(0)
    const [totalFixedAssetsNet1, setTotalFixedAssetsNet1] = useState(0)
    const [totalFixedAssetsGross2, setTotalFixedAssetsGross2] = useState(0)
    const [totalFixedAssetsDepreciation2, setTotalFixedAssetsDepreciation2] = useState(0)
    const [totalFixedAssetsNet2, setTotalFixedAssetsNet2] = useState(0)
    const [totalFixedAssetsGross3, setTotalFixedAssetsGross3] = useState(0)
    const [totalFixedAssetsDepreciation3, setTotalFixedAssetsDepreciation3] = useState(0)
    const [totalFixedAssetsNet3, setTotalFixedAssetsNet3] = useState(0)

    const [suppliersAccountsPayableGross1, setSuppliersAccountsPayableGross1] = useState(0)
    const [suppliersAccountsPayableDepreciation1, setSuppliersAccountsPayableDepreciation1] = useState(0)
    const [suppliersAccountsPayableNet1, setSuppliersAccountsPayableNet1] = useState(0)
    const [suppliersAccountsPayableGross2, setSuppliersAccountsPayableGross2] = useState(0)
    const [suppliersAccountsPayableDepreciation2, setSuppliersAccountsPayableDepreciation2] = useState(0)
    const [suppliersAccountsPayableNet2, setSuppliersAccountsPayableNet2] = useState(0)
    const [suppliersAccountsPayableGross3, setSuppliersAccountsPayableGross3] = useState(0)
    const [suppliersAccountsPayableDepreciation3, setSuppliersAccountsPayableDepreciation3] = useState(0)
    const [suppliersAccountsPayableNet3, setSuppliersAccountsPayableNet3] = useState(0)

    const [totalCurrentAssetsGross1, setTotalCurrentAssetsGross1] = useState(0)
    const [totalCurrentAssetsDepreciation1, setTotalCurrentAssetsDepreciation1] = useState(0)
    const [totalCurrentAssetsNet1, setTotalCurrentAssetsNet1] = useState(0)
    const [totalCurrentAssetsGross2, setTotalCurrentAssetsGross2] = useState(0)
    const [totalCurrentAssetsDepreciation2, setTotalCurrentAssetsDepreciation2] = useState(0)
    const [totalCurrentAssetsNet2, setTotalCurrentAssetsNet2] = useState(0)
    const [totalCurrentAssetsGross3, setTotalCurrentAssetsGross3] = useState(0)
    const [totalCurrentAssetsDepreciation3, setTotalCurrentAssetsDepreciation3] = useState(0)
    const [totalCurrentAssetsNet3, setTotalCurrentAssetsNet3] = useState(0)

    const [totalCurrentAssetsGross_1, setTotalCurrentAssetsGross_1] = useState(0)
    const [totalCurrentAssetsDepreciation_1, setTotalCurrentAssetsDepreciation_1] = useState(0)
    const [totalCurrentAssetsNet_1, setTotalCurrentAssetsNet_1] = useState(0)
    const [totalCurrentAssetsGross_2, setTotalCurrentAssetsGross_2] = useState(0)
    const [totalCurrentAssetsDepreciation_2, setTotalCurrentAssetsDepreciation_2] = useState(0)
    const [totalCurrentAssetsNet_2, setTotalCurrentAssetsNet_2] = useState(0)
    const [totalCurrentAssetsGross_3, setTotalCurrentAssetsGross_3] = useState(0)
    const [totalCurrentAssetsDepreciation_3, setTotalCurrentAssetsDepreciation_3] = useState(0)
    const [totalCurrentAssetsNet_3, setTotalCurrentAssetsNet_3] = useState(0)


    const [cashandFinancialIInstrumentsGross1, setCashandFinancialIInstrumentsGross1] = useState(0)
    const [cashandFinancialIInstrumentsDepreciation1, setCashandFinancialIInstrumentsDepreciation1] = useState(0)
    const [cashandFinancialIInstrumentsNet1, setCashandFinancialIInstrumentsNet1] = useState(0)
    const [cashandFinancialIInstrumentsGross2, setCashandFinancialIInstrumentsGross2] = useState(0)
    const [cashandFinancialIInstrumentsDepreciation2, setCashandFinancialIInstrumentsDepreciation2] = useState(0)
    const [cashandFinancialIInstrumentsNet2, setCashandFinancialIInstrumentsNet2] = useState(0)
    const [cashandFinancialIInstrumentsGross3, setCashandFinancialIInstrumentsGross3] = useState(0)
    const [cashandFinancialIInstrumentsDepreciation3, setCashandFinancialIInstrumentsDepreciation3] = useState(0)
    const [cashandFinancialIInstrumentsNet3, setCashandFinancialIInstrumentsNet3] = useState(0)

    const [valuationDifferenceAssetsGross1, setValuationDifferenceAssetsGross1] = useState(0)
    const [valuationDifferenceAssetsDepreciation1, setValuationDifferenceAssetsDepreciation1] = useState(0)
    const [valuationDifferenceAssetsNet1, setValuationDifferenceAssetsNet1] = useState(0)
    const [valuationDifferenceAssetsGross2, setValuationDifferenceAssetsGross2] = useState(0)
    const [valuationDifferenceAssetsDepreciation2, setValuationDifferenceAssetsDepreciation2] = useState(0)
    const [valuationDifferenceAssetsNet2, setValuationDifferenceAssetsNet2] = useState(0)
    const [valuationDifferenceAssetsGross3, setValuationDifferenceAssetsGross3] = useState(0)
    const [valuationDifferenceAssetsDepreciation3, setValuationDifferenceAssetsDepreciation3] = useState(0)
    const [valuationDifferenceAssetsNet3, setValuationDifferenceAssetsNet3] = useState(0)

    const [totalAssetsGross1, setTotalAssetsGross1] = useState(0)
    const [totalAssetsDepreciation1, setTotalAssetsDepreciation1] = useState(0)
    const [totalAssetsNet1, setTotalAssetsNet1] = useState(0)
    const [totalAssetsGross2, setTotalAssetsGross2] = useState(0)
    const [totalAssetsDepreciation2, setTotalAssetsDepreciation2] = useState(0)
    const [totalAssetsNet2, setTotalAssetsNet2] = useState(0)
    const [totalAssetsGross3, setTotalAssetsGross3] = useState(0)
    const [totalAssetsDepreciation3, setTotalAssetsDepreciation3] = useState(0)
    const [totalAssetsNet3, setTotalAssetsNet3] = useState(0)

    const [year1, setYear1] = useState(undefined);
    const [year2, setYear2] = useState(undefined);
    const [year3, setYear3] = useState(undefined);

    const [year_1, setYear_1] = useState(undefined);
    const [year_2, setYear_2] = useState(undefined);
    const [year_3, setYear_3] = useState(undefined);
    const { i18n, t } = useTranslation();
    const [excelUploadModal, setExcelUploadModal] = useState(false);
    const [file, setFile] = useState(null);
    const [allFieldsNotEmpty, setAllFieldsNotEmpty] = useState(false)
    const [formValue, setFormValue] = useState();
    const [loading, setLoading] = useState(false);
    const [data1, setData1] = useState(null)
    const handleChange = (file) => {
        setFile(file);
    };
    const toggle = () => setExcelUploadModal(!excelUploadModal);
    const formRef = useRef();

    const { user } = props;
    const scrollAmount = 300;

    const scrollLeftHandler = () => {
        scrollContainerRef.current.scrollLeft -= scrollAmount;
    };

    const scrollRightHandler = () => {
        scrollContainerRef.current.scrollLeft += scrollAmount;
    };

    useEffect(() => {
        // const isEmpty = Object.values(form).some((field) => field?.answer === 0 || field?.answer == '');


        // if (isEmpty) {
        //    setButtonStatus(false)
        // } else {
        //     setButtonStatus(true)
        // }
        const form = document.getElementById('myForm'); // Replace 'myForm' with your form's ID
        const formElements = form.getElementsByTagName('input');
        let allFieldsNotEmpty1 = true;
        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            console.log('element', element.value)
            if (element.tagName === 'INPUT' && element.value === '') {
                allFieldsNotEmpty1 = false;
                break;
            }
        }
        setAllFieldsNotEmpty(allFieldsNotEmpty1)

    }, [formValue])

    const getUserData = (year, setter) => {
        if (!loading) {
            setLoading(true);
            let body = {
                year,
                userId: props?.userId,
                token: user?.token
            }
            post('/balance_sheet', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        setter(res?.data);
                    } else {
                        setter(undefined);
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setter(undefined);
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                });
        }
    }

    const getUserData1 = (year, setter) => {
        if (!loading) {
            setLoading(true);
            let body = {
                year,
                userId: props?.userId,
                token: user?.token
            }
            post('/liab', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        setter(res?.data);
                    } else {
                        setter(undefined);
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setter(undefined);
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                });
        }
    }

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"))
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                setname(obj.displayName)
                setemail(obj.email)
                setidx(obj.uid)
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                setname(obj.username)
                setemail(obj.email)
                setidx(obj.uid)
            }
            setTimeout(() => {
                props.resetProfileFlag();
            }, 3000);
        }
    }, [props.success])

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Balance Sheet", link: "#" },
    ]


    // function handleValidSubmit(event, values) {

    //     if (values?.year_one) {
    //         let data = [];
    //         if (values?.year_one) {
    //             data.push({
    //                 ...renameKeys(values, 1),
    //                 userId: user?._id,
    //                 token: user.token,
    //                 year: values?.year_one,
    //                 intangiblefixedassetsGross: Number(intangiblefixedassetsGross1),
    //                 intangiblefixedassetsDepreciation: Number(intangiblefixedassetsDepreciation1),
    //                 intangiblefixedassetsNet: Number(intangiblefixedassetsNet1),
    //                 tangiblefixedassetsGross: Number(tangiblefixedassetsGross1),
    //                 tangiblefixedassetsDepreciation: Number(tangiblefixedassetsDepreciation1),
    //                 tangiblefixedassetsNet: Number(tangiblefixedassetsNet1),
    //                 financialFixedAssetsGross: Number(financialFixedAssetsGross1),
    //                 financialFixedAssetsDepreciation: Number(financialFixedAssetsDepreciation1),
    //                 financialFixedAssetsNet: Number(financialFixedAssetsNet1),
    //                 totalFixedAssetsGross: Number(totalFixedAssetsGross1),
    //                 totalFixedAssetsDepreciation: Number(totalFixedAssetsDepreciation1),
    //                 totalFixedAssetsNet: Number(totalFixedAssetsNet1),
    //                 suppliersAccountsPayableGross: Number(suppliersAccountsPayableGross1),
    //                 suppliersAccountsPayableDepreciation: Number(suppliersAccountsPayableDepreciation1),
    //                 suppliersAccountsPayableNet: Number(suppliersAccountsPayableNet1),
    //                 totalCurrentAssetsGross: Number(totalCurrentAssetsGross1),
    //                 totalCurrentAssetsDepreciation: Number(totalCurrentAssetsDepreciation1),
    //                 totalCurrentAssetsNet: Number(totalCurrentAssetsNet1),
    //                 cashandFinancialIInstrumentsGross: Number(cashandFinancialIInstrumentsGross1),
    //                 cashandFinancialIInstrumentsDepreciation: Number(cashandFinancialIInstrumentsDepreciation1),
    //                 cashandFinancialIInstrumentsNet: Number(cashandFinancialIInstrumentsNet1),
    //                 totalAssetsGross: Number(totalAssetsGross1),
    //                 totalAssetsDepreciation: Number(totalAssetsDepreciation1),
    //                 totalAssetsNet: Number(totalAssetsNet1),
    //             });
    //         }
    //         if (values?.year_two) {
    //             data.push({
    //                 ...renameKeys(values, 2),
    //                 userId: user?._id,
    //                 token: user.token,
    //                 year: values?.year_two,
    //                 intangiblefixedassetsGross: Number(intangiblefixedassetsGross2),
    //                 intangiblefixedassetsDepreciation: Number(intangiblefixedassetsDepreciation2),
    //                 intangiblefixedassetsNet: Number(intangiblefixedassetsNet2),
    //                 tangiblefixedassetsGross: Number(tangiblefixedassetsGross2),
    //                 tangiblefixedassetsDepreciation: Number(tangiblefixedassetsDepreciation2),
    //                 tangiblefixedassetsNet: Number(tangiblefixedassetsNet2),
    //                 financialFixedAssetsGross: Number(financialFixedAssetsGross2),
    //                 financialFixedAssetsDepreciation: Number(financialFixedAssetsDepreciation2),
    //                 financialFixedAssetsNet: Number(financialFixedAssetsNet2),
    //                 totalFixedAssetsGross: Number(totalFixedAssetsGross2),
    //                 totalFixedAssetsDepreciation: Number(totalFixedAssetsDepreciation2),
    //                 totalFixedAssetsNet: Number(totalFixedAssetsNet2),
    //                 suppliersAccountsPayableGross: Number(suppliersAccountsPayableGross2),
    //                 suppliersAccountsPayableDepreciation: Number(suppliersAccountsPayableDepreciation2),
    //                 suppliersAccountsPayableNet: Number(suppliersAccountsPayableNet2),
    //                 totalCurrentAssetsGross: Number(totalCurrentAssetsGross2),
    //                 totalCurrentAssetsDepreciation: Number(totalCurrentAssetsDepreciation2),
    //                 totalCurrentAssetsNet: Number(totalCurrentAssetsNet2),
    //                 cashandFinancialIInstrumentsGross: Number(cashandFinancialIInstrumentsGross2),
    //                 cashandFinancialIInstrumentsDepreciation: Number(cashandFinancialIInstrumentsDepreciation2),
    //                 cashandFinancialIInstrumentsNet: Number(cashandFinancialIInstrumentsNet2),
    //                 totalAssetsGross: Number(totalAssetsGross2),
    //                 totalAssetsDepreciation: Number(totalAssetsDepreciation2),
    //                 totalAssetsNet: Number(totalAssetsNet2),
    //             });
    //         }
    //         if (values?.year_three) {
    //             data.push({
    //                 ...renameKeys(values, 3),
    //                 userId: user?._id,
    //                 token: user.token,
    //                 year: values?.year_three,
    //                 intangiblefixedassetsGross: Number(intangiblefixedassetsGross3),
    //                 intangiblefixedassetsDepreciation: Number(intangiblefixedassetsDepreciation3),
    //                 intangiblefixedassetsNet: Number(intangiblefixedassetsNet3),
    //                 tangiblefixedassetsGross: Number(tangiblefixedassetsGross3),
    //                 tangiblefixedassetsDepreciation: Number(tangiblefixedassetsDepreciation3),
    //                 tangiblefixedassetsNet: Number(tangiblefixedassetsNet3),
    //                 financialFixedAssetsGross: Number(financialFixedAssetsGross3),
    //                 financialFixedAssetsDepreciation: Number(financialFixedAssetsDepreciation3),
    //                 financialFixedAssetsNet: Number(financialFixedAssetsNet3),
    //                 totalFixedAssetsGross: Number(totalFixedAssetsGross3),
    //                 totalFixedAssetsDepreciation: Number(totalFixedAssetsDepreciation3),
    //                 totalFixedAssetsNet: Number(totalFixedAssetsNet3),
    //                 suppliersAccountsPayableGross: Number(suppliersAccountsPayableGross3),
    //                 suppliersAccountsPayableDepreciation: Number(suppliersAccountsPayableDepreciation3),
    //                 suppliersAccountsPayableNet: Number(suppliersAccountsPayableNet3),
    //                 totalCurrentAssetsGross: Number(totalCurrentAssetsGross3),
    //                 totalCurrentAssetsDepreciation: Number(totalCurrentAssetsDepreciation3),
    //                 totalCurrentAssetsNet: Number(totalCurrentAssetsNet3),
    //                 cashandFinancialIInstrumentsGross: Number(cashandFinancialIInstrumentsGross3),
    //                 cashandFinancialIInstrumentsDepreciation: Number(cashandFinancialIInstrumentsDepreciation3),
    //                 cashandFinancialIInstrumentsNet: Number(cashandFinancialIInstrumentsNet3),
    //                 totalAssetsGross: Number(totalAssetsGross3),
    //                 totalAssetsDepreciation: Number(totalAssetsDepreciation3),
    //                 totalAssetsNet: Number(totalAssetsNet3),
    //             });
    //         }
    //         data?.forEach((body, index) => {
    //             post('/balance_sheet/add', body)
    //                 .then((res) => {
    //                     if (data?.length - 1 === index)
    //                         if (res?.statusCode == 200) {
    //                             console.log('request on login', res);
    //                             toast.success(res?.message)
    //                         } else {
    //                             console.log(res?.error);
    //                             toast.error(res?.error)
    //                         }
    //                 })
    //                 .catch((err) => {
    //                     console.log(err)
    //                 })
    //         })
    //     }
    //     else {
    //         toast?.error('First Year is Required')
    //     }
    // }

    const uploadExcel = () => {
        if (file) {
            const form = new FormData();
            form.append("sample", file[0]);

            upload('/profit_loss/sample_upload', form, user?.token)
                .then((res) => {
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        toast.success(res?.message)
                        setExcelUploadModal(false)
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toast.error('Please choose file')
        }
    }

    function renameKeys(obj, to) {
        const newKeys = {};

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const regexPattern = new RegExp(to + "$");
                const newKey = key.replace(regexPattern, ''); // Remove trailing digits
                newKeys[newKey] = obj[key];
            }
        }

        return newKeys;
    }

    const columns = [
        {
            title: 'Asset',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            fixed: 'left',
        },
        {
            title: 'Other',
            render: (item) => <p>action</p>,
            children: [
                {
                    title: 'Age',
                    dataIndex: 'age',
                    key: 'age',
                    width: 150,
                    sorter: (a, b) => a.age - b.age,
                },
                {
                    title: 'Address',
                    children: [
                        {
                            title: 'Street',
                            dataIndex: 'street',
                            key: 'street',
                            width: 150,
                        },
                        {
                            title: 'Block',
                            children: [
                                {
                                    title: 'Building',
                                    dataIndex: 'building',
                                    key: 'building',
                                    width: 100,
                                },
                                {
                                    title: 'Door No.',
                                    dataIndex: 'number',
                                    key: 'number',
                                    width: 100,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Company',
            children: [
                {
                    title: 'Company Address',
                    dataIndex: 'companyAddress',
                    key: 'companyAddress',
                    width: 200,
                },
                {
                    title: 'Company Name',
                    dataIndex: 'companyName',
                    key: 'companyName',
                },
            ],
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: 80,
            fixed: 'right',
        },
    ];
    const data = [
        {
            key: '1',
            asset: <strong>{t("Intangible fixed assets")}</strong>,
            gross1: <strong>{year1?.intangiblefixedassetsGross}</strong>,
            gross2: <strong>{year2?.intangiblefixedassetsGross}</strong>,
            gross3: <strong>{year3?.intangiblefixedassetsGross}</strong>,
            depreciation1: <strong>{year1?.intangiblefixedassetsDepreciation}</strong>,
            depreciation2: <strong>{year2?.intangiblefixedassetsDepreciation}</strong>,
            depreciation3: <strong>{year3?.intangiblefixedassetsDepreciation}</strong>,
            net1: <strong>{year1?.intangiblefixedassetsNet}</strong>,
            net2: <strong>{year2?.intangiblefixedassetsNet}</strong>,
            net3: <strong>{year3?.intangiblefixedassetsNet}</strong>,

        },
        {
            key: '2',
            asset: <span>{t("Research and development costs")}</span>,
            gross1: <span>{year1?.researchAndDevelopmentCostsGross}</span>,
            gross2: <span>{year2?.researchAndDevelopmentCostsGross}</span>,
            gross3: <span>{year3?.researchAndDevelopmentCostsGross}</span>,
            depreciation1: <span>{year1?.researchAndDevelopmentCostsDepreciation}</span>,
            depreciation2: <span>{year2?.researchAndDevelopmentCostsDepreciation}</span>,
            depreciation3: <span>{year3?.researchAndDevelopmentCostsDepreciation}</span>,
            net1: <span>{year1?.researchAndDevelopmentCostsNet}</span>,
            net2: <span>{year2?.researchAndDevelopmentCostsNet}</span>,
            net3: <span>{year3?.researchAndDevelopmentCostsNet}</span>,
        },
        {
            key: '3',
            asset: <span>{t("Concessions and similar rights, patents, licenses, trademarks, processes, software, rights and similar assets")}</span>,
            gross1: <span>{year1?.concessionsAndSimilarRightsGross}</span>,
            gross2: <span>{year2?.concessionsAndSimilarRightsGross}</span>,
            gross3: <span>{year3?.concessionsAndSimilarRightsGross}</span>,
            depreciation1: <span>{year1?.concessionsAndSimilarRightsDepreciation}</span>,
            depreciation2: <span>{year2?.concessionsAndSimilarRightsDepreciation}</span>,
            depreciation3: <span>{year3?.concessionsAndSimilarRightsDepreciation}</span>,
            net1: <span>{year1?.concessionsAndSimilarRightsNet}</span>,
            net2: <span>{year2?.concessionsAndSimilarRightsNet}</span>,
            net3: <span>{year3?.concessionsAndSimilarRightsNet}</span>,

        },
        {
            key: '4',
            asset: <span>{t("Goodwill & Leashold Rights")}</span>,
            gross1: <span>{year1?.goodwillLeasholdRightsGross}</span>,
            gross2: <span>{year2?.goodwillLeasholdRightsGross}</span>,
            gross3: <span>{year3?.goodwillLeasholdRightsGross}</span>,
            depreciation1: <span>{year1?.goodwillLeasholdRightsGross}</span>,
            depreciation2: <span>{year2?.goodwillLeasholdRightsGross}</span>,
            depreciation3: <span>{year3?.goodwillLeasholdRightsGross}</span>,
            net1: <span>{year1?.goodwillLeasholdRightsNet}</span>,
            net2: <span>{year2?.goodwillLeasholdRightsNet}</span>,
            net3: <span>{year3?.goodwillLeasholdRightsNet}</span>,

        },
        {
            key: '5',
            asset: <span> {t("Other intangible fixed assets")}</span>,
            gross1: <span>{year1?.otherIntangibleFixedAssetsGross}</span>,
            gross2: <span>{year2?.otherIntangibleFixedAssetsGross}</span>,
            gross3: <span>{year3?.otherIntangibleFixedAssetsGross}</span>,
            depreciation1: <span>{year1?.otherIntangibleFixedAssetsDepreciation}</span>,
            depreciation2: <span>{year2?.otherIntangibleFixedAssetsDepreciation}</span>,
            depreciation3: <span>{year3?.otherIntangibleFixedAssetsDepreciation}</span>,
            net1: <span>{year1?.otherIntangibleFixedAssetsNet}</span>,
            net2: <span>{year2?.otherIntangibleFixedAssetsNet}</span>,
            net3: <span>{year3?.otherIntangibleFixedAssetsNet}</span>,

        },
        {
            key: '6',
            asset: <strong>{t("Tangible fixed assets")}</strong>,
            gross1: <strong>{year1?.tangiblefixedassetsGross1}</strong>,
            gross2: <strong>{year2?.tangiblefixedassetsGross2}</strong>,
            gross3: <strong>{year3?.tangiblefixedassetsGross3}</strong>,
            depreciation1: <strong>{year1?.tangiblefixedassetsDepreciation1}</strong>,
            depreciation2: <strong>{year2?.tangiblefixedassetsDepreciation2}</strong>,
            depreciation3: <strong>{year3?.tangiblefixedassetsDepreciation3}</strong>,
            net1: <strong>{year1?.tangiblefixedassetsNet1}</strong>,
            net2: <strong>{year2?.tangiblefixedassetsNet2}</strong>,
            net3: <strong>{year3?.tangiblefixedassetsNet3}</strong>,
        },
        {
            key: '7',
            asset: <span>{t("Land")}</span>,
            gross1: <span>{year1?.landGross}</span>,
            gross2: <span>{year2?.landGross}</span>,
            gross3: <span>{year3?.landGross}</span>,
            depreciation1: <span>{year1?.landDepreciation}</span>,
            depreciation2: <span>{year2?.landDepreciation}</span>,
            depreciation3: <span>{year3?.landDepreciation}</span>,
            net1: <span>{year1?.landNet}</span>,
            net2: <span>{year2?.landNet}</span>,
            net3: <span>{year3?.landNet}</span>,

        },
        {
            key: '8',
            asset: <span>{t("Constructions")}</span>,
            gross1: <span>{year1?.constructionsGross}</span>,
            gross2: <span>{year2?.constructionsGross}</span>,
            gross3: <span>{year3?.constructionsGross}</span>,
            depreciation1: <span>{year1?.constructionsDepreciation}</span>,
            depreciation2: <span>{year2?.constructionsDepreciation}</span>,
            depreciation3: <span>{year3?.constructionsDepreciation}</span>,
            net1: <span>{year1?.constructionsNet}</span>,
            net2: <span>{year2?.constructionsNet}</span>,
            net3: <span>{year3?.constructionsNet}</span>,

        },
        {
            key: '9',
            asset: <span>{t("Technical installations, industrial machinery, equipment and tools")}</span>,
            gross1: <span>{year1?.technicalInstallationsGross}</span>,
            gross2: <span>{year2?.technicalInstallationsGross}</span>,
            gross3: <span>{year3?.technicalInstallationsGross}</span>,
            depreciation1: <span>{year1?.technicalInstallationsDepreciation}</span>,
            depreciation2: <span>{year2?.technicalInstallationsDepreciation}</span>,
            depreciation3: <span>{year3?.technicalInstallationsDepreciation}</span>,
            net1: <span>{year1?.technicalInstallationsNet}</span>,
            net2: <span>{year2?.technicalInstallationsNet}</span>,
            net3: <span>{year3?.technicalInstallationsNet}</span>,

        },
        {
            key: '10',
            asset: <span>{t("Office equipment and computer hardware, Furniture, Livestock, Cheptel, Returnable packaging")}</span>,
            gross1: <span>{year1?.officeEquipmentGross}</span>,
            gross2: <span>{year2?.officeEquipmentGross}</span>,
            gross3: <span>{year3?.officeEquipmentGross}</span>,
            depreciation1: <span>{year1?.officeEquipmentDepreciation}</span>,
            depreciation2: <span>{year2?.officeEquipmentDepreciation}</span>,
            depreciation3: <span>{year3?.officeEquipmentDepreciation}</span>,
            net1: <span>{year1?.officeEquipmentNet}</span>,
            net2: <span>{year2?.officeEquipmentNet}</span>,
            net3: <span>{year3?.officeEquipmentNet}</span>,

        },
        {
            key: '11',
            asset: <span>{t("Transport equipment")}</span>,
            gross1: <span>{year1?.transportEquipmentGross}</span>,
            gross2: <span>{year2?.transportEquipmentGross}</span>,
            gross3: <span>{year3?.transportEquipmentGross}</span>,
            depreciation1: <span>{year1?.transportEquipmentDepreciation}</span>,
            depreciation2: <span>{year2?.transportEquipmentDepreciation}</span>,
            depreciation3: <span>{year3?.transportEquipmentDepreciation}</span>,
            net1: <span>{year1?.transportEquipmentNet}</span>,
            net2: <span>{year2?.transportEquipmentNet}</span>,
            net3: <span>{year3?.transportEquipmentNet}</span>,

        },
        {
            key: '12',
            asset: <span>{t("Advance payments on account on orders for intangible fixed assets")}</span>,
            gross1: <span>{year1?.advancePaymentsGross}</span>,
            gross2: <span>{year2?.advancePaymentsGross}</span>,
            gross3: <span>{year3?.advancePaymentsGross}</span>,
            depreciation1: <span>{year1?.advancePaymentsDepreciation}</span>,
            depreciation2: <span>{year2?.advancePaymentsDepreciation}</span>,
            depreciation3: <span>{year3?.advancePaymentsDepreciation}</span>,
            net1: <span>{year1?.advancePaymentsNet}</span>,
            net2: <span>{year2?.advancePaymentsNet}</span>,
            net3: <span>{year3?.advancePaymentsNet}</span>,

        },
        {
            key: '13',
            asset: <strong>{t("Financial fixed assets")}</strong>,
            gross1: <strong>{year1?.financialFixedAssetsGross}</strong>,
            gross2: <strong>{year2?.financialFixedAssetsGross}</strong>,
            gross3: <strong>{year3?.financialFixedAssetsGross}</strong>,
            depreciation1: <strong>{year1?.financialFixedAssetsDepreciation}</strong>,
            depreciation2: <strong>{year2?.financialFixedAssetsDepreciation}</strong>,
            depreciation3: <strong>{year3?.financialFixedAssetsDepreciation}</strong>,
            net1: <strong>{year1?.financialFixedAssetsNet}</strong>,
            net2: <strong>{year2?.financialFixedAssetsNet}</strong>,
            net3: <strong>{year3?.financialFixedAssetsNet3}</strong>,

        },
        {
            key: '14',
            asset: <span>{t("Equity investments")}</span>,
            gross1: <span>{year1?.equityInvestmentsGross}</span>,
            gross2: <span>{year2?.equityInvestmentsGross}</span>,
            gross3: <span>{year3?.equityInvestmentsGross}</span>,
            depreciation1: <span>{year1?.equityInvestmentsDepreciation}</span>,
            depreciation2: <span>{year2?.equityInvestmentsDepreciation}</span>,
            depreciation3: <span>{year3?.equityInvestmentsDepreciation}</span>,
            net1: <span>{year1?.equityInvestmentsNet}</span>,
            net2: <span>{year2?.equityInvestmentsNet}</span>,
            net3: <span>{year3?.equityInvestmentsNet}</span>,

        },
        {
            key: '15',
            asset: <span>{t("Other financial fixed assets (portfolio, long-terms securities)")}</span>,
            gross1: <span>{year1?.otherFinancialFixedAssetsGross}</span>,
            gross2: <span>{year2?.otherFinancialFixedAssetsGross}</span>,
            gross3: <span>{year3?.otherFinancialFixedAssetsGross}</span>,
            depreciation1: <span>{year1?.otherFinancialFixedAssetsDepreciation}</span>,
            depreciation2: <span>{year2?.otherFinancialFixedAssetsDepreciation}</span>,
            depreciation3: <span>{year3?.otherFinancialFixedAssetsDepreciation}</span>,
            net1: <span>{year1?.otherFinancialFixedAssetsNet}</span>,
            net2: <span>{year2?.otherFinancialFixedAssetsNet}</span>,
            net3: <span>{year3?.otherFinancialFixedAssetsNet}</span>,

        },
        {
            key: '16',
            asset: <strong>{t("Total fixed assets")}</strong>,
            gross1: <strong>{year1?.totalFixedAssetsGross}</strong>,
            gross2: <strong>{year2?.totalFixedAssetsGross}</strong>,
            gross3: <strong>{year3?.totalFixedAssetsGross}</strong>,
            depreciation1: <strong>{year1?.totalFixedAssetsDepreciation}</strong>,
            depreciation2: <strong>{year2?.totalFixedAssetsDepreciation}</strong>,
            depreciation3: <strong>{year3?.totalFixedAssetsDepreciation}</strong>,
            net1: <strong>{year1?.totalFixedAssetsNet}</strong>,
            net2: <strong>{year2?.totalFixedAssetsNet}</strong>,
            net3: <strong>{year3?.totalFixedAssetsNet}</strong>,

        },
        {
            key: '17',
            asset: <span>{t("Current assets")}</span>,
            gross1: <span>{year1?.currentAssetsGross}</span>,
            gross2: <span>{year2?.currentAssetsGross}</span>,
            gross3: <span>{year3?.currentAssetsGross}</span>,
            depreciation1: <span>{year1?.currentAssetsDepreciation}</span>,
            depreciation2: <span>{year2?.currentAssetsDepreciation}</span>,
            depreciation3: <span>{year3?.currentAssetsDepreciation}</span>,
            net1: <span>{year1?.currentAssetsNet}</span>,
            net2: <span>{year2?.currentAssetsNet}</span>,
            net3: <span>{year3?.currentAssetsNet}</span>,

        },
        {
            key: '18',
            asset: <span>{t("Inventory and work in progress")}</span>,
            gross1: <span>{year1?.inventoryAndWorkInProgressGross}</span>,
            gross2: <span>{year2?.inventoryAndWorkInProgressGross}</span>,
            gross3: <span>{year3?.inventoryAndWorkInProgressGross}</span>,
            depreciation1: <span>{year1?.inventoryAndWorkInProgressDepreciation}</span>,
            depreciation2: <span>{year2?.inventoryAndWorkInProgressDepreciation}</span>,
            depreciation3: <span>{year3?.inventoryAndWorkInProgressDepreciation}</span>,
            net1: <span>{year1?.inventoryAndWorkInProgressNet}</span>,
            net2: <span>{year2?.inventoryAndWorkInProgressNet}</span>,
            net3: <span>{year3?.inventoryAndWorkInProgressNet}</span>,

        },
        {
            key: '19',
            asset: <strong>{t("Suppliers accounts payable and related accounts")}</strong>,
            gross1: <span>{year1?.suppliersAccountsGross}</span>,
            gross2: <span>{year2?.suppliersAccountsGross}</span>,
            gross3: <span>{year3?.suppliersAccountsGross}</span>,
            depreciation1: <span>{year1?.suppliersAccountsDepreciation}</span>,
            depreciation2: <span>{year2?.suppliersAccountsDepreciation}</span>,
            depreciation3: <span>{year3?.suppliersAccountsDepreciation}</span>,
            net1: <span>{year1?.suppliersAccountsNet}</span>,
            net2: <span>{year2?.suppliersAccountsNet}</span>,
            net3: <span>{year3?.suppliersAccountsNet}</span>,

        },
        {
            key: '20',
            asset: <span>{t("Suppliers Advance payments on account on orders")}</span>,
            gross1: <span>{year1?.suppliersAdvancePaymentsGross}</span>,
            gross2: <span>{year2?.suppliersAdvancePaymentsGross}</span>,
            gross3: <span>{year3?.suppliersAdvancePaymentsGross}</span>,
            depreciation1: <span>{year1?.suppliersAdvancePaymentsDepreciation}</span>,
            depreciation2: <span>{year2?.suppliersAdvancePaymentsDepreciation}</span>,
            depreciation3: <span>{year3?.suppliersAdvancePaymentsDepreciation}</span>,
            net1: <span>{year1?.suppliersAdvancePaymentsNet}</span>,
            net2: <span>{year2?.suppliersAdvancePaymentsNet}</span>,
            net3: <span>{year3?.suppliersAdvancePaymentsNet}</span>,

        },
        {
            key: '21',
            asset: <span>{t("Trade Receivable")}</span>,
            gross1: <span>{year1?.tradeReceivableGross}</span>,
            gross2: <span>{year2?.tradeReceivableGross}</span>,
            gross3: <span>{year3?.tradeReceivableGross}</span>,
            depreciation1: <span>{year1?.tradeReceivableDepreciation}</span>,
            depreciation2: <span>{year2?.tradeReceivableDepreciation}</span>,
            depreciation3: <span>{year3?.tradeReceivableDepreciation}</span>,
            net1: <span>{year1?.tradeReceivableNet}</span>,
            net2: <span>{year2?.tradeReceivableNet}</span>,
            net3: <span>{year3?.tradeReceivableNet}</span>,
        },
        {
            key: '22',
            asset: <span>{t("Other Receivables")}</span>,
            gross1: <span>{year1?.otherReceivablesGross}</span>,
            gross2: <span>{year2?.otherReceivablesGross}</span>,
            gross3: <span>{year3?.otherReceivablesGross}</span>,
            depreciation1: <span>{year1?.otherReceivablesDepreciatio}</span>,
            depreciation2: <span>{year2?.otherReceivablesDepreciatio}</span>,
            depreciation3: <span>{year3?.otherReceivablesDepreciatio}</span>,
            net1: <span>{year1?.otherReceivablesNet}</span>,
            net2: <span>{year2?.otherReceivablesNet}</span>,
            net3: <span>{year3?.otherReceivablesNet}</span>,

        },
        {
            key: '23',
            asset: <strong>{t("Total current assets")}</strong>,
            gross1: <strong>{year1?.totalCurrentAssetsGross}</strong>,
            gross2: <strong>{year2?.totalCurrentAssetsGross}</strong>,
            gross3: <strong>{year3?.totalCurrentAssetsGross}</strong>,
            depreciation1: <strong>{year1?.totalCurrentAssetsDepreciation}</strong>,
            depreciation2: <strong>{year2?.totalCurrentAssetsDepreciation}</strong>,
            depreciation3: <strong>{year3?.totalCurrentAssetsDepreciation}</strong>,
            net1: <strong>{year1?.totalCurrentAssetsNet}</strong>,
            net2: <strong>{year2?.totalCurrentAssetsNet}</strong>,
            net3: <strong>{year3?.totalCurrentAssetsNet}</strong>,

        },
        {
            key: '24',
            asset: <span>{t("Shares and Marketable Securities")}</span>,
            gross1: <span>{year1?.sharesAndMarketableSecuritiesGross}</span>,
            gross2: <span>{year2?.sharesAndMarketableSecuritiesGross}</span>,
            gross3: <span>{year3?.sharesAndMarketableSecuritiesGross}</span>,
            depreciation1: <span>{year1?.sharesAndMarketableSecuritiesDepreciation}</span>,
            depreciation2: <span>{year2?.sharesAndMarketableSecuritiesDepreciation}</span>,
            depreciation3: <span>{year3?.sharesAndMarketableSecuritiesDepreciation}</span>,
            net1: <span>{year1?.sharesAndMarketableSecuritiesNet}</span>,
            net2: <span>{year2?.sharesAndMarketableSecuritiesNet}</span>,
            net3: <span>{year3?.sharesAndMarketableSecuritiesNet}</span>,

        },
        {
            key: '25',
            asset: <span>{t("Cash and cash equivalents")}</span>,
            gross1: <span>{year1?.cashAndCashEquivalentsGross}</span>,
            gross2: <span>{year2?.cashAndCashEquivalentsGross}</span>,
            gross3: <span>{year3?.cashAndCashEquivalentsGross}</span>,
            depreciation1: <span>{year1?.cashAndCashEquivalentsDepreciation}</span>,
            depreciation2: <span>{year2?.cashAndCashEquivalentsDepreciation}</span>,
            depreciation3: <span>{year3?.cashAndCashEquivalentsDepreciation}</span>,
            net1: <span>{year1?.cashAndCashEquivalentsNet}</span>,
            net2: <span>{year2?.cashAndCashEquivalentsNet}</span>,
            net3: <span>{year3?.cashAndCashEquivalentsNet}</span>,

        },
        {
            key: '26',
            asset: <span>{year1?.banksFinancialGross}</span>,
            gross2: <span>{year2?.banksFinancialGross}</span>,
            gross3: <span>{year3?.banksFinancialGross}</span>,
            depreciation1: <span>{year1?.banksFinancialDepreciation}</span>,
            depreciation2: <span>{year2?.banksFinancialDepreciation}</span>,
            depreciation3: <span>{year3?.banksFinancialDepreciation}</span>,
            net1: <span>{year1?.banksFinancialNet}</span>,
            net2: <span>{year2?.banksFinancialNet}</span>,
            net3: <span>{year3?.banksFinancialNet}</span>,

        },
        {
            key: '27',
            asset: <strong>{t("Cash and financial instruments")}</strong>,
            gross1: <strong>{year1?.cashandFinancialIInstrumentsGross}</strong>,
            gross2: <strong>{year2?.cashandFinancialIInstrumentsGross}</strong>,
            gross3: <strong>{year3?.cashandFinancialIInstrumentsGross}</strong>,
            depreciation1: <strong>{year1?.cashandFinancialIInstrumentsDepreciation}</strong>,
            depreciation2: <strong>{year2?.cashandFinancialIInstrumentsDepreciation}</strong>,
            depreciation3: <strong>{year3?.cashandFinancialIInstrumentsDepreciation}</strong>,
            net1: <strong>{year1?.cashandFinancialIInstrumentsNet}</strong>,
            net2: <strong>{year2?.cashandFinancialIInstrumentsNet}</strong>,
            net3: <strong>{year3?.cashandFinancialIInstrumentsNet}</strong>,
        },
        {
            key: '28',
            asset: <span>{t("Valuation difference Assets")}</span>,
            gross1: <span>{year1?.valuationDifferenceAssetsGross}</span>,
            gross2: <span>{year2?.valuationDifferenceAssetsGross}</span>,
            gross3: <span>{year3?.valuationDifferenceAssetsGross}</span>,
            depreciation1: <span>{year1?.valuationDifferenceAssetsDepreciation}</span>,
            depreciation2: <span>{year2?.valuationDifferenceAssetsDepreciation}</span>,
            depreciation3: <span>{year3?.valuationDifferenceAssetsDepreciation}</span>,
            net1: <span>{year1?.valuationDifferenceAssetsNet}</span>,
            net2: <span>{year2?.valuationDifferenceAssetsNet}</span>,
            net3: <span>{year3?.valuationDifferenceAssetsNet}</span>,

        },
        {
            key: '29',
            asset: <strong>{t("Total assets")}</strong>,
            gross1: <strong>{year1?.totalAssetsGross}</strong>,
            gross2: <strong>{year2?.totalAssetsGross}</strong>,
            gross3: <strong>{year3?.totalAssetsGross}</strong>,
            depreciation1: <strong>{year1?.totalAssetsDepreciation}</strong>,
            depreciation2: <strong>{year2?.totalAssetsDepreciation}</strong>,
            depreciation3: <strong>{year3?.totalAssetsDepreciation}</strong>,
            net1: <strong>{year1?.totalAssetsNet}</strong>,
            net2: <strong>{year2?.totalAssetsNet}</strong>,
            net3: <strong>{year3?.totalAssetsNet}</strong>,
        },

    ];

    useEffect(() => {
        if (props?.user?.token)
          getUserDataF();
      }, [props?.user?.token])
    
    const getUserDataF = () => {
        if(!loading){
            setLoading(true)
        let body = {
          userId: props?.userId,
          token: props?.user?.token,
    
        }
        post('/user', body)
          .then((res => {
            setLoading(false);
            if (res?.statusCode == 200) {
            //   console.log('request on login', res);
    
              setData1(res?.data)
              setValue(res?.data?.balanceRating?.rating)
            } else {
              console.log(res?.error);
            }
          }))
          .catch(error => {
            setLoading(false);
            console.log('error while login', error);
            console.log('Something went wrong');
          })
        }
      }
    

    function handleValidSubmit(e, v) {
        if (!loading) {
            setLoading(true)
          let body = {
            userId: props?.userId,
            token: props?.user?.token,
            balanceRating: {
              comment: v?.balanceComment,
              rating: value
            },
    
          }
          put('/user', body)
            .then((res => {
              setLoading(false);
              if (res?.statusCode == 200) {
                // console.log('request on login', res);
                getUserDataF();
                toast.success(res?.message)
                // setValue(0)
              } else {
                console.log(res?.error);
                toast.error(res?.error)
              }
            }))
            .catch(error => {
              setLoading(false);
              console.log('error while login', error);
              console.log('Something went wrong');
              toast.error('Something went wrong')
            })
        }
      }
    

    return (
        <React.Fragment>
           
        <Loader loading={loading} />
            <AvForm
                id="myForm"
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
                ref={formRef}
            >
                {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
                <Card>
                    <CardBody>

                        <Row>
                            <Col lg={12}>
                                <Table dataSource={data}
                                    scroll={{
                                        x: 1800,
                                        y: 450
                                    }}
                                    pagination={false}
                                    className='view_table'
                                >
                                    <Column fixed={'left'} style={{ width: '30%' }} title={t("Asset")} dataIndex="asset" key="asset" />
                                    <ColumnGroup title={<> <AvField type="select" name="year_one" onChange={(e) => getUserData(e.target.value, setYear1)}>
                                        <option value="">{t("Select Year")}</option>
                                        <option>2017</option>
                                        <option>2018</option>
                                        <option>2019</option>
                                        <option>2020</option>
                                        <option>2021</option>
                                        <option>2022</option>
                                    </AvField></>}>
                                        <Column title={t("Gross")} dataIndex="gross1" key="gross1" />
                                        <Column title={t("DepreciationAmortizationImpairment")} dataIndex="depreciation1" key="depreciation1" />
                                        <Column title="Net" dataIndex="net1" key="net1" />
                                    </ColumnGroup>
                                    <ColumnGroup title={<> <AvField type="select" name="year_two" onChange={(e) => getUserData(e.target.value, setYear2)}>
                                        <option value="">{t("Select Year")}</option>
                                        <option>2017</option>
                                        <option>2018</option>
                                        <option>2019</option>
                                        <option>2020</option>
                                        <option>2021</option>
                                        <option>2022</option>
                                    </AvField></>}>
                                        <Column title={t("Gross")} dataIndex="gross2" key="gross2" />
                                        <Column title={t("DepreciationAmortizationImpairment")} dataIndex="depreciation2" key="depreciation2" />
                                        <Column title="Net" dataIndex="net2" key="net2" />
                                    </ColumnGroup>
                                    <ColumnGroup title={<> <AvField type="select" name="year_three" onChange={(e) => getUserData(e.target.value, setYear3)}>
                                        <option value="">{t("Select Year")}</option>
                                        <option>2017</option>
                                        <option>2018</option>
                                        <option>2019</option>
                                        <option>2020</option>
                                        <option>2021</option>
                                        <option>2022</option>
                                    </AvField></>}>
                                        <Column title={t("Gross")} dataIndex="gross3" key="gross3" />
                                        <Column title={t("DepreciationAmortizationImpairment")} dataIndex="depreciation3" key="depreciation3" />
                                        <Column title="Net" dataIndex="net3" key="net3" />
                                    </ColumnGroup>

                                </Table>
                            </Col>

                        </Row>

                    </CardBody>
                </Card>
                <h4 className="card-title mb-4">{t("Liabilities")}</h4>
                <Card>
                    <CardBody>

                        <Row>
                            <Col lg={12}>

                                <TableData
                                    className='common_table'
                                    bordered
                                    style={{ marginBottom: 0 }}
                                >
                                    <thead >

                                        <tr className='right_head'>

                                            <th style={{ width: '50%', textAlign: 'left' }}>
                                                {t("Liabilities")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>
                                                <AvForm>
                                                    <AvField type="select" name="year_one" onChange={(e) => getUserData1(e.target.value, setYear_1)} >
                                                        <option value="">{t("Select Year")}</option>
                                                        <option>2017</option>
                                                        <option>2018</option>
                                                        <option>2019</option>
                                                        <option>2020</option>
                                                        <option>2021</option>
                                                        <option>2022</option>
                                                    </AvField>
                                                </AvForm>
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>
                                                <AvForm>
                                                    <AvField type="select" name="year_two" onChange={(e) => getUserData1(e.target.value, setYear_2)} >
                                                        <option value="">{t("Select Year")}</option>
                                                        <option>2017</option>
                                                        <option>2018</option>
                                                        <option>2019</option>
                                                        <option>2020</option>
                                                        <option>2021</option>
                                                        <option>2022</option>
                                                    </AvField>
                                                </AvForm>
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>
                                                <AvForm>
                                                    <AvField type="select" name="year_three" onChange={(e) => getUserData1(e.target.value, setYear_3)} >
                                                        <option value="">{t("Select Year")}</option>
                                                        <option>2017</option>
                                                        <option>2018</option>
                                                        <option>2019</option>
                                                        <option>2020</option>
                                                        <option>2021</option>
                                                        <option>2022</option>
                                                    </AvField>
                                                </AvForm>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {t("Capital")}
                                            </td>
                                            <td>
                                                {year_1?.capital}
                                            </td>
                                            <td>
                                                {year_2?.capital}
                                            </td>
                                            <td>
                                                {year_3?.capital}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Shareholders subscribed uncalled capital")}
                                            </td>
                                            <td>
                                                {year_1?.shareholdersSubscribedUncalledCapital}
                                            </td>
                                            <td>
                                                {year_2?.shareholdersSubscribedUncalledCapital}
                                            </td>
                                            <td>
                                                {year_3?.shareholdersSubscribedUncalledCapital}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Premiums related to share capital")}
                                            </td>
                                            <td>
                                                {year_1?.premiumsRelatedtoShareCapital}
                                            </td>
                                            <td>
                                                {year_2?.premiumsRelatedtoShareCapital}
                                            </td>
                                            <td>
                                                {year_3?.premiumsRelatedtoShareCapital}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Revaluation difference")}
                                            </td>
                                            <td>
                                                {year_1?.revaluationDifference}
                                            </td>
                                            <td>
                                                {year_2?.revaluationDifference}
                                            </td>
                                            <td>
                                                {year_3?.revaluationDifference}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Reserves not available for distribution")}
                                            </td>
                                            <td>
                                                {year_1?.reservesNotAvailableForDistribution}
                                            </td>
                                            <td>
                                                {year_2?.reservesNotAvailableForDistribution}
                                            </td>
                                            <td>
                                                {year_3?.reservesNotAvailableForDistribution}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Legal Reserve")}
                                            </td>
                                            <td>
                                                {year_1?.legalReserve}
                                            </td>
                                            <td>
                                                {year_2?.legalReserve}
                                            </td>
                                            <td>
                                                {year_3?.legalReserve}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Retained Earnings")}
                                            </td>
                                            <td>
                                                {year_1?.retainedEarnings}
                                            </td>
                                            <td>
                                                {year_2?.retainedEarnings}
                                            </td>
                                            <td>
                                                {year_3?.retainedEarnings}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Profit or loss for the financial year")}
                                            </td>
                                            <td>
                                                {year_1?.profitOrLossForTheFinancialYear}
                                            </td>
                                            <td>
                                                {year_2?.profitOrLossForTheFinancialYear}
                                            </td>
                                            <td>
                                                {year_3?.profitOrLossForTheFinancialYear}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Investment grants")}
                                            </td>
                                            <td>
                                                {year_1?.investmentGrants}
                                            </td>
                                            <td>
                                                {year_2?.investmentGrants}
                                            </td>
                                            <td>
                                                {year_3?.investmentGrants}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Regulated provisions")}
                                            </td>
                                            <td>
                                                {year_1?.regulatedProvisions}
                                            </td>
                                            <td>
                                                {year_2?.regulatedProvisions}
                                            </td>
                                            <td>
                                                {year_3?.regulatedProvisions}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                            <th style={{ width: '50%' }}>
                                                {t("Total Owner's Equity")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_1?.totalOwnersEquity?.toLocaleString()}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_2?.totalOwnersEquity?.toLocaleString()}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_3?.totalOwnersEquity?.toLocaleString()}</th>

                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Loans and debt from financial institutions")}
                                            </td>
                                            <td>
                                                {year_1?.loansandDebtFromFinancialInstitutions}
                                            </td>
                                            <td>
                                                {year_2?.loansandDebtFromFinancialInstitutions}
                                            </td>
                                            <td>
                                                {year_3?.loansandDebtFromFinancialInstitutions}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Leasing debt")}
                                            </td>
                                            <td>
                                                {year_1?.leasingDebt}
                                            </td>
                                            <td>
                                                {year_2?.leasingDebt}
                                            </td>
                                            <td>
                                                {year_3?.leasingDebt}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Provisions")}
                                            </td>
                                            <td>
                                                {year_1?.provisions}
                                            </td>
                                            <td>
                                                {year_2?.provisions}
                                            </td>
                                            <td>
                                                {year_3?.provisions}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                {t("Total Dettes Financieres Et Ressources Assimilees")}
                                            </td>
                                            <td>
                                                {year_1?.totalDettesFinancieresEtRessourcesAssimilees}
                                            </td>
                                            <td>
                                                {year_2?.totalDettesFinancieresEtRessourcesAssimilees}
                                            </td>
                                            <td>
                                                {year_3?.totalDettesFinancieresEtRessourcesAssimilees}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                            <th style={{ width: '50%' }}>
                                                {t("Fixed Liabilities")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_1?.fixedLiabilities?.toLocaleString()}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_2?.fixedLiabilities?.toLocaleString()}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_3?.fixedLiabilities?.toLocaleString()}</th>

                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Current debt")}
                                            </td>
                                            <td>
                                                {year_1?.currentDebt}
                                            </td>
                                            <td>
                                                {year_2?.currentDebt}
                                            </td>
                                            <td>
                                                {year_3?.currentDebt}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Advance payments on account received on orders in progress")}
                                            </td>
                                            <td>
                                                {year_1?.advancePaymentsonAccountReceivedonOrdersInProgress}
                                            </td>
                                            <td>
                                                {year_2?.advancePaymentsonAccountReceivedonOrdersInProgress}
                                            </td>
                                            <td>
                                                {year_3?.advancePaymentsonAccountReceivedonOrdersInProgress}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Accounts payable and related accounts")}
                                            </td>
                                            <td>
                                                {year_1?.accountspayableAndRelatedAccounts}
                                            </td>
                                            <td>
                                                {year_2?.accountspayableAndRelatedAccounts}
                                            </td>
                                            <td>
                                                {year_3?.accountspayableAndRelatedAccounts}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Tax and social security debts")}
                                            </td>
                                            <td>
                                                {year_1?.taxAndSocialSecurityDebts}
                                            </td>
                                            <td>
                                                {year_2?.taxAndSocialSecurityDebts}
                                            </td>
                                            <td>
                                                {year_3?.taxAndSocialSecurityDebts}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Other loans and similar debts")}
                                            </td>
                                            <td>
                                                {year_1?.otherLoansAndSimilarDebts}
                                            </td>
                                            <td>
                                                {year_2?.otherLoansAndSimilarDebts}
                                            </td>
                                            <td>
                                                {year_3?.otherLoansAndSimilarDebts}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Unrealized foreign exchange gains and valuation differences")}
                                            </td>
                                            <td>
                                                {year_1?.unrealizedForeignExchangeGainsAndValuationDifferences}
                                            </td>
                                            <td>
                                                {year_2?.unrealizedForeignExchangeGainsAndValuationDifferences}
                                            </td>
                                            <td>
                                                {year_3?.unrealizedForeignExchangeGainsAndValuationDifferences}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                            <th style={{ width: '50%' }}>
                                                {t("Current liabilities")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_1?.currentLiabilities?.toLocaleString()}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_2?.currentLiabilities?.toLocaleString()}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_3?.currentLiabilities?.toLocaleString()}</th>

                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Other payables")}
                                            </td>
                                            <td>
                                                {year_1?.otherPayables}
                                            </td>
                                            <td>
                                                {year_2?.otherPayables}
                                            </td>
                                            <td>
                                                {year_3?.otherPayables}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Short-term bank advances")}
                                            </td>
                                            <td>
                                                {year_1?.shortTermBankAdvances}
                                            </td>
                                            <td>
                                                {year_2?.shortTermBankAdvances}
                                            </td>
                                            <td>
                                                {year_3?.shortTermBankAdvances}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                            <th style={{ width: '50%' }}>
                                                {t("Total tresorerie-passif")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_1?.totalCashLiabilities?.toLocaleString()}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_2?.totalCashLiabilities?.toLocaleString()}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_3?.totalCashLiabilities?.toLocaleString()}</th>

                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Valuation difference - Liabilities")}
                                            </td>
                                            <td>
                                                {year_1?.valuationDifferenceLiabilities}
                                            </td>
                                            <td>
                                                {year_2?.valuationDifferenceLiabilities}
                                            </td>
                                            <td>
                                                {year_3?.valuationDifferenceLiabilities}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                            <th style={{ width: '50%' }}>
                                                {t("Total liabilities")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_1?.totalLiabilities?.toLocaleString()}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_2?.totalLiabilities?.toLocaleString()}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_3?.totalLiabilities?.toLocaleString()}</th>
                                        </tr>
                                    </tbody>
                                </TableData>
                            </Col>

                        </Row>

                    </CardBody>
                </Card>
               
            </AvForm>


        </React.Fragment>
    )
}



export default  BalanceSheet;




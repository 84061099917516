import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { profileSuccess, apiError } from "../../store/actions"
// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { useTranslation } from "react-i18next"

const SidebarContent = props => {
  const { t } = useTranslation();
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, props?.user?.role, props?.user?.step])

  useEffect(() => {
    ref.current.recalculate()
  });

  useEffect(() => {
    getUser();
  }, [])

  const getUser = async () => {
    let user = JSON.parse(await localStorage.getItem('user'));
    // console.log('side bar', user)
    props.profileSuccess(user)
  }

  console.log('props user', props.user)

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  const isActiveLink = (pathname) => {

    // const tempId = item?._id.slice(item?._id.length - 4, item?._id.length);
    // let pathname = `/${type}/` + tempId + "/" + (item?.name).split(" ").join("-").toLowerCase();
    // console.log('props?.location?.pathname',props?.location?.pathname)
    return props?.location?.pathname === pathname;
  };

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{t("Main")} </li>

            <li>
              <Link to="/dashboard" className={isActiveLink('/dashboard') ? 'waves-effect activeLink_ active' : ''}>
                <i className="mdi mdi-view-dashboard"></i>
                {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                <span>{t("Dashboard")}</span>
              </Link>
            </li>


            {props?.user?.role == 'SME' &&
              <>
                {props?.user?.step > 0 ?
                  <li>
                    <Link to="/profile" className={isActiveLink('/profile') ? 'waves-effect activeLink_ active' : ''}>
                      <i className="mdi mdi-account"></i>
                      <span>{t("Profile")}</span>
                    </Link>
                  </li>
                  :
                  <li className="disable_link">
                    <Link to="/profile" className={isActiveLink('/profile') ? 'waves-effect activeLink_ active' : ''}>
                      <i className="mdi mdi-account"></i>
                      <span>{t("Profile")}</span>
                    </Link>
                  </li>
                }
                {props?.user?.step > 1 ?
                  <li>
                    <Link to="/neuro-profiler" className={isActiveLink('/neuro-profiler') ? 'waves-effect activeLink_ active' : ''}>
                      <i className="mdi mdi-account"></i>
                      <span>{t("Neuroprofiler")}</span>
                    </Link>
                  </li>
                  : null
                  // <li className="disable_link">
                  //   <Link to="#" onClick={(e) => e.preventDefault()}>
                  //     <i className="mdi mdi-account"></i>
                  //     <span>{t("Neuroprofiler")}</span>
                  //   </Link>
                  // </li>
                }
                {/* <li>
                  <Link className=" waves-effect">
                    <i className="mdi mdi-account"></i>
                    <span>{t("Financing Request")}</span>
                    <div className="menu_button_box">
                      <Link to="/financing" data-bs-toggle="tooltip" data-bs-placement="top" title={t("Add")}>
                        <i className="mdi mdi-plus"></i>
                      </Link>
                      <Link to="/financing-details" data-bs-toggle="tooltip" data-bs-placement="top" title={t("View")}>
                        <i className="mdi mdi-eye"></i>
                      </Link>
                    </div>
                  </Link>
                </li> */}
                {props?.user?.step > 1 ?
                  <li>
                    <Link to="/#" className={isActiveLink('/financing') || isActiveLink('/financing-details') ? 'has-arrow waves-effect activeLink active' : 'has-arrow'} >
                      <i className="mdi mdi-account"></i>
                      <span>{t("Financing Request")}</span>
                    </Link>
                    <ul className={isActiveLink('/financing') || isActiveLink('/financing-details') ? "sub-menu mm_show" : "sub-menu"} aria-expanded="false">
                      <li>
                        <Link to="/financing" className={isActiveLink('/financing') ? 'white-color' : null}>{t("Add")}</Link>
                      </li>
                      <li>
                        <Link to="/financing-details" className={isActiveLink('/financing-details') ? 'white-color' : null}>{t("View")} </Link>
                      </li>

                    </ul>
                  </li>
                  : null
                  // <li className="disable_link">
                  //   <Link to="#" onClick={(e) => e.preventDefault()}>
                  //     <i className="mdi mdi-account"></i>
                  //     <span>{t("Financing Request")}</span>
                  //   </Link>
                  // </li>
                }
                {props?.user?.step > 3 ?
                  <li>
                    <Link to="/#" className={isActiveLink('/esg') || isActiveLink('/esg-details') ? 'has-arrow waves-effect activeLink active' : 'has-arrow'} >
                      <i className="mdi mdi-account-settings"></i>
                      <span>{t("ESG Form")}</span>
                    </Link>
                    <ul className={isActiveLink('/esg') || isActiveLink('/esg-details') ? "sub-menu mm_show" : "sub-menu"} aria-expanded="false">
                      <li>
                        <Link to="/esg" className={isActiveLink('/esg') ? "white-color" : null}>{t("Add")}</Link>
                      </li>
                      <li>
                        <Link to="/esg-details" className={isActiveLink('/esg-details') ? "white-color" : null}>{t("View")} </Link>
                      </li>
                    </ul>
                  </li>
                  : null
                  // <li className="disable_link">
                  //   <Link to="#" onClick={(e) => e.preventDefault()}>
                  //     <i className="mdi mdi-account-settings"></i>
                  //     <span>{t("Financing Documents")}</span>
                  //   </Link>
                  // </li>
                }
                {props?.user?.step > 4 ?
                  <li>
                    <Link to="/#" className={isActiveLink('/profit-and-loss-account')
                      || isActiveLink('/profit-and-loss-account-detail')
                      || isActiveLink('/cash-flow')
                      || isActiveLink('/cash-flow-detail')
                      || isActiveLink('/balance-sheet')
                      || isActiveLink('/liabilities')
                      || isActiveLink('/balance-sheet-detail')
                      ? 'has-arrow waves-effect activeLink' : 'has-arrow'}>
                      <i className="mdi mdi-account-settings"></i>
                      <span>{t("Financial Documents")}</span>
                    </Link>
                    <ul className={
                      isActiveLink('/profit-and-loss-account')
                        || isActiveLink('/profit-and-loss-account-detail')
                        || isActiveLink('/cash-flow')
                        || isActiveLink('/cash-flow-detail')
                        || isActiveLink('/balance-sheet')
                        || isActiveLink('/liabilities')
                        || isActiveLink('/balance-sheet-detail') ?
                        'sub-menu mm_show' : 'sub-menu'} aria-expanded="true">

                      <li>
                        <Link to="/#" className={isActiveLink('/profit-and-loss-account') || isActiveLink('/profit-and-loss-account-detail') ? 'has-arrow sub_link_color' : 'has-arrow'}>
                          <i className="mdi mdi-account-settings"></i>
                          <span>{t("Profit & Loss")}</span>
                          {/* <div className="menu_button_box">
                          <Link to="/profit-and-loss-account" data-bs-toggle="tooltip" data-bs-placement="top" title={t("Add")}>
                            <i className="mdi mdi-plus"></i>
                          </Link>
                          <Link to="/profit-and-loss-account-detail" data-bs-toggle="tooltip" data-bs-placement="top" title={t("View")}>
                            <i className="mdi mdi-eye"></i>
                          </Link>
                        </div> */}
                        </Link>
                        <ul className={"sub-menu"} aria-expanded="true">
                          <li>
                            <Link to="/profit-and-loss-account" className={isActiveLink('/profit-and-loss-account') ? 'white-color' : null}>{t("Add")}</Link>
                          </li>
                          <li>
                            <Link to="/profit-and-loss-account-detail" className={isActiveLink('/profit-and-loss-account-detail') ? 'white-color' : null}>{t("View")} </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/#" className={isActiveLink('/cash-flow') || isActiveLink('/cash-flow-detail') ? 'has-arrow sub_link_color' : 'has-arrow'}>
                          <i className="mdi mdi-cash"></i>
                          <span>{t("Cash Flow")}</span>
                          {/* <div className="menu_button_box">
                          <Link to="/cash-flow" data-bs-toggle="tooltip" data-bs-placement="top" title={t("Add")}>
                            <i className="mdi mdi-plus"></i>
                          </Link>
                          <Link to="/cash-flow-detail" data-bs-toggle="tooltip" data-bs-placement="top" title={t("View")}>
                            <i className="mdi mdi-eye"></i>
                          </Link>
                        </div> */}
                        </Link>
                        <ul className="sub-menu" aria-expanded="true">
                          <li>
                            <Link to="/cash-flow" className={isActiveLink('/cash-flow') ? 'white-color' : null}>{t("Add")}</Link>
                          </li>
                          <li>
                            <Link to="/cash-flow-detail" className={isActiveLink('/cash-flow-detail') ? 'white-color' : null}>{t("View")} </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/#" className={isActiveLink('/balance-sheet') || isActiveLink('/liabilities') || isActiveLink('/balance-sheet-detail') ? 'has-arrow sub_link_color' : 'has-arrow'}>
                          <i className="mdi mdi-cash"></i>
                          <span>{t("Balance Sheet")}</span>

                        </Link>
                        <ul className="sub-menu" aria-expanded="true">
                          <li style={{ position: 'relative' }}>
                            <Link to="/balance-sheet" className={isActiveLink('/balance-sheet') ? 'white-color' : null}>{t("AssetOne")}</Link>

                          </li>
                          <li style={{ position: 'relative' }}>
                            <Link to="/liabilities" className={isActiveLink('/liabilities') ? 'white-color' : null}>{t("LiabilitiesOne")} </Link>
                          </li>
                          <li>
                            <Link to="/balance-sheet-detail" className={isActiveLink('/balance-sheet-detail') ? 'white-color' : null}>{t("View")} </Link>
                          </li>
                        </ul>
                      </li>

                    </ul>
                  </li>
                  : null
                  // <li className="disable_link">
                  //   <Link to="#" onClick={(e) => e.preventDefault()}>
                  //     <i className="mdi mdi-account-settings"></i>
                  //     <span>{t("Financing Documents")}</span>
                  //   </Link>
                  // </li>
                }
                {props?.user?.step > 5 ?
                  <li>
                    <Link to="/#" className={isActiveLink('/income-statement-analysis') || isActiveLink('/balance-sheet-analysis') || isActiveLink('/leverage-analysis') ? 'has-arrow waves-effect activeLink active' : 'has-arrow'}>
                      <i className="mdi mdi-chart-bar"></i>
                      <span>{t("Ratio Analysis")}</span>
                    </Link>
                    <ul className={isActiveLink('/income-statement-analysis') || isActiveLink('/balance-sheet-analysis') || isActiveLink('/leverage-analysis') ? "sub-menu mm_show" : "sub-menu"} aria-expanded="false">
                      <li>
                        <Link to="/income-statement-analysis" className={isActiveLink('/income-statement-analysis') ? 'white-color' : null}>{t("Income Statement Analysis")}</Link>
                      </li>
                      <li>
                        <Link to="/balance-sheet-analysis" className={isActiveLink('/balance-sheet-analysis') ? 'white-color' : null}>{t("Balance Sheet Analysis")} </Link>
                      </li>
                      <li>
                        <Link to="/leverage-analysis" className={isActiveLink('/leverage-analysis') ? 'white-color' : null}>{t("Leverage Analysis")} </Link>
                      </li>
                    </ul>
                  </li>
                  : null}
              </>
            }
            {/* <li className="menu-title">{t("Investor Module")} </li> */}
            {
              props?.user?.role == 'Investor' &&
              <>
                <li>
                  <Link to="/profile" className={isActiveLink('/profile') ? 'waves-effect activeLink_ active' : ''}>
                    <i className="mdi mdi-account"></i>
                    <span>{t("Profile")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/investor" className={isActiveLink('/investor') ? 'waves-effect activeLink_ active' : ''}>
                    <i className="mdi mdi-view-dashboard"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                    <span>{t("Search Companies")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/selected-companie" className={isActiveLink('/selected-companie') ? 'waves-effect activeLink_ active' : ''}>
                    <i className="mdi mdi-view-dashboard"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                    <span>{t("My Selected Companies")}</span>
                  </Link>
                </li>
              </>
            }
            {
              props?.user?.role == 'Admin' &&
              <>
                {/* <li className="menu-title">{t("Admine Module")} </li> */}
                <li>
                  <Link to="/#" className={isActiveLink('/segment') || isActiveLink('/question') ? 'has-arrow waves-effect activeLink active' : 'has-arrow'}>
                    <i className="mdi mdi-account"></i>
                    <span>{t("Questions ESG")}</span>
                  </Link>
                  <ul className={isActiveLink('/segment') || isActiveLink('/question') ? 'sub-menu mm_show' : 'sub-menu'} aria-expanded="false">
                    <li>
                      <Link to="/segment" className={isActiveLink('/segment') ? 'white-color' : null}>{t("Add a new segment")}</Link>
                    </li>
                    <li>
                      <Link to="/question" className={isActiveLink('/question') ? 'white-color' : null}>{t("Liste of questions")} </Link>
                    </li>
                    {/* <li>
                      <Link to="/esg-answer-list">{t("Answer")} </Link>
                    </li> */}

                  </ul>
                </li>
                <li>
                  <Link to="/#" className={isActiveLink('/finance-segment') || isActiveLink('/finance-question') ? 'has-arrow waves-effect activeLink active' : 'has-arrow'}>
                    <i className="mdi mdi-account"></i>
                    <span>{t("Financing request Questions")}</span>
                  </Link>
                  <ul className={isActiveLink('/finance-segment') || isActiveLink('/finance-question') ? 'sub-menu mm_show' : 'sub-menu'} aria-expanded="false">
                    <li>
                      <Link to="/finance-segment" className={isActiveLink('/finance-segment') ? 'white-color' : null}>{t("Add a new segment")}</Link>
                    </li>
                    <li>
                      <Link to="/finance-question" className={isActiveLink('/finance-question') ? 'white-color' : null}>{t("Liste of questions")} </Link>
                    </li>


                  </ul>
                </li>
                <li>
                  <Link to="/sme" className={isActiveLink('/sme') ? 'waves-effect activeLink_ active' : ''}>
                    <i className="mdi mdi-view-dashboard"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                    <span>{t("List of SMEs")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/investor" className="waves-effect">
                    <i className="mdi mdi-view-dashboard"></i>
                    
                    <span>{t("Investor")}</span>
                  </Link>
                </li> */
                  <li>
                    <Link to="/weight" className={isActiveLink('/weight') ? 'waves-effect activeLink_ active' : ''}>
                      <i className="mdi mdi-view-dashboard"></i>

                      <span>{t("Rating Formula")}</span>
                    </Link>
                  </li>}
                <li>
                  <Link to="/#" className={isActiveLink('/neuro-profiler-segment') || isActiveLink('/neuro-profiler-question') ? 'has-arrow waves-effect activeLink active' : 'has-arrow'}>
                    <i className="mdi mdi-view-dashboard"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                    <span>{t("Questions Neuroprofiler")}</span>
                  </Link>
                  <ul className={isActiveLink('/add-profile-questions') || isActiveLink('/view-profile-questions') || isActiveLink('/view-profile-questions') ? 'sub-menu mm_show' : 'sub-menu'} aria-expanded="false">
                    <li>
                      <Link to="/neuro-profiler-segment" className={isActiveLink('/neuro-profiler-segment') ? 'white-color' : null}>{t("Segment")}</Link>
                    </li>
                    <li>
                      <Link to="/neuro-profiler-facet" className={isActiveLink('/neuro-profiler-facet') ? 'white-color' : null}>{t("Facet")}</Link>
                    </li>
                    <li>
                      <Link to="/neuro-profiler-question" className={isActiveLink('/neuro-profiler-question') ? 'white-color' : null}>{t("Question")} </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/contact-list" className={isActiveLink('/contact-list') ? 'waves-effect activeLink_ active' : ''}>
                    <i className="mdi mdi-view-dashboard"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                    <span>{t("Contact requests")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/subscribe-list" className={isActiveLink('/subscribe-list') ? 'waves-effect activeLink_ active' : ''}>
                    <i className="mdi mdi-view-dashboard"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                    <span>{t("List of subscribers")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/news-letter" className={isActiveLink('/news-letter') ? 'waves-effect activeLink_ active' : ''}>
                    <i className="mdi mdi-view-dashboard"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                    <span>{t("News Letters")}</span>
                  </Link>
                </li>
              </>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}


const mapStateToProps = state => {
  // console.log('state ', state);
  const user = state.Profile.success;
  return { user };
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  profileSuccess: PropTypes.func,
}

export default withRouter(connect(mapStateToProps, { profileSuccess })(SidebarContent))

import React from 'react';
import { Prompt } from 'react-router-dom';


const MyPrompt = ({ message, onClose }) => {
  return (
    <Prompt
    message={(location, action) => {
      if (action === 'POP') {
        console.log("Backing up...")
      }

      return location.pathname.startsWith("/app")
        ? true
        : `Are you sure you want to leave this page? Your unsaved changes may be lost.`
    }}
  />
  );
};

export default MyPrompt;

import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import "../Tables/datatables.scss"
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import { del, get, post, put, upload } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import DeleteModal from './DeleteModalNews';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import * as Utils from "../Utils";
import Resizer from "react-image-file-resizer";

const Newsletter = (props) => {
    let history = useHistory();
    const [isAdd, setIsAdd] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [column, setColumn] = useState([]);
    const { user } = props;
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const { i18n, t } = useTranslation();
    const [file, setFile] = useState(null);
    const cropperRef = useRef();

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: t("News Letters"), link: "#" },
    ]

    useEffect(() => {
        if (props?.user?.token)
            getUserData()
    }, [props])


    useEffect(() => {
        if (!profileModal)
            setCurrentData(null)
    }, [profileModal])

    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: user?._id,
                token: user?.token
            }
            get('/news', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data)
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }

    useEffect(() => {
        if (!isAdd) {
            setFile(null);
            setCurrentData(null);
        }
    }, [isAdd])

    useEffect(() => {
        if (data?.length) {
            setColumn([
                {
                    name: t("Title"),
                    selector: row => row?.title,
                    maxWidth: "200px"
                },
                {
                    name: t('Description'),
                    cell: row => <span title={row?.desc}>{row?.desc}</span>,
                },
                {
                    name: t('Status'),
                    cell: row =>
                        <div className='status_box'>
                            {row?.isActive ?
                                <button className={'active'} onClick={() => { setCurrentData({ ...row, actionType: "Status" }); setDeleteModal(true) }}>{'Active'}</button>
                                :
                                <button className={'Inactive'} onClick={() => { setCurrentData({ ...row, actionType: "Status" }); setDeleteModal(true) }}>{'Inactive'}</button>
                            }
                        </div>,
                    maxWidth: "120px"
                },
                {
                    name: 'Action',
                    selector: row =>
                        <div className='action_box'>
                            <button onClick={() => { setCurrentData(row); setIsAdd(true); setFile(row?.image); }}><i class="mdi mdi-pencil" data-bs-toggle="tooltip" data-bs-placement="top" title={t("Edit")}></i></button>
                            <button onClick={() => { setCurrentData({ ...row, actionType: "Delete" }); setDeleteModal(true) }}><i class="mdi mdi-delete" data-bs-toggle="tooltip" data-bs-placement="top" title={t('Delete')}></i></button>
                        </div>,
                    maxWidth: "120px"
                },
            ])
        }
    }, [data]);

    useEffect(() => {
        props?.setBreadcrumbItems(t("News Letter"), breadcrumbItems)
    })

    const ExpandedComponent = ({ data }) =>
        <div className='search_expended_component'>
            <h3>Profile</h3>
        </div>;

    const statusChange = () => {
        if (!loading) {
            setLoading(true);
            const body = {
                token: user?.token,
                isActive: !currentData?.isActive,
                newsId: currentData?._id
            }
            put('/news/status', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setDeleteModal(false);
                        toast.success(res?.message);
                        getUserData();
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    const onDelete = () => {
        if (!loading) {
            setLoading(true);
            const body = {
                token: user?.token,
                newsId: currentData?._id
            }
            del('/news', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setDeleteModal(false);
                        toast.success(res?.message);
                        getUserData()
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    toast.error('Something went wrong')
                })
        }
    }

    const handleValidSubmit = async (e, v) => {
        if (!loading) {
            // setLoading(true);
            let body = {
                ...v,
                token: user?.token
            }
            if (file && file.substring(0, 4) == "blob") {
                const fileData = cropperRef?.current?.cropper?.getCroppedCanvas().toDataURL();
                const obj = await urltoFile(fileData, new Date().getTime() + '.webp', 'image/webp');
                const temp = await resizeFile(obj);
                const form = new FormData();
                form.append("image", temp);
                const image = await upload("/news/image_upload", form);
                if (image?.statusCode == 200)
                    body = { ...body, image: image?.data };
                else {
                    setLoading(false);
                    toast.error(image?.error);
                    return;
                }
            }
            if (currentData) {
                body = { ...body, newsId: currentData?._id };
                put("/news", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            getUserData();
                            setIsAdd(false);
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        toast.error("Something Went Wrong!");
                    });
            } else {
                post("/news", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            getUserData();
                            setIsAdd(false);
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const onChangeFile = (e) => {
        console.log('getting event on input img', e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    return (
        <React.Fragment>
            <Loader loading={loading} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={currentData?.actionType == 'Status' ? statusChange : onDelete}
                onCloseClick={() => setDeleteModal(false)}
                data={currentData} />
            <MetaTags>
                <title>News Letter | Demleen </title>
            </MetaTags>
            <Modal centered show={isAdd} className='model_profile'>
                <Modal.Header closeButton onHide={() => setIsAdd(false)}>
                    <Modal.Title style={{ textTransform: 'none' }}>{currentData ? "Update" : "Add New "}News Letter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='profile_d'>
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3 mt-4">
                                        {file ?
                                            <>
                                                <CardTitle >Image</CardTitle>
                                                <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20, width: 400 }}>
                                                    {currentData?.image ?
                                                        <img src={file} style={{ width: 400, height: 'auto' }} />
                                                        :
                                                        <Cropper
                                                            style={{ height: 'auto', width: 400 }}
                                                            aspectRatio={402 / 200}
                                                            preview=".img-preview"
                                                            guides={true}
                                                            src={file}
                                                            ref={cropperRef}
                                                        />
                                                    }
                                                    <i className="mdi mdi-close" style={{
                                                        color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                                        position: 'absolute', top: -16, right: -15, borderRadius: 15, width: 30, height: 30, textAlign: 'center',
                                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                    }}
                                                        onClick={() => { setFile(null); if (currentData) setCurrentData({ ...currentData, image: undefined }) }}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <AvField
                                                name="fileInput"
                                                label="Image"
                                                className="form-control"
                                                placeholder="upload Image"
                                                type="file"
                                                onChange={e => onChangeFile(e)}
                                                multiple={false}
                                                accept="image/*"
                                                required
                                            // value={currentData?.logo}
                                            />
                                        }
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="title"
                                            label="Title *"
                                            placeholder="Enter Title for news letter"
                                            value={currentData?.title}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="desc"
                                            label="Description *"
                                            placeholder="Enter description"
                                            value={currentData?.desc}
                                            required
                                            type="textarea"
                                            rows={5}
                                        />
                                    </div>
                                </Col>
                                <Row>
                                    <Col md={6}>
                                        <button className='apply_btn_b btn-add' onClick={() => setIsAdd(false)} type="reset">{t("Cancel")}</button>
                                    </Col>
                                    <Col md={6}>
                                        <button className='apply_btn_b btn-add' type="submit">{t("Submit")}</button>
                                    </Col>
                                </Row>
                            </Row>
                        </AvForm>
                    </div>

                </Modal.Body>

            </Modal>
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={11}></Col>
                                <Col md={1}>
                                    <button className='apply_btn_b btn-add' onClick={() => setIsAdd(true)}>{t("Add")}</button>
                                </Col>
                            </Row>
                            <div className='investor_component'>
                                <DataTable
                                    columns={column}
                                    data={data}
                                    expandOnRowClicked={true}
                                    expandableRowDisabled={row => row.disabled}
                                    expandableRowsComponent={ExpandedComponent}
                                    expandableRowsComponentProps={row => row.Action}
                                    direction="ltr"
                                    fixedHeader
                                    fixedHeaderScrollHeight="500px"
                                    pagination
                                    responsive
                                    subHeaderAlign="right"
                                    subHeaderWrap
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(Newsletter)
)
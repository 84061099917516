import moment from 'moment'
import React from 'react'
import SimpleBar from "simplebar-react"
export default function CommentSection(props) {
    return (
        <React.Fragment>
            <div className={props?.rightModal == true ? 'right-bar right_bar':'right-bar'}>
                <button className='right_popup_close_btn' onClick={()=>props?.setRightModal(false)}><span className='mdi mdi-close'></span></button>
               
                <SimpleBar style={{ height: "900px" }}>
                
                    <div data-simplebar className="h-100 body_box">
                     <h4 className="card-title comment_title mb-2">Latest Comment <span>{moment(props?.commentedOn).format("DD-MMM-YYYY hh:mm a")}</span></h4>
                     <hr/>
                      <p>{props?.comment}</p>
                    </div>
                </SimpleBar>
            </div>
        </React.Fragment>
    )
}

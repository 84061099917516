import React from 'react'

import ProfileData1 from './ProfileData1'

export default function ReportMemo1(props) {
  return (
    <div>
        <ProfileData1 user={props?.user} userId={props?.userId} isComment={true} />
    </div>
  )
}

import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Button,
    Label
} from "reactstrap"
import { Modal, ModalBody } from 'reactstrap';
import Cropper, { ReactCropperElement } from "react-cropper";
import Resizer from "react-image-file-resizer";
import "cropperjs/dist/cropper.css";
import './style.scss';
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"
import { setBreadcrumbItems } from "../../../store/actions";
import { useTranslation } from 'react-i18next';

import { get, post, put, upload } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import DeleteModal from 'components/DeleteModal';
const AddQuestion = (props) => {
    let history = useHistory();
    const { user, loading, setLoading, currentData, setCurrentData, isAdd, setIsAdd, segments, facets, getData } = props;
    const { i18n, t } = useTranslation();
    const [bullets, setBullets] = useState([{}]);
    const [questionType, setQuestionType] = useState("");
    const [file, setFile] = useState(null)
    const [imageUrl, setImageUrl] = useState(null);
    const [cropImg, setCropImg] = useState(false);
    const formRef = useRef(null);
    const [facetTemp, setFacetTemp] = useState([]);
    const [isProb, setIsProb] = useState(true);

    useEffect(() => {
        if (currentData?.image) {
            // setFile(currentData?.image);
            setImageUrl(currentData?.image);
        }
        if (currentData?.type)
            setQuestionType(currentData?.type);
        if (currentData?.segmentId) {
            let tempFacet = [];
            let temp = Object.assign([], facets);
            temp.forEach(element => {
                if (element?.segmentId == currentData?.segmentId)
                    tempFacet.push(element);
            });
            setFacetTemp(tempFacet);
        }
        setIsProb(currentData?.isProb)
    }, [currentData, facets]);

    // useEffect(() => {
    //     if (facets?.length)
    //         setFacetTemp(facets);
    // }, [facets]);

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1080,
                1080,
                "webp",
                60,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const handleValidSubmit = async (e, values) => {
        // setLoading(true);
        if (!loading) {
            setLoading(true);
            let body = {
                ...values,
                bullets,
                isProb,
                facetId: values?.facetId ? values?.facetId : undefined,
                token: user?.token
            }
            if (file) {
                let obj = await urltoFile(imageUrl, new Date().getTime() + '.png', 'image/png');
                const image = await resizeFile(obj);
                console.log('obje img', obj)
                const form = new FormData();
                form.append("image", image);
                const imageRes = await upload("/nuro/image_upload", form);
                console.log("body before sent", imageRes)
                body.image = imageRes?.data;
            }

            if (currentData) {
                body = {
                    ...body,
                    quesId: currentData?._id
                }

                put('/nuro', body)
                    .then((res => {
                        setLoading(false);

                        if (res?.statusCode == 200) {
                            // console.log('request on login', res);
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                            // history.push('/dashboard')
                        } else if (res?.statusCode == 401) {
                            history?.push('/logout')
                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    }))
                    .catch(error => {
                        setLoading(false);
                        console.log('error while login', error);
                        console.log('Something went wrong');
                        toast.error('Something went wrong')
                    })
            }
            else {
                post('/nuro/add', body)
                    .then((res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            // console.log('request on login', res);
                            toast.success(res?.message)
                            setIsAdd(false);
                            getData();
                            // history.push('/dashboard')
                        } else if (res?.statusCode == 401) {
                            history?.push('/logout')
                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    }))
                    .catch(error => {
                        setLoading(false);
                        console.log('error while login', error);
                        console.log('Something went wrong');
                        toast.error('Something went wrong')
                    })
            }
        }
    }

    const handleBulletRemove = (index) => {
        let temp = Object.assign([], bullets);
        temp.splice(index, 1);
        setBullets(temp);
    }

    const handleBulletInput = (val, index, to) => {
        let temp = Object.assign([], bullets);
        temp[index][to] = val;
        setBullets(temp);
    }

    const getImage = (e) => {
        let file = e.target.files[0];
        console.log('file', file)

        if (file) {
            setFile(URL.createObjectURL(e.target.files[0]))
            // Create a URL for the selected file
            setCropImg(true)

        }
    }

    const cropperRef = useRef(null);
    const cropImage = (e, v) => {
        const fileData = cropperRef?.current?.cropper?.getCroppedCanvas().toDataURL();
        setImageUrl(fileData)
    }

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Questions", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems(t("Questions Neuroprofiler"), breadcrumbItems)
    });

    const handleSegmentChange = (segId) => {
        console.log("facet", segId);
        if (segId) {
            let tempFacet = [];
            let temp = Object.assign([], facets);
            temp.forEach(element => {
                if (element?.segmentId == segId)
                    tempFacet.push(element);
            });
            setFacetTemp(tempFacet);
        }
    }


    console.log("question type", questionType);

    return (
        <React.Fragment>
            <Modal isOpen={cropImg} className='trusted_amount' centered backdrop="static">
                <ModalBody>
                    <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                            cropImage()
                        }}
                        ref={formRef}
                    >
                        <Row>
                            <Col lg={12} className='mb-4'>
                                <Cropper
                                    src={file}
                                    style={{ height: 300, width: "100%" }}
                                    // Cropper.js options
                                    preview=".img-preview"
                                    initialAspectRatio={1 / 1}
                                    guides={true}
                                    ref={cropperRef}
                                />
                            </Col>
                            <Col md={6}>
                                <button type='button' className='btn_bg w-100' onClick={() => { setCropImg(false) }}>Cancel</button>
                            </Col>
                            <Col md={6}>
                                <button type='submit' onClick={() => { setCropImg(false) }} className='btn_bg w-100'>Submit</button>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>

            {/* <span>Add Questions</span> */}
            <div className={'add_question_modale active'}>
                <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                    }}
                >
                    <Row>
                        <Col md={6}>
                            <div className='mb-3'>
                                <AvField
                                    name="segmentId"
                                    className="form-control"
                                    type="select"
                                    label="Segment"
                                    value={currentData?.segmentId}
                                    required
                                    onChange={(e) => handleSegmentChange(e?.target?.value)}
                                >
                                    <option value="">Select Segment</option>
                                    {segments?.map((item) => (
                                        <option value={item?._id}>{item?.title}</option>
                                    ))}
                                </AvField>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='mb-3'>
                                <AvField
                                    name="facetId"
                                    className="form-control"
                                    type="select"
                                    label="Facet"
                                    value={currentData?.facetId}
                                >
                                    <option value="">Select Facet</option>
                                    {facetTemp?.map((item) => (
                                        <option value={item?._id}>{item?.title}</option>
                                    ))}
                                </AvField>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='mb-3'>
                                <AvField
                                    name="title"
                                    className="form-control"
                                    type="textarea"
                                    label="Title (English)"
                                    value={currentData?.title}
                                    rows={5}
                                    placeholder="e.i ( You have the opportunity to invest in a high-potential artwork. You know that you could resell it for $$ CFA francs in some time, but there’s a one in three chance the resale price could be only $$ CFA francs. )"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='mb-3'>
                                <AvField
                                    name="titleFs"
                                    className="form-control"
                                    type="textarea"
                                    label="Title (French)"
                                    value={currentData?.titleFs}
                                    rows={5}
                                    placeholder="e.i ( Vous avez la possibilité d’investir dans une œuvre d’art à fort potentiel. Vous savez que vous pourriez le revendre pour $$ francs CFA dans quelques temps, mais il y a une chance sur trois que le prix de revente ne soit que $$ francs CFA. )"
                                />
                            </div>
                        </Col>
                        <hr></hr>
                        {/* <hr></hr> */}
                        <Col md={6}>
                            <div className='mb-3'>
                                <AvField type="select" name="type" label="Question Type"
                                    onChange={(e) => setQuestionType(e.target.value)}
                                    value={questionType}
                                >
                                    <option value="">Select Question Type</option>
                                    <option value="Binary">Binary</option>
                                    <option value="Weight">Weight Scale</option>
                                    <option value="MCQ">MCQ</option>
                                </AvField>
                            </div>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <div className='mb-3'>
                                        <AvField
                                            name="file"
                                            className="form-control"
                                            // placeholder="Sales of goods for resale"
                                            type="file"
                                            label="Image"
                                            accept=".jpg,.png,.jpeg"
                                            // value={currentData?.image}
                                            onChange={getImage}
                                        // required
                                        />
                                    </div>
                                </Col>

                                <Col md={4}>
                                    <div className='question_img_box'>
                                        <img src={imageUrl} style={{ width: '100%' }} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {questionType == "MCQ" &&
                            <>
                                <Col md={12}>
                                    <Row className='align-items-center'>
                                        <Col md={9}>
                                            <h4>Options</h4>
                                        </Col>
                                        <Col md={3}>
                                            {bullets?.length < 1 &&
                                                <Button type="button" className='btn_bg my-4' color="" onClick={() => setBullets([...bullets, {}])}>
                                                    {t("+ Add More Option")}
                                                </Button>
                                            }
                                        </Col>
                                    </Row>

                                    {bullets?.map((item, index) => (
                                        <>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='mb-3'>
                                                        <AvField
                                                            name={"title" + index}
                                                            className="form-control"
                                                            type="text"
                                                            label="English"
                                                            value={item?.title}
                                                            onChange={(e) => handleBulletInput(e.target.value, index, "title")}
                                                        // required
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='mb-3'>
                                                        <AvField
                                                            name={"titleFs" + index}
                                                            className="form-control"
                                                            type="text"
                                                            label="French"
                                                            value={item?.titleFs}
                                                            onChange={(e) => handleBulletInput(e.target.value, index, "titleFs")}
                                                        // required
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='align-items-center'>
                                                <Col md={6}>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='bullet_button'>
                                                        {bullets?.length > 1 &&
                                                            <Button type="button" className='btn_bg' color="" onClick={() => handleBulletRemove(index)}>
                                                                {t("Delete")}
                                                            </Button>
                                                        }
                                                        {index == bullets?.length - 1 &&
                                                            <Button type="button" className='btn_bg' color="" onClick={() => setBullets([...bullets, {}])}>
                                                                {t("+ Add Bullet Points")}
                                                            </Button>
                                                        }
                                                    </div>
                                                </Col>

                                            </Row>
                                        </>
                                    ))}

                                </Col>
                            </>
                        }
                        {questionType == "Weight" &&
                            <>
                                <Col md={6}>
                                    <div className='mb-3'>
                                        <AvField type="select" name="answerType" label="Answer Type"
                                            value={currentData?.answerType}
                                        >
                                            <option value="">Select Answer Type</option>
                                            <option value="forward">Forward (+)</option>
                                            <option value="reverse">Reverse (-)</option>
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <Row className='align-items-center mt-2 mb-2'>
                                        <Col md={9}>
                                            <h4>Weight 1</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <AvField
                                                    name={"option1.title"}
                                                    className="form-control"
                                                    type="text"
                                                    label="English"
                                                    value={currentData?.option1?.title}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <AvField
                                                    name={"option1.titleFs"}
                                                    className="form-control"
                                                    type="text"
                                                    label="French"
                                                    value={currentData?.option1?.title}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='align-items-center mt-2 mb-2'>
                                        <Col md={9}>
                                            <h4>Weight 2</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <AvField
                                                    name={"option2.title"}
                                                    className="form-control"
                                                    type="text"
                                                    label="English"
                                                    value={currentData?.option2?.title}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <AvField
                                                    name={"option2.titleFs"}
                                                    className="form-control"
                                                    type="text"
                                                    label="French"
                                                    value={currentData?.option2?.title}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='align-items-center mt-2 mb-2'>
                                        <Col md={9}>
                                            <h4>Weight 3</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <AvField
                                                    name={"option3.title"}
                                                    className="form-control"
                                                    type="text"
                                                    label="English"
                                                    value={currentData?.option3?.title}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <AvField
                                                    name={"option3.titleFs"}
                                                    className="form-control"
                                                    type="text"
                                                    label="French"
                                                    value={currentData?.option3?.title}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='align-items-center mt-2 mb-2'>
                                        <Col md={9}>
                                            <h4>Weight 4</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <AvField
                                                    name={"option4.title"}
                                                    className="form-control"
                                                    type="text"
                                                    label="English"
                                                    value={currentData?.option4?.title}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <AvField
                                                    name={"option4.titleFs"}
                                                    className="form-control"
                                                    type="text"
                                                    label="French"
                                                    value={currentData?.option4?.title}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='align-items-center mt-2 mb-2'>
                                        <Col md={9}>
                                            <h4>Weight 5</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <AvField
                                                    name={"option5.title"}
                                                    className="form-control"
                                                    type="text"
                                                    label="English"
                                                    value={currentData?.option5?.title}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <AvField
                                                    name={"option5.titleFs"}
                                                    className="form-control"
                                                    type="text"
                                                    label="French"
                                                    value={currentData?.option5?.title}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        }
                        {questionType == "Binary" &&
                            <Row className='pt-4'>
                                <Col lg={3}>
                                    <div className='prob_box mb-4'>
                                        <AvField type="select" name="answer" label="Correct Answer"
                                            value={currentData?.answer}
                                        >
                                            <option value={""}>Select correct answer</option>
                                            <option value={"Positive"}>Positive</option>
                                            <option value={"Negative"}>Negative</option>
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='prob_box mb-4 mt-4 d-flex align-items-center'>
                                        <Label>Probability</Label>
                                        <div className="toggle-switch mx-3">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-checkbox"
                                                name="toggleSwitch"
                                                id="isProb"
                                                onChange={(e) => setIsProb(!isProb)}
                                                checked={isProb}
                                            />
                                            <label className="toggle-switch-label" htmlFor="isProb">
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}></Col>
                                <Col md={3}>
                                    <div className='mb-3'>
                                        <AvField
                                            name="negativeButton"
                                            className="form-control"
                                            // placeholder="Sales of goods for resale"
                                            type="text"
                                            label="Negative Button English"
                                            value={currentData?.negativeButton}
                                        // required
                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-3'>
                                        <AvField
                                            name="positiveButton"
                                            className="form-control"
                                            // placeholder="Sales of goods for resale"
                                            type="text"
                                            label="Positive Button English"
                                            value={currentData?.positiveButton}
                                        // required
                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-3'>
                                        <AvField
                                            name="negativeButtonFs"
                                            className="form-control"
                                            // placeholder="Sales of goods for resale"
                                            type="text"
                                            label="Negative Button French"
                                            value={currentData?.negativeButtonFs}
                                        // required
                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-3'>
                                        <AvField
                                            name="positiveButtonFs"
                                            className="form-control"
                                            // placeholder="Sales of goods for resale"
                                            type="text"
                                            label="Positive Button French"
                                            value={currentData?.positiveButtonFs}
                                        // required
                                        />
                                    </div>
                                </Col>
                                {/* <Col lg={12}>
                                    <div className='prob_box'>
                                        <p>Probability of correctness</p>
                                        <AvField type="select" name="select" >
                                            <option value={""}>Select</option>
                                            <option value={"10%"}>10%</option>
                                            <option value={"20%"}>20%</option>
                                            <option value={"30%"}>30%</option>
                                            <option value={"40%"}>40%</option>
                                            <option value={"50%"}>50%</option>
                                            <option value={"60%"}>60%</option>
                                            <option value={"70%"}>70%</option>
                                            <option value={"80%"}>80%</option>
                                            <option value={"90%"}>90%</option>
                                            <option value={"100%"}>100%</option>
                                        </AvField>

                                    </div>
                                </Col> */}
                            </Row>
                        }
                    </Row>
                    <div className="text-center">
                        <Button type="reset" onClick={() => { setCurrentData(null); setIsAdd(false) }} className='btn_bg my-4 mx-4' color="">
                            {t("Cancel")}
                        </Button>
                        <Button type="submit" className='btn_bg my-4' color="">
                            {t(currentData ? "Update" : "Save")}
                        </Button>
                    </div>
                </AvForm>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {

    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(AddQuestion)
)
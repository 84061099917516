import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
    CardTitle
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"


// actions


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';

import { get, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import DeleteModal from 'components/DeleteModal';
const Index = (props) => {
    let history = useHistory();
    const [businessStructure, setBusinessStructure] = useState([]);
    const [region, setRegion] = useState([]);
    const [profileModal, setProfileModal] = useState(false);
    const [sdg, setSdg] = useState([]);
    const handleClose = () => setProfileModal(false);
    const handleShow = () => {setProfileModal(true); setCurrentData(null);};
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const {user} = props;
    const [deleteModal,setDeleteModal] = useState(false);
    const [currentData,setCurrentData] = useState(null);
    const { i18n, t } = useTranslation();

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Segment", link: "#" },
    ]

    useEffect(() => {
      
        if (props?.user?.token)
            getUserData()
    }, [props])

    useEffect(()=>{
    if(!profileModal)
      setCurrentData(null)
    },[profileModal])

    const getUserData = () => {
        if (!loading) {
            setLoading(true)
        let body = {
            userId: user?._id,
            token: user?.token
        }
        get('/finance/segment', body)
            .then((res => {
                setLoading(false);
                console.log('segment list', res)
                if (res?.statusCode == 200) {
                    setData(res?.data)
                } else {
                    console.log(res?.error);
                }
            }))
            .catch(error => {
                setLoading(false);
                console.log('error while login', error);
                console.log('Something went wrong');
            })
        }
    }
    useEffect(() => {
        props.setBreadcrumbItems(t("Financing request Questions"), breadcrumbItems)
    })

    const sdgFun = (e) => {
        setSdg(e)
        console.log(e)
    }

    const columns = [

        {
            name: t('Title'),
            selector: row => i18n?.language == 'en' ? row?.title:row?.titleFs,
            // maxWidth: '600px'
        },
       
        {
            name: t('Status'),
            selector: row =>
                <div className='status_box'>

                    {
                        <button className={row?.isActive == true ? 'active':'Inactive'} onClick={() => {setCurrentData({...row, actionType:"Status"}); setDeleteModal(true)}}>{row?.isActive == true ? 'Active':'Inactive'}</button>
                    }


                </div>
            ,
        },
        {
            name: 'Action',
            selector: row =>
                <div className='action_box'>

                    <button onClick={()=>{ setCurrentData(row); setProfileModal(true)}}><i class="mdi mdi-pencil" data-bs-toggle="tooltip" data-bs-placement="top" title={t("Edit")} onClick={ExpandedComponent}></i></button>
                    <button onClick={()=>{ setCurrentData(row); setDeleteModal(true)}}><i class="mdi mdi-delete" data-bs-toggle="tooltip" data-bs-placement="top" title={t('Delete')}></i></button>
                    {/* <button ><i class="mdi mdi-heart" data-bs-toggle="tooltip" data-bs-placement="top" title="Add Company"></i></button> */}
                </div>,

        },
    ];


    const ExpandedComponent = ({ data }) =>
        <div className='search_expended_component'>
            <h3>Profile</h3>
        </div>;

    const handleValidSubmit = (e, values) => {

        // setLoading(true);



        if (!loading) {
            setLoading(true)

            let body = {
                ...values,
                token: user?.token
            }
             if(currentData){
                body = {
                    ...body,
                    segmentId:currentData?._id
                }
                put('/finance/segment', body)
                .then((res => {
                    setLoading(false);
                    
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        toast.success(res?.message)
                        getUserData()
                        setProfileModal(false)
                        // history.push('/dashboard')
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
             }
             else{
            post('/finance/segment', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        getUserData()
                        setProfileModal(false)
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
            }
        }
    }


    const statusChange = () => {
        if (!loading) {
            setLoading(true)
            setDeleteModal(false)
            const body = {
                token: user?.token,
                isActive: !currentData?.isActive,
                segmentId: currentData?._id
            }

            put('/finance/segment', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        console.log('status change', res);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        getUserData()
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    const onDelete = () => {
        if (!loading) {
            setLoading(true)
            setDeleteModal(false)
            const body = {
                token: user?.token,
                segmentId: currentData?._id
            }

            post('/finance/segment/delete', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        console.log('status change', res);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        getUserData()
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }



    return (
        <React.Fragment>
            <Loader loading={loading} />
            <DeleteModal 
            show={deleteModal}  
            onDeleteClick={currentData?.actionType == 'Status' ? statusChange:onDelete} 
            onCloseClick={()=>setDeleteModal(false)}
            data={currentData}  />
            <MetaTags>
                <title>Esg Segment | Demleen </title>
            </MetaTags>
            <Modal centered show={profileModal} className='model_profile' onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Add Segment")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='add_segment'>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <h4>English</h4>
                            <div className='mb-3'>
                                <AvField
                                    name="title"

                                    className="form-control"
                                    // placeholder="Sales of goods for resale
                                    type="text"
                                    label="Title"
                                    value={currentData?.title}

                                // required
                                />
                            </div>
                          
                            <h4>French</h4>
                            <div className='mb-3'>
                                <AvField
                                    name="titleFs"

                                    className="form-control"
                                    // placeholder="Sales of goods for resale
                                    type="text"
                                    label="Title"
                                    value={currentData?.titleFs}

                                // required
                                />
                            </div>
                            
                            <div className="text-center">
                                <Button type="submit" className='btn_bg' color="">
                                    {t(currentData ? "Update":"Save")}
                                </Button>
                            </div>
                        </AvForm>
                    </div>

                </Modal.Body>

            </Modal>

            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody >

                            <div className='investor_component'>
                                <div className='search_section'>
                                    <button onClick={handleShow} className='add_button' style={{ width: 'fit-content' }}>Add</button>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    className='finenceTable_section'
                                    // expandableRows={true}
                                    expandOnRowClicked={true}
                                    expandableRowDisabled={row => row.disabled}
                                    expandableRowsComponent={ExpandedComponent}
                                    expandableRowsComponentProps={row => row.Action}
                                    direction="ltr"
                                    fixedHeader
                                    fixedHeaderScrollHeight="500px"
                                    pagination
                                    responsive
                                    subHeaderAlign="right"
                                    subHeaderWrap

                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}

const mapStatetoProps = state => {

    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(Index)
)
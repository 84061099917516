import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// esg

import ESG from '../pages/esg/index'

//Financing request details
import Financing from '../pages/FinancingRequestForm/index'
//cash flow
import CashFlow from '../pages/cashflow/index'
import CashFlowData from '../pages/cashflow/DataTable'
// Pages Calendar
import Calendar from "../pages/Calendar/index"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailCompose from "../pages/Email/email-compose"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Charts
import ChartsAppex from "../pages/Charts/charts-appex";
import ChartsChartist from "../pages/Charts/charts-chartist";
import ChartsJs from "../pages/Charts/charts-chartjs";
import ChartsKnob from "../pages/Charts/charts-knob";
import ChartsC3 from "../pages/Charts/charts-c3";
import ChartsSparkLine from "../pages/Charts/charts-sparkline";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import Iconion from "../pages/Icons/Iconion"
import IconFontawesome from "../pages/Icons/IconFontawesome"
import IconThemify from "../pages/Icons/IconThemify"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconTypicons from "../pages/Icons/IconTypicons"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormUpload from "../pages/Forms/FormUpload"
import FormXeditable from "../pages/Forms/FormXeditable"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiBadge from "../pages/Ui/UiBadge"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiPagination from "../pages/Ui/UiPagination"
import UiPopoverTooltips from "../pages/Ui/UiPopoverTooltips"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiSweetAlert from "../pages/Ui/UiSweetAlert"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"

//Extra Pages
import PagesTimeline from "../pages/Extra Pages/pages-timeline";
import PagesInvoice from "../pages/Extra Pages/pages-invoice";
import PagesDirectory from "../pages/Extra Pages/pages-directory";
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";



//Profit and Loss account
import ProfitandLossaccount from "../pages/ProfitandLossAccount"

//Income Statement Analysis
import IncomeStatementAnalysis from '../pages/RatioAnalysis/IncomeStatementAnalysis'
import BalanceSheetAnalysis from "pages/BalanceSheetAnalysis/BalanceSheetAnalysis"
import LeverageAnalysis from "pages/LeverageAnalysis/LeverageAnalysis"
import DataTable from "pages/ProfitandLossAccount/DataTable"
import SearchInvester from "pages/InvesterModule/SearchDetails"
import SearchDetails from "pages/SmeModule/SearchDetails"
import Home from "front/Home"
import SelectedCompanies from "pages/SmeModule/SelectedCompanies"
import SelectedCompaniesInvestor from "pages/InvesterModule/SelectedCompanies"
import Sme from "pages/Admin/Sme"
import Investor from "pages/Admin/Investor"
import Contact from "front/Contact"
import InvestorWaitingList from "front/InvestorWaitingList"
import SME from "front/SME"
import About from "front/About"
import OurSolution from "front/OurSolution"
import MentionsLegales from "front/MentionsLegales"
import TermsofService from "front/TermsofService"
import PrivacyPolicy from "front/PrivacyPolicy"
import Segment from "pages/esg/Segment"
import Question from "pages/esg/Question"
import ContactList from 'pages/Admin/UserList'
import EsgList from "pages/esg/Question/EsgList"
import EsgDetails from "pages/esg/Question/EsgDetails"
import SMEEsgDetails from "pages/esg/DataTable"
import FinanceSegment from "pages/FinancingRequestForm/Segment"
import FinanceQuestion from "pages/FinancingRequestForm/Question"
// import UserProfileQuestion from "pages/UserProfileQuestion/Segment"

import EsgDetail from "pages/esg/EsgDetail"
import FinancingDetails from "pages/FinancingRequestForm/FinancingDetails"
import BalanceSheet from "pages/BalanceSheet/BalanceSheet"
import BalanceSheetView from "pages/BalanceSheet/DataTable"
import Liabilities from "pages/BalanceSheet/Liabilities"
import Neuroprofiler from "pages/UserProfileQuestion/Neuroprofiler"
import SubscribeList from "pages/Admin/SubscribeList"
import Weight from "pages/SmeModule/Weight"
import NeuroprofilerInvite from "pages/UserProfileQuestion/NeuroprofilerInvite"
import AddQuestion from "pages/UserProfileQuestion/Segment/AddQuestion"
import Newsletter from "pages/Admin/NewsLetter";
import NuroSegment from "pages/UserProfileQuestion/Segment";
import NuroFacet from "pages/UserProfileQuestion/Facet";
import NuroQuestion from "pages/UserProfileQuestion/Question";
import NewTest from "pages/UserProfileQuestion/NewTest";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //calendar
  { path: "/calendar", component: Calendar },

  // // //profile
  { path: "/profile", component: UserProfile },
  //esg
  { path: "/esg", component: ESG },
  { path: "/esg-details", component: EsgDetail },
  //Financing
  { path: "/financing", component: Financing },
  { path: "/financing-details", component: FinancingDetails },
  //CashFlow
  { path: "/cash-flow", component: CashFlow },
  { path: "/cash-flow-detail", component: CashFlowData },
  //ProfitandLossaccount
  { path: "/profit-and-loss-account", component: ProfitandLossaccount },
  { path: "/profit-and-loss-account-detail", component: DataTable },
  //IncomeStatementAnalysis
  { path: "/income-statement-analysis", component: IncomeStatementAnalysis },
  //BalanceSheetAnalysis
  { path: "/balance-sheet-analysis", component: BalanceSheetAnalysis },
  //BalanceSheet
  { path: "/balance-sheet", component: BalanceSheet },
  { path: "/balance-sheet-detail", component: BalanceSheetView },
  { path: "/liabilities", component: Liabilities },
  //LeverageAnalysis
  { path: "/leverage-analysis", component: LeverageAnalysis },
  //Investor Module
  { path: "/search-companie", component: SearchInvester },
  { path: "/search-details", component: SearchDetails },
  { path: "/selected-companies", component: SelectedCompanies },
  { path: "/selected-companie", component: SelectedCompaniesInvestor },
  //Admin Module
  { path: "/sme", component: Sme },
  { path: "/investor", component: Investor },
  { path: "/segment", component: Segment },
  { path: "/question", component: Question },
  { path: "/contact-list", component: ContactList },
  { path: "/subscribe-list", component: SubscribeList },
  { path: "/esg-answer-list", component: EsgList },
  { path: "/esg-details", component: EsgDetails },
  { path: "/sme-esg-details", component: SMEEsgDetails },
  { path: "/finance-segment", component: FinanceSegment },
  { path: "/finance-question", component: FinanceQuestion },
  { path: "/weight", component: Weight },
  { path: "/news-letter", component: Newsletter },
  { path: "/neuro-profiler-segment", component: NuroSegment },
  { path: "/neuro-profiler-facet", component: NuroFacet },
  { path: "/neuro-profiler-question", component: NuroQuestion },
  // { path: "/view-profile-questions", component: UserProfileQuestion },
  { path: "/add-profile-questions", component: AddQuestion },
  { path: "/neuro-profiler", component: Neuroprofiler },
  { path: "/cognative-test", component: NewTest },



  // //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },
  { path: "/email-compose", component: EmailCompose },

  // //Charts
  { path: "/apex-charts", component: ChartsAppex },
  { path: "/charts-chartist", component: ChartsChartist },
  { path: "/charts-chartjs", component: ChartsJs },
  { path: "/charts-knob", component: ChartsKnob },
  { path: "/charts-c3", component: ChartsC3 },
  { path: "/sparkline-charts", component: ChartsSparkLine },

  // // Icons
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-ion", component: Iconion },
  { path: "/icons-fontawesome", component: IconFontawesome },
  { path: "/icons-themify", component: IconThemify },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-typicons", component: IconTypicons },

  // // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-badge", component: UiBadge },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-pagination", component: UiPagination },
  { path: "/ui-popover-tooltip", component: UiPopoverTooltips },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rangeslider", component: UiRangeSlider },

  // //Extra Pages
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-invoice", component: PagesInvoice },
  { path: "/pages-directory", component: PagesDirectory },
  { path: "/pages-blank", component: PagesBlank },

  // this route should be at the end of all other routes
  { path: "/dashboard", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: '/contact', component: Contact },
  { path: '/investor-waiting-list', component: InvestorWaitingList },
  { path: '/sme-form', component: SME },
  { path: '/about-us', component: About },
  { path: '/our-solution', component: OurSolution },
  { path: '/mentions-legales', component: MentionsLegales },
  { path: '/terms-of-service', component: TermsofService },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: '/', component: Home },
  { path: "/neuro-profiler/:id/:amount/:memId", component: NeuroprofilerInvite },
]
const homeRout = [



]

export { userRoutes, authRoutes }
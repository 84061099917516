import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
    Modal, ModalHeader, ModalBody, ModalFooter, Tooltip
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import line from "../../assets/images/line.png"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import IncomestatementanalysisChart from '../AllCharts/apex/balanceChart';
import GraphThree from '../AllCharts/apex/graphthreeChart';
import BalancepieChart from '../AllCharts/apex/balancepieChart';
import BalancepieChart1 from '../AllCharts/apex/balancepieChart1';

import BarApexChart from "../AllCharts/apex/balanceChart1";
import ChartRatio from 'pages/AllCharts/chartjs/chartRatio';
import Doughnut from 'pages/AllCharts/apex/balanceChart';
import DountChart from 'pages/AllCharts/chartjs/dountchart';
import { get } from 'helpers/helper_api';
// import { withTranslation } from "react-i18next"

const dataBal = [
    {
        title: 'Net Working Capital',
        ratio: 2.5,
        desc: "Net Working Capital Desc",
        label: 'Amount'
    },
    {
        title: 'Net Cash',
        ratio: 2.5,
        desc: "Net Cash Desc",
        label: 'Amount'
    },
    {
        title: 'Days Of Sales Outstanding',
        ratio: 2.5,
        desc: "Days Of Sales Outstanding Desc"
    },
    {
        title: 'Days Of Invertory On Hands',
        ratio: 2.5,
        desc: "Days Of Invertory On Hands Desc"
    },
    {
        title: 'Number Of Days Of Payables',
        ratio: 2.5,
        desc: "Number Of Days Of Payables Desc"
    },
    {
        title: 'Cash Conversion Cycle',
        ratio: 2.5,
        desc: "Cash Conversion Cycle Desc"
    },
    {
        title: 'Working Capital',
        ratio: 2.5,
        desc: "Working Capital Desc",
        label: 'Amount'
    },
    {
        title: 'Equity-to-Working Capital Ratio',
        ratio: 2.5,
        desc: "Equity-to-Working Capital Ratio Desc",
        label: 'Times'
    },
    // {
    //     title: 'Funded Capital Ratio',
    //     ratio: 2.5,
    //     desc: "Funded Capital Ratio Desc"
    // },
    {
        title: 'Current Ratio',
        ratio: 2.5,
        desc: "Current Ratio Desc",
        label: 'Times'
    },
    {
        title: 'Liquidity Cover',
        ratio: 2.5,
        desc: "Liquidity Cover Desc",
        label: 'Times'
    },
    {
        title: 'Cash ratio',
        ratio: 2.5,
        desc: "Cash ratio Desc",
        label: 'Times'
    }
    // {
    //     title: 'Receivable turnover',
    //     ratio: 2.5,
    //     desc: "Receivable turnover Desc"
    // },
    // {
    //     title: 'Inventory turnover',
    //     ratio: 2.5,
    //     desc: "Inventory turnover Desc"
    // },
    // {
    //     title: 'Payables turnover',
    //     ratio: 2.5,
    //     desc: "Payables turnover Desc"
    // },
    // {
    //     title: 'Accounts Payables ratio',
    //     ratio: 2.5,
    //     desc: "Accounts Payables ratio Desc"
    // },
    // {
    //     title: 'Accounts Receivables ratio',
    //     ratio: 2.5,
    //     desc: "Accounts Receivables ratio Desc"
    // },

]


function BalanceSheetAnalysis(props) {
    const { i18n, t } = useTranslation();
    const [yearsArr, setYearsArr] = useState(undefined);
    const [years, setYears] = useState('');
    const [helpModal, setHelpModal] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [explainData, setExplainData] = useState(undefined)
    const [explainData1, setExplainData1] = useState(dataBal[activeTab])
    const [data, setData] = useState(undefined)
    const [title, setTitle] = useState(undefined);
    const [value, setValue] = useState(undefined);
    const [graphTitle, setGraphTitle] = useState(undefined)
    const [graphValue, setGraphValue] = useState(undefined)
    const [curruntData, setCurruntData] = useState(undefined)
    const [typeGraph, setTypeGraph] = useState(undefined)
    const [horizontal, setHorizontal] = useState(false)
    const [allGraph, setAllGraph] = useState(undefined)


    const { user } = props;
    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: t("Balance Sheet Analysis"), link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems(t("Balance Sheet Analysis"), breadcrumbItems)
    }, [i18n?.language])



    const changeTab = (item, index) => {
        setActiveTab(index)
        setCurruntData(item)
        setTypeGraph(item?.type)
        // localStorage.setItem('type', item?.type)
        setExplainData1(dataBal[index])

        if (item?.type == '5') {
            setHorizontal(false)
        }
        if (item?.type == '3') {
            setHorizontal(true)
        }

        if (item?.graph?.length > 0) {
            let graphT = [];

            item?.graph.map((item) => (
                graphT.push(item?.title)
            ))
            setGraphTitle(graphT?.reverse())
            let graphV = [];
            item?.graph.map((item) => (
                graphV.push(item?.value)
            ))
            setGraphValue(graphV?.reverse())
        }
        // setCurruntData(explainData?.loop[index])
    }

    useEffect(() => {

        if (user?.token) {
            getData()
        }
    }, [user])

    const getData = () => {
        let body = {
            token: user?.token,
        }
        let url = '/ratio/liquid';

        get(url, body)
            .then((res) => {
                if (res?.statusCode == 200) {
                    // console.log('ration res', res?.data)
                    if (res?.data) {



                        if (res?.data?.years?.length) {
                            if (years == '') {
                                setYears(res?.data?.years[0])
                            }
                            let yearsArr = res?.data?.years?.reverse();
                            setYearsArr(yearsArr)

                            setAllGraph(res?.data?.allGraprh)



                            setExplainData(res?.data?.loop)
                            let typeG = localStorage.getItem('type');
                            if (res?.data?.loop?.length) {

                                if (typeG) {
                                    setTypeGraph(typeG)

                                } else {
                                    setTypeGraph(res?.data?.loop[0]?.type)
                                }

                                setCurruntData(res?.data?.loop[0])
                            }
                            if (res?.data?.loop?.length > 0) {
                                let graphT = [];

                                res?.data?.loop[0]?.graph.map((item) => (
                                    graphT.push(item?.title)
                                ))
                                setGraphTitle(graphT?.reverse())
                                let graphV = [];
                                res?.data?.loop[0]?.graph.map((item) => (
                                    graphV.push(item?.value)
                                ))
                                setGraphValue(graphV?.reverse())
                            }

                            // rest of your code
                        } else {
                            console.error('Data is undefined in the response');
                        }


                    }
                }
            }).catch((err) => {
                console.log('err', err)
            })
    }

    useEffect(() => {
        // let typeG = localStorage.getItem('type');
        // setTypeGraph(typeG)
    }, [localStorage.getItem('type')])

    useEffect(() => {


        if (allGraph?.length) {
            let temp = [];
            // console.log('ration temp', temp)

            let temp1 = [];
            if (typeGraph == '5') {
                temp.push(allGraph[0]?.title)
                temp.push(allGraph[1]?.title)
                temp.push(allGraph[6]?.title)
                // allGraph?.map((item) => (
                //     temp.push(item?.title)
                // ))
            }

            if (typeGraph == '6') {
                temp.push(allGraph[2]?.title)
                temp.push(allGraph[3]?.title)
                temp.push(allGraph[4]?.title)
                temp.push(allGraph[5]?.title)
                // allGraph?.map((item) => (
                //     temp.push(item?.title)
                // ))
            }
            if (typeGraph == '3') {
                temp.push(allGraph[7]?.title)
                temp.push(allGraph[8]?.title)
                temp.push(allGraph[9]?.title)
                temp.push(allGraph[10]?.title)
                // allGraph?.map((item) => (
                //     temp.push(item?.title)
                // ))
            }
            if (typeGraph == '3') {
                temp1.push(allGraph[7]?.value)
                temp1.push(allGraph[8]?.value)
                temp1.push(allGraph[9]?.value)
                temp1.push(allGraph[10]?.value)
                // res?.data?.allGraprh?.map((item) => (
                //     temp1.push(item?.value)
                // ))
            }
            if (typeGraph == '6') {
                temp1.push(allGraph[2]?.value)
                temp1.push(allGraph[3]?.value)
                temp1.push(allGraph[4]?.value)
                temp1.push(allGraph[5]?.value)
                // res?.data?.allGraprh?.map((item) => (
                //     temp1.push(item?.value)
                // ))
            }
            if (typeGraph == '5') {
                temp1.push(allGraph[0]?.value)
                temp1.push(allGraph[1]?.value)
                temp1.push(allGraph[6]?.value)
                // res?.data?.allGraprh?.map((item) => (
                //     temp1.push(item?.value)
                // ))
            }


            // console.log('ration temp', temp)
            setTitle(temp)
            setValue(temp1)
        }

    }, [allGraph, years, typeGraph])


    const changeYearsData = (item) => {

        setYears(item)
        let body = {
            token: user?.token,
        }
        if (item)
            get(`/ratio/liquid?year=${item}`, body)
                .then((res) => {
                    if (res?.statusCode == 200) {
                        // console.log('ration res', res?.data)
                        if (res?.data) {
                            if (res?.data?.years?.length) {

                                let yearsArr = res?.data?.years?.reverse();
                                setYearsArr(yearsArr)

                                setAllGraph(res?.data?.allGraprh)



                                // rest of your code
                            } else {
                                console.error('Data is undefined in the response');
                            }


                        }
                    }
                }).catch((err) => {
                    console.log('err', err)
                })


    }
    // console.log('useruser', graphTitle, graphValue, curruntData, explainData1)
    console.log('right graph', allGraph)

    return (
        <React.Fragment>
            <MetaTags>
                <title>Profit and Loss account | Demleen </title>
            </MetaTags>
            <Modal size="lg" className='file_upload_popup' isOpen={helpModal} centered>
                <ModalHeader toggle={() => setHelpModal(!helpModal)}>Instruction</ModalHeader>
                <ModalBody>
                    <div className="popup_content">
                        <ul>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {t("RATIO ANALYSIS")}
                            </li>

                        </ul>
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("EmailContact") }}></p>
                    </div>
                </ModalBody>

            </Modal>

            <button className="help_btn" onClick={() => setHelpModal(!helpModal)}><span>i</span> Help</button>
            <Card>
                <CardBody>
                    <h6 className='explain-text'><strong><span>{t("profit_chat_label")} : {years}</span></strong></h6>
                </CardBody>
            </Card>
            <Card className='ratio_section'>
                <CardBody>
                    <div className='top_ratio'>
                        <ul className='ratio_list'>
                            {
                                explainData?.map((item, index) => (
                                    <li className={index == activeTab ? 'active' : null} onClick={() => changeTab(item, index)}>
                                        <h3>{t(item?.title)}</h3>
                                        <h1 style={{ textTransform: "none" }}>{Number(item?.value).toLocaleString()} {t(item?.valueType)}</h1>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </CardBody>
            </Card>
            <Row>
                {
                    explainData1 &&
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p className='explain-text'><span>{t(curruntData?.title)} :</span> {t(explainData1?.desc)}</p>
                            </CardBody>
                        </Card>
                    </Col>
                }
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <h4 className='text-center grap_heading mb-3'>{t(curruntData?.title)}</h4>
                            <div className='year_chart'>
                                {
                                    typeGraph == '5' &&
                                    <>
                                        {
                                            graphTitle && graphValue && <BarApexChart label={explainData1?.label} horizontal={horizontal} title={graphTitle} value={graphValue} />
                                        }
                                    </>
                                }
                                {
                                    typeGraph == '3' &&
                                    <>
                                        {
                                            graphTitle && graphValue && <BarApexChart label={explainData1?.label} horizontal={horizontal} title={graphTitle} value={graphValue} />
                                        }
                                    </>
                                }

                                {
                                    typeGraph == '6' &&
                                    <div className='right_graph6'>
                                        {
                                            graphTitle && graphValue && <BalancepieChart title={graphTitle} value={graphValue} />
                                        }
                                    </div>
                                }


                            </div>
                        </CardBody>
                    </Card>

                </Col>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <h4 className='text-center mb-3 grap_heading'>{t("Liqudity Ratios")}</h4>
                            <div className='ratio_year_tabs'>
                                <ul>
                                    {
                                        yearsArr?.map((item, index) => (
                                            <li>
                                                <button onClick={() => changeYearsData(item)} className={item == years ? 'active' : null}>{item}</button>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>

                            <div className='year_chart mt-3'>
                                {
                                    typeGraph == '5' &&
                                    <>
                                        {
                                            title ? <IncomestatementanalysisChart title={title?.map(element => t(element))} value={value} /> : null
                                        }
                                    </>
                                }
                                {
                                    typeGraph == '3' &&
                                    <>
                                        {
                                            title ? <GraphThree title={title?.map(element => t(element))} value={value} /> : null
                                        }
                                    </>
                                }
                                {
                                    typeGraph == '6' &&
                                    <div className='right_graph6'>
                                        {
                                            title ? <BalancepieChart1 title={title?.map(element => t(element))} value={value} /> : null
                                        }
                                    </div>
                                }

                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}

BalanceSheetAnalysis.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag, setBreadcrumbItems })(BalanceSheetAnalysis)
)
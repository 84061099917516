import { useTranslation } from 'react-i18next';
export const projectName = "Demleen";
export const themeColor = "#012E67";
// export const API_URL = "http://192.168.1.148:3000/v1-solution";
// export const API_URL = "http://87.106.121.220";
export const API_URL = "https://demleen.com/v1-solution";
export const getBusinessSector = () => {
    const { t } = useTranslation();
    return [
        { label: t("Agriculture, Plantations, Other rural sectors"), value: "Agriculture, Plantations, Other rural sectors" },
        { label: t("Basic Metal Production"), value: "Basic Metal Production" },
        { label: t("Business & Professional services"), value: "Business & Professional services" },
        { label: t("Chemical industries"), value: "Chemical industries" },
        { label: t("Construction, Building Materials"), value: "Construction, Building Materials" },
        { label: t("Education"), value: "Education" },
        { label: t("Financial services"), value: "Financial services" },
        { label: t("Food and drink industries"), value: "Food and drink industries" },
        { label: t("Forestry, Wood, Pulp and paper"), value: "Forestry, Wood, Pulp and paper" },
        { label: t("Health services"), value: "Health services" },
        { label: t("Hotels, Tourism, Catering"), value: "Hotels, Tourism, Catering" },
        { label: t("IT, Telecommunications services"), value: "IT, Telecommunications services" },
        { label: t("Mechanical and electrical engineering"), value: "Mechanical and electrical engineering" },
        { label: t("Mining (coal, other mining)"), value: "Mining (coal, other mining)" },
        { label: t("Oil and gas production, Oil refining"), value: "Oil and gas production, Oil refining" },
        { label: t("Pharmaceuticals"), value: "Pharmaceuticals" },
        { label: t("Public services"), value: "Public services" },
        { label: t("Publishing, Communication, Multimedia"), value: "Publishing, Communication, Multimedia" },
        { label: t("Technology"), value: "Technology" },
        { label: t("Textile, Clothing,Footwear"), value: "Textile, Clothing,Footwear" },
        { label: t("Trade, Trading, Distribution"), value: "Trade, Trading, Distribution" },
        { label: t("Transport, Logistics,Mobility"), value: "Transport, Logistics,Mobility" },
        { label: t("Utilities (water, gas, electricity)"), value: "Utilities (water, gas, electricity)" },
    ]
}
export const getRegions = () => {
    const { t } = useTranslation();
    return [
        { label: t("Western Africa"), value: "Western Africa" },
        { label: t("Eastern Africa"), value: "Eastern Africa" },
        { label: t("Northern Africa"), value: "Northern Africa" },
        { label: t("Southern Africa"), value: "Southern Africa" },
        { label: t("Central Africa"), value: "Central Africa" },
    ]
}
export const getSDG = () => {
    const { t } = useTranslation();
    return [

        { label: t("SDG 1 - No poverty"), value: "SDG 1 - No poverty" },
        { label: t("SDG 2 - Zero hunger"), value: "SDG 2 - Zero hunger" },
        { label: t("SDG 3 - Good health and well-being"), value: "SDG 3 - Good health and well-being" },
        { label: t("SDG 4 - Quality education"), value: "SDG 4 - Quality education" },
        { label: t("SDG 5 - Gender equality"), value: "SDG 5 - Gender equality" },
        { label: t("SDG 6 - Clean water and sanitation"), value: "SDG 6 - Clean water and sanitation" },
        { label: t("SDG 7 - Affordable and clean energy"), value: "SDG 7 - Affordable and clean energy" },
        { label: t("SDG 8 - Decent work and economic growth"), value: "SDG 8 - Decent work and economic growth" },
        { label: t("SDG 9 - Industry innovation and infrastructure"), value: "SDG 9 - Industry innovation and infrastructure" },
        { label: t("SDG 10 - Reduced inequalities"), value: "SDG 10 - Reduced inequalities" },
        { label: t("SDG 11 - Sustainable cities and communities"), value: "SDG 11 - Sustainable cities and communities" },
        { label: t("SDG 12 - Responsible consumption and production"), value: "SDG 12 - Responsible consumption and production" },
        { label: t("SDG 13 - Climate action"), value: "SDG 13 - Climate action" },
        { label: t("SDG 14 - Life below water"), value: "SDG 14 - Life below water" },
        { label: t("SDG 15 - Life on land"), value: "SDG 15 - Life on land" },
        { label: t("SDG 16 - Peace, justice and strong institutions"), value: "SDG 16 - Peace, justice and strong institutions" },
        { label: t("SDG 17 - Partnership for the goals"), value: "SDG 17 - Partnership for the goals" },
    ];
}
export const getYears = () => {
    return [
        { label: "2017", value: "2017" },
        { label: "2018", value: "2018" },
        { label: "2019", value: "2019" },
        { label: "2020", value: "2020" },
        { label: "2021", value: "2021" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },
        { label: "2024", value: "2024" }
    ];
}
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Table,
  CardTitle
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import { post, put } from 'helpers/helper_api';
import Loader from 'components/Loader';
import RangeSlider from 'react-bootstrap-range-slider';
import { toast } from 'react-toastify';
import moment from 'moment';

const ProfitandLoss = (props) => {
  const { user } = props;
  console.log(user)
  const [loading, setLoading] = useState(false);
  const [year1, setYear1] = useState(undefined);
  const [year2, setYear2] = useState(undefined);
  const [year3, setYear3] = useState(undefined);
  const [value, setValue] = useState(0);
  const [data, setData] = useState(null);
  const getUserData = (year, setter) => {
    if (!loading) {
      setLoading(true);
      let body = {
        year,
        userId: props?.userId,
        token: user?.token
      }
      post('/profit_loss', body)
        .then((res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            // console.log('request on login', res);
            setter(res?.data);
          } else {
            setter(undefined);
            console.log(res?.error);
          }
        }))
        .catch(error => {
          setter(undefined);
          setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
        });


    }
  }
  useEffect(() => {
    if (props?.user?.token)
      getUserData1();
  }, [props?.user?.token])

  const getUserData1 = () => {
    if (!loading) {
      setLoading(true)
      let body = {
        userId: props?.userId,
        token: props?.user?.token,

      }
      post('/user', body)
        .then((res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            // console.log('request on login', res);

            setData(res?.data)
            setValue(res?.data?.profRating?.rating)
          } else {
            console.log(res?.error);
          }
        }))
        .catch(error => {
          setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
        })
    }
  }

  const { t } = useTranslation();
 
  

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody >
              {/* <CardTitle className="h4 mb-3">Stripped example </CardTitle> */}
              {/*                   
                  <BootstrapTable
                  keyField="id"
                  data={data}
                  columns={columns}
                  expandRow={ expandRow }
                  
                /> */}
              <div className=''>
                <table class="table normel_tabkle table-bordered" >
                  <thead style={{ backgroundColor: '#1b005e', color: '#fff' }}>
                    <tr className='col_header'>
                      <th scope="col">{t("Years")}</th>
                      <th style={{ width: '16.66666666666667%' }}>
                        <AvForm>
                          <AvField type="select" name="year_one" onChange={(e) => getUserData(e.target.value, setYear1)} >
                            <option value="">{t("Select Year")}</option>
                            <option>2017</option>
                            <option>2018</option>
                            <option>2019</option>
                            <option>2020</option>
                            <option>2021</option>
                            <option>2022</option>
                          </AvField>
                        </AvForm>
                      </th>
                      <th style={{ width: '16.66666666666667%' }}>
                        <AvForm>
                          <AvField type="select" name="year_two" onChange={(e) => getUserData(e.target.value, setYear2)} >
                            <option value="">{t("Select Year")}</option>
                            <option>2017</option>
                            <option>2018</option>
                            <option>2019</option>
                            <option>2020</option>
                            <option>2021</option>
                            <option>2022</option>
                          </AvField>
                        </AvForm>
                      </th>
                      <th style={{ width: '16.66666666666667%' }}>
                        <AvForm>
                          <AvField type="select" name="year_three" onChange={(e) => getUserData(e.target.value, setYear3)} >
                            <option value="">{t("Select Year")}</option>
                            <option>2017</option>
                            <option>2018</option>
                            <option>2019</option>
                            <option>2020</option>
                            <option>2021</option>
                            <option>2022</option>
                          </AvField>
                        </AvForm>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("Sales of goods for resale")}</td>
                      <td className='text_right'>{year1?.TA}</td>
                      <td className='text_right'>{year2?.TA}</td>
                      <td className='text_right'>{year3?.TA}</td>
                    </tr>
                    <tr>
                      <td>{t("Purchases of goods for resale")}</td>
                      <td className='text_right'>{year1?.RA}</td>
                      <td className='text_right'>{year2?.RA}</td>
                      <td className='text_right'>{year3?.RA}</td>
                    </tr>
                    <tr>
                      <td>{t("Changes in inventory of goods for resale")}</td>
                      <td className='text_right'>{year1?.RB}</td>
                      <td className='text_right'>{year2?.RB}</td>
                      <td className='text_right'>{year3?.RB}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("GROSS MARGIN")}</th>
                      <th className='text_right' scope="col">{year1?.XA}</th>
                      <th className='text_right' scope="col">{year2?.XA}</th>
                      <th className='text_right' scope="col">{year3?.XA}</th>
                    </tr>
                    <tr>
                      <td>{t("Sales of manuf. goods, services, goods for resale")}</td>
                      <td className='text_right'>{year1?.TB}</td>
                      <td className='text_right'>{year2?.TB}</td>
                      <td className='text_right'>{year3?.TB}</td>
                    </tr>
                    <tr>
                      <td>{t("Works & Services")}</td>
                      <td className='text_right'>{year1?.TC}</td>
                      <td className='text_right'>{year2?.TC}</td>
                      <td className='text_right'>{year3?.TC}</td>
                    </tr>
                    <tr>
                      <td>{t("Ancillary income")}</td>
                      <td className='text_right'>{year1?.TD}</td>
                      <td className='text_right'>{year2?.TD}</td>
                      <td className='text_right'>{year3?.TD}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("TURNOVER")}</th>
                      <th className='text_right' scope="col">{year1?.XB}</th>
                      <th className='text_right' scope="col">{year2?.XB}</th>
                      <th className='text_right' scope="col">{year3?.XB}</th>
                    </tr>
                    <tr>
                      <td>{t("Changes in inventory of finished goods, work in progress")}</td>
                      <td className='text_right'>{year1?.TE}</td>
                      <td className='text_right'>{year2?.TE}</td>
                      <td className='text_right'>{year3?.TE}</td>
                    </tr>
                    <tr>
                      <td>{t("Own work capitalized")}</td>
                      <td className='text_right'>{year1?.TF}</td>
                      <td className='text_right'>{year2?.TF}</td>
                      <td className='text_right'>{year3?.TF}</td>
                    </tr>
                    <tr>
                      <td>{t("Operating grants")}</td>
                      <td className='text_right'>{year1?.TG}</td>
                      <td className='text_right'>{year2?.TG}</td>
                      <td className='text_right'>{year3?.TG}</td>
                    </tr>
                    <tr>
                      <td>{t("Other operating income")}</td>
                      <td className='text_right'>{year1?.TH}</td>
                      <td className='text_right'>{year2?.TH}</td>
                      <td className='text_right'>{year3?.TH}</td>
                    </tr>
                    <tr>
                      <td>{t("Transfers of operating expenses")}</td>
                      <td className='text_right'>{year1?.TI}</td>
                      <td className='text_right'>{year2?.TI}</td>
                      <td className='text_right'>{year3?.TI}</td>
                    </tr>
                    <tr>
                      <td>{t("Inventory purchases - Raw materials (and supplies)")}</td>
                      <td className='text_right'>{year1?.RC}</td>
                      <td className='text_right'>{year2?.RC}</td>
                      <td className='text_right'>{year3?.RC}</td>
                    </tr>
                    <tr>
                      <td>{t("Changes in inventory of raw materials (and supplies)")}</td>
                      <td className='text_right'>{year1?.RD}</td>
                      <td className='text_right'>{year2?.RD}</td>
                      <td className='text_right'>{year3?.RD}</td>
                    </tr>
                    <tr>
                      <td>{t("Inventory purchases - Other consumables")}</td>
                      <td className='text_right'>{year1?.RE}</td>
                      <td className='text_right'>{year2?.RE}</td>
                      <td className='text_right'>{year3?.RE}</td>
                    </tr>
                    <tr>
                      <td>{t("Changes in inventory of other consumables")}</td>
                      <td className='text_right'>{year1?.RF}</td>
                      <td className='text_right'>{year2?.RF}</td>
                      <td className='text_right'>{year3?.RF}</td>
                    </tr>
                    <tr>
                      <td>{t("Transport of goods and collective transport of employees")}</td>
                      <td className='text_right'>{year1?.RG}</td>
                      <td className='text_right'>{year2?.RG}</td>
                      <td className='text_right'>{year3?.RG}</td>
                    </tr>
                    <tr>
                      <td>{t("External Services")}</td>
                      <td className='text_right'>{year1?.RH}</td>
                      <td className='text_right'>{year2?.RH}</td>
                      <td className='text_right'>{year3?.RH}</td>
                    </tr>
                    <tr>
                      <td>{t("Taxes and duties")}</td>
                      <td className='text_right'>{year1?.RI}</td>
                      <td className='text_right'>{year2?.RI}</td>
                      <td className='text_right'>{year3?.RI}</td>
                    </tr>
                    <tr>
                      <td>{t("Other external expenses")}</td>
                      <td className='text_right'>{year1?.RJ}</td>
                      <td className='text_right'>{year2?.RJ}</td>
                      <td className='text_right'>{year3?.RJ}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("VALUE ADDED")}</th>
                      <th className='text_right' scope="col">{year1?.XC}</th>
                      <th className='text_right' scope="col">{year2?.XC}</th>
                      <th className='text_right' scope="col">{year3?.XC}</th>
                    </tr>
                    <tr>
                      <td>{t("Salaries and Payroll Expenses")}</td>
                      <td className='text_right'>{year1?.RK}</td>
                      <td className='text_right'>{year2?.RK}</td>
                      <td className='text_right'>{year3?.RK}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("GROSS OPERATING PROFIT BEFORE DEPRECIATION & AMORTIZATION")}</th>
                      <th className='text_right' scope="col">{year1?.XD}</th>
                      <th className='text_right' scope="col">{year2?.XD}</th>
                      <th className='text_right' scope="col">{year3?.XD}</th>
                    </tr>
                    <tr>
                      <td>{t("Reversal of depreciation, amortization, impairment losses and provisions (Operating income)")}</td>
                      <td className='text_right'>{year1?.TJ}</td>
                      <td className='text_right'>{year2?.TJ}</td>
                      <td className='text_right'>{year3?.TJ}</td>
                    </tr>
                    <tr>
                      <td>{t("Depreciation, amortization, impairment and provisions - Operating expenses")}</td>
                      <td className='text_right'>{year1?.RL}</td>
                      <td className='text_right'>{year2?.RL}</td>
                      <td className='text_right'>{year3?.RL}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("TOTAL OPERATING PROFIT")}</th>
                      <th className='text_right' scope="col">{year1?.XE}</th>
                      <th className='text_right' scope="col">{year2?.XE}</th>
                      <th className='text_right' scope="col">{year3?.XE}</th>
                    </tr>
                    <tr>
                      <td>{t("Financial Income")}</td>
                      <td className='text_right'>{year1?.TK}</td>
                      <td className='text_right'>{year2?.TK}</td>
                      <td className='text_right'>{year3?.TK}</td>
                    </tr>
                    <tr>
                      <td>{t("Reversal of provisions for liabilities and impairment losses (Financial income)")}</td>
                      <td className='text_right'>{year1?.TL}</td>
                      <td className='text_right'>{year2?.TL}</td>
                      <td className='text_right'>{year3?.TL}</td>
                    </tr>
                    <tr>
                      <td>{t("Transfers of financial expenses")}</td>
                      <td className='text_right'>{year1?.TM}</td>
                      <td className='text_right'>{year2?.TM}</td>
                      <td className='text_right'>{year3?.TM}</td>
                    </tr>
                    <tr>
                      <td>{t("Financial Expenses")}</td>
                      <td className='text_right'>{year1?.RM}</td>
                      <td className='text_right'>{year2?.RM}</td>
                      <td className='text_right'>{year3?.RM}</td>
                    </tr>
                    <tr>
                      <td>{t("Amortization, impairment and provisions - Financial expenses")}</td>
                      <td className='text_right'>{year1?.RN}</td>
                      <td className='text_right'>{year2?.RN}</td>
                      <td className='text_right'>{year3?.RN}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("TOTAL FINANCIAL INCOME")}</th>
                      <th className='text_right' scope="col">{year1?.XF}</th>
                      <th className='text_right' scope="col">{year2?.XF}</th>
                      <th className='text_right' scope="col">{year3?.XF}</th>
                    </tr>
                    <tr>
                      <td>{t("0 Pre-tax profit or loss before exceptional items")}</td>
                      <td className='text_right'>{year1?.XG}</td>
                      <td className='text_right'>{year2?.XG}</td>
                      <td className='text_right'>{year3?.XG}</td>
                    </tr>
                    <tr>
                      <td>{t("Income from sale of assets")}</td>
                      <td className='text_right'>{year1?.TN}</td>
                      <td className='text_right'>{year2?.TN}</td>
                      <td className='text_right'>{year3?.TN}</td>
                    </tr>
                    <tr>
                      <td>{t("Other exceptional income")}</td>
                      <td className='text_right'>{year1?.TO}</td>
                      <td className='text_right'>{year2?.TO}</td>
                      <td className='text_right'>{year3?.TO}</td>
                    </tr>
                    <tr>
                      <td>{t("Book value of assets sold")}</td>
                      <td className='text_right'>{year1?.RO}</td>
                      <td className='text_right'>{year2?.RO}</td>
                      <td className='text_right'>{year3?.RO}</td>
                    </tr>
                    <tr>
                      <td>{t("Other exceptional expenses")}</td>
                      <td className='text_right'>{year1?.RP}</td>
                      <td className='text_right'>{year2?.RP}</td>
                      <td className='text_right'>{year3?.RP}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("RESULTAT HORS ACTIVITES ORDINAIRES")}</th>
                      <th className='text_right' scope="col">{year1?.XH}</th>
                      <th className='text_right' scope="col">{year2?.XH}</th>
                      <th className='text_right' scope="col">{year3?.XH}</th>
                    </tr>
                    <tr>
                      <td>{t("Employee profit-sharing")}</td>
                      <td className='text_right'>{year1?.RQ}</td>
                      <td className='text_right'>{year2?.RQ}</td>
                      <td className='text_right'>{year3?.RQ}</td>
                    </tr>
                    <tr>
                      <td>{t("Income taxes")}</td>
                      <td className='text_right'>{year1?.RS}</td>
                      <td className='text_right'>{year2?.RS}</td>
                      <td className='text_right'>{year3?.RS}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("PROFIT AND LOSS")}</th>
                      <th className='text_right' scope="col">{year1?.XI}</th>
                      <th className='text_right' scope="col">{year2?.XI}</th>
                      <th className='text_right' scope="col">{year3?.XI}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>

        </Col>
      </Row>

    </React.Fragment>
  )
}



export default ProfitandLoss;
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import * as Utils from "../Utils";
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { withRouter, useLocation } from "react-router-dom"
import { setBreadcrumbItems, profileSuccess } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import ExcelFile from '../../assets/excel/sample.xlsx'
import { FileUploader } from "react-drag-drop-files";
import { upload, post, put, uploadWithTokan } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import MyPrompt from 'components/MyPrompt';
import DeleteModal from '../Admin/DeleteModal';
import Loader from 'components/Loader';

const Index = props => {
  const location = useLocation();
  const fileTypes = ["xlsx"];
  const { i18n, t } = useTranslation();
  const years = Utils.getYears();
  const [grossMargin1, setGrossMargin1] = useState(0);
  const [grossMargin2, setGrossMargin2] = useState(0);
  const [grossMargin3, setGrossMargin3] = useState(0);
  const [turnover1, setTurnover1] = useState(0);
  const [turnover2, setTurnover2] = useState(0);
  const [turnover3, setTurnover3] = useState(0);
  const [addedValue1, setAddedValue1] = useState(0);
  const [addedValue2, setAddedValue2] = useState(0);
  const [addedValue3, setAddedValue3] = useState(0);
  const [grossOperating1, setGrossOperating1] = useState(0);
  const [grossOperating2, setGrossOperating2] = useState(0);
  const [grossOperating3, setGrossOperating3] = useState(0);
  const [totalOperating1, setTotalOperating1] = useState(0);
  const [totalOperating2, setTotalOperating2] = useState(0);
  const [totalOperating3, setTotalOperating3] = useState(0);
  const [totalFinancial1, setTotalFinancial1] = useState(0);
  const [totalFinancial2, setTotalFinancial2] = useState(0);
  const [totalFinancial3, setTotalFinancial3] = useState(0);
  const [pretaxprofit1, setPretaxprofit1] = useState(0);
  const [pretaxprofit2, setPretaxprofit2] = useState(0);
  const [pretaxprofit3, setPretaxprofit3] = useState(0);
  const [exceptionalProfit1, setExceptionalProfit1] = useState(0);
  const [exceptionalProfit2, setExceptionalProfit2] = useState(0);
  const [exceptionalProfit3, setExceptionalProfit3] = useState(0);
  const [profitorLoss1, setProfitorLoss1] = useState(0);
  const [profitorLoss2, setProfitorLoss2] = useState(0);
  const [profitorLoss3, setProfitorLoss3] = useState(0);
  const [excelUploadModal, setExcelUploadModal] = useState(false);
  const [file, setFile] = useState(null);
  const [allFieldsNotEmpty, setAllFieldsNotEmpty] = useState(false)
  const [helpModal, setHelpModal] = useState(false);
  const [formValue, setFormValue] = useState();
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState();
  const [currentData, setCurrentData] = useState(null);
  const [type, setType] = useState('save');
  const handleChange = (file) => {
    setFile(file);
  };
  const toggle = () => setExcelUploadModal(!excelUploadModal);
  const formRef = useRef();

  const { user } = props;

  const [year1, setYear1] = useState(undefined);
  const [year2, setYear2] = useState(undefined);
  const [year3, setYear3] = useState(undefined);


  const dataReset = {
    "TA": 0,
    "RA": 0,
    "RB": 0,
    "XA": 0,
    "TB": 0,
    "TC": 0,
    "TD": 0,
    "XB": 0,
    "TE": 0,
    "TF": 0,
    "TG": 0,
    "TH": 0,
    "TI": 0,
    "RC": 0,
    "RD": 0,
    "RE": 0,
    "RF": 0,
    "RG": 0,
    "RH": 0,
    "RI": 0,
    "RJ": 0,
    "XC": 0,
    "RK": 0,
    "XD": 0,
    "TJ": 0,
    "RL": 0,
    "XE": 0,
    "TK": 0,
    "TL": 0,
    "TM": 0,
    "RM": 0,
    "RN": 0,
    "XF": 0,
    "XG": 0,
    "TN": 0,
    "TO": 0,
    "RO": 0,
    "RP": 0,
    "XH": 0,
    "RQ": 0,
    "RS": 0,
    "XI": 0,
  }

  useEffect(() => {
    turnoverFun();
    grossMarginFun();
    addedValueFun();
    grossOperatingFun();
    totalOperatingFun();
    totalFinancialFun();
    pretaxprofitFun();
    exceptionalProfitFun();
    profitorLossFun();
  }, [year1, year2, year3]);

  const getUserData = (year, setter) => {
    if (!loading) {
      setLoading(true);
      setter(dataReset)
      let body = {
        year,
        userId: user?._id,
        token: user?.token
      }
      post('/profit_loss', body)
        .then((res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            console.log('res for profit', location?.state?.id, res);
            // if (location?.state?.id)
            setter(res?.data);
            // else {
            //   setCurrentData({ actionType: 'Confirm', confirmMsg: 'You have already submitted data for ' + year + 'Try to edit it!' })
            //   setDeleteModal(true)

            // }

          } else {
            setter(dataReset);
            console.log(res?.error);
            console.log('404', res?.data)
          }
        }))
        .catch(error => {
          setter(dataReset);
          setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');

        });
    }
  }

  // console.log('year1', year1, turnover1, typeof turnover1, Number(turnover1));

  useEffect(() => {
    // const isEmpty = Object.values(form).some((field) => field?.answer === 0 || field?.answer == '');


    // if (isEmpty) {
    //    setButtonStatus(false)
    // } else {
    //     setButtonStatus(true)
    // }
    const form = document.getElementById('myForm'); // Replace 'myForm' with your form's ID
    const formElements = form.getElementsByTagName('input');
    let allFieldsNotEmpty1 = true;
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      console.log('element', element.value)
      if (element.tagName === 'INPUT' && element.value === '') {
        allFieldsNotEmpty1 = false;
        break;
      }
    }
    setAllFieldsNotEmpty(allFieldsNotEmpty1)

  }, [formValue])

  // console.log('formValue', location)
  const onChangeSetValue = (val) => {

    turnoverFun();
    grossMarginFun();
    addedValueFun();
    grossOperatingFun();
    totalOperatingFun();
    totalFinancialFun();
    pretaxprofitFun();
    exceptionalProfitFun();
    profitorLossFun();
    setFormValue(val)

  }

  const grossMarginFun = () => {
    let grosstotal1 = 0;
    grosstotal1 += Math.abs(Number(formRef.current?._inputs?.TA1?.value));
    grosstotal1 = grosstotal1 - Math.abs(Number(formRef.current?._inputs?.RA1?.value));
    grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.RB1?.value);
    let grosstotal2 = Math.abs(Number(formRef.current?._inputs?.TA2?.value));
    grosstotal2 = grosstotal2 - Math.abs(Number(formRef.current?._inputs?.RA2?.value));
    grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.RB2?.value);
    let grosstotal3 = Math.abs(Number(formRef.current?._inputs?.TA3?.value));
    grosstotal3 = grosstotal3 - Math.abs(Number(formRef.current?._inputs?.RA3?.value));
    grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.RB3?.value);
    setGrossMargin1(grosstotal1)
    setGrossMargin2(grosstotal2)
    setGrossMargin3(grosstotal3)
  }

  const turnoverFun = (val) => {
    // console.log('form', formRef.current?._inputs?.TB?.value);
    let turnoverTotal1 = Math.abs(Number(formRef.current?._inputs?.TB1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TC1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TD1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TA1?.value));
    let turnoverTotal2 = Math.abs(Number(formRef.current?._inputs?.TB2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TC2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TD2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TA2?.value));
    let turnoverTotal3 = Math.abs(Number(formRef.current?._inputs?.TB3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TC3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TD3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TA3?.value));
    setTurnover1(turnoverTotal1);
    setTurnover2(turnoverTotal2);
    setTurnover3(turnoverTotal3);
  }

  const addedValueFun = (val) => {

    let turnoverTotal1 = Math.abs(Number(formRef.current?._inputs?.TB1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TC1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TD1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TA1?.value));
    let turnoverTotal2 = Math.abs(Number(formRef.current?._inputs?.TB2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TC2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TD2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TA2?.value));
    let turnoverTotal3 = Math.abs(Number(formRef.current?._inputs?.TB3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TC3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TD3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TA3?.value));

    let addedValuetotal1 = turnoverTotal1 - Math.abs(Number(formRef.current?._inputs?.RA1?.value)) + Number(formRef.current?._inputs?.RB1?.value) + Number(formRef.current?._inputs?.TE1?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF1?.value)) + Math.abs(Number(formRef.current?._inputs?.TG1?.value)) + Math.abs(Number(formRef.current?._inputs?.TH1?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI1?.value)) - Math.abs(Number(formRef.current?._inputs?.RC1?.value)) + Number(formRef.current?._inputs?.RD1?.value) - Math.abs(Number(formRef.current?._inputs?.RE1?.value)) +
      Number(formRef.current?._inputs?.RF1?.value) - Math.abs(Number(formRef.current?._inputs?.RG1?.value)) - Math.abs(Number(formRef.current?._inputs?.RH1.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI1?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ1?.value));
    let addedValuetotal2 = turnoverTotal2 - Math.abs(Number(formRef.current?._inputs?.RA2?.value)) + Number(formRef.current?._inputs?.RB2?.value) + Number(formRef.current?._inputs?.TE2?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF2?.value)) + Math.abs(Number(formRef.current?._inputs?.TG2?.value)) + Math.abs(Number(formRef.current?._inputs?.TH2?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI2?.value)) - Math.abs(Number(formRef.current?._inputs?.RC2?.value)) + Number(formRef.current?._inputs?.RD2?.value) - Math.abs(Number(formRef.current?._inputs?.RE2?.value)) +
      Math.abs(Number(formRef.current?._inputs?.RF2?.value)) - Math.abs(Number(formRef.current?._inputs?.RG2?.value)) - Math.abs(Number(formRef.current?._inputs?.RH2.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI2?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ2?.value));
    let addedValuetotal3 = turnoverTotal3 - Math.abs(Number(formRef.current?._inputs?.RA3?.value)) + Number(formRef.current?._inputs?.RB3?.value) + Number(formRef.current?._inputs?.TE3?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF3?.value)) + Math.abs(Number(formRef.current?._inputs?.TG3?.value)) + Math.abs(Number(formRef.current?._inputs?.TH3?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI3?.value)) - Math.abs(Number(formRef.current?._inputs?.RC3?.value)) + Number(formRef.current?._inputs?.RD3?.value) - Math.abs(Number(formRef.current?._inputs?.RE3?.value)) +
      Number(formRef.current?._inputs?.RF3?.value) - Math.abs(Number(formRef.current?._inputs?.RG3?.value)) - Math.abs(Number(formRef.current?._inputs?.RH3?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI3?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ3?.value));
    setAddedValue1(addedValuetotal1);
    setAddedValue2(addedValuetotal2);
    setAddedValue3(addedValuetotal3);
  }
  const grossOperatingFun = () => {
    // let turnoverTotal = Number(formRef.current?._inputs?.TB?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TC?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TD?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TA?.value);

    // let addedValuetotal = turnoverTotal - Number(formRef.current?._inputs?.RA?.value) - Number(formRef.current?._inputs?.RB?.value) + Number(formRef.current?._inputs?.TE?.value) +
    //   Number(formRef.current?._inputs?.TF?.value) + Number(formRef.current?._inputs?.TG?.value) + Number(formRef.current?._inputs?.TH?.value) +
    //   Number(formRef.current?._inputs?.TI?.value) - Number(formRef.current?._inputs?.RC?.value) - Number(formRef.current?._inputs?.RD?.value) - Number(formRef.current?._inputs?.RE?.value) -
    //   Number(formRef.current?._inputs?.RF?.value) - Number(formRef.current?._inputs?.RG?.value) - Number(formRef.current?._inputs?.RH.value) -
    //   Number(formRef.current?._inputs?.RI?.value) - Number(formRef.current?._inputs?.RJ?.value);
    let turnoverTotal1 = Math.abs(Number(formRef.current?._inputs?.TB1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TC1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TD1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TA1?.value));
    let turnoverTotal2 = Math.abs(Number(formRef.current?._inputs?.TB2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TC2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TD2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TA2?.value));
    let turnoverTotal3 = Math.abs(Number(formRef.current?._inputs?.TB3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TC3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TD3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TA3?.value));

    let addedValuetotal1 = turnoverTotal1 - Math.abs(Number(formRef.current?._inputs?.RA1?.value)) + Number(formRef.current?._inputs?.RB1?.value) + Number(formRef.current?._inputs?.TE1?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF1?.value)) + Math.abs(Number(formRef.current?._inputs?.TG1?.value)) + Math.abs(Number(formRef.current?._inputs?.TH1?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI1?.value)) - Math.abs(Number(formRef.current?._inputs?.RC1?.value)) + Number(formRef.current?._inputs?.RD1?.value) - Math.abs(Number(formRef.current?._inputs?.RE1?.value)) +
      Number(formRef.current?._inputs?.RF1?.value) - Math.abs(Number(formRef.current?._inputs?.RG1?.value)) - Math.abs(Number(formRef.current?._inputs?.RH1.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI1?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ1?.value));
    let addedValuetotal2 = turnoverTotal2 - Math.abs(Number(formRef.current?._inputs?.RA2?.value)) + Number(formRef.current?._inputs?.RB2?.value) + Number(formRef.current?._inputs?.TE2?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF2?.value)) + Math.abs(Number(formRef.current?._inputs?.TG2?.value)) + Math.abs(Number(formRef.current?._inputs?.TH2?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI2?.value)) - Math.abs(Number(formRef.current?._inputs?.RC2?.value)) + Number(formRef.current?._inputs?.RD2?.value) - Math.abs(Number(formRef.current?._inputs?.RE2?.value)) +
      Math.abs(Number(formRef.current?._inputs?.RF2?.value)) - Math.abs(Number(formRef.current?._inputs?.RG2?.value)) - Math.abs(Number(formRef.current?._inputs?.RH2.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI2?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ2?.value));
    let addedValuetotal3 = turnoverTotal3 - Math.abs(Number(formRef.current?._inputs?.RA3?.value)) + Number(formRef.current?._inputs?.RB3?.value) + Number(formRef.current?._inputs?.TE3?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF3?.value)) + Math.abs(Number(formRef.current?._inputs?.TG3?.value)) + Math.abs(Number(formRef.current?._inputs?.TH3?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI3?.value)) - Math.abs(Number(formRef.current?._inputs?.RC3?.value)) + Number(formRef.current?._inputs?.RD3?.value) - Math.abs(Number(formRef.current?._inputs?.RE3?.value)) +
      Number(formRef.current?._inputs?.RF3?.value) - Math.abs(Number(formRef.current?._inputs?.RG3?.value)) - Math.abs(Number(formRef.current?._inputs?.RH3?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI3?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ3?.value));
    let grossOperatingTotal1 = addedValuetotal1 - Math.abs(Number(formRef.current?._inputs?.RK1?.value));
    let grossOperatingTotal2 = addedValuetotal2 - Math.abs(Number(formRef.current?._inputs?.RK2?.value));
    let grossOperatingTotal3 = addedValuetotal3 - Math.abs(Number(formRef.current?._inputs?.RK3?.value));
    setGrossOperating1(grossOperatingTotal1)
    setGrossOperating2(grossOperatingTotal2)
    setGrossOperating3(grossOperatingTotal3)
  }

  const totalOperatingFun = () => {
    // let turnoverTotal = Number(formRef.current?._inputs?.TB?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TC?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TD?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TA?.value);

    // let addedValuetotal = turnoverTotal - Number(formRef.current?._inputs?.RA?.value) - Number(formRef.current?._inputs?.RB?.value) + Number(formRef.current?._inputs?.TE?.value) +
    //   Number(formRef.current?._inputs?.TF?.value) + Number(formRef.current?._inputs?.TG?.value) + Number(formRef.current?._inputs?.TH?.value) +
    //   Number(formRef.current?._inputs?.TI?.value) - Number(formRef.current?._inputs?.RC?.value) - Number(formRef.current?._inputs?.RD?.value) - Number(formRef.current?._inputs?.RE?.value) -
    //   Number(formRef.current?._inputs?.RF?.value) - Number(formRef.current?._inputs?.RG?.value) - Number(formRef.current?._inputs?.RH.value) -
    //   Number(formRef.current?._inputs?.RI?.value) - Number(formRef.current?._inputs?.RJ?.value);
    let turnoverTotal1 = Math.abs(Number(formRef.current?._inputs?.TB1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TC1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TD1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TA1?.value));
    let turnoverTotal2 = Math.abs(Number(formRef.current?._inputs?.TB2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TC2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TD2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TA2?.value));
    let turnoverTotal3 = Math.abs(Number(formRef.current?._inputs?.TB3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TC3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TD3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TA3?.value));

    let addedValuetotal1 = turnoverTotal1 - Math.abs(Number(formRef.current?._inputs?.RA1?.value)) + Number(formRef.current?._inputs?.RB1?.value) + Number(formRef.current?._inputs?.TE1?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF1?.value)) + Math.abs(Number(formRef.current?._inputs?.TG1?.value)) + Math.abs(Number(formRef.current?._inputs?.TH1?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI1?.value)) - Math.abs(Number(formRef.current?._inputs?.RC1?.value)) + Number(formRef.current?._inputs?.RD1?.value) - Math.abs(Number(formRef.current?._inputs?.RE1?.value)) +
      Number(formRef.current?._inputs?.RF1?.value) - Math.abs(Number(formRef.current?._inputs?.RG1?.value)) - Math.abs(Number(formRef.current?._inputs?.RH1.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI1?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ1?.value));
    let addedValuetotal2 = turnoverTotal2 - Math.abs(Number(formRef.current?._inputs?.RA2?.value)) + Number(formRef.current?._inputs?.RB2?.value) + Number(formRef.current?._inputs?.TE2?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF2?.value)) + Math.abs(Number(formRef.current?._inputs?.TG2?.value)) + Math.abs(Number(formRef.current?._inputs?.TH2?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI2?.value)) - Math.abs(Number(formRef.current?._inputs?.RC2?.value)) + Number(formRef.current?._inputs?.RD2?.value) - Math.abs(Number(formRef.current?._inputs?.RE2?.value)) +
      Math.abs(Number(formRef.current?._inputs?.RF2?.value)) - Math.abs(Number(formRef.current?._inputs?.RG2?.value)) - Math.abs(Number(formRef.current?._inputs?.RH2.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI2?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ2?.value));
    let addedValuetotal3 = turnoverTotal3 - Math.abs(Number(formRef.current?._inputs?.RA3?.value)) + Number(formRef.current?._inputs?.RB3?.value) + Number(formRef.current?._inputs?.TE3?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF3?.value)) + Math.abs(Number(formRef.current?._inputs?.TG3?.value)) + Math.abs(Number(formRef.current?._inputs?.TH3?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI3?.value)) - Math.abs(Number(formRef.current?._inputs?.RC3?.value)) + Number(formRef.current?._inputs?.RD3?.value) - Math.abs(Number(formRef.current?._inputs?.RE3?.value)) +
      Number(formRef.current?._inputs?.RF3?.value) - Math.abs(Number(formRef.current?._inputs?.RG3?.value)) - Math.abs(Number(formRef.current?._inputs?.RH3?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI3?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ3?.value));
    let grossOperatingTotal1 = addedValuetotal1 - Math.abs(Number(formRef.current?._inputs?.RK1?.value));
    let grossOperatingTotal2 = addedValuetotal2 - Math.abs(Number(formRef.current?._inputs?.RK2?.value));
    let grossOperatingTotal3 = addedValuetotal3 - Math.abs(Number(formRef.current?._inputs?.RK3?.value));
    let totalOperatingTotal1 = grossOperatingTotal1 + Math.abs(Number(formRef.current?._inputs?.TJ1?.value)) - Math.abs(Number(formRef.current?._inputs?.RL1?.value));
    let totalOperatingTotal2 = grossOperatingTotal2 + Math.abs(Number(formRef.current?._inputs?.TJ2?.value)) - Math.abs(Number(formRef.current?._inputs?.RL2?.value));
    let totalOperatingTotal3 = grossOperatingTotal3 + Math.abs(Number(formRef.current?._inputs?.TJ3?.value)) - Math.abs(Number(formRef.current?._inputs?.RL3?.value));

    setTotalOperating1(totalOperatingTotal1)
    setTotalOperating2(totalOperatingTotal2)
    setTotalOperating3(totalOperatingTotal3)
  }

  const totalFinancialFun = () => {

    let total1 = Math.abs(Number(formRef.current?._inputs?.TK1?.value)) + Math.abs(Number(formRef.current?._inputs?.TL1?.value)) + Math.abs(Number(formRef.current?._inputs?.TM1?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RM1?.value)) - Math.abs(Number(formRef.current?._inputs?.RN1?.value));
    let total2 = Math.abs(Number(formRef.current?._inputs?.TK2?.value)) + Math.abs(Number(formRef.current?._inputs?.TL2?.value)) + Math.abs(Number(formRef.current?._inputs?.TM2?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RM2?.value)) - Math.abs(Number(formRef.current?._inputs?.RN2?.value));
    let total3 = Math.abs(Number(formRef.current?._inputs?.TK3?.value)) + Math.abs(Number(formRef.current?._inputs?.TL3?.value)) + Math.abs(Number(formRef.current?._inputs?.TM3?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RM3?.value)) - Math.abs(Number(formRef.current?._inputs?.RN3?.value));

    setTotalFinancial1(total1)
    setTotalFinancial2(total2)
    setTotalFinancial3(total3)
  }

  const pretaxprofitFun = () => {
    // let turnoverTotal = Number(formRef.current?._inputs?.TB?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TC?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TD?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TA?.value);

    // let addedValuetotal = turnoverTotal - Number(formRef.current?._inputs?.RA?.value) - Number(formRef.current?._inputs?.RB?.value) + Number(formRef.current?._inputs?.TE?.value) +
    //   Number(formRef.current?._inputs?.TF?.value) + Number(formRef.current?._inputs?.TG?.value) + Number(formRef.current?._inputs?.TH?.value) +
    //   Number(formRef.current?._inputs?.TI?.value) - Number(formRef.current?._inputs?.RC?.value) - Number(formRef.current?._inputs?.RD?.value) - Number(formRef.current?._inputs?.RE?.value) -
    //   Number(formRef.current?._inputs?.RF?.value) - Number(formRef.current?._inputs?.RG?.value) - Number(formRef.current?._inputs?.RH.value) -
    //   Number(formRef.current?._inputs?.RI?.value) - Number(formRef.current?._inputs?.RJ?.value);
    // let grossOperatingTotal = addedValuetotal - Number(formRef.current?._inputs?.RK?.value);
    // let totalOperatingTotal = grossOperatingTotal + Number(formRef.current?._inputs?.TJ?.value) - Number(formRef.current?._inputs?.RL?.value);

    let turnoverTotal1 = Math.abs(Number(formRef.current?._inputs?.TB1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TC1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TD1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TA1?.value));
    let turnoverTotal2 = Math.abs(Number(formRef.current?._inputs?.TB2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TC2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TD2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TA2?.value));
    let turnoverTotal3 = Math.abs(Number(formRef.current?._inputs?.TB3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TC3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TD3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TA3?.value));

    let addedValuetotal1 = turnoverTotal1 - Math.abs(Number(formRef.current?._inputs?.RA1?.value)) + Number(formRef.current?._inputs?.RB1?.value) + Number(formRef.current?._inputs?.TE1?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF1?.value)) + Math.abs(Number(formRef.current?._inputs?.TG1?.value)) + Math.abs(Number(formRef.current?._inputs?.TH1?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI1?.value)) - Math.abs(Number(formRef.current?._inputs?.RC1?.value)) + Number(formRef.current?._inputs?.RD1?.value) - Math.abs(Number(formRef.current?._inputs?.RE1?.value)) +
      Number(formRef.current?._inputs?.RF1?.value) - Math.abs(Number(formRef.current?._inputs?.RG1?.value)) - Math.abs(Number(formRef.current?._inputs?.RH1.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI1?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ1?.value));
    let addedValuetotal2 = turnoverTotal2 - Math.abs(Number(formRef.current?._inputs?.RA2?.value)) + Number(formRef.current?._inputs?.RB2?.value) + Number(formRef.current?._inputs?.TE2?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF2?.value)) + Math.abs(Number(formRef.current?._inputs?.TG2?.value)) + Math.abs(Number(formRef.current?._inputs?.TH2?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI2?.value)) - Math.abs(Number(formRef.current?._inputs?.RC2?.value)) + Number(formRef.current?._inputs?.RD2?.value) - Math.abs(Number(formRef.current?._inputs?.RE2?.value)) +
      Math.abs(Number(formRef.current?._inputs?.RF2?.value)) - Math.abs(Number(formRef.current?._inputs?.RG2?.value)) - Math.abs(Number(formRef.current?._inputs?.RH2.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI2?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ2?.value));
    let addedValuetotal3 = turnoverTotal3 - Math.abs(Number(formRef.current?._inputs?.RA3?.value)) + Number(formRef.current?._inputs?.RB3?.value) + Number(formRef.current?._inputs?.TE3?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF3?.value)) + Math.abs(Number(formRef.current?._inputs?.TG3?.value)) + Math.abs(Number(formRef.current?._inputs?.TH3?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI3?.value)) - Math.abs(Number(formRef.current?._inputs?.RC3?.value)) + Number(formRef.current?._inputs?.RD3?.value) - Math.abs(Number(formRef.current?._inputs?.RE3?.value)) +
      Number(formRef.current?._inputs?.RF3?.value) - Math.abs(Number(formRef.current?._inputs?.RG3?.value)) - Math.abs(Number(formRef.current?._inputs?.RH3?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI3?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ3?.value));
    let grossOperatingTotal1 = addedValuetotal1 - Math.abs(Number(formRef.current?._inputs?.RK1?.value));
    let grossOperatingTotal2 = addedValuetotal2 - Math.abs(Number(formRef.current?._inputs?.RK2?.value));
    let grossOperatingTotal3 = addedValuetotal3 - Math.abs(Number(formRef.current?._inputs?.RK3?.value));
    let totalOperatingTotal1 = grossOperatingTotal1 + Math.abs(Number(formRef.current?._inputs?.TJ1?.value)) - Math.abs(Number(formRef.current?._inputs?.RL1?.value));
    let totalOperatingTotal2 = grossOperatingTotal2 + Math.abs(Number(formRef.current?._inputs?.TJ2?.value)) - Math.abs(Number(formRef.current?._inputs?.RL2?.value));
    let totalOperatingTotal3 = grossOperatingTotal3 + Math.abs(Number(formRef.current?._inputs?.TJ3?.value)) - Math.abs(Number(formRef.current?._inputs?.RL3?.value));


    let pretaxprofitTotal1 = totalOperatingTotal1 + Math.abs(Number(formRef.current?._inputs?.TK1?.value)) + Math.abs(Number(formRef.current?._inputs?.TL1?.value)) + Math.abs(Number(formRef.current?._inputs?.TM1?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RM1?.value)) - Math.abs(Number(formRef.current?._inputs?.RN1?.value));
    let pretaxprofitTotal2 = totalOperatingTotal2 + Math.abs(Number(formRef.current?._inputs?.TK2?.value)) + Math.abs(Number(formRef.current?._inputs?.TL2?.value)) + Math.abs(Number(formRef.current?._inputs?.TM2?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RM2?.value)) - Math.abs(Number(formRef.current?._inputs?.RN2?.value));
    let pretaxprofitTotal3 = totalOperatingTotal3 + Math.abs(Number(formRef.current?._inputs?.TK3?.value)) + Math.abs(Number(formRef.current?._inputs?.TL3?.value)) + Math.abs(Number(formRef.current?._inputs?.TM3?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RM3?.value)) - Math.abs(Number(formRef.current?._inputs?.RN3?.value));
    setPretaxprofit1(pretaxprofitTotal1);
    setPretaxprofit2(pretaxprofitTotal2);
    setPretaxprofit3(pretaxprofitTotal3);
  }

  const exceptionalProfitFun = () => {
    let exceptionalProfitTotal1 = Math.abs(Number(formRef.current?._inputs?.TN1?.value));
    exceptionalProfitTotal1 = exceptionalProfitTotal1 + Math.abs(Number(formRef.current?._inputs?.TO1?.value));
    exceptionalProfitTotal1 = exceptionalProfitTotal1 - Math.abs(Number(formRef.current?._inputs?.RO1?.value));
    exceptionalProfitTotal1 = exceptionalProfitTotal1 - Math.abs(Number(formRef.current?._inputs?.RP1?.value));
    let exceptionalProfitTotal2 = Math.abs(Number(formRef.current?._inputs?.TN2?.value));
    exceptionalProfitTotal2 = exceptionalProfitTotal2 + Math.abs(Number(formRef.current?._inputs?.TO2?.value));
    exceptionalProfitTotal2 = exceptionalProfitTotal2 - Math.abs(Number(formRef.current?._inputs?.RO2?.value));
    exceptionalProfitTotal2 = exceptionalProfitTotal2 - Math.abs(Number(formRef.current?._inputs?.RP2?.value));
    let exceptionalProfitTotal3 = Math.abs(Number(formRef.current?._inputs?.TN3?.value));
    exceptionalProfitTotal3 = exceptionalProfitTotal3 + Math.abs(Number(formRef.current?._inputs?.TO3?.value));
    exceptionalProfitTotal3 = exceptionalProfitTotal3 - Math.abs(Number(formRef.current?._inputs?.RO3?.value));
    exceptionalProfitTotal3 = exceptionalProfitTotal3 - Math.abs(Number(formRef.current?._inputs?.RP3?.value));
    setExceptionalProfit1(exceptionalProfitTotal1)
    setExceptionalProfit2(exceptionalProfitTotal2)
    setExceptionalProfit3(exceptionalProfitTotal3)
  }

  const profitorLossFun = () => {
    // let turnoverTotal = Number(formRef.current?._inputs?.TB?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TC?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TD?.value);
    // turnoverTotal = turnoverTotal + Number(formRef.current?._inputs?.TA?.value);

    // let addedValuetotal = turnoverTotal - Number(formRef.current?._inputs?.RA?.value) - Number(formRef.current?._inputs?.RB?.value) + Number(formRef.current?._inputs?.TE?.value) +
    //   Number(formRef.current?._inputs?.TF?.value) + Number(formRef.current?._inputs?.TG?.value) + Number(formRef.current?._inputs?.TH?.value) +
    //   Number(formRef.current?._inputs?.TI?.value) - Number(formRef.current?._inputs?.RC?.value) - Number(formRef.current?._inputs?.RD?.value) - Number(formRef.current?._inputs?.RE?.value) -
    //   Number(formRef.current?._inputs?.RF?.value) - Number(formRef.current?._inputs?.RG?.value) - Number(formRef.current?._inputs?.RH.value) -
    //   Number(formRef.current?._inputs?.RI?.value) - Number(formRef.current?._inputs?.RJ?.value);
    // let grossOperatingTotal = addedValuetotal - Number(formRef.current?._inputs?.RK?.value);
    let turnoverTotal1 = Math.abs(Number(formRef.current?._inputs?.TB1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TC1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TD1?.value));
    turnoverTotal1 = turnoverTotal1 + Math.abs(Number(formRef.current?._inputs?.TA1?.value));
    let turnoverTotal2 = Math.abs(Number(formRef.current?._inputs?.TB2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TC2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TD2?.value));
    turnoverTotal2 = turnoverTotal2 + Math.abs(Number(formRef.current?._inputs?.TA2?.value));
    let turnoverTotal3 = Math.abs(Number(formRef.current?._inputs?.TB3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TC3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TD3?.value));
    turnoverTotal3 = turnoverTotal3 + Math.abs(Number(formRef.current?._inputs?.TA3?.value));

    let addedValuetotal1 = turnoverTotal1 - Math.abs(Number(formRef.current?._inputs?.RA1?.value)) + Number(formRef.current?._inputs?.RB1?.value) + Number(formRef.current?._inputs?.TE1?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF1?.value)) + Math.abs(Number(formRef.current?._inputs?.TG1?.value)) + Math.abs(Number(formRef.current?._inputs?.TH1?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI1?.value)) - Math.abs(Number(formRef.current?._inputs?.RC1?.value)) + Number(formRef.current?._inputs?.RD1?.value) - Math.abs(Number(formRef.current?._inputs?.RE1?.value)) +
      Number(formRef.current?._inputs?.RF1?.value) - Math.abs(Number(formRef.current?._inputs?.RG1?.value)) - Math.abs(Number(formRef.current?._inputs?.RH1.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI1?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ1?.value));
    let addedValuetotal2 = turnoverTotal2 - Math.abs(Number(formRef.current?._inputs?.RA2?.value)) + Number(formRef.current?._inputs?.RB2?.value) + Number(formRef.current?._inputs?.TE2?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF2?.value)) + Math.abs(Number(formRef.current?._inputs?.TG2?.value)) + Math.abs(Number(formRef.current?._inputs?.TH2?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI2?.value)) - Math.abs(Number(formRef.current?._inputs?.RC2?.value)) + Number(formRef.current?._inputs?.RD2?.value) - Math.abs(Number(formRef.current?._inputs?.RE2?.value)) +
      Math.abs(Number(formRef.current?._inputs?.RF2?.value)) - Math.abs(Number(formRef.current?._inputs?.RG2?.value)) - Math.abs(Number(formRef.current?._inputs?.RH2.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI2?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ2?.value));
    let addedValuetotal3 = turnoverTotal3 - Math.abs(Number(formRef.current?._inputs?.RA3?.value)) + Number(formRef.current?._inputs?.RB3?.value) + Number(formRef.current?._inputs?.TE3?.value) +
      Math.abs(Number(formRef.current?._inputs?.TF3?.value)) + Math.abs(Number(formRef.current?._inputs?.TG3?.value)) + Math.abs(Number(formRef.current?._inputs?.TH3?.value)) +
      Math.abs(Number(formRef.current?._inputs?.TI3?.value)) - Math.abs(Number(formRef.current?._inputs?.RC3?.value)) + Number(formRef.current?._inputs?.RD3?.value) - Math.abs(Number(formRef.current?._inputs?.RE3?.value)) +
      Number(formRef.current?._inputs?.RF3?.value) - Math.abs(Number(formRef.current?._inputs?.RG3?.value)) - Math.abs(Number(formRef.current?._inputs?.RH3?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RI3?.value)) - Math.abs(Number(formRef.current?._inputs?.RJ3?.value));
    let grossOperatingTotal1 = addedValuetotal1 - Math.abs(Number(formRef.current?._inputs?.RK1?.value));
    let grossOperatingTotal2 = addedValuetotal2 - Math.abs(Number(formRef.current?._inputs?.RK2?.value));
    let grossOperatingTotal3 = addedValuetotal3 - Math.abs(Number(formRef.current?._inputs?.RK3?.value));
    let totalOperatingTotal1 = grossOperatingTotal1 + Math.abs(Number(formRef.current?._inputs?.TJ1?.value)) - Math.abs(Number(formRef.current?._inputs?.RL1?.value));
    let totalOperatingTotal2 = grossOperatingTotal2 + Math.abs(Number(formRef.current?._inputs?.TJ2?.value)) - Math.abs(Number(formRef.current?._inputs?.RL2?.value));
    let totalOperatingTotal3 = grossOperatingTotal3 + Math.abs(Number(formRef.current?._inputs?.TJ3?.value)) - Math.abs(Number(formRef.current?._inputs?.RL3?.value));


    let pretaxprofitTotal1 = totalOperatingTotal1 + Math.abs(Number(formRef.current?._inputs?.TK1?.value)) + Math.abs(Number(formRef.current?._inputs?.TL1?.value)) + Math.abs(Number(formRef.current?._inputs?.TM1?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RM1?.value)) - Math.abs(Number(formRef.current?._inputs?.RN1?.value));
    let pretaxprofitTotal2 = totalOperatingTotal2 + Math.abs(Number(formRef.current?._inputs?.TK2?.value)) + Math.abs(Number(formRef.current?._inputs?.TL2?.value)) + Math.abs(Number(formRef.current?._inputs?.TM2?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RM2?.value)) - Math.abs(Number(formRef.current?._inputs?.RN2?.value));
    let pretaxprofitTotal3 = totalOperatingTotal3 + Math.abs(Number(formRef.current?._inputs?.TK3?.value)) + Math.abs(Number(formRef.current?._inputs?.TL3?.value)) + Math.abs(Number(formRef.current?._inputs?.TM3?.value)) -
      Math.abs(Number(formRef.current?._inputs?.RM3?.value)) - Math.abs(Number(formRef.current?._inputs?.RN3?.value));
    // let exceptionalProfitTotal = pretaxprofitTotal1 + Number(formRef.current?._inputs?.TN?.value);
    // exceptionalProfitTotal = exceptionalProfitTotal + Number(formRef.current?._inputs?.TO?.value);
    // exceptionalProfitTotal = exceptionalProfitTotal + Number(formRef.current?._inputs?.RO?.value);
    // exceptionalProfitTotal = exceptionalProfitTotal + Number(formRef.current?._inputs?.RP?.value);
    let exceptionalProfitTotal1 = pretaxprofitTotal1 + Math.abs(Number(formRef.current?._inputs?.TN1?.value));
    exceptionalProfitTotal1 = exceptionalProfitTotal1 + Math.abs(Number(formRef.current?._inputs?.TO1?.value));
    exceptionalProfitTotal1 = exceptionalProfitTotal1 - Math.abs(Number(formRef.current?._inputs?.RO1?.value));
    exceptionalProfitTotal1 = exceptionalProfitTotal1 - Math.abs(Number(formRef.current?._inputs?.RP1?.value));
    let exceptionalProfitTotal2 = pretaxprofitTotal2 + Math.abs(Number(formRef.current?._inputs?.TN2?.value));
    exceptionalProfitTotal2 = exceptionalProfitTotal2 + Math.abs(Number(formRef.current?._inputs?.TO2?.value));
    exceptionalProfitTotal2 = exceptionalProfitTotal2 - Math.abs(Number(formRef.current?._inputs?.RO2?.value));
    exceptionalProfitTotal2 = exceptionalProfitTotal2 - Math.abs(Number(formRef.current?._inputs?.RP2?.value));
    let exceptionalProfitTotal3 = pretaxprofitTotal3 + Math.abs(Number(formRef.current?._inputs?.TN3?.value));
    exceptionalProfitTotal3 = exceptionalProfitTotal3 + Math.abs(Number(formRef.current?._inputs?.TO3?.value));
    exceptionalProfitTotal3 = exceptionalProfitTotal3 - Math.abs(Number(formRef.current?._inputs?.RO3?.value));
    exceptionalProfitTotal3 = exceptionalProfitTotal3 - Math.abs(Number(formRef.current?._inputs?.RP3?.value));

    let profitorLossTotal1 = exceptionalProfitTotal1 - Math.abs(Number(formRef.current?._inputs?.RQ1?.value)) - Math.abs(Number(formRef.current?._inputs?.RS1?.value));
    let profitorLossTotal2 = exceptionalProfitTotal2 - Math.abs(Number(formRef.current?._inputs?.RQ2?.value)) - Math.abs(Number(formRef.current?._inputs?.RS2?.value));
    let profitorLossTotal3 = exceptionalProfitTotal3 - Math.abs(Number(formRef.current?._inputs?.RQ3?.value)) - Math.abs(Number(formRef.current?._inputs?.RS3?.value));
    setProfitorLoss1(profitorLossTotal1)
    setProfitorLoss2(profitorLossTotal2)
    setProfitorLoss3(profitorLossTotal3)
  }

  const breadcrumbItems = [
    { title: "Demleen", link: "#" },
    { title: t("Profit & Loss"), link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems(t("Profit & Loss"), breadcrumbItems)
  }, [])

  function handleValidSubmit(event, values) {
    if (!loading) {
      setLoading(true)
      if (values?.year_one) {
        let data = [];
        if (values?.year_one) {
          data.push({
            ...renameKeys(values, 1),
            userId: user?._id,
            contentId: year1?._id,
            token: user.token,
            year: values?.year_one,
            isSubmit: type == 'submit' ? true : undefined,
            XA: Number(grossMargin1),
            XB: Number(turnover1),
            XC: Number(addedValue1),
            XD: Number(grossOperating1),
            XE: Number(totalOperating1),
            XF: Number(totalFinancial1),
            XG: Number(pretaxprofit1),
            XH: Number(exceptionalProfit1),
            XI: Number(profitorLoss1),
          });
        }
        if (values?.year_two) {
          data.push({
            ...renameKeys(values, 2),
            userId: user?._id,
            contentId: year2?._id,
            token: user.token,
            year: values?.year_two,
            isSubmit: type == 'submit' ? true : undefined,
            XA: Number(grossMargin2),
            XB: Number(turnover2),
            XC: Number(addedValue2),
            XD: Number(grossOperating2),
            XE: Number(totalOperating2),
            XF: Number(totalFinancial2),
            XG: Number(pretaxprofit2),
            XH: Number(exceptionalProfit2),
            XI: Number(profitorLoss2),
          });
        }
        if (values?.year_three) {
          data.push({
            ...renameKeys(values, 3),
            userId: user?._id,
            contentId: year3?._id,
            token: user.token,
            year: values?.year_three,
            isSubmit: type == 'submit' ? true : undefined,
            XA: Number(grossMargin3),
            XB: Number(turnover3),
            XC: Number(addedValue3),
            XD: Number(grossOperating3),
            XE: Number(totalOperating3),
            XF: Number(totalFinancial3),
            XG: Number(pretaxprofit3),
            XH: Number(exceptionalProfit3),
            XI: Number(profitorLoss3),
          });
        }
        // console.log('data', data);
        data?.forEach((body, index) => {
          post('/profit_loss/add', body)
            .then((res) => {
              if (data?.length - 1 === index)
                if (res?.statusCode == 200) {
                  // console.log('request on login', res);
                  toast.success(res?.message);
                  // setLoading(false);
                  let body = {
                    userId: user?._id,
                    token: user?.token
                  }
                  post('/user/profile/status', body)
                    .then((json => {
                      if (json?.statusCode == 200) {
                        if (json?.data?.cashFlow) {
                          if (json?.data?.balance) {
                            updateProfile();
                          } else
                            setLoading(false);
                        } else
                          setLoading(false);
                      } else {
                        setLoading(false);
                      }
                    }));
                } else {
                  console.log(res?.error);
                  toast.error(res?.error);
                  setLoading(false);
                }
            })
            .catch((err) => {
              setLoading(false)
              console.log(err)
            })
        })
      }
      else {
        setLoading(false)
        toast?.error('First Year is Required')
      }
    }
  }

  const updateProfile = () => {
    let body = {
      userId: user?._id,
      token: user?.token,
      step: 6
    }
    put('/user', body)
      .then((res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          props?.profileSuccess({ ...user, ...body });
          localStorage.setItem("user", JSON.stringify({ ...user, ...body }));
        }
      }))
      .catch(error => {
        setLoading(false);
        console.log('error while login', error);
      })
  }

  const uploadExcel = () => {
    if (!loading) {
      if (file) {
        const form = new FormData();
        form.append("sample", file[0]);
        uploadWithTokan('/profit_loss/sample_upload', form, user?.token)
          .then((res) => {
            setLoading(false)
            if (res?.statusCode == 200) {
              // console.log('request on login', res);
              toast.success(res?.message)
              setExcelUploadModal(false);
              res?.data?.forEach((item, index) => {
                if (index < 3) {
                  if (index == 0) {
                    setTimeout(() => {
                      getUserData(item, setYear1);
                    }, 500);
                  } if (index == 1)
                    setTimeout(() => {
                      getUserData(item, setYear2);
                    }, 800);
                  else
                    setTimeout(() => {
                      getUserData(item, setYear3);
                    }, 1100);
                }
              });
            } else {
              console.log(res?.error);
              toast.error(res?.error)
            }
          })
          .catch((err) => {
            setLoading(false)
            console.log(err)
          })
      } else {
        toast.error('Please choose file')
      }
    }
  }

  function renameKeys(obj, to) {
    const newKeys = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const regexPattern = new RegExp(to + "$");
        const newKey = key.replace(regexPattern, ''); // Remove trailing digits
        newKeys[newKey] = obj[key];
      }
    }

    return newKeys;
  }

  const onConfirm = () => {
    if (currentData?.actionType == 'Status') {
      statusChange();
    } else if (currentData?.actionType == 'Delete') {
      onDelete()
    } else {
      setDeleteModal(false)
    }
  }

  return (
    <React.Fragment>
      <MyPrompt />
      <MetaTags>
        <title>Profit and Loss account | Demleen </title>
      </MetaTags>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={onConfirm}
        onCloseClick={() => setDeleteModal(false)}
        data={currentData}
      />

      <Modal size="lg" className='file_upload_popup' isOpen={helpModal} centered>
        <ModalHeader toggle={() => setHelpModal(!helpModal)}>Instruction</ModalHeader>
        <ModalBody>
          <div className="popup_content">
            <ul>
              <li><i className="mdi mdi-hand-pointing-right"></i>
                {t("FINANCIAL DOCUMENTS one")}
              </li>
              <li><i className="mdi mdi-hand-pointing-right"></i>
                {t("FINANCIAL DOCUMENTS two")}
              </li>
            </ul>
            <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("EmailContact") }}></p>
          </div>
        </ModalBody>

      </Modal>

      <button className="help_btn" onClick={() => setHelpModal(!helpModal)}><span>i</span> Help</button>

      <Modal className='file_upload_popup' isOpen={excelUploadModal} centered>
        <ModalHeader toggle={toggle}>Upload Excel</ModalHeader>
        <ModalBody>

          <FileUploader
            multiple={true}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          />
          <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>

        </ModalBody>
        <ModalFooter>
          <Button className='btn_bg' onClick={uploadExcel}>
            Upload
          </Button>{' '}
          <Button color="secondary" className='btn_secondary btn_bg' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <AvForm
        id="myForm"
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v)
        }}
        ref={formRef}
      >
        {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
        <Loader loading={loading} />
        <Card>
          <CardBody>

            <Row>
              <Col lg={12}>
                <Table
                  bordered
                  style={{ marginBottom: 0 }}
                >

                  <tbody >

                    <tr>
                      <td style={{ padding: 0, border: 'none' }} colspan="2">
                        <Table
                          className='common_table'
                          style={{ border: 'none', borderColor: 'transparent', marginBottom: 0 }}
                        >
                          <thead >
                            <tr className='right_head'>
                              <th colspan="1" className='profit_lo_heading' style={{ textAlign: 'left' }}>{t("3 years minimum")}</th>
                              <th colspan="3">
                                <a className='table_btn' href={ExcelFile} download >{t("Download Excel Sample")}
                                </a>
                                <button onClick={toggle} type='button' className='table_btn'>{t("Upload via Excel")}
                                </button>
                              </th>

                            </tr>
                            <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_1'>

                              <th style={{ width: '50%' }}>
                                {/* {t("English")} */}
                              </th>
                              <th style={{ width: '16.66666666666667%' }}>

                                <AvField type="select" name="year_one" value={year1?.year} onChange={(e) => getUserData(e.target.value, setYear1)}>
                                  <option value="">Select Year*</option>
                                  {years?.map((item) => (
                                    <option value={item?.value}>{item?.label}</option>
                                  ))}
                                </AvField>
                              </th>
                              <th style={{ width: '16.66666666666667%' }}>
                                <AvField type="select" name="year_two" value={year2?.year} onChange={(e) => getUserData(e.target.value, setYear2)}>
                                  <option value="">Select Year</option>
                                  {years?.map((item) => (
                                    <option value={item?.value}>{item?.label}</option>
                                  ))}
                                </AvField>
                              </th>
                              <th style={{ width: '16.66666666666667%' }}>
                                <AvField type="select" name="year_three" value={year3?.year} onChange={(e) => getUserData(e.target.value, setYear3)}>
                                  <option value="">Select Year</option>
                                  {years?.map((item) => (
                                    <option value={item?.value}>{item?.label}</option>
                                  ))}
                                </AvField>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>

                              <td>
                                {t("Sales of goods for resale")}
                              </td>

                              <td>
                                <AvField
                                  name="TA1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.TA}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TA2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}

                                  value={year2?.TA}
                                // required
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TA3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"

                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  value={year3?.TA}
                                // required
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Purchases of goods for resale")}
                              </td>
                              <td>
                                <AvField
                                  name="RA1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RA}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RA2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RA}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RA3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  value={year3?.RA}

                                // required
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Changes in inventory of goods for resale")}
                              </td>
                              <td>
                                <AvField
                                  name="RB1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RB}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RB2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RB}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RB3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RB}
                                />
                              </td>
                            </tr>
                            <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                              <th style={{ width: '50%' }}>
                                {t("GROSS MARGIN")}
                              </th>
                              <th style={{ width: '16.66666666666667%' }}>{grossMargin1 ? grossMargin1?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{grossMargin2 ? grossMargin2?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{grossMargin3 ? grossMargin3?.toLocaleString() : 0}</th>

                            </tr>
                            <tr>

                              <td>
                                {t("Sales of manuf. goods, services, goods for resale")}
                              </td>

                              <td>
                                <AvField
                                  name="TB1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  value={year1?.TB}

                                // required
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TB2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}

                                  value={year2?.TB}
                                // required
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TB3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"

                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  value={year3?.TB}
                                // required
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Works & Services")}
                              </td>
                              <td>
                                <AvField
                                  name="TC1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.TC}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TC2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TC}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TC3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}

                                  value={year3?.TC}
                                // required
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Ancillary income")}
                              </td>
                              <td>
                                <AvField
                                  name="TD1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.TD}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TD2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TD}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TD3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.TD}
                                />
                              </td>
                            </tr>
                            <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                              <th style={{ width: '50%' }}>
                                {t("TURNOVER")}
                              </th>
                              <th style={{ width: '16.66666666666667%' }}>{turnover1 ? turnover1?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{turnover2 ? turnover2?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{turnover3 ? turnover3?.toLocaleString() : 0}</th>

                            </tr>
                            <tr>

                              <td>
                                {t("Changes in inventory of finished goods, work in progress")}
                              </td>

                              <td>
                                <AvField
                                  name="TE1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"

                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  value={year1?.TE}
                                // required
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TE2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"

                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  value={year2?.TE}
                                // required
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TE3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"

                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  value={year3?.TE}
                                // required
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Own work capitalized")}
                              </td>
                              <td>
                                <AvField
                                  name="TF1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.TF}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TF2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TF}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TF3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"

                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  value={year3?.TF}

                                // required
                                />
                              </td>
                            </tr>

                            <tr>

                              <td>
                                {t("Operating grants")}
                              </td>
                              <td>
                                <AvField
                                  name="TG1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.TG}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TG2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TG}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TG3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.TG}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Other operating income")}
                              </td>
                              <td>
                                <AvField
                                  name="TH1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.TH}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TH2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TH}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TH3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.TH}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Transfers of operating expenses")}
                              </td>
                              <td>
                                <AvField
                                  name="TI1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.TI}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TI2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TI}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TI3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.TI}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Inventory purchases - Raw materials (and supplies)")}
                              </td>
                              <td>
                                <AvField
                                  name="RC1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RC}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RC2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RC}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RC3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RC}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Changes in inventory of raw materials (and supplies)")}
                              </td>
                              <td>
                                <AvField
                                  name="RD1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RD}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RD2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RD}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RD3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RD}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Inventory purchases - Other consumables")}
                              </td>
                              <td>
                                <AvField
                                  name="RE1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RE}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RE2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RE}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RE3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RE}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Changes in inventory of other consumables")}
                              </td>
                              <td>
                                <AvField
                                  name="RF1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RF}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RF2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RF}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RF3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required

                                  value={year3?.RF}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Transport of goods and collective transport of employees")}
                              </td>
                              <td>
                                <AvField
                                  name="RG1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RG}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RG2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RG}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RG3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RG}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("External Services")}
                              </td>
                              <td>
                                <AvField
                                  name="RH1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RH}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RH2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RH}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RH3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RH}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Taxes and duties")}
                              </td>
                              <td>
                                <AvField
                                  name="RI1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RI}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RI2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RI}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RI3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RI}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Other external expenses")}
                              </td>
                              <td>
                                <AvField
                                  name="RJ1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RJ}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RJ2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RJ}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RJ3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RJ}
                                />
                              </td>
                            </tr>
                            <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                              <th style={{ width: '50%' }}>
                                {t("VALUE ADDED")}
                              </th>
                              <th style={{ width: '16.66666666666667%' }}>{addedValue1 ? addedValue1?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{addedValue2 ? addedValue2?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{addedValue3 ? addedValue3?.toLocaleString() : 0}</th>

                            </tr>
                            <tr>

                              <td>
                                {t("Salaries and Payroll Expenses")}
                              </td>
                              <td>
                                <AvField
                                  name="RK1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RK}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RK2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RK}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RK3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RK}
                                />
                              </td>
                            </tr>
                            <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                              <th style={{ width: '50%' }}>
                                {t("GROSS OPERATING PROFIT BEFORE DEPRECIATION & AMORTIZATION")}
                              </th>
                              <th style={{ width: '16.66666666666667%' }}>{grossOperating1 ? grossOperating1?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{grossOperating2 ? grossOperating2?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{grossOperating3 ? grossOperating3?.toLocaleString() : 0}</th>

                            </tr>
                            <tr>

                              <td>
                                {t("Reversal of depreciation, amortization, impairment losses and provisions (Operating income)")}
                              </td>
                              <td>
                                <AvField
                                  name="TJ1"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  value={year1?.TJ}
                                // required
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TJ2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TJ}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TJ3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.TJ}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>
                                {t("Depreciation, amortization, impairment and provisions - Operating expenses")}
                              </td>
                              <td>
                                <AvField
                                  name="RL1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RL}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RL2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RL}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RL3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RL}
                                />
                              </td>
                            </tr>
                            <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                              <th style={{ width: '50%' }}>
                                {t("TOTAL OPERATING PROFIT")}
                              </th>
                              <th style={{ width: '16.66666666666667%' }}>{totalOperating1 ? totalOperating1?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{totalOperating2 ? totalOperating2?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{totalOperating3 ? totalOperating3?.toLocaleString() : 0}</th>

                            </tr>
                            <tr>

                              <td>
                                {t("Financial Income")}

                              </td>
                              <td>
                                <AvField
                                  name="TK1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.TK}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TK2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TK}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TK3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.TK}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>

                                {t(" Reversal of provisions for liabilities and impairment losses (Financial income)")}
                              </td>
                              <td>
                                <AvField
                                  name="TL1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.TL}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TL2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TL}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TL3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.TL}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>

                                {t("Transfers of financial expenses")}
                              </td>
                              <td>
                                <AvField
                                  name="TM1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.TM}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TM2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TM}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TM3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.TM}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>

                                {t("Financial Expenses")}
                              </td>
                              <td>
                                <AvField
                                  name="RM1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RM}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RM2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RM}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RM3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RM}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>

                                {t("Amortization, impairment and provisions - Financial expenses")}
                              </td>
                              <td>
                                <AvField
                                  name="RN1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RN}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RN2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RN}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RN3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RN}
                                />
                              </td>
                            </tr>
                            <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                              <th style={{ width: '50%' }}>
                                {t("TOTAL FINANCIAL INCOME")}
                              </th>
                              <th style={{ width: '16.66666666666667%' }}>{totalFinancial1 ? totalFinancial1?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{totalFinancial2 ? totalFinancial2?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{totalFinancial3 ? totalFinancial3?.toLocaleString() : 0}</th>

                            </tr>
                            <tr>

                              <td>

                                {t("0 Pre-tax profit or loss before exceptional items")}
                              </td>
                              <td>
                                <AvField
                                  name="XG1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  value={pretaxprofit1}
                                  disabled

                                />
                              </td>
                              <td>
                                <AvField
                                  name="XG2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  value={pretaxprofit2}
                                  disabled

                                />
                              </td>
                              <td>
                                <AvField
                                  name="XG3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  value={pretaxprofit3}
                                  disabled

                                />
                              </td>
                            </tr>
                            <tr>

                              <td>

                                {t("Income from sale of assets")}

                              </td>
                              <td>
                                <AvField
                                  name="TN1"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  value={year1?.TN}
                                // required
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TN2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TN}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TN3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.TN}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>

                                {t("Other exceptional income")}

                              </td>
                              <td>
                                <AvField
                                  name="TO1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.TO}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TO2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.TO}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="TO3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.TO}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>

                                {t("Book value of assets sold")}

                              </td>
                              <td>
                                <AvField
                                  name="RO1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RO}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RO2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RO}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RO3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RO}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>

                                {t("Other exceptional expenses")}

                              </td>
                              <td>
                                <AvField
                                  name="RP1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RP}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RP2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RP}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RP3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RP}
                                />
                              </td>
                            </tr>
                            <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                              <th style={{ width: '50%' }}>
                                {t("RESULTAT HORS ACTIVITES ORDINAIRES")}
                              </th>
                              <th style={{ width: '16.66666666666667%' }}>{exceptionalProfit1 ? exceptionalProfit1.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{exceptionalProfit2 ? exceptionalProfit2.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{exceptionalProfit3 ? exceptionalProfit3.toLocaleString() : 0}</th>

                            </tr>
                            <tr>

                              <td>

                                {t("Employee profit-sharing")}

                              </td>
                              <td>
                                <AvField
                                  name="RQ1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RQ}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RQ2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RQ}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RQ3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RQ}
                                />
                              </td>
                            </tr>
                            <tr>

                              <td>

                                {t("Income taxes")}

                              </td>
                              <td>
                                <AvField
                                  name="RS1"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year1?.RS}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RS2"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year2?.RS}
                                />
                              </td>
                              <td>
                                <AvField
                                  name="RS3"

                                  className="form-control"
                                  // placeholder="Sales of goods for resale"
                                  type="number"
                                  onChange={(e) => onChangeSetValue(e.target.value)}
                                  onWheel={(event) => event.currentTarget.blur()}
                                  // required
                                  value={year3?.RS}
                                />
                              </td>
                            </tr>
                            <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                              <th style={{ width: '50%' }}>
                                {t("PROFIT AND LOSS")}
                              </th>
                              <th style={{ width: '16.66666666666667%' }}>{profitorLoss1 ? profitorLoss1?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{profitorLoss2 ? profitorLoss2?.toLocaleString() : 0}</th>
                              <th style={{ width: '16.66666666666667%' }}>{profitorLoss3 ? profitorLoss3?.toLocaleString() : 0}</th>

                            </tr>
                          </tbody>
                        </Table>
                      </td>

                    </tr>

                  </tbody>
                </Table>
              </Col>


            </Row>

          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div className="text-center">
              <Button type="submit" className='btn_bg' color="">
                {t("Save")}
              </Button>
              <Button type="submit" onClick={() => setType('submit')} className='btn_bg mx-5' color="">
                {t("Save & Submit")}
              </Button>
            </div>
          </CardBody>
        </Card>
      </AvForm>


    </React.Fragment>
  )
}

Index.propTypes = {
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const user = state.Profile.success;
  return { user };
}

export default withRouter(
  connect(mapStatetoProps, { setBreadcrumbItems, profileSuccess })(Index)
)





import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { Card, CardBody, Col, ModalHeader, Modal, ModalBody, Row, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { get, post } from 'helpers/helper_api';
import { connect } from "react-redux"
import { withRouter, useHistory, Link } from "react-router-dom";
import jsonp from 'jsonp';
import { toast } from 'react-toastify';
import { ModalTitle } from 'react-bootstrap';
import { setBreadcrumbItems } from "../../store/actions";
import * as Utils from '../../pages/Utils'
import Loader from 'components/Loader';
import index_ from 'pages/esg/index_';
import PieChart1 from 'pages/AllCharts/C3charts/piechart1';

const Neuroprofiler = (props) => {
    let history = useHistory();
    const [formData, setFormData] = useState({
        step: 1,
    });
    const { user } = props;
    const { i18n, t } = useTranslation();
    const [data, setData] = useState([
        // {
        //     title: "You have already invested in a project and you can now:",
        //     titleFs: "Vous avez déjà investi dans un projet. Vous pouvez choisir de:",
        //     type: "Binary",
        //     image: "https://demleen.com/images/question/1701758395069.webp",
        //     negativeButton: "Stop",
        //     positiveButton: "Continue",
        //     negativeButtonFs: "arrêter",
        //     positiveButtonFs: "continuer",
        //     line: "What you decide ?",
        //     lineFs: "Que décidez-vous?",
        //     used: false
        // },
        {
            _id: 1,
            title: "You have an investment proposal with a 1 out 2 chance to earn XXX or lose XXX Francs CFA",
            titleFs: "On vous propose de faire un investissement avec 1 chance sur 2 de gagner XXX ou perdre XXX Francs CFA.",
            type: "Binary",
            image: "https://demleen.com/images/question/1701781154840.webp",
            negativeButton: "Stop",
            positiveButton: "Continue",
            negativeButtonFs: "arrêter",
            positiveButtonFs: "continuer",
            line: "Do you invest in that case ?",
            lineFs: "Souhaitez-vous investir dans ce cas ?",
            used: false
        },
        // {
        //     title: "You have already invested in a project. You can: What you decide ?",
        //     titleFs: "Vous avez investi dans un projet. Vous pouvez Que décidez-vous?",
        //     type: "Binary",
        //     image: "https://demleen.com/images/question/1701336836177.webp",
        //     negativeButton: "Stop",
        //     positiveButton: "Continue",
        //     negativeButtonFs: "arrêter",
        //     positiveButtonFs: "continuer",
        //     line: "Do you invest in that case ?",
        //     lineFs: "Souhaitez-vous investir dans ce cas ?",
        //     used: false
        // },
        // {
        //     title: "You have an investment proposal with a 1 out 2 chance to earn $$ or lose $$ Francs CFA Do you invest in that case ?",
        //     titleFs: "On vous propose de faire un investissement avec 1 chance sur 2 de gagner $$ ou perdre $$ Francs CFA. Souhaitez-vous investir dans ce cas ?",
        //     type: "Binary",
        //     image: "https://demleen.com/images/question/1701336377387.webp",
        //     negativeButton: "No",
        //     positiveButton: "Yes",
        //     negativeButtonFs: "Non",
        //     positiveButtonFs: "Oui",
        //     line: "Do you invest in that case ?",
        //     lineFs: "Souhaitez-vous investir dans ce cas ?",
        //     used: false
        // },
        {
            _id: 2,
            title: "You have the opportunity to invest in an artwork. You know that you could resell it for XXX Francs CFA in the future but that there is also a 1 out of 3 chance that you resell it only for XXX Francs CFA. Move the slider on the highest price you are willing to pay for this artwork.",
            titleFs: "Vous avez l’opportunité d’investir dans une œuvre d’art à fort potentiel. Vous savez que vous pourrez la revendre XXX Francs CFA dans quelques temps mais vous avez 1 chance sur 3 que le prix de revente soit de XXX de Francs CFA seulement. Placez le curseur sur le prix maximum que vous êtes prêt à investir dans ce projet",
            type: "Gamble",
            image: "https://demleen.com/images/question/1701758261434.webp",
            negativeButton: "Stop",
            positiveButton: "Continue",
            negativeButtonFs: "arrêter",
            positiveButtonFs: "continuer",
            line: "Do you invest in that case ?",
            lineFs: "Souhaitez-vous investir dans ce cas ?",
            used: false
        }
    ]);
    const [value, setValue] = useState(0);
    const [qid, setQid] = useState(undefined);
    const [currentQues, setCurrentQues] = useState(undefined);
    const [currentOptId, setCurrentOtpId] = useState(undefined);
    const [resultModal, setResultModal] = useState(false);
    const [invite, setInvite] = useState(false);
    const [trustedAmount, setTrustedAmount] = useState(false);
    const [trustedAmountVal, setTrustedAmountVal] = useState('')
    const [start, setStart] = useState(true);
    const [result, setResult] = useState(undefined);
    const [answeredQues, setAnsweredQues] = useState([]);
    const [isMemResult, setIsMemResult] = useState(false);
    const [loading, setLoading] = useState(false)
    const [member, setMember] = useState([]);
    const [discModal, setDiscModal] = useState(false);
    // console.log("lang", i18n?.language);

    useEffect(() => {
        if (user?.token) {
            getUserData();
        }
    }, [user?.token])

    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: user?._id,
                token: user?.token,
            }
            post('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        setMember(res?.data?.member)
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
            post("/nuro/profile", { userId: user?._id })
                .then(res => {
                    setLoading(false)
                    if (res?.statusCode == 200) {
                        setResult(res?.data)
                        if (!res?.data?.risk_profile) {
                            setTrustedAmount(true)
                        } else {
                            // setDiscModal(true);
                            history.push('/cognative-test');
                        }
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.error(err);
                    toast.error("Something Went Wrong!");
                })
            // get("/nuro/list", { token: user?.token })
            //     .then(res => {
            //         setLoading(false)
            //         if (res?.statusCode == 200) {
            //             res?.data?.forEach((item) => {
            //                 item.used = false;
            //             });
            //             setData(res?.data);
            //         }
            //     })
            //     .catch(err => {
            //         setLoading(false)
            //         console.error(err);
            //         toast.error("Something Went Wrong!");
            //     })
        }
    }

    // useEffect(() => {
    //     if (data?.length) {
    //         if (!qid)
    //             createTest();
    //     }
    // }, [data]);

    useEffect(() => {
        // console.log("qid", qid);
        if (qid)
            getQues([]);
    }, [qid]);

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: t("Neuroprofiler"), link: "#" },
    ]

    useEffect(() => {
        props?.setBreadcrumbItems(t("Profile") + ' ' + t("Neuroprofiler"), breadcrumbItems)
    })

    const handleInputChange = (e) => {
        // const { name, value } = e.target;
        // setFormData({ ...formData, [name]: value });

    };

    const nextStep = () => {
        if (!loading) {
            setLoading(true)
            fetch("https://testapi.neuroprofiler.com/risk-test/" + qid, {
                // fetch("http://146.59.154.238:443/risk-test/create", {
                method: "POST",
                headers: {
                    'accept': 'application/json',
                    'x-api-key': 'a64d54e8-62a0-41e1-a9a8-e2a71486a4c3',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "answer_id": currentOptId
                })
            })
                .then(json => json.json())
                .then(res => {
                    // console.log("response from send Response", res);
                    // setLoading(false)
                    if (!res.finished) {
                        setAnsweredQues([...answeredQues, currentQues]);
                        getQues([...answeredQues, currentQues]);
                    } else {
                        getResult();
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.error(err);
                    toast.error("Something Went wrong while creating a test!");
                })
        }
    }

    const prevStep = () => {
        // setFormData({ ...formData, step: formData.step - 1 });
        setLoading(true);
        fetch("https://testapi.neuroprofiler.com/risk-test/back/" + qid, {
            // fetch("http://146.59.154.238:443/risk-test/create", {
            method: "PUT",
            headers: {
                'accept': 'application/json',
                'x-api-key': 'a64d54e8-62a0-41e1-a9a8-e2a71486a4c3',
                'Content-Type': 'application/json'
            },
            body: undefined
            // JSON.stringify({
            //     "answer_id": currentOptId
            // })
        })
            .then(json => json.json())
            .then(res => {
                setLoading(false);
                setValue(0);
                setCurrentOtpId("option_0");
                setCurrentQues(answeredQues[answeredQues?.length - 1]);
                let temp = Object.assign([], answeredQues);
                temp.pop();
                setAnsweredQues(temp);
            })
            .catch(err => {
                setLoading(false)
                console.error(err);
                toast.error("Something Went wrong while creating a test!");
            })
    };

    const { step, firstName, lastName, email, password } = formData;

    const handleValidSubmit = (e, v) => {
        // console.log('trusted amount', typeof Number(v?.trusted_amount))

        fetch("https://testapi.neuroprofiler.com/risk-test/create", {
            // fetch("http://146.59.154.238:443/risk-test/create", {
            method: "POST",
            headers: {
                'accept': 'application/json',
                'x-api-key': 'a64d54e8-62a0-41e1-a9a8-e2a71486a4c3',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "trusted_amount": Number(v?.trusted_amount),
                "investment_horizon": "1year",
                "questionnaire_version": "short"
            })
        })
            .then(json => json.json())
            .then(res => {
                // console.log("response from create Test", res);
                if (res?.id) {
                    setQid(res?.id);
                    setTrustedAmount(false)
                    setStart(false)
                    setTrustedAmountVal(v?.trusted_amount)
                } else {
                    toast.error("Something Went Wrong! Test Could not be started!");
                }
            })
            .catch(err => {
                console.error(err);
                toast.error("Something Went wrong while creating a test!");
            })
    }

    const handleValidSubmit1 = (e, v) => {
        // history.push('/neuro-profiler/' + user?._id, { userId: user?._id })
        if (!loading) {
            setLoading(true)
            let body = {
                token: user?.token,
                smeId: user?._id,
                memberId: v?.memberId,
                lang: i18n?.language,
                path: Utils?.API_URL + '/neuro-profiler/' + user?._id + '/' + trustedAmountVal + "/" + v?.memberId,
            }
            post('/nuro/invite_member', body)
                .then(res => {
                    setLoading(false)
                    // console.log("response from create Test", res);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        setInvite(false);
                    } else if (res?.statusCode == 208) {
                        toast.error(res?.error);
                    } else {
                        toast.error("Something Went Wrong! Test Could not be started!");
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.error(err);
                    toast.error("Something Went wrong while creating a test!");
                })
        }
    }

    const getQues = (answered) => {
        fetch("https://testapi.neuroprofiler.com/risk-test/" + qid, {
            method: "GET",
            headers: {
                'accept': 'application/json',
                'x-api-key': 'a64d54e8-62a0-41e1-a9a8-e2a71486a4c3',
                'Content-Type': 'application/json'
            }
        })
            .then(json => json.json())
            .then(res => {
                // console.log("response from get question", res);
                if (res) {
                    if (!res?.finished) {
                        setCurrentOtpId(res?.options[0]?.option_id);
                        let temp = Object.assign([], data);
                        console.log("answered before setting next question", answered);
                        if (res?.options?.length == 2) {
                            setValue("");
                            for (const item of temp) {
                                if (item?.type == "Binary") {
                                    // let tempAnswered = Object.assign([], answered);
                                    // let used = tempAnswered.findIndex(x => x?._id == item?._id);
                                    // if (used == -1) {
                                    setCurrentQues({ ...item, ...res });
                                    // break; // This will stop the loop when item is 3.
                                    // }
                                }
                            }
                        } else {
                            for (const item of temp) {
                                setValue(0);
                                if (item?.type == "Gamble") {
                                    let tempAnswered = Object.assign([], answeredQues);
                                    let used = tempAnswered.findIndex(x => x?._id == item?._id);
                                    if (used == -1) {
                                        setCurrentQues({ ...item, ...res });
                                        break; // This will stop the loop when item is 3.
                                    }
                                }
                            }
                        }
                        setLoading(false);
                    }
                } else {
                    toast.error("Something Went Wrong! Question Not Found!");
                    setLoading(false);
                }
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
                toast.error("Something Went wrong while creating a test!");
            })
    }

    const handleSliderValue = (val) => {
        setValue(val)
        let optionId = undefined;
        currentQues?.options?.forEach((item) => {
            // console.log("checking for option", val, item?.binary_gamble?.y);
            let check = currentQues?.reference_gamble?.x - item?.binary_gamble?.x
            if (check == val)
                setCurrentOtpId(item?.option_id);
        })
    }

    const getResult = () => {
        fetch("https://testapi.neuroprofiler.com/risk-test/results/" + qid, {
            method: "GET",
            headers: {
                'accept': 'application/json',
                'x-api-key': 'a64d54e8-62a0-41e1-a9a8-e2a71486a4c3',
                'Content-Type': 'application/json'
            }
        })
            .then(json => json.json())
            .then(res => {
                // console.log("response from get question", res);
                let risk_profile = undefined;
                if (res?.loss_aversion > 0 && res?.loss_aversion <= 2) {
                    risk_profile = "Verydynamic";
                } else if (res?.loss_aversion > 2 && res?.loss_aversion <= 4) {
                    risk_profile = "Dynamic";
                } else if (res?.loss_aversion > 4 && res?.loss_aversion <= 6) {
                    risk_profile = "Moderate";
                } else if (res?.loss_aversion > 6 && res?.loss_aversion <= 8) {
                    risk_profile = "Conservative";
                } else if (res?.loss_aversion > 8 && res?.loss_aversion <= 10) {
                    risk_profile = "Veryconservative";
                } else {
                    risk_profile = "Verydynamic";
                }
                // console.log('loss_aversion',loss_aversion)
                // return
                setResult(res);
                let body = {
                    ...res,
                    risk_profile: risk_profile,
                    userId: user?._id,
                    trusted_amount: trustedAmountVal
                }
                post('/nuro/save', body)
                    .then((res => {

                        setLoading(false);
                        if (res?.statusCode == 200) {
                            // console.log('request on login', res);
                            toast.success(res?.message);
                            setResult(res?.data);
                            // setDiscModal(true);
                            history.push('/cognative-test');
                            // setCurrentQues(null)
                        } else if (res?.statusCode == 401) {
                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    }))
                    .catch(error => {
                        setLoading(false);
                        console.log('error while login', error);
                        // console.log('Something went wrong');
                        toast.error('Something went wrong')
                    })
                setResultModal(true);
                // post()
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                toast.error("Something Went wrong while creating a test!");
            })
    }

    // console.log("current ques", currentOptId, answeredQues);

    const replaceAmount = (str, type) => {
        // console.log('str', str, currentQues)
        let options = currentQues?.options;
        if (options?.length) {
            let max = 0;
            let min = 0;
            if (currentQues?.type == "Binary") {
                min = options[0]?.binary_gamble?.x?.toLocaleString();
                max = options[0]?.binary_gamble?.y?.toLocaleString();
                if (type == "bull") {
                    const newStr = str.replace(/\X\X\X/g, (match, index, original) => {
                        // console.log("str", match, index);
                        if (original.indexOf('XXX') === index) {
                            return `<span style="color:green;">${max}</span>`; // Replace the first XXX
                            // return max; // Replace the first XXX
                        } else {
                            return `<span style="color:red;">${max}</span>`; // Replace the second XXX
                            // return min; // Replace the second XXX
                        }
                        // return index === 0 ? max : min;
                    });
                    // let temp = str?.split('XXX');
                    // console.log('str', temp, max, min)
                    // let newStr = "";
                    // if (temp?.length>0)
                    //     newStr = temp[0] + max + temp?.length > 0 ? temp[1] : "";
                    // if (temp?.length > 1)
                    //     newStr += min + temp[2];
                    // console.log("str", newStr);
                    return newStr;
                } else {
                    if (str?.includes('XXX')) {
                        let temp = str?.split('XXX');
                        let newStr = temp[0] + `<span style="color:green;">${max}</span>` + temp[1];
                        if (temp?.length > 1)
                            newStr += `<span style="color:red;">${min}</span>` + temp[2];
                        // console.log("new Str2", newStr);
                        return newStr;
                    } else
                        return str;
                }
            } else {
                // min = currentQues?.reference_gamble?.x?.toLocaleString();
                // max = currentQues?.reference_gamble?.y?.toLocaleString()
                min = `<span style="color:red;">${currentQues?.reference_gamble?.x?.toLocaleString()}</span>`;
                max = `<span style="color:green;">${currentQues?.reference_gamble?.y?.toLocaleString()}</span>`;
                let temp = str?.split('XXX');
                let newStr = temp[0] + max + temp[1];
                if (temp?.length > 1)
                    newStr += min + temp[2];
                // console.log("new Str", newStr);
                return newStr;
            }
        }
        return ""
    }

    const formatTooltip = (value) => {
        // Customize the tooltip label here
        return value?.toLocaleString();
    };

    const clearFunction = (id) => {
        if (!loading) {
            let body = {
                neuroId: id,
            }
            post('/nuro/clear', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        setResult(null)
                        setTrustedAmount(true)
                    } else if (res?.statusCode == 401) {
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    // console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    // console.log('result',result)

    return (
        <Row>
            <Loader loading={loading} />
            <Modal isOpen={trustedAmount} className='trusted_amount' centered backdrop="static">
                <ModalTitle>
                    {t("Insert Trusted Amount")}
                </ModalTitle>
                <ModalBody>
                    <Label>{i18n?.language == 'en' ? 'Please enter an amount you are comfortable with when discussing investment. By default, the amount is set at 1,000,000.' : 'insérez un montant que vous pourriez investir personnellement. Par défaut, le système utilise 1 million Francs CFA'}</Label>
                    <AvForm
                        onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                        }}
                    >
                        <Row>
                            <Col lg={12}>
                                <div className='mb-3'>
                                    <AvField type="text" name="trusted_amount" value={"1000000"} placeholder={"Amount"} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <button type='button' className='btn_bg w-100' onClick={() => { setTrustedAmount(false) }}>{t("Cancel")}</button>
                            </Col>
                            <Col md={6}>
                                <button type='submit' className='btn_bg w-100'>{t("Submit")}</button>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>
            <Modal isOpen={invite} className='trusted_amount' centered backdrop="static">
                <ModalTitle >
                    Invite other person for this test
                </ModalTitle>
                <ModalBody>
                    <AvForm
                        onValidSubmit={(e, v) => {
                            handleValidSubmit1(e, v)
                        }}
                    >
                        <Row>
                            <Col lg={12}>
                                <div className='mb-3'>
                                    <AvField type="select" name="memberId" required>
                                        <option value={""}>Select member</option>
                                        {
                                            member?.map((item, index) => (
                                                <option value={item?._id} key={index}>{item?.fname} {item?.lname} ( {item?.email} )</option>
                                            ))
                                        }
                                    </AvField>
                                </div>
                            </Col>
                            <Col md={6}>
                                <button type='button' className='btn_bg w-100' onClick={() => { setInvite(false) }}>Cancel</button>
                            </Col>
                            <Col md={6}>
                                <button type='submit' className='btn_bg w-100' >Submit</button>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>
            <Modal isOpen={isMemResult} className='trusted_amount' centered backdrop="static" style={{ maxWidth: 800 }}>
                <ModalHeader toggle={() => setIsMemResult(false)}>
                    Members Result
                </ModalHeader >
                <ModalBody>
                    <div className='member_section member_section_new'>
                        <Row>
                            {result?.memResult?.map((item) => (
                                <Col lg={6} md={6}>
                                    <blockquote className="card-blockquote mb-0">
                                        <ul className='member_section_detail member_result'>
                                            <Card>
                                                <CardBody>
                                                    {item?.member?.length &&
                                                        <h4>{item?.member[0]?.name + " " + item?.member[0]?.lname}</h4>
                                                    }
                                                    <li><p><strong>Loss Aversion : </strong> {item?.loss_aversion}</p></li>
                                                    <li><p><strong>Loss Aversion Quantile : </strong> {item?.loss_aversion_quantile}</p></li>
                                                    <li><p><strong>Risk Profile : </strong> {item?.risk_profile && 'Very Conservatif'}</p></li>
                                                    <li><p><strong>Safe Asset Proportion in Allocation : </strong> {item?.safe_asset_proportion_in_allocation}</p></li>
                                                    <li><p><strong>Winning Appetite : </strong> {item?.winning_appetite}</p></li>
                                                    <li><p><strong>Winning Appetite Quantile : </strong> {item?.winning_appetite_quantile}</p></li>
                                                </CardBody>
                                            </Card>
                                        </ul>
                                    </blockquote>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
            <Modal size="lg" className='file_upload_popup ' isOpen={discModal} centered>
                <ModalHeader >{i18n?.language == "en" ?
                    "Disclaimer for the Behavioral and Personality Test Platform in the Onboarding Process"
                    :
                    "Avis de non-responsabilité concernant la plateforme de tests comportementaux et de personnalité dans le processus d'intégration"
                }
                </ModalHeader>
                <ModalBody>
                    <div className="popup_content">
                        {/* <p className="mt-0">{t("Dashboard one")}</p> */}
                        <ul>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {i18n?.language == "en" ? "The results obtained through this test on our digital platform are provided for indicative purposes and are an integral part of our onboarding process, but do not constitute a definitive professional evaluation of your behavior or personality."
                                    : "Les résultats obtenus à travers ce test sur notre plateforme digitale sont fournis à titre indicatif et font partie intégrante de notre processus d'onboarding, mais ne constituent pas une évaluation professionnelle définitive de votre comportement ou de votre personnalité."}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {i18n?.language == "en" ? "This test evaluates certain aspects of your behavior, such as risk aversion, winning appetite, as well as elements of the Big Five personality test, including optimism, depression, and conscientiousness."
                                    : "Ce test évalue certains aspects de votre comportement, tels que l'aversion au risque, l'appétit pour la gagne, ainsi que des éléments du test de personnalité des Big Five, comme l'optimisme, la dépression et la rigueur."}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {i18n?.language == "en" ? "The answers you have provided are self-reported and may be influenced by various factors, including your current emotional state and your interpretation of the questions."
                                    : "Les réponses que vous avez fournies sont auto-déclarées et peuvent être influencées par divers facteurs, y compris votre état émotionnel actuel et votre interprétation des questions."}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {i18n?.language == "en" ? "The results of this test will not be the only elements taken into account in our evaluation and decision-making process regarding your funding application."
                                    : "Les résultats de ce test ne seront pas les seuls éléments pris en compte dans notre processus d'évaluation et de décision concernant votre demande de financement."}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {i18n?.language == "en" ? "While we strive to ensure the accuracy and reliability of our assessment tools, we cannot guarantee that the results will be accurate, complete, or up-to-date."
                                    : "Bien que nous nous efforcions de garantir l'exactitude et la fiabilité de nos outils d'évaluation, nous ne pouvons pas garantir que les résultats seront exacts, complets ou à jour."}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {i18n?.language == "en" ? "We strongly recommend consulting qualified professionals for a thorough evaluation and personalized advice before making any decisions based solely on the results of this test."
                                    : "Nous recommandons vivement de consulter des professionnels qualifiés pour une évaluation approfondie et des conseils personnalisés avant de prendre toute décision basée uniquement sur les résultats de ce test."}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {i18n?.language == "en" ? "The platform will not be held responsible for any loss or damage resulting from the use of or reliance on the results of this test."
                                    : "La plateforme ne sera pas tenue responsable de toute perte ou dommage résultant de l'utilisation ou de la confiance accordée aux résultats de ce test."}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {i18n?.language == "en" ? "By participating in this test, you agree that the results may be used to improve our services and research, in compliance with your confidentiality and data protection."
                                    : "En participant à ce test, vous acceptez que les résultats soient utilisés dans le cadre de l'amélioration de nos services et de nos recherches, dans le respect de votre confidentialité et de la protection de vos données personnelles."}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {i18n?.language == "en" ? "Your answers and the test results are subject to our privacy policy, which we encourage you to read carefully."
                                    : "Vos réponses et les résultats du test sont soumis à notre politique de confidentialité, que nous vous encourageons à lire attentivement."}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {i18n?.language == "en" ? "By using this platform and taking this test, you acknowledge that you have understood and accepted the terms of this disclaimer and our general terms of use."
                                    : "En utilisant cette plateforme et en répondant à ce test, vous reconnaissez avoir compris et accepté les termes de ce disclaimer et nos conditions générales d'utilisation."}
                            </li>

                        </ul>
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("EmailContact") }}></p>
                        <div className='d-flex justify-content-center mt-4 mb-4'>
                            <Link to={"/cognative-test"}>
                                <button className='clear_profile' type='button'>Agree And Continue</button>
                            </Link>
                        </div>
                    </div>
                </ModalBody>

            </Modal>
            {/* {
                result?.risk_profile &&
                <Col className='col-12'>
                    <div className='member_section member_section_new'>
                        <Row>
                            <Col lg={12}>
                                <div class="page_wrapper page_wrapper_page_sme">
                                    <div className='success_box'>
                                        <div class="custom-modal">
                                            <div class="succes succes-animation icon-top"><i class="fa fa-check"></i></div>
                                            <div class="border-bottom"></div>
                                            <p className="success-paragraph">
                                                {i18n?.language == 'en' ? 'You have completed the questionnaire successfully. When talking about investment, your test result describes the following profile.' : "Vous avez complété le questionnaire avec succès. En matière d'investissement, le résultat du test établit un profil"}
                                            </p>
                                            <div class="content mt-0">
                                                <p class="type"><strong>{t(result?.risk_profile)}</strong></p>
                                            </div>
                                            <button className='clear_profile' type='button' onClick={() => clearFunction(result?._id)}>Clear Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            } */}
            {currentQues &&
                <Col className="col-12">
                    <div className='top_header-box'>
                        <h4 className="card-title mb-0">{t("Neuroprofiler Questions")}</h4>
                        <div className='reuro-btn-box'>
                            {result?.memResult?.length ?
                                <button className='btn_bg' onClick={() => setIsMemResult(true)}>Member Result</button> : null
                            }
                            <button className='btn_bg' onClick={() => setInvite(true)}>Invite Member</button>
                        </div>
                    </div>
                    <Card>
                        <CardBody >
                            <div className="wizard-form">
                                <p className='total_question'>Question {answeredQues?.length + 1}/{currentQues?.max_depth}</p>
                                <div className="wizard-step">
                                    {step === 1 && (
                                        <div className='card_box_question'>
                                            {currentQues?.type == "Gamble" ?
                                                <>
                                                    <Row>
                                                        <Col md={currentQues?.image ? 8 : 12}>
                                                            {i18n?.language == "en" ?
                                                                <p className='p1' dangerouslySetInnerHTML={{ __html: replaceAmount(currentQues?.title, "ques") }}></p> :
                                                                <p className='p1' dangerouslySetInnerHTML={{ __html: replaceAmount(currentQues?.titleFs, "ques") }}></p>
                                                            }
                                                            <ul>
                                                                {currentQues?.bullets?.map((item) => (
                                                                    <li>{i18n?.language == "en" ? <p className='p1' dangerouslySetInnerHTML={{ __html: "֎" + replaceAmount(item?.title, "bull") }}></p> : <p className='p1' dangerouslySetInnerHTML={{ __html: "֎" + replaceAmount(item?.titleFs, "bull") }}></p>}</li>
                                                                ))}
                                                            </ul>
                                                            <p className='p1'>{i18n?.language == 'en' ? currentQues?.line : currentQues?.lineFs}</p>
                                                        </Col>
                                                        {
                                                            currentQues?.image && <Col md={4}>
                                                                <div className='question_image_section'>
                                                                    <img src={currentQues?.image} alt='question_img' />
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>
                                                    <div className='range_slider_box'>
                                                        <div className='range_slider_box_2'>
                                                            <span>{currentQues?.reference_gamble?.x?.toLocaleString()}</span>
                                                            <div className='inpu_amount'>
                                                                {/* <input type='number' className='form-control' onChange={(e) => setValue(e.target.value)} placeholder='0' /> */}
                                                                <span>{(currentQues?.reference_gamble?.x - currentQues?.options[currentQues?.options?.length - 1]?.binary_gamble?.x)?.toLocaleString()}</span>
                                                            </div>
                                                        </div>
                                                        <RangeSlider
                                                            tooltip="on"
                                                            tooltipPlacement="bottom"
                                                            value={value}
                                                            tooltipLabel={formatTooltip}
                                                            min={currentQues ? String(currentQues?.reference_gamble?.x) : "0"}
                                                            max={currentQues ? String(currentQues?.reference_gamble?.x - currentQues?.options[currentQues?.options?.length - 1]?.binary_gamble?.x) : "0"}
                                                            // range={currentQues ? currentQues?.options?.map((item) => { return item?.binary_gamble?.y }) : ["0"]}
                                                            step={10000}
                                                            onChange={changeEvent => handleSliderValue(changeEvent.target.value)}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <Row>
                                                        <Col md={currentQues?.image ? 8 : 12}>
                                                            {/* neutral investment – if the case the slider max is previously selected */}
                                                            {currentQues?.options[1]?.binary_gamble?.px == 1 && currentQues?.options[1]?.binary_gamble?.x == 0
                                                                // || currentQues?.options[1]?.binary_gamble?.py == 1 && currentQues?.options[1]?.binary_gamble?.y == 0
                                                                ?
                                                                <p className='p1'>{i18n?.language == 'en' ? '“You can invest again in a new investment with a 50% chance of earning' : '“Vous pouvez investir à nouveau avec 50% de chance de gagner reference_gamble.x Francs CFA et 50% chance de perdre'}
                                                                    <span style={{ color: "green" }}> {currentQues?.options[0]?.binary_gamble?.y.toLocaleString()} </span>
                                                                    {i18n?.language == 'en' ? 'Francs CFA and a 50% chance of losing' : 'Francs CFA et 50% chance de perdre'}
                                                                    <span style={{ color: "red" }}> {currentQues?.options[0]?.binary_gamble?.x.toLocaleString()} </span>
                                                                    {i18n?.language == 'en' ? 'Francs CFA. What do you do ?”' : 'Francs CFA. Que décidez-vous?”'}
                                                                </p>
                                                                :
                                                                <div>
                                                                    <p className='p1'>{i18n?.language == 'en' ? 'You have already invested in a project. Your current options are :' : 'Vous avez déjà investi dans un projet. Vous avez, à présent, le choix de :'}</p>
                                                                    {currentQues?.options?.map((item, index) => {
                                                                        // if (index == 0) {
                                                                        if (item?.binary_gamble?.px == 1) {
                                                                            if (item?.binary_gamble?.x > 0)
                                                                                return <p className='p2'>{i18n?.language == 'en' ? '֎ stop now and earn' : '֎ Arrêter maintenant et gagner'} <span style={{ color: "green" }}>{item?.binary_gamble?.x?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA for sure' : 'Francs CFA en même temps'}</p>
                                                                            if (item?.binary_gamble?.x < 0)
                                                                                return <p className='p2'>{i18n?.language == 'en' ? '֎ stop now and lose' : '֎ Arrêter maintenant et perdre'} <span style={{ color: "red" }}>{item?.binary_gamble?.x?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA for sure' : 'Francs CFA en même temps'}</p>
                                                                        } else if (item?.binary_gamble?.px == 0) {
                                                                            if (item?.binary_gamble?.py == 1) {
                                                                                if (item?.binary_gamble?.y > 0)
                                                                                    return <p className='p2'>{i18n?.language == 'en' ? '֎ stop now and earn' : '֎ Arrêter maintenant et gagner'} <span style={{ color: "green" }}>{item?.binary_gamble?.y?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA for sure' : 'Francs CFA en même temps'}</p>
                                                                                if (item?.binary_gamble?.y < 0)
                                                                                    return <p className='p2'>{i18n?.language == 'en' ? '֎ stop now and lose' : '֎ Arrêter maintenant et perdre'} <span style={{ color: "red" }}>{item?.binary_gamble?.y?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA for sure' : 'Francs CFA en même temps'}</p>
                                                                            }
                                                                        }
                                                                        // } else {
                                                                        if (item?.binary_gamble?.py != 1)
                                                                            return <p className='p2'>{i18n?.language == 'en' ? '֎ continue to invest with a' : '֎ Continuer l’investissement avec'} {item?.binary_gamble.py * 100}{i18n?.language == 'en' ? '% chance to' : '% de chance'}
                                                                                {item?.binary_gamble.y > 0 && <span> {i18n?.language == 'en' ? 'earn' : 'de gagner'} <span style={{ color: "green" }}>{item?.binary_gamble.y?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA' : 'Francs CFA'} </span>}
                                                                                {item?.binary_gamble.y < 0 && <span> {i18n?.language == 'en' ? 'lose' : 'de perdre'} <span style={{ color: "red" }}>{item?.binary_gamble.y?.toLocaleString()}</span> {i18n?.language == 'en' ? 'Francs CFA' : 'Francs CFA'} </span>}
                                                                                {item?.binary_gamble.y == 0 && `${i18n?.language == 'en' ? 'earn or lose nothing' : 'de ne rien gagner et de ne rien perdre'}`}
                                                                                {i18n?.language == 'en' ? 'and a' : 'et'} {item?.binary_gamble.py * 100}{i18n?.language == 'en' ? '% chance to' : '% chance'}
                                                                                {item?.binary_gamble.x > 0 && <span> {i18n?.language == 'en' ? 'earn' : 'de gagner'} <span style={{ color: "green" }}>{item?.binary_gamble.x?.toLocaleString()}</span> Francs CFA.</span>}
                                                                                {item?.binary_gamble.x < 0 && <span> {i18n?.language == 'en' ? 'lose' : 'de perdre'} <span style={{ color: "red" }}>{item?.binary_gamble.x?.toLocaleString()}</span> Francs CFA.</span>}
                                                                                {item?.binary_gamble.x == 0 && `${i18n?.language == 'en' ? 'earn or lose nothing' : 'de ne rien gagner et de ne rien perdre'}`}
                                                                            </p>
                                                                    })}
                                                                    <p className='p1'>{i18n?.language == 'en' ? 'What do you do in that case ?' : 'Que décidez-vous de faire ?'}</p>
                                                                </div>
                                                            }
                                                            {/* {i18n?.language == "en" ?
                                                                <p className='p1' dangerouslySetInnerHTML={{ __html: replaceAmount(currentQues?.title, "ques") }}></p> :
                                                                <p className='p1' dangerouslySetInnerHTML={{ __html: replaceAmount(currentQues?.titleFs, "ques") }}></p>
                                                            }
                                                            <ul>
                                                                {currentQues?.bullets?.map((item) => (
                                                                    <li>{i18n?.language == "en" ? <p className='p1' dangerouslySetInnerHTML={{ __html: "֎" + replaceAmount(item?.title, "bull") }}></p> : <p className='p1' dangerouslySetInnerHTML={{ __html: "֎" + replaceAmount(item?.titleFs, "bull") }}></p>}</li>
                                                                ))}
                                                            </ul>
                                                            <p className='p1'>{i18n?.language == 'en' ? currentQues?.line : currentQues?.lineFs}</p> */}
                                                        </Col>
                                                        {
                                                            currentQues?.image && <Col md={4}>
                                                                <div className='question_image_section'>
                                                                    <img src={currentQues?.image} alt='question_img' />
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>
                                                    <div className='yes_no_button'>
                                                        <button className={value == 'no' ? 'active' : ''} onClick={() => { setValue("no"); setCurrentOtpId(currentQues?.options[1]?.option_id) }}>{i18n?.language == 'en' ? "I stop" : currentQues?.negativeButtonFs}</button>
                                                        <button className={value == 'yes' ? 'active' : ''} onClick={() => { setValue("yes"); setCurrentOtpId(currentQues?.options[0]?.option_id) }}>{i18n?.language == 'en' ? "I continue" : currentQues?.positiveButtonFs}</button>
                                                    </div>
                                                </>
                                            }
                                            <div className='button_box_next'>
                                                <button style={{ visibility: answeredQues?.length > 0 ? "visible" : "hidden" }} onClick={prevStep}>Previous</button>
                                                <button onClick={nextStep}>Next</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            }
            {/* <Col md={12} className='d-flex justify-content-center mt-4 mb-4'>
               
            </Col> */}
        </Row >
    );
};

const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(Neuroprofiler)
);
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
import { useTranslation } from 'react-i18next';
import IncomestatementanalysisChart from '../../../pages/AllCharts/apex/balanceChart';
import GraphThree from '../../../pages/AllCharts/apex/graphthreeChart';
import BarApexChart from "../../../pages/AllCharts/apex/balanceChart1";
import { get } from 'helpers/helper_api';

const dataBal = [
    {
        title: 'Financial Independence Ratio',
        ratio: 2.5,
        desc: "Financial Independence Ratio Desc"
    },
    {
        title: 'Funded Capital Ratio',
        ratio: 2.5,
        desc: "Funded Capital Ratio Desc"
    },
    {
        title: 'GearingLeveragedebttoequityratio',
        ratio: 2.5,
        desc: "GearingLeveragedebttoequityratio Desc"
    },
    {
        title: 'Total debt ratio',
        ratio: 2.5,
        desc: "Total debt ratio Desc"
    },
    {
        title: 'Equity ratio',
        ratio: 2.5,
        desc: "Equity ratio Desc"
    },
    {
        title: 'Percentage of Interest Charges to EBIT',
        ratio: 2.5,
        desc: "Percentage of Interest Charges to EBIT Desc"
    },
    {
        title: 'Net Profit Margin',
        ratio: 2.5,
        desc: "Net Profit Margin Desc"
    },
    {
        title: 'Interest Coverage',
        ratio: 2.5,
        desc: "Interest Coverage Desc"
    },
    {
        title: 'Fixed Charge Coverage',
        ratio: 2.5,
        desc: "Fixed Charge Coverage Desc"
    },
    {
        title: 'Net-Debt-to-EBITDA',
        ratio: 2.5,
        desc: "Net-Debt-to-EBITDA Desc"
    },

]

function LeverageAnalysis({ userId, user }) {
    const { i18n, t } = useTranslation();
    const [yearsArr, setYearsArr] = useState(undefined);
    const [years, setYears] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [explainData, setExplainData] = useState(undefined);
    const [explainData1, setExplainData1] = useState(dataBal[activeTab]);
    const [title, setTitle] = useState(undefined);
    const [value, setValue] = useState(undefined);
    const [graphTitle, setGraphTitle] = useState(undefined);
    const [graphValue, setGraphValue] = useState(undefined);
    const [curruntData, setCurruntData] = useState(undefined);
    const [typeGraph, setTypeGraph] = useState(undefined);
    const [horizontal, setHorizontal] = useState(false);
    const [allGraph, setAllGraph] = useState(undefined);

    const changeTab = (item, index) => {
        setActiveTab(index)
        setCurruntData(item)
        setTypeGraph(item?.type)
        setExplainData1(dataBal[index])
        if (item?.type == '1') {
            setHorizontal(false)
        }
        if (item?.type == '3') {
            setHorizontal(true)
        }

        if (item?.graph?.length > 0) {
            let graphT = [];

            item?.graph.map((item) => (
                graphT.push(item?.title)
            ))
            setGraphTitle(graphT?.reverse())
            let graphV = [];
            item?.graph.map((item) => (
                graphV.push(item?.value)
            ))
            setGraphValue(graphV?.reverse())
        }
    }

    useEffect(() => {
        if (user?.token) {
            getData()
        }
    }, [user, userId])

    const getData = () => {
        let body = {
            token: user?.token,
        }
        let url = '/ratio/solve?userId=' + userId;
        get(url, body)
            .then((res) => {
                if (res?.statusCode == 200) {
                    // console.log('ration res', res?.data)
                    if (res?.data) {
                        if (res?.data?.years?.length) {
                            if (years == '') {
                                setYears(res?.data?.years[0])
                                // console.log('first year',res?.data?.years[0])
                            }
                            let yearsArr = res?.data?.years?.reverse();
                            setYearsArr(yearsArr)
                            setAllGraph(res?.data?.allGraprh)
                            setExplainData(res?.data?.loop)
                            if (res?.data?.loop?.length) {
                                setTypeGraph(res?.data?.loop[0]?.type)
                                setCurruntData(res?.data?.loop[0])
                            }
                            if (res?.data?.loop?.length > 0) {
                                let graphT = [];

                                res?.data?.loop[0]?.graph.map((item) => (
                                    graphT.push(item?.title)
                                ))
                                setGraphTitle(graphT?.reverse())
                                let graphV = [];
                                res?.data?.loop[0]?.graph.map((item) => (
                                    graphV.push(item?.value)
                                ))
                                setGraphValue(graphV?.reverse())
                            }
                        } else {
                            console.error('Data is undefined in the response');
                        }
                    }
                }
            }).catch((err) => {
                console.log('err', err)
            })
    }

    useEffect(() => {
        if (allGraph?.length) {
            let temp = [];
            // console.log('ration temp', temp)

            let temp1 = [];
            if (typeGraph == '1') {
                temp.push(allGraph[0]?.title)
                temp.push(allGraph[1]?.title)
                temp.push(allGraph[3]?.title)
                temp.push(allGraph[4]?.title)
                temp.push(allGraph[5]?.title)
                temp.push(allGraph[6]?.title)
                // allGraph?.map((item) => (
                //     temp.push(item?.title)
                // ))
            }
            if (typeGraph == '3') {
                temp.push(allGraph[2]?.title)
                temp.push(allGraph[7]?.title)
                temp.push(allGraph[8]?.title)

                // allGraph?.map((item) => (
                //     temp.push(item?.title)
                // ))
            }
            if (typeGraph == '3') {
                temp1.push(allGraph[2]?.value)
                temp1.push(allGraph[7]?.value)
                temp1.push(allGraph[8]?.value)

                // res?.data?.allGraprh?.map((item) => (
                //     temp1.push(item?.value)
                // ))
            }
            if (typeGraph == '1') {
                temp1.push(allGraph[0]?.value)
                temp1.push(allGraph[1]?.value)
                temp1.push(allGraph[3]?.value)
                temp1.push(allGraph[4]?.value)
                temp1.push(allGraph[5]?.value)
                temp1.push(allGraph[6]?.value)
                // res?.data?.allGraprh?.map((item) => (
                //     temp1.push(item?.value)
                // ))
            }


            // console.log('ration temp', temp)
            setTitle(temp)
            setValue(temp1)
        }

    }, [allGraph, years, typeGraph])

    const changeYearsData = (item) => {
        setYears(item)
        let body = {
            token: user?.token,
        }
        let url = `/ratio/solve?year=${item}&userId=${userId}`;
        get(url, body)
            .then((res) => {
                if (res?.statusCode == 200) {
                    // console.log('ration res', res?.data)
                    if (res?.data) {
                        if (res?.data?.years?.length) {

                            let yearsArr = res?.data?.years?.reverse();
                            setYearsArr(yearsArr)

                            setAllGraph(res?.data?.allGraprh)


                        } else {
                            console.error('Data is undefined in the response');
                        }


                    }
                }
            }).catch((err) => {
                console.log('err', err)
            })

    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h6 className='explain-text'><strong><span>{t("profit_chat_label")} : {years}</span></strong></h6>
                </CardBody>
            </Card>
            <Card className='ratio_section'>
                <CardBody>
                    <div className='top_ratio'>
                        <ul className='ratio_list'>
                            {
                                explainData?.map((item, index) => (
                                    <li className={index == activeTab ? 'active' : null} onClick={() => changeTab(item, index)}>
                                        <h3>{t(item?.title)}</h3>
                                        <h1 style={{ textTransform: "none" }}>{Number(item?.value).toLocaleString()} {item?.valueType}</h1>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </CardBody>
            </Card>
            <Row>
                {explainData1 &&
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p className='explain-text'><span>{t(curruntData?.title)} :</span> {t(explainData1?.desc)}</p>
                            </CardBody>
                        </Card>
                    </Col>
                }
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <h4 className='text-center mb-3 grap_heading'>{t(explainData?.title)}</h4>
                            <div className='year_chart'>
                                {
                                    typeGraph == '1' &&
                                    <>
                                        {
                                            graphTitle && graphValue && <BarApexChart horizontal={horizontal} title={graphTitle} value={graphValue} />
                                        }
                                    </>
                                }
                                {
                                    typeGraph == '3' &&
                                    <>
                                        {
                                            graphTitle && graphValue && <BarApexChart horizontal={horizontal} title={graphTitle} value={graphValue} />
                                        }
                                    </>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <h4 className='text-center mb-3 grap_heading'>{t(explainData?.title)} {t("Solvency Ratios")}</h4>
                            <div className='ratio_year_tabs'>
                                <ul>
                                    {
                                        yearsArr?.map((item, index) => (
                                            <li>
                                                <button onClick={() => changeYearsData(item)} className={item == years ? 'active' : null}>{item}</button>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className='year_chart mt-3'>
                                {
                                    typeGraph == '1' &&
                                    <>
                                        {
                                            title ? <IncomestatementanalysisChart title={title?.map(element => t(element))} value={value} /> : null
                                        }
                                    </>
                                }
                                {
                                    typeGraph == '3' &&
                                    <>
                                        {
                                            title ? <GraphThree title={title?.map(element => t(element))} value={value} /> : null
                                        }
                                    </>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default LeverageAnalysis;
import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation'
import img1 from './assets/img/cyber/banner-2.jpg'
import img2 from './assets/img/f-second.jpg'
import { useTranslation } from 'react-i18next';
export default function PrivacyPolicy() {
    const { i18n, t } = useTranslation();
    const [activeTab, setActiveTab] = useState('1')
    return (
        <div className='body_front'>
            <Header />
            <div class="breadcrumb-area shadow dark bg-fixed text-light inner_banner">
                <div class="container">
                    <div class="row align-center">
                        <div class="col-lg-6">
                            <h2>Politique de Confidentialité</h2>
                        </div>

                    </div>
                </div>
            </div>

            <div class="case-details-area services-details-area default-padding">
                <div class="container">
                    {/* <div class="row">
            <div class="col-lg-12 thumb mb-5">
              <img src={img1} alt="Thumb" />
            </div>
          </div> */}
                    <div class="row">
                        <div class="col-lg-12 content px-3">

                            <h2>DEMLEEN (« DEMLEEN », « notre », « nous » et « nos ») et nos partenaires respectent votre vie privée</h2>
                            <p>Nous vous demandons de bien vouloir lire attentivement la présente politique de confidentialité pour comprendre comment sont collectées, traitées et conservées vos données personnelles lors de votre utilisation du présent site internet Demleen, accessible via l’url www.demleen.com.</p>
                            <p>L’ensemble des données personnelles collectées sur le présent site internet sont traitées sous la responsabilité de la société DEMLEEN, SASU au capital social de 1000 €, immatriculée au Registre du Commerce et des Sociétés de Saint-Mandé sous le numéro 911783736, et ayant son siège social 5 AVENUE DU GENERAL DE GAULLE, 94160 ST MANDE, France.</p>
                            <p>L’ensemble des données personnelles collectées sur le présent site internet sont traitées sous la responsabilité de la société DEMLEEN, SASU au capital social de 1000 €, immatriculée au Registre du Commerce et des Sociétés de Saint-Mandé sous le numéro 911783736, et ayant son siège social 5 AVENUE DU GENERAL DE GAULLE, 94160 ST MANDE, France.
                                Au sens de la réglementation applicable aux données personnelles, DEMLEEN est donc responsable de traitement.
                            </p>
                            <p><strong>Cette politique de confidentialité décrit:</strong></p>
                            <p>1. Comment DEMLEEN  utilise vos données personnelles</p>
                            <p>2. Comment DEMLEEN partage vos données personnelles</p>
                            <p>3. Comment DEMLEEN  protège vos données personnelles</p>
                            <p>4. Où DEMLEEN  héberge et transfère vos données personnelles</p>
                            <p>5. Comment vous pouvez exercer vos droits relatifs à vos données personnelles</p>
                            <p>6. Les mises à jour de la politique de confidentialité</p>
                            <p>Comment nous contacter</p>
                            <h3>I. Comment DEMLEEN  utilise vos données personnelles </h3>
                            <p><strong>DEMLEEN peut utiliser vos données personnelles aux fins suivantes :</strong></p>
                            <p>1. Vous adresser notre newsletter, dans le cas où vous y êtes inscrit</p>
                            <p>2. Répondre à votre demande de contact effectuée à partir de notre site internet</p>
                            <p>La plupart des traitements listés ci-dessus sont nécessaires à l’exécution du contrat passé avec DEMLEEN lorsque vous utilisez notre site internet.</p>
                            <p>Le traitement de vos données personnelles afin de vous envoyer notre newsletter est, en revanche, basé uniquement sur votre consentement à recevoir notre newsletter, que vous pouvez retirer à tout moment. Si vous ne consentez pas à l’envoi de la newsletter, veuillez noter que cela ne vous empêchera pas de créer votre compte client et de passer des commandes sur notre site internet.</p>
                            <h3>II. Comment DEMLEEN partage vos données personnelles</h3>
                            <p>Au sein de DEMLEEN, et au regard de chaque finalité de traitement, les données personnelles vous concernant sont collectées, traitées et stockées par le personnel habilité de DEMLEEN, uniquement dans le cadre de leurs compétences respectives, et notamment par le service client, le département marketing et le département informatique.</p>
                            <p><strong>Nous ne partageons pas les données personnelles avec d’autres entreprises, organisations et personnes, à moins que l’une des circonstances suivantes s’applique :</strong></p>
                            <p><strong>1. Le partage avec consentement préalable :</strong> après avoir obtenu votre consentement, DEMLEEN partagera les informations que vous avez autorisées avec les tiers ou catégories de tiers spécifiques renseignés à l’occasion de la collecte de votre consentement.</p>
                            <p><strong>2. Le partage avec nos prestataires :</strong> DEMLEEN peut également divulguer vos informations à des entreprises qui fournissent des services pour nous ou en notre nom. Ces prestataires de services comprennent des entreprises qui proposent des services informatiques tels notamment notre hébergeur ou notre prestataire d’envoi d’emails, des services de livraison de nos produits, ou qui proposent des activités marketing pour notre compte. Ces prestataires de services peuvent utiliser vos informations uniquement dans le but de vous fournir des services au nom de DEMLEEN.</p>
                            <p><strong>En exécution d’une obligation légale, le partage conformément aux lois et à la réglementation :</strong> DEMLEEN, peut partager vos informations comme le stipulent les lois et la réglementation, afin de résoudre les différends d’ordre juridique, ou comme le stipulent les autorités judiciaires ou administratives en vertu de la loi.</p>
                            <p><strong>DEMLEEN</strong> s’assurera de la légalité de tout partage des données personnelles via des clauses de traitement de données avec les entreprises avec lesquelles vos données personnelles sont partagées, les obligeant à se conformer à cette politique de confidentialité et à prendre les mesures de sécurité et confidentialité appropriées lors du traitement de données à caractère personnel.</p>
                            <h3>III. Comment DEMLEEN protège vos données personnelles</h3>
                            <p><strong>DEMLEEN</strong> attache une grande importance à la sécurité de vos données personnelles et a adopté des pratiques courantes de l'industrie afin de protéger vos données personnelles et d'éviter l'accès non autorisé, la divulgation, l'utilisation, la modification, les dommages ou la perte de ces informations.
                            </p>
                            <p>Nous avons par ailleurs pris les précautions utiles afin de préserver, par notre hébergeur, la sécurité et la confidentialité des données, et notamment empêcher qu’elles ne soient déformées, endommagées ou communiquées à des personnes non autorisées.</p>
                            <p><strong>DEMLEEN adopte également les mesures organisationnelles suivantes :</strong></p>
                            <p>1. Nous adoptons des mesures raisonnables et réalisables afin de veiller à ce que les données personnelles recueillies soient minimes et pertinentes selon ce qui est nécessaire, eu égard aux fins pour lesquelles elles sont traitées.</p>
                            <p>2. Nous conservons vos données personnelles pendant la durée qui est strictement nécessaire au regard de la finalité du traitement, à moins que la conservation de vos données soit requise ou permise par la loi. A titre d’exemple, nous conservons les données liées à l’exécution de vos commandes pour la durée requise par la loi au titre de conservation des dossiers comptables, à savoir au maximum 10 ans à compter de l’exercice concerné.</p>
                            <p>3. Nous déployons les mécanismes de contrôle d'accès afin de s'assurer que seul le personnel habilité peut accéder à vos données personnelles.</p>
                            <p>En cas de violation des données personnelles, DEMLEEN respectera les exigences légales et réglementaires applicables à la notification des violations de données personnelles auprès des autorités de contrôle compétentes et/ou des personnes concernées.</p>
                            <h3>IV. Où DEMLEEN héberge et transfère vos données personnelles</h3>
                            <p>Vos données personnelles seront hébergées au sein des infrastructures d’hébergement de notre hébergeur, OVH, situé en France.</p>
                            <p>Lorsque de tels transferts existent, nous nous assurons que ces transferts de données personnelles soient encadrés conformément à la réglementation applicable afin d’assurer un niveau de protection des données adéquat, soit par une décision d’adéquation de la Commission européenne, soit par le biais d’instruments juridiques tels que des contrats de transferts de données intégrant les Clauses Contractuelles Types de la Commission Européenne.</p>
                            <p>Pour toute demande concernant les destinataires et les transferts de données que nous effectuons en dehors de l’Union Européenne, veuillez nous contacter aux adresses indiquées dans la rubrique « Comment nous contacter » ci-dessous.</p>
                            <h3>V. Comment vous pouvez gérer vos droits relatifs à vos données personnelles</h3>
                            <p>Vous disposez d’un droit d’accès, de rectification, d’effacement, de limitation, d’opposition concernant le traitement de vos données personnelles ainsi que du droit de définir des directives relatives au sort de vos données après votre mort et du droit à la portabilité de vos données personnelles.</p>
                            <p>Vous pouvez nous contacter à tout moment aux adresses indiquées dans la rubrique ‘’Comment nous contacter’’ ci-dessous afin d’exercer vos droits en matière de données personnelles dans les conditions posées par la réglementation applicable. Vous devez indiquer quel droit vous entendez exercer ainsi que l’ensemble des précisions nécessaires pour que nous puissions répondre à votre demande.</p>
                            <p>Ces droits s’exercent dans les conditions posées par la réglementation applicable.</p>
                            <p><strong>- Le droit d’accès</strong> signifie que vous pouvez nous demander à tout moment de vous indiquer si nous traitons des données personnelles vous concernant et, le cas échéant, de vous indiquer quelles sont les données personnelles concernées ainsi que les caractéristiques du ou des traitements effectués.</p>
                            <p><strong>- Le droit de rectification</strong> signifie que vous pouvez nous demander la rectification de vos données personnelles lorsqu’elles sont inexactes. Vous pouvez également demander à ce que vos données personnelles, dès lors qu’incomplètes, soient complétées dans la mesure où cela est pertinent au regard de la finalité du traitement en cause.</p>
                            <p><strong>- Le droit à l’effacement</strong> signifie que vous pouvez demander d’effacer vos données personnelles notamment lorsque :</p>
                            <p>(i) Leur conservation n’est plus nécessaire au regard des finalités pour lesquelles elles ont été collectées ;</p>
                            <p>(ii) Vos données personnelles sont traitées sur le fondement de votre consentement, vous souhaitez retirer ce consentement, et il n’existe pas d'autre fondement juridique susceptible de justifier le traitement ;</p>
                            <p>(iii) Vous vous êtes opposé au traitement de vos données personnelles et vous souhaitez en conséquence qu’elles soient effacées ;
                            </p>
                            <p>(iv) Vos données personnelles ont fait l'objet d'un traitement illicite ;
                            </p>
                            <p>(v) Vos données personnelles doivent être effacées pour respecter une obligation légale qui est prévue soit par le droit de l'Union Européenne soit par le droit français.
                            </p>
                            <p><strong>- Le droit à la limitation</strong> signifie que vous pouvez nous demander de procéder à la limitation du traitement de vos données personnelles :</p>
                            <p>(i) Lorsque vous contestez l’exactitude de vos données personnelles pendant une durée nous permettant de vérifier l’exactitude de celles-ci ;
                            </p>
                            <p>(ii) Lorsque suite à un traitement établi comme non conforme, vous préférez la limitation du traitement à l’effacement complet de vos données personnelles ;
                            </p>
                            <p>(iii) Lorsque nous n’avons plus besoin de vos données personnelles aux fins du traitement mais que celles-ci vous sont encore nécessaires pour la constatation, l’exercice ou la défense de droits en justice ;</p>
                            <p>(iv) Lorsque vous vous êtes opposé au traitement de vos données personnelles et vous souhaitez une limitation du traitement pendant la durée nous permettant de vérifier si le motif légitime que vous invoquez se justifie.</p>
                            <p>La limitation du traitement signifie que le traitement de vos données personnelles s’entendra alors du seul stockage de vos données personnelles correspondantes. Nous n’effectuerons alors plus aucune autre opération sur les données personnelles considérées.
                            </p>
                            <p><strong>- Le droit d’opposition</strong> signifie que vous pouvez vous opposer au traitement de vos données personnelles, lorsque ce traitement est fondé sur la poursuite de l’intérêt légitime de DEMLEEN. Le droit d’opposition s’exerce sous réserve de justifier d’un motif légitime tenant à votre situation particulière. Nous cesserons alors le traitement en cause sauf s’il existe des motifs légitimes et impérieux en justifiant la poursuite en conformité avec la réglementation applicable.
                            </p>
                            <p><strong>- Le droit de définir des directives relatives au sort de vos données après votre mort</strong> vous permet de faire connaître vos instructions concernant la conservation, l'effacement et la communication de vos données personnelles après votre décès.</p>
                            <p><strong>- Le droit à la portabilité</strong> signifie que vous pouvez nous demander, dans les conditions posées par la réglementation applicable, de recevoir vos données personnelles dans un format structuré, couramment utilisé et lisible par machine, et de vous les transmettre, ou de nous demander de les transmettre directement à un tiers de votre choix lorsque cela est juridiquement et techniquement possible.</p>
                            <p>Lorsque nous traitons vos données personnelles sur la base de votre consentement, vous disposez enfin de la faculté de retirer votre consentement à tout moment en vous adressant aux adresses indiquées dans la rubrique ’’Comment nous contacter’’ ou en cliquant sur le lien de désabonnement présent dans chacune de nos communications.</p>
                            <p>Toutefois, le retrait de votre consentement ne remet pas en cause la validité du traitement effectué avant ce retrait.</p>
                             <h3>VI. Les mises à jour de cette politique de confidentialité</h3>
                             <p>DEMLEEN se réserve le droit à tout moment de modifier ou mettre à jour, en tout ou en partie, la présente politique de confidentialité, en raison de la modification de la réglementation applicable en matière de protection des données personnelles ou des traitements de données effectués.</p>
                             <p>Toute modification substantielle de la politique de confidentialité vous sera notifiée par e-mail lorsque vous nous avez communiqué une adresse mail valide et sera publiée sur le site internet. Nous vous recommandons de prendre régulièrement connaissance de la présente politique de confidentialité afin d’avoir une parfaite connaissance de nos engagements en matière de sécurité et de protection de vos données personnelles.</p>
                             <h3>VII. Comment nous contacter</h3>
                             <p>Si vous avez des questions, des commentaires ou des suggestions, veuillez nous contacter en visitant la page contactez-nous ou en les soumettant à contact@demleen.com.</p>
                             <p>Ou par courrier postal à 5 AVENUE DU GENERAL DE GAULLE, 94160 ST MANDE, France.</p>
                             <p>Si vous n'êtes pas satisfait de la réponse apportée par DEMLEEN à une demande d’exercice de droits conformément à l’article V ci-dessus ou que vous souhaitez signaler une atteinte à la réglementation applicable en matière de protection des données, vous disposez du droit d’introduire une réclamation auprès de la CNIL par courrier (CNIL - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07) ou sur son site Internet (www.cnil.fr), ou auprès de l'autorité de protection des données du pays dans lequel vous résidez ou travaillez habituellement.</p>




                            <p>Demleen est une plate-forme web, disponible à l’adresse <a href='www.demleen.com'>www.demleen.com</a> (le « Site »)</p>
                            <p>Ce Site est édité par la Société Demleen, domiciliée au 5 Avenue du Général de Gaulle, 94160 Saint-Mandé, France, immatriculée au registre du commerce et des sociétés de Créteil sous le numéro d’identification 911783736, (ci-après « l’Editeur »). Demleen est accessible aux utilisateurs (le ou les « Utilisateurs »).</p>
                            <p>Demleen est hébergé par OVH, dont le siège social est situé au 2 rue kellermann BP 80157 59053 ROUBAIX CEDEX 1 - France. Cet hébergeur peut être contacté à cette adresse électronique : contact@ovh.com.</p>
                            <p>L’objet des présentes Conditions Générales d’Utilisation (les « Conditions » ou prises dans leur ensemble, le « Contrat ») est de définir les termes et les conditions régissant les relations entre les Utilisateurs et DEMLEEN. En cas de non-respect des termes des présentes Conditions, Demleen se réserve le droit de prendre toute mesure de nature à préserver ses intérêts et notamment à en assurer l'exécution.</p>
                            <p>L’accès et l’utilisation du site sont soumis à l’acceptation et au respect des présentes Conditions Générales d’Utilisation.</p>
                            <p>L’Éditeur se réserve le droit de modifier, à tout moment et sans préavis, le Site et des services ainsi que les présentes CGU, notamment pour s’adapter aux évolutions du Site par la mise à disposition de nouvelles fonctionnalités ou la suppression ou la modification de fonctionnalités existantes.</p>
                            <p>Il est donc conseillé à l’Utilisateur de se référer avant toute navigation à la dernière version des Conditions Générales d’Utilisation accessible à tout moment sur le Site.</p>
                            <p>Les présentes Conditions Générales d’Utilisation s’appliquent, en tant que de besoin, à toute déclinaison ou extension du Site sur les réseaux sociaux et/ou communautaires existants ou à venir.
                            </p>
                            <h3>Article 1er : Objet du site</h3>
                            <p>Le Site permet à l’Utilisateur d’accéder notamment aux services suivants : Site d'information (le ou les « Service(s) ») .
                            </p>
                            <p>DEMLEEN, selon les présentes Conditions, accorde aux Utilisateurs un droit d’accès limité révocable, non exclusif, non cessible aux Services à titre strictement personnel. Toute utilisation de la plate-forme contraire à sa finalité est strictement interdite et constitue un manquement aux présentes dispositions.
                            </p>
                            <h3>Article 2 : Accès au site
                            </h3>
                            <p>Pour être éligible au Service, l’Utilisateur peut être une personne physique ou morale.
                            </p>
                            <p>Demleen se réserve le droit de suspendre ou de refuser un accès d’un ou plusieurs Utilisateurs au Site.
                            </p>
                            <h3>Article 3 : Gestion du site
                            </h3>
                            <p>L’Editeur met en œuvre des solutions techniques nécessaires afin de permettre un accès continu au site pour l’Utilisateur. Toutefois il se réserve la possibilité de limiter ou suspendre l’accès à tout ou partie du Site à tout moment, et sans préavis. Une telle interruption pourra notamment être rendue nécessaire pour des raisons de maintenances du site ou de son contenu, ou pour toute autre raison jugée nécessaire au bon fonctionnement du site par l’Editeur.
                            </p>
                            <p>L’utilisation de la Plateforme requiert une connexion et un navigateur internet. La plateforme est accessible à l’adresse suivante : <a href='www.demleen.com'>www.demleen.com</a>.
                            </p>
                            <p>Tous matériels et logiciels nécessaires à l’accès à la Plateforme et à l’utilisation des Services restent exclusivement à la charge de l’Utilisateur.
                            </p>
                            <h3>Article 4 : Contact
                            </h3>
                            <p>L’utilisateur a la possibilité de contacter l’Editeur pour tout question ou demande d’information concernant le Site, ou tout signalement de contenu ou d’activités illicites par mail à l’adresse eryomi@demleen.com.
                            </p>
                            <h3>Article 5 : Utilisation des services de la plateforme
                            </h3>
                            <p><strong>Les Utilisateurs s’interdisent :
                            </strong></p>
                            <p>1. de transmettre, publier, distribuer, enregistrer ou détruire tout matériel, en particulier les contenus de Demleen, en violation des lois ou réglementations en vigueur concernant la collecte, le traitement ou le transfert d'informations personnelles ;
                            </p>
                            <p>2. de diffuser des données, informations, ou contenus à caractère diffamatoire, injurieux, obscène, offensant, violent ou incitant à la violence, ou à caractère politique, raciste ou xénophobe et de manière générale tout contenu qui serait contraire aux lois et règlements en vigueur ou aux bonnes mœurs ;
                            </p>
                            <p>3. de référencer ou créer des liens vers tout contenu ou information disponible depuis les sites de Demleen, sauf accord exprès, préalable et écrit de Demleen  ;
                            </p>
                            <p>4. d’utiliser des informations, contenus ou toutes données présentes sur le Site afin de proposer un service considéré comme concurrentiel à Demleen ;
                            </p>
                            <p>5. de vendre, échanger ou monnayer des informations, contenus ou données présentes sur la plateforme ou des Service proposés par la Plateforme, sans l’accord expresse et écrit de Demleen  ;
                            </p>
                            <p>6. de pratiquer de l’ingénierie inversée (Reverse Engineering), décompiler, désassembler, déchiffrer ou autrement tenter d’obtenir le code source en relation avec toute propriété intellectuelle sous-jacente utilisée pour fournir tout ou partie des Services ;
                            </p>
                            <p>7. d’utiliser des logiciels ou appareils manuels ou automates, robots de codage ou autres moyens pour accéder, explorer, extraire ou indexer toute page du Site ;
                            </p>
                            <p>8. de mettre en danger ou essayer de mettre en danger la sécurité numérique de Demleen. Cela comprend les tentatives de contrôler, scanner ou tester la vulnérabilité du système ou réseau ou de violer des mesures de sécurité ou d’authentification sans une autorisation préalable expresse ;
                            </p>
                            <p>9. de contrefaire ou d’utiliser les produits, les logos, les marques ou tout autre élément protégé par les droits de propriété intellectuel de Demleen ;</p>
                            <p>10. de simuler l’apparence ou le fonctionnement du Site, en procédant par exemple à un effet miroir ;
                            </p>
                            <p>11. de perturber ou troubler, directement ou indirectement Demleen, ou imposer une charge disproportionnée sur l’infrastructure du Site, ou de tenter de transmettre ou d’activer des virus informatiques via ou sur le Site ;
                            </p>
                            <p>Il est rappelé que les violations de la sécurité du système ou du réseau peuvent conduire à des poursuites civiles et pénales. Demleen vérifie l’absence de telle violation et peut faire appel aux autorités judiciaires pour poursuivre, le cas échéant, des Utilisateurs ayant participé à de telles violations.
                            </p>
                            <p>Les Utilisateurs s’engagent à utiliser le Site de manière loyale, conformément à sa finalité et aux dispositions légales, réglementaires, aux présentes Conditions et aux usages en vigueur.
                            </p>
                            <h3>Article 6 : Propriété intellectuelle</h3>
                            <p>L’ensemble du contenu du Site, notamment les designs, textes, graphiques, images, vidéos, informations, logos, icônes-boutons, logiciels, fichiers audio et autres appartient à Demleen, lequel est seul titulaire de l’intégralité des droits de propriété intellectuelle y afférents.
                            </p>
                            <p>Toute représentation et/ou reproduction et/ou exploitation partielle ou totale des contenus et Services proposés par Demleen, par quelque procédé que ce soit, sans l'autorisation préalable et écrite de Demleen, est strictement interdite et serait susceptible de donner lieu à des poursuites judiciaires.</p>
                            <h3>Article 7 : Données à caractère personnelles</h3>
                            <p>Toutes les données à caractère personnel dont dispose Demleen sont recueillies légalement et loyalement selon les modalités de la politique de confidentialité accessible à cette adresse : <a href='https://www.demleen.com/politique-de-confidentialit%C3%A9'>https://www.demleen.com/politique-de-confidentialit%C3%A9</a> .
                            </p>
                            <p>Ces données sont fournies par les Utilisateurs qui acceptent de manière volontaire et expresse les présentes Conditions autorisant Demleen à traiter, divulguer ou transférer ces données à tout tiers cela afin de permettre (i) à l’Utilisateur de profiter pleinement des Services et des fonctions proposés par le Site, (ii) prévenir toute fraude et /ou (iii) à des fins statistiques.
                            </p>
                            <p>Les données à caractère personnel sont stockées par Demleen en vue de leur traitement dans le cadre de l’utilisation des Services. Elles sont conservées aussi longtemps que nécessaire pour l’apport des Services et fonctions offerts par le Site.
                            </p>
                            <p>L’Utilisateur reste toujours propriétaire des informations le concernant qu’il transmet à Demleen. Il dispose, conformément à la loi n° 78-17 du 6 janvier 1978 selon sa version consolidée au 24 mars 2020, d’un droit d’accès, de rectification et de suppression des données à caractère personnel le concernant, ainsi que du droit de s’opposer à la communication de ces données à des tiers pour de justes motifs.
                            </p>
                            <p>L’Utilisateur pourra exercer ses droits en écrivant à l’adresse électronique suivante : eryomi@demleen.com.
                            </p>
                            <p>Une réponse à la requête de l’Utilisateur lui sera adressée dans un délai de 30 jours.
                            </p>
                            <h3>Article 8 : Responsabilité
                            </h3>
                            <p>Il est rappelé que les données publiées par les Utilisateurs et les informations partagées par ces derniers peuvent être captées et exploitées par d’autres Utilisateurs ou des tiers. En ce sens, Demleen ne garantit pas le respect de la propriété de ces données, il incombe à l’Utilisateur de prendre l’ensemble des dispositions nécessaires afin que soit préservée la propriété de ses données.
                            </p>
                            <p>Demleen ne garantit pas le fonctionnement sans interruption ou sans erreur de fonctionnement des Services, en particulier, la responsabilité de Demleen ne saurait être engagée en cas d’interruption d’accès à la Plateforme en raison d’opérations de maintenance, de mises à jour ou d’améliorations techniques.
                            </p>
                            <p>En tout état de cause, Demleen ne saurait en aucune circonstance être responsable au titre des pertes ou dommages indirects ou imprévisibles de l’Utilisateur ou de tout tiers, ce qui inclut notamment tout gain manqué, tout investissement malheureux, inexactitude ou corruption de fichiers ou données, préjudice d’image ou commercial, perte de chiffre d’affaires ou bénéfice, perte de clientèle ou perte de chance lié à quelque titre et sur quelque fondement que ce soit.
                            </p>
                            <p>En outre, Demleen ne saurait être responsable de tout retard ou inexécution du présent Contrat justifié par un cas de force majeure, telle qu’elle est définie par la jurisprudence des cours et tribunaux français.
                            </p>
                            <h3>Article 9 : Convention de preuves
                            </h3>
                            <p>Les systèmes et fichiers informatiques font foi dans les rapports entre Demleen  et l’Utilisateur.
                            </p>
                            <p>Ainsi, Demleen  pourra valablement produire dans le cadre de toute procédure, aux fins de preuve les données, fichiers, programmes, enregistrements ou autres éléments, reçus, émis ou conservés au moyen des systèmes informatiques exploités, sur tous supports numériques ou analogiques, et s’en prévaloir sauf erreur manifeste.
                            </p>
                            <h3>Article 10 : Indivisibilité
                            </h3>
                            <p>Le fait que l’une quelconque des dispositions du Contrat soit ou devienne illégale ou inapplicable n’affectera en aucune façon la validité ou l’applicabilité des autres stipulations du Contrat.
                            </p>
                            <h3>Article 11 : Règlement des différents
                            </h3>
                            <p>La conclusion, l’interprétation et la validité du présent Contrat sont régis par la loi française, quel que soit le pays d’origine de l’Utilisateur ou le pays depuis lequel l’Utilisateur accède à Demleen et nonobstant les principes de conflits de lois.
                            </p>
                            <p>Dans l’hypothèse où un différend portant sur la validité, l’exécution ou l’interprétation du présent Contrat et serait porté devant les juridictions civiles, il sera soumis à la compétence exclusive des tribunaux français auquel il est fait expressément attribution de compétence, même en cas de référé ou de pluralité de défendeurs.
                            </p>
                            <p>L’Utilisateur est informé qu’il peut en tout état de cause recourir à une médiation conventionnelle ou à tout mode alternatif de règlement des différends (conciliation par exemple) en cas de contestation.
                            </p>
                            <h3>Article 12 : Durée des conditions générales d’utilisations
                            </h3>
                            <p>Les présentes conditions générales d’utilisation sont conclues pour une durée indéterminée, l’Utilisateur est tenu de les respecter à compter du début de son utilisation des Services.
                            </p>
                            <p>L’Editeur se réserve la possibilité de modifier ce document à tout moment et sans préavis. Les utilisateurs seront informés de chaque mise à jour du document
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, profileSuccess } from "../../store/actions"

// import images
import logoLightPng from "../../assets/images/logo-sm.png"
import logoDark from "../../assets/images/logo-sm.png"
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import { post } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Login = props => {
  const history = useHistory();
  const [isVisi, setIsVisi] = useState(false);
  // handleValidSubmit
  const { i18n, t } = useTranslation();

  //  console.log('login ',props)

  const handleValidSubmit = (e, values) => {
    // setLoading(true);
    const body = {
      ...values
    }
    post('/login', body)
      .then((res => {
        // setLoading(false);
        if (res?.statusCode == 200) {
          // console.log('request on login', res?.data);
          toast.success(res?.message)
          props.loginUser(res?.user, props.history);
          props.profileSuccess(res?.user);
          localStorage.setItem("user", JSON.stringify(res?.data));

          history.push('/dashboard')
        } else if (res?.statusCode == 401) {
          toast.error(res?.error)
        } else {
          console.log(res?.error);
          toast.error(res?.error)
        }
      }))
      .catch(error => {
        // setLoading(false);
        console.log('error while login', error);
        console.log('Something went wrong');
        toast.error('Something went wrong')
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("Login")} | Demleen</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-2">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="60" className="auth-logo-dark" />
                      <img src={logoLightPng} alt="" height="60" className="auth-logo-light" />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">{t("Welcome Back")}</h4>
                    <p className="text-muted text-center">{t("Signin to continue with Demleen")}</p>
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {/* {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null} */}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"

                          className="form-control"
                          placeholder="Email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3 password">
                        <AvField
                          name="password"
                          label={t("Password")}
                          type={isVisi ? "text" : "password"}
                          required
                          placeholder={t("Password")}
                        />
                        <i className="mdi mdi-eye" onClick={() => setIsVisi(!isVisi)} />
                      </div>
                      <div className="mb-3">
                        <AvField type="select" name="type" value={"SME"} label={t("Login as")} required>
                          {/* <option value="">Select type</option> */}
                          <option value={"SME"} selected>{t("SME1")}</option>
                          <option value={"Investor"}>{t("Investor")}</option>
                          <option value={"Admin"}>Admin</option>


                        </AvField>
                      </div>
                      <div className="mb-3 row mt-4">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              {t("Remember me")}
                            </label>
                          </div>
                        </div>
                        <div className="col-6 text-end">
                          <button className="btn btn_bg w-md waves-effect waves-light" type="submit">{t("Log in")}</button>
                        </div>
                      </div>
                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock"></i>{t("Forgot your password")}</Link>
                        </div>
                      </div>

                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t("Don’t have an account ")}{" "}
                  <Link
                    to="register"
                    className="text-primary"
                  >
                    {" "}
                    {t("Signup now")}{" "}
                  </Link>{" "}
                </p>

                <p>
                  © {new Date().getFullYear()} Demleen
                  <span className="d-none d-sm-inline-block"> - All Rights Reserved. <i className="mdi mdi-heart text-danger"></i> Design by <a href='https://www.rajmith.com/'>RAJMITH</a> .</span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, profileSuccess })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
import usFlag from "../assets/images/flags/us.jpg"
import spain from "../assets/images/flags/spain.jpg"
import germany from "../assets/images/flags/germany.jpg"
import italy from "../assets/images/flags/italy.jpg"
import french from "../assets/images/flags/french.jpg"

const languages = {
  fs: {
    label: "French",
    flag: french,
  },
  en: {
    label: "English",
    flag: usFlag,
  }
}

export default languages

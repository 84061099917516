
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { AvField, AvForm, AvInput, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation'
import { MultiSelect } from "react-multi-select-component";


// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

// actions
import { editProfile, resetProfileFlag } from "../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
function SME(props) {
    const { i18n, t } = useTranslation();
    const [activeTab, setActiveTab] = useState('1')
    const [sdg, setSdg] = useState([]);
    const [region, setRegion] = useState([]);
    const [investment, seInvestment] = useState([]);
    const [businessStructure, setBusinessStructure] = useState([]);
    const options = [

        { label: t("SDG 1 - No poverty"), value: t("SDG 1 - No poverty") },
        { label: t("SDG 2 - Zero hunger"), value: t("SDG 2 - Zero hunger") },
        { label: t("SDG 3 - Good health and well-being"), value: t("SDG 3 - Good health and well-being") },
        { label: t("SDG 4 - Quality education"), value: t("SDG 4 - Quality education") },
        { label: t("SDG 5 - Gender equality"), value: t("SDG 5 - Gender equality") },
        { label: t("SDG 6 - Clean water and sanitation"), value: t("SDG 6 - Clean water and sanitation") },
        { label: t("SDG 7 - Affordable and clean energy"), value: t("SDG 7 - Affordable and clean energy") },
        { label: t("SDG 8 - Decent work and economic growth"), value: t("SDG 8 - Decent work and economic growth") },
        { label: t("SDG 9 - Industry innovation and infrastructure"), value: t("SDG 9 - Industry innovation and infrastructure") },
        { label: t("SDG 10 - Reduced inequalities"), value: t("SDG 10 - Reduced inequalities") },
        { label: t("SDG 11 - Sustainable cities and communities"), value: t("SDG 11 - Sustainable cities and communities") },
        { label: t("SDG 12 - Responsible consumption and production"), value: t("SDG 12 - Responsible consumption and production") },
        { label: t("SDG 13 - Climate action"), value: t("SDG 13 - Climate action") },
        { label: t("SDG 14 - Life below water"), value: t("SDG 14 - Life below water") },
        { label: t("SDG 15 - Life on land"), value: t("SDG 15 - Life on land") },
        { label: t("SDG 16 - Peace, justice and strong institutions"), value: t("SDG 16 - Peace, justice and strong institutions") },
        { label: t("SDG 17 - Partnership for the goals"), value: t("SDG 17 - Partnership for the goals") },
    ];

    const BusinessSector = [
        { label: t("Agriculture, Plantations, Other rural sectors"), value: t("Agriculture, Plantations, Other rural sectors") },
        { label: t("Basic Metal Production"), value: t("Basic Metal Production") },
        { label: t("Business & Professional services"), value: t("Business & Professional services") },
        { label: t("Chemical industries"), value: t("Chemical industries") },
        { label: t("Construction, Building Materials"), value: t("Construction, Building Materials") },
        { label: t("Education"), value: t("Education") },
        { label: t("Financial services"), value: t("Financial services") },
        { label: t("Food and drink industries"), value: t("Food and drink industries") },
        { label: t("Forestry, Wood, Pulp and paper"), value: t("Forestry, Wood, Pulp and paper") },
        { label: t("Health services"), value: t("Health services") },
        { label: t("Hotels, Tourism, Catering"), value: t("Hotels, Tourism, Catering") },
        { label: t("IT, Telecommunications services"), value: t("IT, Telecommunications services") },
        { label: t("Mechanical and electrical engineering"), value: t("Mechanical and electrical engineering") },
        { label: t("Mining (coal, other mining)"), value: t("Mining (coal, other mining)") },
        { label: t("Oil and gas production, Oil refining"), value: t("Oil and gas production, Oil refining") },
        { label: t("Pharmaceuticals"), value: t("Pharmaceuticals") },
        { label: t("Public services"), value: t("Public services") },
        { label: t("Publishing, Communication, Multimedia"), value: t("Publishing, Communication, Multimedia") },
        { label: t("Technology"), value: t("Technology") },
        { label: t("Textile, Clothing,Footwear"), value: t("Textile, Clothing,Footwear") },
        { label: t("Trade, Trading, Distribution"), value: t("Trade, Trading, Distribution") },
        { label: t("Transport, Logistics,Mobility"), value: t("Transport, Logistics,Mobility") },
        { label: t("Utilities (water, gas, electricity)"), value: t("Utilities (water, gas, electricity)") },
    ]
    const regionoptions = [
        { label: t("Western Africa"), value: t("Western Africa") },
        { label: t("Eastern Africa"), value: t("Eastern Africa") },
        { label: t("Northern Africa"), value: t("Northern Africa") },
        { label: t("Southern Africa"), value: t("Southern Africa") },
        { label: t("Central Africa"), value: t("Central Africa") },
    ]

    const investmentoptions = [
        { label: t("Equity"), value: t("Equity") },
        { label: t("Debt / Loans"), value: t("Debt / Loans") },
        { label: t("Guarantees"), value: t("Guarantees") },
        { label: t("Grants"), value: t("Grants") },
    ]
    const sdgFun = (e) => {
        setSdg(e)
        console.log(e)
    }
    return (
        <div className='body_front'>
            <Header />
            <div class="breadcrumb-area shadow dark bg-fixed text-light inner_banner">
                <div class="container">
                    <div class="row align-center">
                        <div class="col-lg-6">
                            <h2>SME</h2>
                        </div>

                    </div>
                </div>
            </div>

            <div class="contact-area overflow-hidden default-padding">
                <div class="container">
                    <div class="row justify-content-center">

                        <div class="col-lg-8 contact-form-box">
                            <div class="content">
                                <div class="heading">
                                    <h2>Need Help?</h2>
                                    <p>Reach out to the world’s most reliable IT services.</p>
                                </div>
                                <AvForm action="assets/mail/contact.php" method="POST" class="contact-form">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <AvField class="form-control" id="name" name="fname" placeholder="First Name*" type="text" />
                                                <span class="alert-error"></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <AvField class="form-control" id="name" name="lname" placeholder="Last Name*" type="text" />
                                                <span class="alert-error"></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <AvField class="form-control" id="email" name="email" placeholder="Email*" type="email" />
                                                <span class="alert-error"></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div className="form-grou">

                                                <AvField class="form-control" id="text" name="eur" placeholder="Amount sought*" type="number" />
                                                <span class="alert-error"></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div className="form-group">
                                                <label className='label_text'>{t("Industrie")}</label>
                                                <MultiSelect
                                                    options={BusinessSector} // Options to display in the dropdown
                                                    hasSelectAll={true}
                                                    value={businessStructure}
                                                    onChange={setBusinessStructure}
                                                    overrideStrings={{
                                                        selectSomeItems: t("Select Some items..."),
                                                        allItemsAreSelected: t("All Items are Selected"),
                                                        selectAll: t("Select All"),
                                                        search: t("Search"),
                                                    }}

                                                />
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div className="form-group">
                                                <label className='label_text'>{t("Type of financing sought")}</label>
                                                <MultiSelect
                                                    options={BusinessSector} // Options to display in the dropdown
                                                    hasSelectAll={true}
                                                    value={businessStructure}
                                                    onChange={setBusinessStructure}
                                                    overrideStrings={{
                                                        selectSomeItems: t("Select Some items..."),
                                                        allItemsAreSelected: t("All Items are Selected"),
                                                        selectAll: t("Select All"),
                                                        search: t("Search"),
                                                    }}

                                                />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div className="form-group mb-3">
                                                <label className='label_text'>{t("The Sustainable Development Goals that affect your business")}</label>
                                                <MultiSelect
                                                    options={investmentoptions} // Options to display in the dropdown
                                                    hasSelectAll={true}
                                                    value={investment}
                                                    onChange={seInvestment}
                                                    overrideStrings={{
                                                        selectSomeItems: t("Select Some items..."),
                                                        allItemsAreSelected: t("All Items are Selected"),
                                                        selectAll: t("Select All"),
                                                        search: t("Search"),
                                                    }}

                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div className="form-group form_group">
                                                <label className='label_text'>{t("Do you have your last 3 annual financial items?*")}</label>
                                                <AvRadioGroup inline name="existance" >
                                                    <AvRadio label={t("Yes")} value="Yes" />
                                                    <AvRadio label={t("No")} value="No" />
                                                </AvRadioGroup>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group comments">
                                                <AvField type="textarea" rows={5} class="form-control" id="comments" name="comments" placeholder="Please describe what you need." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <button type="submit" className='btn' name="submit" id="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </div>

                                    <div class="col-md-12 alert-notification">
                                        <div id="message" class="alert-msg"></div>
                                    </div>
                                </AvForm>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


SME.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {

    const { error, success } = state.Profile
    return { error, success }
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag, setBreadcrumbItems })(SME)
)
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"






const Index = props => {

    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [idx, setidx] = useState(1)

    const { i18n, t } = useTranslation();

    const [sdg, setSdg] = useState([]);
    const [region, setRegion] = useState([]);
    const [investment, seInvestment] = useState([]);
    const [businessStructure, setBusinessStructure] = useState([]);
    const [lang, setLang] = useState(null);
    const [wastemanagement, setWastemanagement] = useState(false);
    const [hSSmanagement, setHSSmanagement] = useState(false);
    const [administrationBoard, setAdministrationBoard] = useState(false);
    const [explicitdisclosure, setExplicitdisclosure] = useState(false);
    const [separation, setSeparation] = useState(false);
  
    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"))
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                setname(obj.displayName)
                setemail(obj.email)
                setidx(obj.uid)
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                setname(obj.username)
                setemail(obj.email)
                setidx(obj.uid)
            }
            setTimeout(() => {
                props.resetProfileFlag();
            }, 3000);
        }
    }, [props.success])

    useEffect(() => {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
        if (currentLanguage)
            setLang(currentLanguage)
    }, [lang])


    const sdgFun = (e) => {
        setSdg(e)
        console.log(e)
    }

    const getAge = (e) => {
        let userDateinput = e.target.value;
        let birthDate = new Date(userDateinput);
        // console.log(" birthDate"+ birthDate);

        // get difference from current date;
        let difference = Date.now() - birthDate.getTime();

        let ageDate = new Date(difference);
        // console.log(" ageDate"+ ageDate);
        let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
        // console.log(" calculatedAge", calculatedAge);
        setAge(calculatedAge)
    }

    // console.log('age',age)

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "ESG", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('ESG', breadcrumbItems)
    })

    function handleValidSubmit(event, values) {
        props.editProfile(values)
    }

    const wasteManagement = (e) =>{
        if(e.target?.value == "Yes"){
            setWastemanagement(true)
        }else{
            setWastemanagement(false)
        }
    }
    const administrationBoardFun = (e) =>{
        if(e.target?.value == "Yes"){
            setAdministrationBoard(true)
        }else{
            setAdministrationBoard(false)
        }
    }
    const explicitdisclosureFun = (e) =>{
        if(e.target?.value == "Yes"){
            setExplicitdisclosure(true)
        }else{
            setExplicitdisclosure(false)
        }
    }
    const separationFun = (e) =>{
        if(e.target?.value == "Yes"){
            setSeparation(true)
        }else{
            setSeparation(false)
        }
    }


     const HSSmanagementFun = (e) =>{
        if(e.target?.value == "Yes"){
            setHSSmanagement(true)
        }else{
            setHSSmanagement(false)
        }
     }

    return (
        <React.Fragment>
            <MetaTags>
                <title>ESG | Demleen </title>
            </MetaTags>


            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
            >
                <h4 className="card-title mb-4">{t("Environment")}</h4>
                <p className='subheading'>{t("Environmental impact is a key topic and many businesses communicate on their energy consumption levels to demonstrate their ability to manage and or mitigate these.")}</p>
                <Card>
                    <CardBody>

                        <Row>
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="fname"
                                        label={t("Total energy consumption (MWh/m€) ?")}
                                        value={name}
                                        className="form-control"
                                        // placeholder={t("First Name")}
                                        type="number"

                                    />

                                </div>
                            </Col>
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="lname"
                                        label={t("Water consumption ?")}
                                        value={name}
                                        className="form-control"
                                        // placeholder={t("Last Name")}
                                        type="number"

                                    />

                                </div>
                            </Col>

                            <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <label>{t("Existance of a waste management policy for the organisation")}</label>
                                    <AvRadioGroup inline name="existance" onChange={wasteManagement}>
                                        <AvRadio label={t("Yes")} value={t("Yes")} />
                                        <AvRadio label={t("No")} value={t("No")} />
                                    </AvRadioGroup>
                                </div>
                            </Col>
                            {
                                wastemanagement == true && <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="activity_description"

                                        className="form-control"
                                        placeholder={t("Short Description")}
                                        type="textarea"

                                    />

                                </div>
                            </Col>
                            }

                        </Row>

                    </CardBody>
                </Card>


                <h4 className="card-title mb-4">{t("Social")}</h4>
                <p className='subheading'>{t("The social impact of your entreprise is a key aspect to highlight. The following questions are created in that context.")}</p>
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="BusinessLegalName"
                                        label={t("Number of current employees ?")}

                                        className="form-control"
                                        // placeholder="Business Legal Name"
                                        type="number"

                                    />

                                </div>
                            </Col>
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="company_name"
                                        label={t("Number of women employed ?")}

                                        className="form-control"
                                        // placeholder="Company Name"
                                        type="number"

                                    />

                                </div>
                            </Col>
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="start_date"
                                        label={t("Number of women employed as key decision partner ?")}
                                        value={name}
                                        className="form-control"
                                        // placeholder="Business Start Date"
                                        type="number"

                                    />

                                </div>
                            </Col>
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="registration_number"
                                        label={t("Number of employees with a disability ?")}
                                        className="form-control"
                                        // placeholder="Registration Number"
                                        type="number"

                                    />

                                </div>
                            </Col>
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="taxId"
                                        label={t("Number of permanent employees ?")}
                                        className="form-control"
                                        // placeholder="Tax Id"
                                        type="number"

                                    />

                                </div>
                            </Col>

                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="company_address"
                                        label={t("Number of non-permament employees ?")}
                                        className="form-control"
                                        // placeholder="Company Address"
                                        type="number"

                                    />

                                </div>
                            </Col>
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="state"
                                        label={t("Number of employees under 35 years old ?")}
                                        className="form-control"
                                        // placeholder="State"
                                        type="number"

                                    />

                                </div>
                            </Col>
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="city"
                                        label={t("Average number of training hours per employee (total training hours / total workforce) ?")}
                                        className="form-control"
                                        // placeholder="City"
                                        type="number"

                                    />

                                </div>
                            </Col>

                            <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <label>{t("Existence of an HSS management system (health, safety, security) ?")}</label>
                                    <AvRadioGroup inline name="HSSmanagement" onChange={HSSmanagementFun}>
                                        <AvRadio label={t("Yes")} value={t("Yes")} />
                                        <AvRadio label={t("No")} value={t("No")} />
                                    </AvRadioGroup>
                                </div>
                            </Col>
                            {
                                hSSmanagement == true && <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="activity_description"

                                        className="form-control"
                                        placeholder={t("Short Description")}
                                        type="textarea"

                                    />

                                </div>
                            </Col>
                            }
                            <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="website"
                                        label={t("Number of beneficiaries related to the good pursuit of the business (number of children, or spouses or parents financially supported bu the employees)")}
                                        className="form-control"
                                        // placeholder="City"
                                        type="number"

                                    />

                                </div>
                            </Col>



                        </Row>
                    </CardBody>
                </Card>
                <h4 className="card-title mb-4">{t("Governance")}</h4>
                <p className='subheading'>{t("Having a governance policy is considered as a key step for the sustainability of any business on the path to growth. It also reassures financial partners.")}</p>
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="fname"
                                        label={t("How many persons do own your company ?")}
                                        value={name}
                                        className="form-control"
                                        // placeholder={t("First Name")}
                                        type="number"

                                    />

                                </div>
                            </Col>
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="lname"
                                        label={t("How much is owned by the first shareholder ?")}
                                        value={name}
                                        className="form-control"
                                        // placeholder={t("Last Name")}
                                        type="number"

                                    />

                                </div>
                            </Col>

                            <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <label>{t("Have you established an Administration Board ?")}</label>
                                    <AvRadioGroup inline name="administrationBoard" onChange={administrationBoardFun}>
                                        <AvRadio label={t("Yes")} value={t("Yes")} />
                                        <AvRadio label={t("No")} value={t("No")} />
                                    </AvRadioGroup>
                                </div>
                            </Col>
                            {
                                administrationBoard == true && <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="activity_description"

                                        className="form-control"
                                        placeholder={t("Short Description")}
                                        type="textarea"

                                    />

                                </div>
                            </Col>
                            }
                            <Col lg={6} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="lname"
                                        label={t("Number of independent board members for your company ?")}
                                        value={name}
                                        className="form-control"
                                        // placeholder={t("Last Name")}
                                        type="number"

                                    />

                                </div>
                            </Col>
                            <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <label>{t("Have you done a explicit disclosure of formalised business Code of Conduct and Corruption Policy, that is available?")}</label>
                                    <AvRadioGroup inline name="explicitdisclosure" onChange={explicitdisclosureFun}>
                                        <AvRadio label={t("Yes")} value={t("Yes")} />
                                        <AvRadio label={t("No")} value={t("No")} />
                                    </AvRadioGroup>
                                </div>
                            </Col>
                            {
                                explicitdisclosure == true && <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="activity_description"

                                        className="form-control"
                                        placeholder={t("Short Description")}
                                        type="textarea"

                                    />

                                </div>
                            </Col>
                            }
                            <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <label>{t("Is there a separation of the roles of CEO and Chair of Board ?")}</label>
                                    <AvRadioGroup inline name="separation" onChange={separationFun}>
                                        <AvRadio label={t("Yes")} value={t("Yes")} />
                                        <AvRadio label={t("No")} value={t("No")} />
                                    </AvRadioGroup>
                                </div>
                            </Col>
                            {
                                separation == true && <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="activity_description"

                                        className="form-control"
                                        placeholder={t("Short Description")}
                                        type="textarea"

                                    />

                                </div>
                            </Col>
                            }
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <div className="text-center">
                            <Button type="submit" className='btn_bg' color="">
                                {t("Save")}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </AvForm>


        </React.Fragment>
    )
}

Index.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {

    const { error, success } = state.Profile
    return { error, success }
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag, setBreadcrumbItems })(Index)
)